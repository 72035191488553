/* Copyright Levelise Ltd 2019-2020 */
import React, { Component } from 'react';

const AdminConext = React.createContext({
    status: {},
    error: null,
    setStatus: () => {},
    setError: () => {},
    clearState: () => {}
})

export default AdminConext;

export class AdminProvider extends Component {
    state = {
        status: {},
        error: null
    }

    setStatus = status => this.setState({ status });
    setError = error => this.setState({ error });
    clearState = () => this.setState({ status: {}, error: null });

    render(){
        const contextValue = {
            status: this.state.status,
            error: this.state.error,

            setStatus: this.setStatus,
            setError: this.setError,
            clearState: this.clearState,
        }
        return (
            <AdminConext.Provider value={contextValue}>
                {this.props.children}
            </AdminConext.Provider>
        )
    }
}