/* Copyright Levelise Ltd 2024 */
import React from 'react';
import { colors } from '../../utils/chart';
import './index.css';

const SwitchComponent = ({ onChange, controlState, values, height, width, marginTop, fontSize, name }) => (
	<div className='switch-container' style={{
		height: height || 18,
		maxWidth: width || 250,
		minWidth: width || 150,
		display: 'flex',
		borderRadius: 3,
		padding: 2,
		backgroundColor: 'rgba(0,0,0,0.144)',
		boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1)',
		marginTop: marginTop || 0
	}}>
		{values.map((ele, idx) => (
			<div
				key={idx}
				className="switch-child-button switch-child-con-1"
				data-cy={`${(name || '') + idx }switch`}
				onClick={() => onChange(ele.value)}
				style={{
					flexGrow: 1,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: controlState === ele.value ? colors.levelise : colors.gray,
					borderRadius: controlState === ele.value ? 3 : 0,
					borderLeftWidth: values.length > 2 && idx !== 0 ? 0.1 : 0,
					borderLeftStyle: 'solid',
					borderLeftColor: '#ccc'
				}}
			>
				<span
					style={{
						fontSize: fontSize || 10,
						color: controlState === ele.value ? colors.white : '#000',
						fontFamily: controlState === ele.value ? 'Inter-Bold' : 'Inter-Medium',
					}}
				>
					{ele.label}
				</span>
			</div>
		))}
	</div>
);

export default SwitchComponent;
