/* Copyright Levelise Ltd 2020 */
import React from 'react';
import cx from 'classnames';
import { Button } from '../Form/index';
import './index.css';  

const Popup = ({type, text, onConfirm, confirm, onClose, close, textStyle, disableConfirm=false}) => {  

return (  
        <div className='popup'>  
            <div className={cx('popup-inner', type)}>  
                <p className={cx('popup-text', type)} style={textStyle}>{text}</p>
                <div className='popup-btn-wrapper'>
                    <Button className={cx('btn-confirm', type)} onClick={() => onConfirm()} disabled={disableConfirm}>{confirm}</Button> 
                    <Button className={cx('btn-cancel', type)} onClick={() => onClose()}>{close}</Button>  
                </div>
            </div>  
        </div>  
    );
}  

export default Popup;