/* Copyright Levelise Ltd 2020-2022 */
import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { getUnixTime, subDays } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { getSinceAndBefore } from '../../utils/utils';
import cx from 'classnames';
import { Chart } from 'chart.js';
import FleetService from '../../services/fleet-service';
import FleetContext from '../../contexts/FleetContext';

import DemandResponseChart from './charts/demandResponse';
import Ppm from './charts/ppm';
import Dispatch from './charts/dispatch';

import { CONTRACT_TIMEFRAME, TIME_FRAMES, fleet, timestampSec, timestampDay, resolutions } from '../../utils/constants';
import { filterDataByTimeframe } from '../../utils/utils';
import './index.css';

const days = {
	[TIME_FRAMES.thirty_six_months]: 1096,
	[TIME_FRAMES.twelve_months]: 366,
	[TIME_FRAMES.three_months]: 91,
};

const half_hours = {
	[TIME_FRAMES.twenty_one_days]: 1440 * 60 * 21,
	[TIME_FRAMES.one_week]: 1440 * 60 * 7,
};

const minutes = {
	[TIME_FRAMES.twenty_four_hours]: 1440 * 60,
	[TIME_FRAMES.twelve_hours]: 720 * 60,
	[TIME_FRAMES.six_hours]: 360 * 60,
	[TIME_FRAMES.one_hour]: 60 * 60,
	[TIME_FRAMES.fifteen_minutes]: 15 * 60,
};

const FleetPerformanceChart = ({ view, performance, showDistinct, showPerformance }) => {
	const fleetContext = useContext(FleetContext);
	const intervalId = useRef();
	const hasFmData = useRef(null);
	const [fmData, setfmData] = useState({ hasFmData: false, contract: null, reports: [], ppm: [] });
	const [data, setData] = useState({
		type: '',
		contract: null,
		timeFrame: '',
		reports: [],
		ppm: [],
		updated: false,
		resolution: '',
	});

	const fetchRecords = useCallback(
		async (resolution, since = null, before = null) => {
			if (since !== null && before !== null && since > before) {
				return new Promise((resolve, reject) => resolve([]));
			}

			if (fleetContext.currentDataType === fleet || fleetContext.currentDataType === '') {
				try {
					const res = await FleetService.getFleetRecords(resolution, since, before);
					return res;
				} catch (err) {
					fleetContext.setError(err);
					return await Promise.reject(err);
				}
			}

			try {
				const res_1 = await FleetService.getAggFacilityRecords(
					fleetContext.currentDataType,
					resolution,
					since,
					before
				);
				return res_1;
			} catch (err_1) {
				fleetContext.setError(err_1);
				return await Promise.reject(err_1);
			}
		},
		[fleetContext.currentDataType]
	);

	const fetchPPM = useCallback(
		async (resolution, since = null, before = null) => {
			if (since !== null && before !== null && since > before) {
				return new Promise((resolve, reject) => resolve([]));
			}

			if (fleetContext.currentDataType !== fleet && fleetContext.currentDataType !== '') {
				try {
					const res = await FleetService.getAggFacilityPpm(
						fleetContext.currentDataType,
						resolution,
						since,
						before
					);
					return res;
				} catch (err) {
					fleetContext.setError(err);
					return await Promise.reject(err);
				}
			}
			return new Promise((resolve, reject) => resolve([]));
		},
		[fleetContext.currentDataType]
	);

	const getDispatch = useCallback(() => {
		for (let i = 0; i < fleetContext.aggFacilities.length; i++) {
			const parentAf = fleetContext.aggFacilities[i];
			if (parentAf.name === fleetContext.currentDataType) {
				return !!parentAf.contracts.length ? parentAf.contracts[0].dispatch : 0;
			}

			if (parentAf.hasOwnProperty('constituents')) {
				const childAf = parentAf.constituents.find((_af) => _af['name'] === fleetContext.currentDataType);
				if (!!childAf) {
					return !!childAf.contracts.length ? childAf.contracts[0].dispatch : 0;
				}
			}
		}
		return 0;
	}, [fleetContext.aggFacilities, fleetContext.currentDataType]);

	const doUpdate = () => {
		return !(
			hasFmData.current ||
			Object.values(Chart.instances).some((ci) => ci.tooltip?._active?.length) ||
			Object.values(Chart.instances).some((ci) => ci.getZoomLevel() > 1.0)
		);
	};

	const handleSetData = useCallback(
		(type, contract, timeFrame, reports, ppm, updated = false, resolution = '') => {
			setData({ type, contract, timeFrame, reports, ppm, updated, resolution });
			if (fmData.hasFmData) {
				hasFmData.current = false;
				setfmData({ hasFmData: false, contract: null, reports: [], ppm: [] });
			}
		},
		[fmData.hasFmData]
	);

	const handleTimeframeChange = useCallback(() => {
		const dispatch = getDispatch();
		const type = fleetContext.currentDataType;
		const timeFrame = fleetContext.selectedTimeFrame;

		// eslint-disable-next-line default-case
		switch (timeFrame) {
			case TIME_FRAMES.all:
				Promise.all([fetchRecords(resolutions.week), fetchPPM(resolutions.week)]).then((d) => {
					if (!!d) {
						handleSetData(type, dispatch, timeFrame, d[0], d[1], false, resolutions.week);
					}
				});
				break;
			case TIME_FRAMES.thirty_six_months:
			case TIME_FRAMES.twelve_months:
			case TIME_FRAMES.three_months:
				Promise.all([fetchRecords(resolutions.day), fetchPPM(resolutions.day)]).then((d) => {
					if (!!d) {
						const filteredData = filterDataByTimeframe(d[0], days[timeFrame], timestampDay);
						const filteredPPM = filterDataByTimeframe(d[1], days[timeFrame], timestampDay);
						handleSetData(type, dispatch, timeFrame, filteredData, filteredPPM, false, resolutions.day);
					}
				});
				break;
			case TIME_FRAMES.twenty_one_days:
			case TIME_FRAMES.one_week:
				Promise.all([fetchRecords(resolutions.half_hour), fetchPPM(resolutions.half_hour)]).then((d) => {
					if (!!d) {
						const filteredData = filterDataByTimeframe(d[0], half_hours[timeFrame], timestampSec);
						const filteredPPM = filterDataByTimeframe(d[1], half_hours[timeFrame], timestampSec);
						handleSetData(type, dispatch, timeFrame, filteredData, filteredPPM, false);
					}
				});
				break;
			case TIME_FRAMES.twenty_four_hours:
			case TIME_FRAMES.six_hours:
			case TIME_FRAMES.one_hour:
				Promise.all([fetchRecords(resolutions.minute), fetchPPM(resolutions.minute)]).then((d) => {
					if (!!d) {
						if (timeFrame !== TIME_FRAMES.twenty_four_hours) {
							d[0] = filterDataByTimeframe(d[0], minutes[timeFrame], timestampSec);
							d[1] = filterDataByTimeframe(d[1], minutes[timeFrame], timestampSec);
						}
						handleSetData(type, dispatch, timeFrame, d[0], d[1], false);
					}
				});

				intervalId.current = setInterval(() => {
					if (
						fmData.hasFmData ||
						Object.values(Chart.instances).some((ci) => ci.tooltip?._active?.length) ||
						Object.values(Chart.instances).some((ci) => ci.getZoomLevel() > 1.0)
					)
						return;

					Promise.all([fetchRecords(resolutions.minute), fetchPPM(resolutions.minute)]).then((d) => {
						if (!!d) {
							if (timeFrame !== TIME_FRAMES.twenty_four_hours) {
								d[0] = filterDataByTimeframe(d[0], minutes[timeFrame], timestampSec);
								d[1] = filterDataByTimeframe(d[1], minutes[timeFrame], timestampSec);
							}
							handleSetData(type, dispatch, timeFrame, d[0], d[1], true);
						}
					});
				}, 5 * 60000);
				break;
			case TIME_FRAMES.fifteen_minutes:
				let sinceRecordSec, sincePpmSec;
				Promise.all([fetchRecords(resolutions.second), fetchPPM(resolutions.minute)]).then((d) => {
					if (!!d) {
						d[1] = filterDataByTimeframe(d[1], minutes[timeFrame], timestampSec);
					}
					handleSetData(type, dispatch, timeFrame, d[0], d[1], false);
					if (!!d[0].length) sinceRecordSec = d[0][0][timestampSec] + 1;
					if (!!d[1].length) sincePpmSec = d[1][0][timestampSec] + 1;
				});

				intervalId.current = setInterval(() => {
					if (fmData.hasFmData || Object.values(Chart.instances).some((ci) => ci.tooltip?._active?.length))
						return;

					Promise.all([
						fetchRecords(resolutions.second, sinceRecordSec),
						fetchPPM(resolutions.minute, sincePpmSec),
					]).then((d) => {
						if (!!d) {
							handleSetData(type, dispatch, timeFrame, d[0], d[1], true);
							if (!!d[0].length) sinceRecordSec = d[0][0][timestampSec] + 1;
							if (!!d[1].length) sincePpmSec = d[1][0][timestampSec] + 1;
						}
					});
				}, 6000);
				break;
			case TIME_FRAMES.select:
				const { startDate, endDate, timezone } = fleetContext;
				if (startDate && endDate) {
					const [since, before, resolution] = getSinceAndBefore(startDate, endDate, timezone);

					if (since < before) {
						Promise.all([
							fetchRecords(resolution, since, before),
							fetchPPM(resolution, since, before),
						]).then((d) => {
							if (!!d) {
								handleSetData(type, dispatch, timeFrame, d[0] || [], d[1] || [], false, resolution);
							}
						});
					}
				}
				break;
		}
	}, [
        fleetContext.startDate,
		fleetContext.endDate,
		fleetContext.timezone,
		fleetContext.currentDataType,
		fleetContext.selectedTimeFrame,
		fmData.hasFmData,
		fetchPPM,
		fetchRecords,
		getDispatch,
		handleSetData,
	]);

	const handleFmData = (since) => {
		const allowedTimeFrames = [...CONTRACT_TIMEFRAME, TIME_FRAMES.select];
		if (allowedTimeFrames.indexOf(fleetContext.selectedTimeFrame) >= 0) {
			if (fleetContext.selectedTimeFrame === TIME_FRAMES.select) {
				const timestamp = getUnixTime(zonedTimeToUtc(subDays(new Date(), 15), fleetContext.timezone));
				if (data.resolution !== resolutions.minute || since < timestamp) return;
			}

			const records = fetchRecords(resolutions.second, since);
			const ppm = fetchPPM(resolutions.minute, since, since + 900);
			Promise.all([records, ppm]).then((d) => {
				if (!!d) {
					const afContract = getDispatch();
					hasFmData.current = true;
					setfmData({ hasFmData: true, contract: afContract, reports: d[0], ppm: d[1] });
				}
			});
		}
	};

	const extractSelectedTimezone = () => {
		if (fleetContext.selectedTimezone.length) {
			const list = fleetContext.selectedTimezone.split(' | ');
			return list[list.length - 1].trim();
		}

		return '';
	};

	useEffect(() => {
		if (fmData.hasFmData) {
			hasFmData.current = false;
			setfmData({ hasFmData: false, contract: null, reports: [], ppm: [] });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fleetContext.resetZoom]);

	useEffect(() => {
		if (view === performance && !fleetContext.tokenUpdating) {
			clearInterval(intervalId.current);
			handleTimeframeChange();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		fleetContext.currentDataType,
		fleetContext.timezone,
		fleetContext.selectedTimeFrame,
		fleetContext.startDate,
		fleetContext.endDate,
        fleetContext.tokenUpdating
	]);

	useEffect(() => {
		return () => clearInterval(intervalId.current);
	}, []);

	return (
		<>
			<div className={cx('left-graphs-wrapper', fleetContext.currentDataType !== fleet ? 'af-view' : '')}>
				{fleetContext.currentDataType !== fleet && (
					<Dispatch
						fmData={fmData}
						data={data}
						timezone={fleetContext.timezone}
						selectedTimezone={extractSelectedTimezone()}
					/>
				)}
			</div>
			<div className="right-graphs-wrapper">
				<DemandResponseChart
					fmData={fmData}
					handleFmData={(timestamp) => handleFmData(timestamp)}
					data={data}
					showDistinct={showDistinct}
					timezone={fleetContext.timezone}
					selectedTimezone={extractSelectedTimezone()}
					notAfView={fleetContext.currentDataType === fleet}
				/>
				{fleetContext.currentDataType !== fleet && (
					<Ppm
						fmData={fmData}
						handleFmData={(timestamp) => handleFmData(timestamp)}
						data={data}
						timezone={fleetContext.timezone}
						selectedTimezone={extractSelectedTimezone()}
					/>
				)}
			</div>
		</>
	);
};

export default FleetPerformanceChart;
