/* Copyright Levelise Ltd 2020 */

export const customStyles = () => {
    // const windowWidth = window.innerWidth;
    const borderStyle = '1px solid rgb(180, 180, 180)';
    // const _width = windowWidth <= 1090 && windowWidth >= 660 
    //     ? '230px' : windowWidth < 660
    //     ? '180px' : '100%'; 
    const _width = '100%';
    return {
        menu: (provided, state) => ({
            ...provided,
            padding: 0,
            minWidth: _width,
            width: _width,
        }),
        container: (provided) => ({
            ...provided,
            minHeight: '1px',
            width: '100%',
            textAlign: 'left',
            border: 'none',
        }),
        control: (provided) => ({
            ...provided,
            minHeight: '20px',
            height: '20px',
            minWidth: _width,
            width: _width,
            border: borderStyle,
            boxShadow: 'none',
            '&:hover': {border: '1px solid grey'},
            '&:focus': {border: '1px solid grey'}
        }),
        input: (provided) => ({
            ...provided,
            margin: '1.5px',
            paddingBottom: '0px',
            paddingTop: '0px',
            minHeight: '18px',
            height: '18px',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            height: '18px',
            minHeight: '1px',
            paddingTop: '0',
            paddingBottom: '0',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            minHeight: '1px',
            marginBottom: '4px',
            marginTop: '4px',
            height: '10px'
        }),
        clearIndicator: (provided) => ({
            ...provided,
            minHeight: '1px',
            height: '10px',
        }),
        valueContainer: (provided) => ({
            ...provided,
            minHeight: '1px',
            height: '15px',
            padding: '0 4px',
        }),
        singleValue: (provided) => ({
            ...provided,
            minHeight: '0px',
            paddingBottom: '0px',
        }),
    };

}