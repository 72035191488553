/* Copyright Levelise Ltd 2021-2022 */
import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import cx from 'classnames';
import {
    TIME_FRAMES,
    PPM_MINUTE,
    PPM_HALF_HOUR_AND_DAY,
    timestampSec,
    timestampDay,
    resolutions,
    concentrator,
} from '../../../utils/constants';
import {
    CONTRACT_ANNOTATION,
    getTimeFormat,
    PPM_LOSS,
    dataset,
    options,
    xAxis,
    concentratorPpm,
    ppm,
    ldPpm,
    lqPpm,
    maxPpm,
    meanPpm,
    medianPpm,
    minPpm,
    udPpm,
    uqPpm,
    medianConcentratorPpm,
    meanConcentratorPpm,
    maxConcentratorPpm,
    minConcentratorPpm,
    lqConcentratorPpm,
    uqConcentratorPpm,
    ldConcentratorPpm,
    udConcentratorPpm,
    fleetChartTitles
} from '../../../utils/chart';
import { formatTimestamp } from '../../../utils/utils';


const x = { type: 'time' };
const x1 = { type: 'time' };
const y = {
    stacked: false,
    ticks: { min: 0, max: 100, stepValue: 20, fontSize: 10 },
    label: 'PPM (%)',
    gridLines: { drawOnChartArea: false }
};
const y1 = null;
const legendLabels = {
    font: { size: 10 }, 
    color: 'black',
    usePointStyle: true,
    boxWidth: 4,
    boxHeight: 4,
    padding: 8,
    generateLabels: function (chart) {
        const ls = [
            ppm.type, concentratorPpm.type,
            'Mean', 'Median', 'Full Range', 'Interdecile Range', 'Interquartile Range',
            'Concentrator Mean', 'Concentrator Median', 'Full Range', 'Interdecile Range', 'Interquartile Range'
        ];
        const fs = [
            ppm.backgroundColor, concentratorPpm.backgroundColor,
            meanPpm.backgroundColor, medianPpm.backgroundColor, maxPpm.backgroundColor, udPpm.backgroundColor, uqPpm.backgroundColor,
            meanConcentratorPpm.backgroundColor, medianConcentratorPpm.backgroundColor, maxConcentratorPpm.backgroundColor, udConcentratorPpm.backgroundColor, uqConcentratorPpm.backgroundColor
        ];
        const ss = [
            ppm.borderColor, concentratorPpm.borderColor,
            meanPpm.borderColor, medianPpm.borderColor, maxPpm.borderColor, udPpm.borderColor, uqPpm.borderColor,
            meanConcentratorPpm.borderColor, medianConcentratorPpm.borderColor, maxConcentratorPpm.borderColor, udConcentratorPpm.borderColor, uqConcentratorPpm.borderColor
        ];

        const labels = ls.map((label, i) => ({ text: label, fillStyle: fs[i], hidden: false, strokeStyle: ss[i] }));
        if (!chart.data.datasets[0].hidden) {
            for (let i = 2; i < labels.length; i++) labels[i].hidden = true;
        } else {
            labels[0].hidden = true;
            labels[1].hidden = true;
            if (!chart.data.datasets[4].hidden)
                for (let i = 9; i < labels.length; i++) labels[i].hidden = true;
            else
                for (let i = 4; i < 7; i++) labels[i].hidden = true;
        }
        return labels.filter(l => !l.hidden);
    }
};

const Ppm = ({ fmData, handleFmData, data, timezone, selectedTimezone }) => {
    const concentratorRadio = 'Concentrator Ranges', actualRadio = 'Actual Ranges';
    const ppmRef = useRef();
    const [option, setOption] = useState(actualRadio);
    const [currentResolution, setCurrentResolution] = useState(resolutions.minute);
    const [chart, setChart] = useState({
        data: {
            labels: [],
            datasets: [
                dataset('line', ppm.type, ppm.backgroundColor, ppm.borderColor, 'y', 'start', 0, 1, 0, 1, 3, 1, 0, 0),
                dataset('line', concentratorPpm.type, concentratorPpm.backgroundColor, concentratorPpm.borderColor, 'y', false, 0, 1, 0, 1, 3, 1, 0, 0),

                dataset('line', meanPpm.type, meanPpm.backgroundColor, meanPpm.borderColor, 'y'),
                dataset('line', medianPpm.type, medianPpm.backgroundColor, medianPpm.borderColor, 'y'),
                dataset('line', minPpm.type, minPpm.backgroundColor, minPpm.borderColor, 'y', '+1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', ldPpm.type, ldPpm.backgroundColor, ldPpm.borderColor, 'y', '+1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', lqPpm.type, lqPpm.backgroundColor, lqPpm.borderColor, 'y', '+1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', uqPpm.type, uqPpm.backgroundColor, uqPpm.borderColor, 'y', false, 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', udPpm.type, udPpm.backgroundColor, udPpm.borderColor, 'y', '-1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', maxPpm.type, maxPpm.backgroundColor, maxPpm.borderColor, 'y', '-1', 0, 0, 0, 0, 0.1, 0, 0, 0),

                dataset('line', meanConcentratorPpm.type, meanConcentratorPpm.backgroundColor, meanConcentratorPpm.borderColor, 'y'),
                dataset('line', medianConcentratorPpm.type, medianConcentratorPpm.backgroundColor, medianConcentratorPpm.borderColor, 'y'),
                dataset('line', minConcentratorPpm.type, minConcentratorPpm.backgroundColor, minConcentratorPpm.borderColor, 'y', '+1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', ldConcentratorPpm.type, ldConcentratorPpm.backgroundColor, ldConcentratorPpm.borderColor, 'y', '+1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', lqConcentratorPpm.type, lqConcentratorPpm.backgroundColor, lqConcentratorPpm.borderColor, 'y', '+1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', udConcentratorPpm.type, udConcentratorPpm.backgroundColor, udConcentratorPpm.borderColor, 'y', false, 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', uqConcentratorPpm.type, uqConcentratorPpm.backgroundColor, uqConcentratorPpm.borderColor, 'y', '-1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', maxConcentratorPpm.type, maxConcentratorPpm.backgroundColor, maxConcentratorPpm.borderColor, 'y', '-1', 0, 0, 0, 0, 0.1, 0, 0, 0),
            ]
        },
        options: options(fleetChartTitles.ppm[0], legendLabels, x, x1, y, y1)
    });

    const handleDataOnChange = (data) => {
        let resolution = data.resolution;
        if (data.timeFrame !== TIME_FRAMES.select) {
            switch (data.timeFrame) {
                case TIME_FRAMES.fifteen_minutes:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.one_hour:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.six_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.twenty_four_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.one_week:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.twenty_one_days:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.three_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.twelve_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.thirty_six_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.all:
                    resolution = resolutions.week;
                    break;
                default:
                    break;
            }
        }

        switch (resolution) {
            case resolutions.week:
            case resolutions.day:
                populateChartWithDailyRecords(data.ppm, resolution);
                break
            case resolutions.half_hour:
                populateChartWithHalfHourlyRecords(data.ppm, resolution);
                break
            case resolutions.minute:
                const isMinute = data.timeFrame !== TIME_FRAMES.fifteen_minutes;
                if (isMinute) populateChartWithMinutelyRecords(data.ppm, resolution, isMinute);
                else populateChartWithMinutelyRecords(data.ppm, resolution, isMinute, data.updated);
                break
            default:
                break;
        }
    }

    const populateChartWithDailyRecords = (reports, resolution) => {
        let labels = [];
        let datasets = chart.data.datasets.map(d => { d.data = []; return d })

        if (!reports.length) return;
        for (let i = reports.length - 1; i >= 0; i--) {
            datasets[2].data.push(reports[i].ppm[PPM_HALF_HOUR_AND_DAY[meanPpm.type]]);
            datasets[3].data.push(reports[i].ppm[PPM_HALF_HOUR_AND_DAY[medianPpm.type]]);
            datasets[4].data.push(reports[i].ppm[PPM_HALF_HOUR_AND_DAY[minPpm.type]]);
            datasets[5].data.push(reports[i].ppm[PPM_HALF_HOUR_AND_DAY[ldPpm.type]]);
            datasets[6].data.push(reports[i].ppm[PPM_HALF_HOUR_AND_DAY[lqPpm.type]]);
            datasets[7].data.push(reports[i].ppm[PPM_HALF_HOUR_AND_DAY[uqPpm.type]]);
            datasets[8].data.push(reports[i].ppm[PPM_HALF_HOUR_AND_DAY[udPpm.type]]);
            datasets[9].data.push(reports[i].ppm[PPM_HALF_HOUR_AND_DAY[maxPpm.type]]);
            if (reports[i].hasOwnProperty(concentrator)) {
                datasets[10].data.push(reports[i][concentrator][PPM_HALF_HOUR_AND_DAY[meanPpm.type]]);
                datasets[11].data.push(reports[i][concentrator][PPM_HALF_HOUR_AND_DAY[medianPpm.type]]);
                datasets[12].data.push(reports[i][concentrator][PPM_HALF_HOUR_AND_DAY[minPpm.type]]);
                datasets[13].data.push(reports[i][concentrator][PPM_HALF_HOUR_AND_DAY[ldPpm.type]]);
                datasets[14].data.push(reports[i][concentrator][PPM_HALF_HOUR_AND_DAY[lqPpm.type]]);
                datasets[15].data.push(reports[i][concentrator][PPM_HALF_HOUR_AND_DAY[uqPpm.type]]);
                datasets[16].data.push(reports[i][concentrator][PPM_HALF_HOUR_AND_DAY[udPpm.type]]);
                datasets[17].data.push(reports[i][concentrator][PPM_HALF_HOUR_AND_DAY[maxPpm.type]]);
            } else {
                for (let i = 10; i < 18; i++) datasets[i].data.push(null);
            }

            labels.push(reports[i][timestampDay] * 86400 * 1000);
        }

        datasets[0].hidden = true;
        datasets[1].hidden = true;
        datasets[2].hidden = false;
        datasets[3].hidden = false;
        datasets[10].hidden = false;
        datasets[11].hidden = false;
        if (option === concentratorRadio) {
            for (let i = 4; i < 10; i++) datasets[i].hidden = true;
            for (let i = 12; i < 18; i++) datasets[i].hidden = false;
        } else {
            for (let i = 4; i < 10; i++) datasets[i].hidden = false;
            for (let i = 12; i < 18; i++) datasets[i].hidden = true;
        }

        const timeFormat = getTimeFormat(resolution);
        handleSetChart(labels, datasets, resolution, timeFormat, PPM_LOSS)
    }

    const populateChartWithHalfHourlyRecords = (reports, resolution) => {
        let labels = [];
        let datasets = chart.data.datasets.map(d => { d.data = []; return d })

        if (!reports.length) return;
        for (let i = reports.length - 1; i >= 0; i--) {
            datasets[2].data.push(reports[i].ppm[PPM_HALF_HOUR_AND_DAY[meanPpm.type]]);
            datasets[3].data.push(reports[i].ppm[PPM_HALF_HOUR_AND_DAY[medianPpm.type]]);
            datasets[4].data.push(reports[i].ppm[PPM_HALF_HOUR_AND_DAY[minPpm.type]]);
            datasets[5].data.push(reports[i].ppm[PPM_HALF_HOUR_AND_DAY[ldPpm.type]]);
            datasets[6].data.push(reports[i].ppm[PPM_HALF_HOUR_AND_DAY[lqPpm.type]]);
            datasets[7].data.push(reports[i].ppm[PPM_HALF_HOUR_AND_DAY[uqPpm.type]]);
            datasets[8].data.push(reports[i].ppm[PPM_HALF_HOUR_AND_DAY[udPpm.type]]);
            datasets[9].data.push(reports[i].ppm[PPM_HALF_HOUR_AND_DAY[maxPpm.type]]);
            if (reports[i].hasOwnProperty(concentrator)) {
                datasets[10].data.push(reports[i][concentrator][PPM_HALF_HOUR_AND_DAY[meanPpm.type]]);
                datasets[11].data.push(reports[i][concentrator][PPM_HALF_HOUR_AND_DAY[medianPpm.type]]);
                datasets[12].data.push(reports[i][concentrator][PPM_HALF_HOUR_AND_DAY[minPpm.type]]);
                datasets[13].data.push(reports[i][concentrator][PPM_HALF_HOUR_AND_DAY[ldPpm.type]]);
                datasets[14].data.push(reports[i][concentrator][PPM_HALF_HOUR_AND_DAY[lqPpm.type]]);
                datasets[15].data.push(reports[i][concentrator][PPM_HALF_HOUR_AND_DAY[uqPpm.type]]);
                datasets[16].data.push(reports[i][concentrator][PPM_HALF_HOUR_AND_DAY[udPpm.type]]);
                datasets[17].data.push(reports[i][concentrator][PPM_HALF_HOUR_AND_DAY[maxPpm.type]]);
            } else {
                for (let i = 10; i < 18; i++) datasets[i].data.push(null);
            }

            labels.push(reports[i][timestampSec] * 1000);
        }

        datasets[0].hidden = true;
        datasets[1].hidden = true;
        datasets[2].hidden = false;
        datasets[3].hidden = false;
        datasets[10].hidden = false;
        datasets[11].hidden = false;
        if (option === concentratorRadio) {
            for (let i = 4; i < 10; i++) datasets[i].hidden = true;
            for (let i = 12; i < 18; i++) datasets[i].hidden = false;
        } else {
            for (let i = 4; i < 10; i++) datasets[i].hidden = false;
            for (let i = 12; i < 18; i++) datasets[i].hidden = true;
        }

        const end = reports[0][timestampSec];
        const start = reports[reports.length - 1][timestampSec];
        const annotation = end - start <= 90000 ? CONTRACT_ANNOTATION(end, timezone) : {};
        const timeFormat = getTimeFormat(resolution);
        handleSetChart(labels, datasets, resolution, timeFormat, { ...annotation, ...PPM_LOSS })
    }

    const populateChartWithMinutelyRecords = (reports, resolution, isMinute, update = false) => {
        let labels = update ? [...chart.data.labels] : [];
        let datasets = update ? [...chart.data.datasets] : chart.data.datasets.map(d => { d.data = []; return d });

        if (!reports.length) return;
        for (let i = reports.length - 1; i >= 0; i--) {
            datasets = datasets.map(set => {
                if (update) set.data.shift();
                switch (set.label) {
                    case ppm.type:
                        set.hidden = false;
                        set.data.push(reports[i][PPM_MINUTE[set.label]]);
                        break;
                    case concentratorPpm.type:
                        set.hidden = false;
                        set.data.push(reports[i][PPM_MINUTE[set.label]]);
                        break;
                    default:
                        set.hidden = true;
                }

                return set;
            });

            if (update) {
                labels.shift();
            }

            labels.push(reports[i][timestampSec] * 1000);
        }

        if (!isMinute) resolution = resolutions.second;

        const annotation = CONTRACT_ANNOTATION(reports[0][timestampSec], timezone);
        const timeFormat = getTimeFormat(resolution);
        handleSetChart(labels, datasets, resolution, timeFormat, { ...annotation, ...PPM_LOSS })
    }

    const getTimeFrame = () => {
        let timeFrame = data.timeFrame;
        if (timeFrame === TIME_FRAMES.select) {
            const { ppm, resolution } = data;
            const reportsLen = ppm.length;
            const { thirty_six_months, twelve_months, two_months, fifteen_days, one_week, twenty_four_hours } = TIME_FRAMES;

            switch (resolution) {
                case resolutions.day:
                    timeFrame = reportsLen <= 60 ? two_months : reportsLen <= 366 ? twelve_months : thirty_six_months;
                    break;
                case resolutions.half_hour:
                    timeFrame = reportsLen <= 50 ? twenty_four_hours : reportsLen <= 336
                        ? one_week : fifteen_days;
                    break;
                case resolutions.minute:
                    timeFrame = twenty_four_hours;
                    break;
                default:
                    break;
            }
        }

        if (fmData.hasFmData) {
            return TIME_FRAMES.fifteen_minutes;
        }

        return timeFrame;
    }

    const tooltipCallbacks = (timeFrame, datasets) => {
        return {
            ...chart.options.plugins.tooltip.callbacks,
            title: function (context) {
                const context0 = context[0];
                const ts = context0.parsed.x;
                let title = context0.label;
                if (!!title && !!ts) {
                    const unit = context0.chart.scales.x._unit || 'minute';
                    let start = '', end = '';
                    switch (unit) {
                        case 'second':
                            start = formatTimestamp(ts / 1000, selectedTimezone, 'dd/MM/yy HH:mm:ss');
                            end = formatTimestamp((ts / 1000) + (30 * 60), selectedTimezone, 'HH:mm:ss');
                            break;
                        case 'minute':
                            if (timeFrame === TIME_FRAMES.one_week || timeFrame === TIME_FRAMES.twenty_one_days) {
                                start = formatTimestamp(ts / 1000, selectedTimezone, 'dd/MM/yy HH:mm');
                                end = formatTimestamp((ts / 1000) + (59 * 60), selectedTimezone, 'HH:mm');
                            } else {
                                start = formatTimestamp(ts / 1000, selectedTimezone, 'dd/MM/yy HH:mm');
                                end = formatTimestamp((ts / 1000) + (30 * 60), selectedTimezone, 'HH:mm');
                            }
                            break;
                        case 'day':
                        case 'week':
                            start = formatTimestamp(ts / 1000, selectedTimezone, 'dd/MM/yy');
                            end = formatTimestamp(ts / 1000, selectedTimezone, 'dd/MM/yy');
                            break;
                        default:
                            break;
                    }

                    title = start + ' - ' + end;
                }
                return title;
            },
            label: function (context) {
                let label = context.dataset.label || '';
                if (label === medianPpm.type || label === meanPpm.type || label === ppm.type ||
                    label === medianConcentratorPpm.type || label === meanConcentratorPpm.type || label === concentratorPpm.type) {
                    return `${label}: ${context.formattedValue}`;
                }
                if (label === maxPpm.type) {
                    let lower = Math.round(datasets[4].data[context.dataIndex] * 1000) / 1000;
                    let upper = Math.round(parseFloat(context.formattedValue) * 1000) / 1000;
                    return `Full Range: ${lower} — ${upper}`;
                }
                if (label === udPpm.type) {
                    let lower = Math.round(datasets[5].data[context.dataIndex] * 1000) / 1000;
                    let upper = Math.round(parseFloat(context.formattedValue) * 1000) / 1000;
                    return `Interdecile Range: ${lower} — ${upper}`;
                }
                if (label === uqPpm.type) {
                    let lower = Math.round(datasets[6].data[context.dataIndex] * 1000) / 1000;
                    let upper = Math.round(parseFloat(context.formattedValue) * 1000) / 1000;
                    return `Interquartile Range: ${lower} — ${upper}`;
                }
                if (label === maxConcentratorPpm.type) {
                    let lower = Math.round(datasets[12].data[context.dataIndex] * 1000) / 1000;
                    let upper = Math.round(parseFloat(context.formattedValue) * 1000) / 1000;
                    return `Concentrator Full Range: ${lower} — ${upper}`;
                }
                if (label === udConcentratorPpm.type) {
                    let lower = Math.round(datasets[13].data[context.dataIndex] * 1000) / 1000;
                    let upper = Math.round(parseFloat(context.formattedValue) * 1000) / 1000;
                    return `Concentrator Interdecile Range: ${lower} — ${upper}`;
                }

                if (label === uqConcentratorPpm.type) {
                    let lower = Math.round(datasets[14].data[context.dataIndex] * 1000) / 1000;
                    let upper = Math.round(parseFloat(context.formattedValue) * 1000) / 1000;
                    return `Concentrator Interquartile Range: ${lower} — ${upper}`;
                }
            }
        }
    }

    const handleSetChart = (labels, datasets, resolution, time, annotations) => {
        const title = `${fleetChartTitles.ppm[0]} by ${resolution}` + (resolution === resolutions.day || resolution === resolutions.week ? ' in fleet timezone' : '');
        const timeFrame = getTimeFrame();
        const update = {
            data: { labels: labels, datasets: datasets },
            options: {
                ...chart.options,
                layout: { padding: { right: 12 } },
                plugins: {
                    ...chart.options.plugins,
                    annotation: { annotations },
                    title: { display: true, text: title, position: 'top' },
                    tooltip: { ...chart.options.plugins.tooltips, callbacks: tooltipCallbacks(timeFrame, datasets) }
                },
                scales: {
                    ...chart.options.scales,
                    y: { ...chart.options.scales.y, min: 0, max: 100, stacked: false },
                    x: xAxis(timeFrame, time, selectedTimezone, 'top', true),
                    x1: xAxis(timeFrame, time, selectedTimezone, 'bottom', false, true)
                },
                onClick: (event, items, chart) => {
                    if (!!items.length) handleFmData(parseInt(chart.data.labels[items[0].index] / 1000));
                }
            }
        };

        update.options.plugins.zoom.zoom.drag.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        update.options.plugins.zoom.zoom.pinch.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        setChart(update);
        setCurrentResolution(resolution);
    }

    useEffect(() => {
        if (fmData.hasFmData && !!fmData.ppm.length) {
            populateChartWithMinutelyRecords(fmData.ppm, resolutions.minute, false)
        }
    }, [fmData.ppm, selectedTimezone])

    useEffect(() => {
        if (!fmData.hasFmData && data.updated) {
            handleDataOnChange(data);
        }
    }, [data.ppm])

    useEffect(() => {
        if (!!data.resolution && data.timeFrame === TIME_FRAMES.select) {
            handleDataOnChange(data);
        }
    }, [data.resolution, data.ppm])

    useEffect(() => {
        if (!fmData.hasFmData) {
            handleDataOnChange(data);
        }
    }, [data.timeFrame, data.type, fmData.hasFmData, selectedTimezone])

    useEffect(() => {
        const ch = ppmRef.current;
        if (!ch) return;
        // else...
        if (option === concentratorRadio) {
            for (let i = 4; i < 10; i++) ch.data.datasets[i].hidden = true;
            for (let i = 12; i < 18; i++) ch.data.datasets[i].hidden = false;
        } else {
            for (let i = 4; i < 10; i++) ch.data.datasets[i].hidden = false;
            for (let i = 12; i < 18; i++) ch.data.datasets[i].hidden = true;
        }

        ch.update();
    }, [option])

    return (
        <div className={cx("fleet-ppm-chart af-records-chart")}
            style={{ paddingBottom: currentResolution !== resolutions.second && currentResolution !== resolutions.minute ? 15 : 0 }}>
            <Line
                id={fleetChartTitles.ppm[1]}
                ref={ppmRef}
                data={chart.data}
                options={chart.options}
            />
            {currentResolution !== resolutions.second && currentResolution !== resolutions.minute &&
                <div className="ppmType" onChange={e => setOption(e.target.value)}>
                    <div>
                        <input type="radio" id="all-ppm" value={actualRadio} name="all-ppm"
                            onChange={() => { }} checked={option === actualRadio} />
                        <label htmlFor='all-ppm'>{actualRadio}</label>
                    </div>
                    <div>
                        <input type="radio" id="concentrator-ppm" value={concentratorRadio} name="concentrator-ppm"
                            onChange={() => { }} checked={option === concentratorRadio} />
                        <label htmlFor='concentrator-ppm'>{concentratorRadio}</label>
                    </div>
                </div>
            }
        </div>
    )
}

export default Ppm;