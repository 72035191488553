/* Copyright Levelise Ltd 2024 */
import React from 'react';
import TabContainer from '../TabContainer';
import FacilityInfoTabCoT from '../FacilityInfoTabCoT';

function FacilityDetailTabCoT({ facility }) {
	return (
		<TabContainer title="Facility">
			<FacilityInfoTabCoT facility={facility} />
		</TabContainer>
	);
}

export default FacilityDetailTabCoT;
