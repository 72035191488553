/* Copyright Levelise Ltd 2024 */
import React, { useContext } from 'react';
import './index.css';
import NavBar from '../../components/NavBar';
import { routes } from '../../utils/constants';
import ChangePasswordForm from '../../components/ChangeSecurity';
import SettingsForm from '../../components/SettingsFrom/index';
import { useNavigate } from 'react-router-dom';
import FleetContext from '../../contexts/FleetContext';
import FacilityContext from '../../contexts/FacilityContext';
import UserContext from '../../contexts/UserContext';
import FleetStatusContext from '../../contexts/FleetStatusContext';
import IdleService from '../../services/idle-service';
import TokenService from '../../services/token-service';
import UserService from '../../services/user-service';

function UserRoute() {
	const navigate = useNavigate();
	const fleetContext = useContext(FleetContext);
	const facilityContext = useContext(FacilityContext);
	const userContext = useContext(UserContext);
	const fleetStatusContext = useContext(FleetStatusContext);

	const logout = () => {
		userContext.clearUser();
		fleetContext.clearState();
		facilityContext.clearState();
		fleetStatusContext.clearState();
		IdleService.unRegisterIdleResets();
		UserService.removePreferences();
		UserService.removeUser();
		UserService.removeUserDetail();
		TokenService.clearAuthToken();
		TokenService.clearCallbackBeforeExpiry();
		navigate('/auth'); // /login
	};

	return (
		<div>
			<NavBar route={routes.user} containerStyle={{zIndex: 3}}/>
			<div className="user-container">
				<div className="content-container">
					<SettingsForm handleRedirect={() => navigate(-1)} />
				</div>
				<div className="content-container">
					<ChangePasswordForm onChangePasswordSuccess={logout} />
				</div>
			</div>
		</div>
	);
}

export default UserRoute;
