/* Copyright Levelise Ltd 2019-2024 */
import React, { useState, useEffect, useContext } from 'react';
import { getUnixTime } from 'date-fns';
import facilityContext from '../../contexts/FacilityContext';
import './index.css';
import PowerFlowsDiagram from '../../assets/images/PowerFlowsDiagram';

const EnergyBalance = ({ record, recordByMinute }) => {
	const context = useContext(facilityContext);
	const [isDisconnected, setIsDisconnected] = useState(true);
	const [isRecovering, setIsRecovering] = useState(true);
	const [timestampMsec, setTimestampMsec] = useState(0);
	const [timestampSec, setTimestampSec] = useState(0);
	const [isDc, setIsDc] = useState(false);
	const [hasHotWater, setHasHotWater] = useState(context.facility.hasOwnProperty('hotWaterTank'));
	const [drawOff, setDrawOff] = useState({ flow: '', temp: '' });
	const [temps, setTemps] = useState({ temp1_8C: '', temp3_8C: '', temp5_8C: '', temp7_8C: '' });
	const [energy, setEnergy] = useState({
		recordTimestamp: '',
		batteryChargingW: '',
		batteryDischargingW: '',
		loadW: '',
		gridIncomingW: '',
		gridOutgoingW: '',
		pvW: '',
		dispatch: '',
		soc: '',
		hotWater: '',
	});

	const updateState = () => {
		if (record && Object.keys(record).length) {
			const { drDispatchedImportW, drDispatchedExportW } = record;
			const drDispatchedW =
				drDispatchedImportW < drDispatchedExportW ? -drDispatchedExportW : drDispatchedImportW;
			if (handleBySecondRecordState(record.timestampMsec) && timestampMsec < record.timestampMsec) {
				const obj = {
					recordTimestamp: record.timestampMsec,
					batteryChargingW: record.batteryChargingW,
					batteryDischargingW: record.batteryDischargingW,
					loadW: record.loadW,
					gridIncomingW: record.gridIncomingW,
					gridOutgoingW: record.gridOutgoingW,
					pvW: record.pvW,
					dispatch: !!drDispatchedW || drDispatchedW === 0 ? Math.abs(drDispatchedW) : '',
					soc: !!record.soc || record.soc === 0 ? Math.round(record.soc) : '',
					hotWater: hasHotWater ? record.hwHeatingW : '',
				};

				if (Object.hasOwn(record, 'dcPvW')) {
					obj['dcPvW'] = record.dcPvW;
				}
				if (Object.hasOwn(record, 'inverterChargingW')) {
					obj['inverterChargingW'] = record.inverterChargingW;
				}
				if (Object.hasOwn(record, 'inverterDischargingW')) {
					obj['inverterDischargingW'] = record.inverterDischargingW;
				}

				setEnergy(obj);
			}
			setTimestampMsec(record.timestampMsec);
		}
	};

	const updateTempState = () => {
		if (Object.keys(recordByMinute).length) {
			const timestamp = recordByMinute.timestampSec;
			if (timestampSec < timestamp && handleByMinuteRecordState(timestamp)) {
				setDrawOff({
					flow: !!recordByMinute.flowL ? recordByMinute.flowL.toFixed(2) : 0,
					temp: !!recordByMinute.outletTempC ? recordByMinute.outletTempC.toFixed(1) : 0,
				});
				setTemps({
					temp1_8C: !!recordByMinute.temp1_8C ? recordByMinute.temp1_8C.toFixed(1) : '',
					temp3_8C: !!recordByMinute.temp3_8C ? recordByMinute.temp3_8C.toFixed(1) : '',
					temp5_8C: !!recordByMinute.temp5_8C ? recordByMinute.temp5_8C.toFixed(1) : '',
					temp7_8C: !!recordByMinute.temp7_8C ? recordByMinute.temp7_8C.toFixed(1) : '',
				});
			}
			setTimestampSec(timestamp);
		}
	};

	const handleByMinuteRecordState = (tSec) => {
		const ts = getUnixTime(new Date());
		if (ts - tSec > 180) {
			return timestampSec < tSec;
		}
		return true;
	};

	const handleBySecondRecordState = (tMsec) => {
		const ts = getUnixTime(new Date());
		if (ts * 1000 - tMsec > 180000) {
			const recovering = timestampMsec < tMsec;
			setIsRecovering(recovering);
			setIsDisconnected(!recovering);
			return recovering;
		}
		setIsRecovering(false);
		setIsDisconnected(false);
		return true;
	};

	useEffect(() => updateState(), [record]);
	useEffect(() => updateTempState(), [recordByMinute]);
	useEffect(() => {
		const facilityHaveBattery = context.facility.hasOwnProperty('batterySystem');
        const facilityHasHotWater = context.facility.hasOwnProperty('hotWaterTank');
		let dcCheck = false;
		if (facilityHaveBattery) {
			dcCheck = (context.facility.batterySystem?.meteringType?.id & 0x40) > 0;
		}

		setIsDc(dcCheck);
        setHasHotWater(facilityHasHotWater)
	}, [context.facility]);

	return (
		<>
			<PowerFlowsDiagram
				record={energy}
				isDc={isDc}
				isDisconnected={isDisconnected}
				hasHotWater={hasHotWater}
				drawOff={drawOff}
				temps={temps}
				timestamp={timestampMsec}
				timezone={context.selectedTimezone}
                isRecovering={isRecovering}
			/>
		</>
	);
};

export default EnergyBalance;
