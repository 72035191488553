/* Copyright Levelise Ltd 2019 */
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown } from '@fortawesome/free-regular-svg-icons';
import TokenService from '../../services/token-service';
import UserService from '../../services/user-service';
import './index.css';

const NotFound = () => {
    const handleLinktoLoginPage = () => {
        UserService.removeUser();
        UserService.removeUserDetail();
        TokenService.clearAuthToken();
        TokenService.clearCallbackBeforeExpiry();
    }

    return (
        <div id="not-found-page">
            <FontAwesomeIcon icon={faFrown} size="10x"/>
            <h1>Page Not Found!</h1>
            <p>Sorry, but the page you were trying to view does not exist.</p>
            <p>Please make sure you have typed the current URL or</p> 
            <p>click on the link below to go the login page.</p> 
            <p>
                &#10094;&#10094;
                <Link to="/" onClick={() => handleLinktoLoginPage()}>Return to login page</Link>
            </p>
        </div>
    )
}

export default NotFound;