/* Copyright Levelise Ltd 2024 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { add } from '../../utils/constants';
import './index.css';

const AddBtn = ({ onAddClick, style, testTag }) => {
	return (
		<div id="add-btn" className={add} style={style} onClick={() => onAddClick()} data-cy={testTag}>
			<FontAwesomeIcon icon={faAdd} style={{ fontSize: 11.5 }} />
		</div>
	);
};

export default AddBtn;
