/* Copyright Levelise Ltd 2024 */
import React, { useCallback, useMemo, useState } from 'react';
import TabContainer from '../TabContainer';
import CustomerSearchComponent from '../CustomerSearchComponent';
import FleetService from '../../services/fleet-service';
import UserService from '../../services/user-service';
import './index.css';

function CustomerListCoT({ currentDrus, onSelectSearchResult, selectedFacility, selectedCustomer}) {
	const [loading, setLoading] = useState(false);

	const [customerSearchTerm, setCustomerSearchTerm] = useState('');
	const [customerIds, setCustomerIds] = useState('');
	const [druId, setDruId] = useState('');

	const [showDruSearchResult, setShowDruSearchResult] = useState(false);

	const [customers, setCustomers] = useState(null);

	const [druSearchResult, setDruSearchResult] = useState([]);

	const onCustomerClicked = useCallback((customer) => {
		onSelectSearchResult(customer, 'customer');
	}, []);

	const selectDru = (dru) => {
		onSelectSearchResult(dru, 'dru');
	};

	const getCustomersList = useCallback(
		(customers) => {
			if (customers && customers?.length > 0) {
				return customers?.map((customer, index) => (
					<li
						key={index}
						style={{
							cursor: 'pointer',
						}}
						className={`${customer?.customerId === selectedCustomer?.customerId ? 'selected-customer': ''}`}
					>
						<span className="strong customer" onClick={() => onCustomerClicked(customer)}>
							{customer?.name} {customer?.surname}
						</span>
						<span className="subtitle customer" onClick={() => onCustomerClicked(customer)}>
							{customer?.address1}
						</span>
						<span className="subtitle customer" onClick={() => onCustomerClicked(customer)}>
							{customer?.city}
						</span>
					</li>
				));
			}
		},
		[onCustomerClicked, selectedCustomer]
	);

	const customersList = useMemo(() => getCustomersList(customers), [customers, getCustomersList]);

	const includes = (dru) => {
		if (!druId.length) return false;
		// else...
		const match = druId.toLowerCase();

		if (String(dru).includes(match)) return true;

		return false;
	};

	const getList = (druList) => {
		if (druList && druList.length) {
			const filteredList = druList.sort((a, b) => parseInt(a) - parseInt(b)).filter((dru) => includes(dru));
			const cleanList = Array.from(new Set(filteredList));
			return cleanList;
		}
		
		return [];
	};

	const onSearchClicked = async () => {
		setLoading(true);

		if (druId.length > 0) {
			const listOfDru = getList(currentDrus);
			setDruSearchResult(listOfDru);
			setShowDruSearchResult(true);
			setLoading(false);

			return;
		}
		try {
			const res = await FleetService.getUserCustomers(UserService.getUsername(), customerIds, customerSearchTerm);

			if (res && Object.hasOwn(res, 'customers') && res.customers?.length > 0) {
				setCustomers(res.customers);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};

	const onClearClicked = () => {
		setCustomers(null);
		setCustomerIds('');
		setCustomerSearchTerm('');
		setDruId('');

		setShowDruSearchResult(false);
	};

	const listContent = () => {
		return druSearchResult.map((dru, i) => {
			return (
				<li key={i} className={`dru-item ${dru === selectedFacility?.druId ? 'selected': ''}`} onClick={() => selectDru(parseInt(dru))}>
					<span style={{ fontFamily: 'Inter-Bold' }}>{`${dru}`}</span>
				</li>
			);
		});
	};

	return (
		<TabContainer title="Find facility">
			<CustomerSearchComponent
				customerIds={customerIds}
				customerSearchTerm={customerSearchTerm}
				druSearchInput={druId}
				loading={loading}
				onCustomerIdsChange={(e) => {
					setCustomerIds(e.target.value);
					setDruId('');
				}}
				onCustomerSearchTermChange={(e) => {
					setCustomerSearchTerm(e.target.value);
					setDruId('');
				}}
				onDrusSearchTermChange={(e) => setDruId(e.target.value)}
				onClearClicked={onClearClicked}
				onSearchClicked={onSearchClicked}
				disableCustomerId={druId.length > 0}
				disableSearchInput={druId.length > 0}
				disableDruId={customerIds || customerSearchTerm}
				showDruSearch
			/>
			{!showDruSearchResult ? (
				<ul className={'cot-customer-list'}>
					{customersList}
					{customers?.length ? (
						<li
							key={'lastCustomer'}
							style={{
								cursor: 'pointer',
							}}
						>
							<span className="strong customer"></span>
						</li>
					) : null}
				</ul>
			) : null}

			{druId.length > 0 && showDruSearchResult ? (
				<ul className={'dru-list-cot'}>
					{listContent()}
					<li
						key={'lastDruList'}
						className="dru-item"
						style={{
							minHeight: 26,
						}}
					>
						<span></span>
					</li>
				</ul>
			) : null}
		</TabContainer>
	);
}

export default CustomerListCoT;
