/* Copyright Levelise Ltd 2024 */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import NavBar from '../../components/NavBar';
import { PERMISSIONS, routes } from '../../utils/constants';
import './index.css';
import TabContainer from '../../components/TabContainer';
import { Button, SpecInput } from '../../components/Form';
import { hasPermission } from '../../utils/utils';
import FleetService from '../../services/fleet-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ExpandableListItem from '../../components/ExpandableLIstItem';
import FleetContext from '../../contexts/FleetContext';

function Orders({ orders }) {
	const [selectedOrder, setSelectedOrder] = useState(null);

	return (
		<TabContainer title="Orders" containerStyle={{ height: '100%' }}>
			<ul className="orders-list-container">
				{orders.map((ele, idx) => (
					<li className="orders-list-item" key={`${idx}orders`}>
						<span
							className="strong customer"
							onClick={() => {
								if (selectedOrder === ele.orderId) {
									setSelectedOrder(null);
								} else {
									setSelectedOrder(ele.orderId);
								}
							}}
							style={{ cursor: 'pointer', fontSize: '0.8rem' }}
						>
							{selectedOrder === ele.orderId ? (
								<FontAwesomeIcon
									icon={faChevronDown}
									style={{
										marginRight: 5,
										fontSize: 10,
										marginBottom: 1,
										color: '#444444',
									}}
								/>
							) : (
								<FontAwesomeIcon
									icon={faChevronRight}
									style={{
										marginRight: 5,
										fontSize: 10,
										marginBottom: 1,
										color: '#444444',
									}}
								/>
							)}
							{`${ele.orderId} ${ele?.shopifyOrderNumber ? `(${ele.shopifyOrderNumber})` : ''}`}
						</span>
						{selectedOrder === ele.orderId ? (
							<div>
								<ul className="orders-detail-container">
									<li className="orders-detail-items">
										<span className="strong customer">Order ID</span>
										<span className="em customer">{ele && ele?.orderId ? ele.orderId : '—'}</span>
									</li>
									<li className="orders-detail-items">
										<span className="strong customer">Shopify ID</span>
										<span className="em customer">
											{ele && ele?.shopifyId ? ele.shopifyId : '—'}
										</span>
									</li>
									<li className="orders-detail-items">
										<span className="strong customer">Shopify Order Number</span>
										<span className="em customer">
											{ele && ele?.shopifyOrderNumber ? ele.shopifyOrderNumber : '—'}
										</span>
									</li>
									<li className="orders-detail-items">
										<span className="strong customer">Shopify Customer ID</span>
										<span className="em customer">
											{ele && ele?.shopifyCustomerId ? ele.shopifyCustomerId : '—'}
										</span>
									</li>
									<li className="orders-detail-items">
										<span className="strong customer">Company Contact Name</span>
										<span className="em customer">
											{ele && ele?.companyContactName ? ele.companyContactName : '—'}
										</span>
									</li>
									<li className="orders-detail-items">
										<span className="strong customer">Company Name</span>
										<span className="em customer">
											{ele && ele?.companyName ? ele.companyName : '—'}
										</span>
									</li>
									<li className="orders-detail-items">
										<span className="strong customer">Company Address</span>
										<span className="em customer">
											{ele && ele?.companyAddress ? ele.companyAddress : '—'}
										</span>
									</li>
									<li className="orders-detail-items">
										<span className="strong customer">Shipping Contact Name</span>
										<span className="em customer">
											{ele && ele?.shippingContactName ? ele.shippingContactName : '—'}
										</span>
									</li>
									<li className="orders-detail-items">
										<span className="strong customer">Shipping Address</span>
										<span className="em customer">
											{ele && ele?.shippingAddress ? ele.shippingAddress : '—'}
										</span>
									</li>
									<li className="orders-detail-items">
										<span className="strong customer">Shipping Contact Number</span>
										<span className="em customer">
											{ele && ele?.shippingContactNumber ? ele.shippingContactNumber : '—'}
										</span>
									</li>
									<li className="orders-detail-items">
										<span className="strong customer">Billing Address</span>
										<span className="em customer">
											{ele && ele?.billingAddress ? ele.billingAddress : '—'}
										</span>
									</li>
									<li className="orders-detail-items">
										<span className="strong customer">Email</span>
										<span className="em customer">
											{ele && ele?.emailAddress ? ele.emailAddress : '—'}
										</span>
									</li>
									<li className="orders-detail-items">
										<span className="strong customer">Contact Number</span>
										<span className="em customer">
											{ele && ele?.contactNumber ? ele.contactNumber : '—'}
										</span>
									</li>
									<ExpandableListItem
										className="orders-detail-items"
										title="Discounts"
										childrenClassName="discounts-container"
										collapsedValueDisplay={ele?.discounts?.length || 0}
										expandByDefault={false}
									>
										<>
											{ele &&
												ele?.discounts &&
												ele.discounts.map((discount, index) => (
													<ul key={`${index}discounts`} className="discount-list-item">
														<li>
															<span className="strong customer">Code</span>
															<span className="em customer">
																{discount && Object.hasOwn(discount, 'code')
																	? discount.code
																	: '—'}
															</span>
														</li>
														<li>
															<span className="strong customer">Amount</span>
															<span className="em customer">
																{discount && Object.hasOwn(discount, 'discountPounds')
																	? discount.discountPounds
																	: '—'}
															</span>
														</li>
														<li>
															<span className="strong customer">Type</span>
															<span className="em customer">
																{discount && Object.hasOwn(discount, 'type')
																	? discount.type
																	: '—'}
															</span>
														</li>
													</ul>
												))}
										</>
									</ExpandableListItem>
									<ExpandableListItem
										className="orders-detail-items"
										childrenClassName="discounts-container"
										title="Line Items"
										collapsedValueDisplay={ele?.lineItems?.length || 0}
										expandByDefault={false}
									>
										<>
											{ele &&
												ele?.lineItems &&
												ele.lineItems.map((lineItem, index) => (
													<ul key={`${index}lineItems`} className="discount-list-item">
														<li>
															<span className="strong customer">Package ID</span>
															<span className="em customer">
																{lineItem && Object.hasOwn(lineItem, 'packageId')
																	? lineItem.packageId
																	: '—'}
															</span>
														</li>
														<li>
															<span className="strong customer">Package Name</span>
															<span className="em customer">
																{lineItem && Object.hasOwn(lineItem, 'packageName')
																	? lineItem.packageName
																	: '—'}
															</span>
														</li>
														<li>
															<span className="strong customer">Package Description</span>
															<span className="em customer">
																{lineItem &&
																Object.hasOwn(lineItem, 'packageDescription ')
																	? lineItem.packageDescription
																	: '—'}
															</span>
														</li>
														<li>
															<span className="strong customer">Package Details</span>
															<span className="em customer">
																{lineItem && Object.hasOwn(lineItem, 'packageDetails')
																	? lineItem.packageDetails
																	: '—'}
															</span>
														</li>
														<li>
															<span className="strong customer">Quantity</span>
															<span className="em customer">
																{lineItem && Object.hasOwn(lineItem, 'quantity')
																	? lineItem.quantity
																	: '—'}
															</span>
														</li>
													</ul>
												))}
										</>
									</ExpandableListItem>
									{/* <li className="orders-detail-items sub-list-in-list-item">
										<span
											className="strong customer"
											onClick={() => {
												setShowDiscounts(!showDiscounts);
											}}
											style={{ cursor: 'pointer', fontSize: '0.8rem' }}
										>
											{showDiscounts ? ( 
												<FontAwesomeIcon
													icon={faChevronDown}
													style={{
														marginRight: 5,
														fontSize: 10,
														marginBottom: 1,
														color: '#444444',
													}}
												/>
											) : (
												<FontAwesomeIcon
													icon={faChevronRight}
													style={{
														marginRight: 5,
														fontSize: 10,
														marginBottom: 1,
														color: '#444444',
													}}
												/>
											)}
											Discounts
										</span>
										{showDiscounts ? (
											<ul className="discounts-container">
												{ele &&
													ele?.discounts &&
													ele.discounts.map((discount, index) => (
														<div key={`${index}discounts`} className="discount-list-item">
															<li>
																<span className="strong customer">Code</span>
																<span className="em customer">
																	{discount && Object.hasOwn(discount, 'code')
																		? discount.code
																		: '—'}
																</span>
															</li>
															<li>
																<span className="strong customer">Amount</span>
																<span className="em customer">
																	{discount &&
																	Object.hasOwn(discount, 'discountPounds')
																		? discount.discountPounds
																		: '—'}
																</span>
															</li>
															<li>
																<span className="strong customer">Type</span>
																<span className="em customer">
																	{discount && Object.hasOwn(discount, 'type')
																		? discount.type
																		: '—'}
																</span>
															</li>
														</div>
													))}
											</ul>
										) : null}
									</li> */}
									{/* <li className="orders-detail-items sub-list-in-list-item">
										<span
											className="strong customer"
											onClick={() => {
												setShowLineItems(!showLineItems);
											}}
											style={{ cursor: 'pointer', fontSize: '0.8rem' }}
										>
											{showLineItems ? (
												<FontAwesomeIcon
													icon={faChevronDown}
													style={{
														marginRight: 5,
														fontSize: 10,
														marginBottom: 1,
														color: '#444444',
													}}
												/>
											) : (
												<FontAwesomeIcon
													icon={faChevronRight}
													style={{
														marginRight: 5,
														fontSize: 10,
														marginBottom: 1,
														color: '#444444',
													}}
												/>
											)}
											Line Items
										</span>
										{showLineItems ? (
											<ul className="discounts-container">
												{ele &&
													ele?.lineItems &&
													ele.lineItems.map((lineItem, index) => (
														<div key={`${index}lineItems`} className="discount-list-item">
															<li>
																<span className="strong customer">Package ID</span>
																<span className="em customer">
																	{lineItem && Object.hasOwn(lineItem, 'packageId')
																		? lineItem.packageId
																		: '—'}
																</span>
															</li>
															<li>
																<span className="strong customer">Package Name</span>
																<span className="em customer">
																	{lineItem && Object.hasOwn(lineItem, 'packageName')
																		? lineItem.packageName
																		: '—'}
																</span>
															</li>
															<li>
																<span className="strong customer">
																	Package Description
																</span>
																<span className="em customer">
																	{lineItem &&
																	Object.hasOwn(lineItem, 'packageDescription ')
																		? lineItem.packageDescription
																		: '—'}
																</span>
															</li>
															<li>
																<span className="strong customer">Package Details</span>
																<span className="em customer">
																	{lineItem &&
																	Object.hasOwn(lineItem, 'packageDetails')
																		? lineItem.packageDetails
																		: '—'}
																</span>
															</li>
															<li>
																<span className="strong customer">Quantity</span>
																<span className="em customer">
																	{lineItem && Object.hasOwn(lineItem, 'quantity')
																		? lineItem.quantity
																		: '—'}
																</span>
															</li>
														</div>
													))}
											</ul>
										) : null}
									</li> */}
								</ul>
							</div>
						) : null}
					</li>
				))}
				<li className="orders-list-item">
					<span className="em customer">&nbsp;</span>
				</li>
			</ul>
		</TabContainer>
	);
}

function InstallationCompanyInfo({ selectedCompany }) {
	return (
		<TabContainer title="Info">
			<ul className="customer-info-container">
				<li>
					<span className="strong customer">ID</span>
					<span className="em customer">
						{selectedCompany && selectedCompany?.companyId ? selectedCompany.companyId : '—'}
					</span>
				</li>
				<li>
					<span className="strong customer">Name</span>
					<span className="em customer">
						{selectedCompany && selectedCompany?.companyName ? selectedCompany.companyName : '—'}
					</span>
				</li>
				<li>
					<span className="strong customer">Shopify Customer ID</span>
					<span className="em customer">
						{selectedCompany && selectedCompany?.shopifyCustomerId
							? selectedCompany.shopifyCustomerId
							: '—'}
					</span>
				</li>
				<li>
					<span className="strong customer">Admin name</span>
					<span className="em customer">
						{selectedCompany && selectedCompany?.installerAdmin
							? `${selectedCompany.installerAdmin?.firstName || ''} ${
									selectedCompany?.installerAdmin.lastName || ''
							  }`
							: '—'}
					</span>
				</li>
				<li>
					<span className="strong customer">Admin email</span>
					<span className="em customer">
						{selectedCompany && selectedCompany?.installerAdmin && selectedCompany.installerAdmin?.email
							? selectedCompany.installerAdmin.email
							: '—'}
					</span>
				</li>
				<li>
					<span className="strong customer"></span>
					<span className="em customer">&nbsp;</span>
				</li>
			</ul>
		</TabContainer>
	);
}

function OrdersRoute() {
	const fleetContext = useContext(FleetContext);
	const [searchTerm, setSearchTerm] = useState('');
	const [companies, setCompanies] = useState([]);
	const [orders, setOrders] = useState([]);
	const [searchResults, setSearchResults] = useState(null);
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [loading, setLoading] = useState(false);

	const onSearchTermChange = (e) => {
		setSearchTerm(e.target.value);
	};

	const onClearClicked = () => {
		setSearchTerm('');
		setSearchResults(null);
	};

	const onSearchClicked = () => {
		if (searchTerm) {
			const searchRes = companies.filter((ele) =>
				ele.companyName.toLowerCase().includes(searchTerm.toLowerCase())
			);
			setSearchResults(searchRes);
		} else {
			setSearchResults(null);
		}
	};

	const fetchInstallationCompanies = useCallback(async () => {
		if (!hasPermission(PERMISSIONS.CAN_ACCESS_ORDERS)) {
			return;
		}
		setLoading(true);
		try {
			const res = await FleetService.getInstallationCompanies();

			if (res && Object.hasOwn(res, 'companies')) {
				setCompanies(res.companies);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			console.log('In fetch companies: ', err);
		}
	}, []);

	const fetchOrders = useCallback(async (companyId) => {
		if (!hasPermission(PERMISSIONS.CAN_ACCESS_ORDERS) || !companyId) {
			return;
		}

		setLoading(true);
		try {
			const res = await FleetService.getOrdersForInstallationCompany(companyId);

			if (res && Object.hasOwn(res, 'orders')) {
				setOrders(res.orders);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			console.log('In fetch orders: ', err);
		}
	}, []);

	const onCompanySelected = (company) => {
		setOrders([]);
		setSelectedCompany(company);
		fetchOrders(company.companyId);
	};

	const companiesList = useMemo(() => {
		let data = companies;
		if (searchResults) {
			data = searchResults;
		}

		return (
			<>
				{data.map((ele, idx) => (
					<li
						key={idx}
						className={`${
							selectedCompany && selectedCompany?.companyId === ele.companyId ? 'selected' : ''
						}`}
						onClick={() => {
							onCompanySelected(ele);
						}}
					>
						<span>{`${ele.companyName} (${ele.companyId})`}</span>
					</li>
				))}
			</>
		);
	}, [companies, searchResults, selectedCompany]);

	useEffect(() => {
		if (!fleetContext.tokenUpdating) {
		fetchInstallationCompanies();
		}
	}, [fleetContext.tokenUpdating]);

	return (
		<div>
			<NavBar route={routes.orders} />
			<div className="orders-page">
				<div className="child-containers container-border-style">
					<TabContainer title="Installation companies">
						<div>
							<div className="search-inputs-container">
								<div className="label-input-container c-o-t">
									<label htmlFor="idInput">Name</label>
									<SpecInput
										name="searchNameInput"
										type="text"
										placeholder="Company name"
										value={searchTerm}
										onChange={onSearchTermChange}
									/>
								</div>

								<div className="customer-search-button-container c-o-t">
									<Button className="configure-btn reset" onClick={onClearClicked}>
										Clear
									</Button>
									<Button
										className={'configure-btn submit'}
										disabled={loading || !searchTerm}
										onClick={onSearchClicked}
									>
										Search
									</Button>
								</div>
							</div>
							<ul className={'installation-companies-list'}>
								{companiesList}
								{companies?.length ? (
									<li
										key={'lastCompany'}
										style={{
											cursor: 'pointer',
										}}
									>
										<span className="strong customer"></span>
									</li>
								) : null}
							</ul>
						</div>
					</TabContainer>
				</div>
				<div className="child-containers container-border-style">
					<InstallationCompanyInfo selectedCompany={selectedCompany} />
				</div>
				<div className="child-containers container-border-style orders-column">
					<Orders orders={orders} />
				</div>
			</div>
		</div>
	);
}

export default OrdersRoute;
