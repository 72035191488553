/* Copyright Levelise Ltd 2021-2022 */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import cx from 'classnames';
import FleetContext from '../../../contexts/FleetContext';
import {
    TIME_FRAMES,
    BATTERY_SOC,
    BATTERY_SOC_HALF_HOUR_AND_DAY,
    resolutions,
    timestampDay,
    timestampSec
} from '../../../utils/constants';
import {
    CONTRACT_ANNOTATION,
    getTimeFormat,
    ldSoc,
    lqSoc,
    maxSoc,
    meanSoc,
    medianSoc,
    minSoc,
    udSoc,
    uqSoc,
    dataset,
    options,
    fleetChartTitles,
    xAxis,
    tooltipTitleCallbacks
} from '../../../utils/chart';


const x = { type: 'time' }
const x1 = null
const y = {
    stacked: false,
    ticks: {
        suggestedMin: 0,
        suggestedMax: 100,
        steps: 10,
        stepValue: 10,
        fontSize: 10
    },
    label: 'SoC (%)',
    gridLines: { drawOnChartArea: true }
}
const y1 = null
const legendLabels = {
    font: { size: 10 }, 
    color: 'black',
    usePointStyle: true,
    boxWidth: 4,
    boxHeight: 4,
    padding: 8,
    generateLabels: function (chart) {
        let ls = ['Mean', 'Median', 'Full Range', 'Interdecile Range', 'Interquartile Range'];
        let fs = [meanSoc.backgroundColor, medianSoc.backgroundColor, maxSoc.backgroundColor,
        udSoc.backgroundColor, uqSoc.backgroundColor];
        let ss = [meanSoc.borderColor, medianSoc.borderColor, maxSoc.borderColor,
        udSoc.borderColor, uqSoc.borderColor];
        return ls.map((label, i) => {
            return {
                text: label,
                fillStyle: fs[i],
                hidden: false,
                lineCap: '',
                lineDash: [],
                lineDashOffset: 0,
                lineJoin: '',
                lineWidth: 1,
                strokeStyle: ss[i]
            }
        })
    }
}

const SocByBsChart = ({ fmData, handleFmData, data, timezone, selectedTimezone, showPerformance }) => {
    const context = useContext(FleetContext);
    const socBsRef = useRef();
    const [chart, setChart] = useState({
        data: {
            labels: [],
            datasets: [
                dataset('line', meanSoc.type, meanSoc.backgroundColor, meanSoc.borderColor, 'y'),
                dataset('line', medianSoc.type, medianSoc.backgroundColor, medianSoc.borderColor, 'y'),
                dataset('line', minSoc.type, minSoc.backgroundColor, minSoc.borderColor, 'y', '+1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', ldSoc.type, ldSoc.backgroundColor, ldSoc.borderColor, 'y', '+1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', lqSoc.type, lqSoc.backgroundColor, lqSoc.borderColor, 'y', '+1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', uqSoc.type, uqSoc.backgroundColor, uqSoc.borderColor, 'y', false, 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', udSoc.type, udSoc.backgroundColor, udSoc.borderColor, 'y', '-1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', maxSoc.type, maxSoc.backgroundColor, maxSoc.borderColor, 'y', '-1', 0, 0, 0, 0, 0.1, 0, 0, 0),
            ]
        },
        options: options(fleetChartTitles.socBs[0], legendLabels, x, x1, y, y1)
    });

    const handleDataOnChange = (data) => {
        let resolution = data.resolution;
        if (data.timeFrame !== TIME_FRAMES.select) {
            switch (data.timeFrame) {
                case TIME_FRAMES.fifteen_minutes:
                    resolution = resolutions.second;
                    break;
                case TIME_FRAMES.one_hour:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.six_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.twenty_four_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.one_week:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.twenty_one_days:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.three_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.twelve_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.thirty_six_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.all:
                    resolution = resolutions.week;
                    break;
                default:
                    break;
            }
        }

        switch (resolution) {
            case resolutions.week:
            case resolutions.day:
                populateChartByDay(data.soc, resolution, context.selectedBatterySystemType);
                break;
            case resolutions.half_hour:
                populateChartByMinuteAndHalfHour(data.soc, resolution, context.selectedBatterySystemType);
                break;
            case resolutions.minute:
                populateChartByMinuteAndHalfHour(data.soc, resolution, context.selectedBatterySystemType);
                break;
            case resolutions.second:
                populateChartByMinuteAndHalfHour(data.soc, resolutions.minute, context.selectedBatterySystemType, data.updated);
                break;
            default:
                break;
        }
    }

    const populateChartByDay = (reports, resolution, type) => {
        let labels = [];
        let datasets = chart.data.datasets.map(d => {
            d.data = [];
            return d;
        });

        if (!reports.length) return;
        for (let i = reports.length - 1; i >= 0; i--) {
            datasets = datasets.map(set => {
                if (reports[i]['byType'].hasOwnProperty(type)) {
                    set.data.push(reports[i]['byType'][type][BATTERY_SOC_HALF_HOUR_AND_DAY[set.label]]);
                } else {
                    set.data.push(null);
                }

                return set;
            });

            labels.push(reports[i][timestampDay] * 86400 * 1000);
        }

        handleSetChart(type, labels, datasets, resolution, []);
    }

    const populateChartByMinuteAndHalfHour = (reports, resolution, type, update = false) => {
        let labels = [];
        let datasets = chart.data.datasets.map(d => { d.data = []; return d });

        if (!reports.length) return;

        const isHalfHour = resolution === resolutions.half_hour;
        for (let i = reports.length - 1; i >= 0; i--) {
            datasets = datasets.map(set => {
                if (reports[i]['byType'].hasOwnProperty(type)) {
                    if (isHalfHour) set.data.push(reports[i]['byType'][type][BATTERY_SOC_HALF_HOUR_AND_DAY[set.label]]);
                    else set.data.push(reports[i]['byType'][type][BATTERY_SOC[set.label]]);
                } else {
                    set.data.push(null);
                }

                return set;
            });

            labels.push(reports[i][timestampSec] * 1000);
        }

        const end = reports[0][timestampSec];
        const start = labels[0] / 1000;
        const annotation = end - start <= 90000 ? CONTRACT_ANNOTATION(end, timezone) : [];
        handleSetChart(type, labels, datasets, resolution, annotation);
    }

    const getTimeFrame = () => {
        let timeFrame = data.timeFrame;
        if (timeFrame === TIME_FRAMES.select) {
            const { soc, resolution } = data;
            const reportsLen = soc.length;
            const { thirty_six_months, twelve_months, two_months, fifteen_days, one_week, twenty_four_hours } = TIME_FRAMES;

            switch (resolution) {
                case resolutions.day:
                    timeFrame = reportsLen <= 60 ? two_months : reportsLen <= 366 ? twelve_months : thirty_six_months;
                    break;
                case resolutions.half_hour:
                    timeFrame = reportsLen <= 50 ? twenty_four_hours : reportsLen <= 336
                        ? one_week : fifteen_days;
                    break;
                case resolutions.minute:
                    timeFrame = twenty_four_hours;
                    break;
                default:
                    break;
            }
        }

        if (fmData.hasFmData) {
            return TIME_FRAMES.fifteen_minutes;
        }

        return timeFrame;
    }

    const handleSetChart = (type, labels, datasets, resolution, annotation) => {
        const timeFrame = getTimeFrame();
        let title = !!type ? `${context.batterySystems[type]} ${fleetChartTitles.socBs[0]}` : fleetChartTitles.socBs[0];
        title += resolution === resolutions.day || resolution === resolutions.week ? ` by ${resolution} in fleet timezone` : ` by ${resolution}`;
        const update = {
            data: { labels: labels, datasets: datasets },
            options: {
                ...chart.options,
                layout: { padding: { right: 12 } },
                plugins: {
                    ...chart.options.plugins,
                    annotation: { annotations: annotation },
                    title: { ...chart.options.plugins.title, text: title },
                    tooltip: {
                        ...chart.options.plugins.tooltip,
                        callbacks: {
                            ...tooltipTitleCallbacks(selectedTimezone),
                            label: function (tooltipItem) {
                                let label = tooltipItem.dataset.label || '';

                                if (label === medianSoc.type || label === meanSoc.type) {
                                    return `${label}: ${tooltipItem.formattedValue}`;
                                }

                                if (label === maxSoc.type) {
                                    let lower = Math.round(datasets[2].data[tooltipItem.dataIndex] * 1000) / 1000;
                                    let upper = Math.round(tooltipItem.formattedValue * 1000) / 1000;
                                    return `Full Range: ${lower} — ${upper}`;
                                }

                                if (label === udSoc.type) {
                                    let lower = Math.round(datasets[3].data[tooltipItem.dataIndex] * 1000) / 1000;
                                    let upper = Math.round(tooltipItem.formattedValue * 1000) / 1000;
                                    return `Interdecile Range: ${lower} — ${upper}`;
                                }

                                if (label === uqSoc.type) {
                                    let lower = Math.round(datasets[4].data[tooltipItem.dataIndex] * 1000) / 1000;
                                    let upper = Math.round(tooltipItem.formattedValue * 1000) / 1000;
                                    return `Interquartile Range: ${lower} — ${upper}`;
                                }
                            },
                        }
                    }
                },
                scales: {
                    ...chart.options.scales,
                    x: xAxis(timeFrame, getTimeFormat(resolution), selectedTimezone)
                },
                onClick: (event, items, chart) => {
                    if (!!items.length) handleFmData(parseInt(chart.data.labels[items[0].index] / 1000));
                }
            }
        };

        update.options.plugins.zoom.zoom.drag.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        update.options.plugins.zoom.zoom.pinch.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        setChart(update);
    }

    useEffect(() => {
        if (fmData.hasFmData && !!fmData.soc.length) {
            populateChartByMinuteAndHalfHour(fmData.soc, resolutions.second, context.selectedBatterySystemType);
        }
    }, [fmData.soc, selectedTimezone])

    useEffect(() => {
        if (data.updated) {
            handleDataOnChange(data);
        }
    }, [data.soc])

    useEffect(() => {
        if (!!data.resolution && data.timeFrame === TIME_FRAMES.select) {
            handleDataOnChange(data);
        }
    }, [data.resolution, data.soc])

    useEffect(() => {
        if (!fmData.hasFmData) {
            handleDataOnChange(data);
        }
    }, [data.timeFrame, data.type, context.selectedBatterySystemType, fmData.hasFmData, selectedTimezone])

    return (
        <div className={cx("fleet-system-soc-chart af-records-chart", !showPerformance ? 'fleet-records' : '')}>
            <Line
                id={fleetChartTitles.socBs[1]}
                ref={socBsRef}
                data={chart.data}
                options={chart.options}
            />
        </div>
    )
}

export default SocByBsChart;
