/* Copyright Levelise Ltd 2020-2021 */
import React, { useState, useEffect } from 'react';
import { TIME_FRAMES } from '../../utils/constants';

const SummaryTable = ({ facility, records, timeFrame, dates }) => {
    const [isDC, setIsDC] = useState(false);

    const conversionFactor = {
        [TIME_FRAMES.twenty_four_hours]: 60 * 1000,
        [TIME_FRAMES.six_hours]: 60 * 1000,
        [TIME_FRAMES.one_hour]: 60 * 1000,
        [TIME_FRAMES.fifteen_minutes]: 60 * 60 * 1000
    };
    const timeFrames = [
        TIME_FRAMES.one_week,
        TIME_FRAMES.twenty_one_days,
        TIME_FRAMES.three_months,
        TIME_FRAMES.twelve_months,
        TIME_FRAMES.thirty_six_months,
        TIME_FRAMES.all
    ];

    const [data, setData] = useState({
        fromPv: 0,
        fromDcPv: 0,
        toInverter: 0,
        fromInverter: 0,
        loadFromInverter: 0,
        batteryFromDcPv: 0,
        inverterFromBattery: 0,
        toLoad: 0,
        loadFromPv: 0,
        loadFromBattery: 0,
        loadFromGrid: 0,
        toHw: 0,
        hwFromPv: 0,
        hwFromBattery: 0,
        hwFromGrid: 0,
        toBattery: 0,
        fromBattery: 0,
        batteryFromPv: 0,
        batteryFromGrid: 0,
        toGrid: 0,
        fromGrid: 0,
        gridFromPV: 0,
        gridFromBattery: 0,
        drImport: 0,
        drExport: 0,
        energyFlowDifferenceForDr: 0,
        energyFlowDifferenceForBattery: 0,
        energyFlowDifferenceForInverter: 0,
        energyFlowDifferenceForGrid: 0,
        loadFromDcPv: 0,
        batteryFromInverter: 0,
        inverterFromGrid: 0,
        inverterFromDcPv: 0,
        gridFromInverter: 0,
        gridFromDcPv: 0,
        inverterFromPv: 0,
    })

    const calculateFromSums = (sums, isKwh) => {
        const loadKey = isKwh ? 'loadKwh' : 'loadW';
        const pvKey = isKwh ? 'pvKwh' : 'pvW';
        const dcPvKey = isKwh ? 'dcPvKwh' : 'dcPvW';
        const loadFromPvKey = isKwh ? 'loadFromPvKwh' : 'loadFromPvW';
        const loadFromBatteryKey = isKwh ? 'loadFromBatteryKwh' : 'loadFromBatteryW';
        const hwHeatingKey = isKwh ? 'hwHeatingKwh' : 'hwHeatingW';
        const hwFromPvKey = isKwh ? 'hwFromPvKwh' : 'hwFromPvW';
        const hwFromBatteryKey = isKwh ? 'hwFromBatteryKwh' : 'hwFromBatteryW';
        const batteryChargingKey = isKwh ? 'batteryChargingKwh' : 'batteryChargingW';
        const batteryDischargingKey = isKwh ? 'batteryDischargingKwh' : 'batteryDischargingW';
        const inverterChargingKey = isKwh ? 'inverterChargingKwh' : 'inverterChargingW';
        const inverterDischargingKey = isKwh ? 'inverterDischargingKwh' : 'inverterDischargingW';
        const loadFromInverterKey = isKwh ? 'loadFromInverterKwh' : 'loadFromInverterW';
        const inverterFromBatteryKey = isKwh ? 'inverterFromBatteryKwh' : 'inverterFromBatteryW';
        const inverterFromPvKey = isKwh ? 'inverterFromPvKwh' : 'inverterFromPvW';
        const batteryFromDcPvKey = isKwh ? 'batteryFromDcPvKwh' : 'batteryFromDcPvW';
        const batteryFromPvKey = isKwh ? 'batteryFromPvKwh' : 'batteryFromPvW';
        const gridIncomingKey = isKwh ? 'gridIncomingKwh' : 'gridIncomingW';
        const gridOutgoingKey = isKwh ? 'gridOutgoingKwh' : 'gridOutgoingW';
        const drDispatchedImportKey = isKwh ? 'drDispatchedImportKwh' : 'drDispatchedImportW';
        const drDispatchedExportKey = isKwh ? 'drDispatchedExportKwh' : 'drDispatchedExportW';
        if (timeFrame === TIME_FRAMES.fifteen_minutes) {
            const fromPv = sums[pvKey] || 0;
            const fromDcPv = sums[dcPvKey] || 0;
            const fromInverter = sums[inverterDischargingKey] || 0;
            const toInverter = sums[inverterChargingKey] || 0;
            const toLoad = sums[loadKey] || 0;
            const toHw = sums[hwHeatingKey] || 0;
            const toBattery = sums[batteryChargingKey] || 0;
            const fromBattery = sums[batteryDischargingKey] || 0;
            let batteryFromDcPv = sums[batteryFromDcPvKey] || 0;
            const toGrid = sums[gridOutgoingKey] || 0;
            const fromGrid = sums[gridIncomingKey] || 0;

            const batteryDischargingPortion = fromBattery / (fromDcPv + fromBattery);
            const inverterFromBattery = fromInverter * batteryDischargingPortion;

            const loadFromPv = Math.min(toLoad, fromPv) || 0;
            const loadFromInverter = Math.min(toLoad - loadFromPv, fromInverter);
            const inverterFromPv = Math.min(fromPv - loadFromPv, toInverter);

            let loadFromBattery = Math.max(0, Math.min(toLoad - fromPv, fromBattery)) || 0;
            if (isDC) {
                loadFromBattery = loadFromInverter * batteryDischargingPortion;
            }

            let loadFromGrid = (toLoad - (loadFromPv + loadFromBattery)) || 0;
            const hwFromPv = Math.max(0, Math.min(toHw, fromPv - loadFromPv)) || 0;
            const hwFromBattery = Math.max(0, Math.min(toHw - hwFromPv, fromBattery - loadFromBattery)) || 0;
            const hwFromGrid = (toHw - (hwFromPv + hwFromBattery)) || 0;
            let batteryFromPv = Math.max(0, Math.min(toBattery, fromPv - (loadFromPv + (hwFromPv || 0)))) || 0;
            const gridFromPV = Math.max(0, Math.min(toGrid, fromPv - (loadFromPv + (hwFromPv || 0) + batteryFromPv))) || 0;
            const gridFromBattery = (toGrid - gridFromPV) || 0;

            let batteryFromGrid = (toBattery - batteryFromPv) || 0;
            let batteryFromInverter =  0;
            const loadFromDcPv = loadFromInverter - loadFromBattery;

            if (isDC) {
                batteryFromDcPv = Math.min(fromDcPv, toBattery);
                batteryFromPv = (toBattery - batteryFromDcPv) * (inverterFromPv / toInverter);
                if(isNaN(batteryFromPv)) {
                    batteryFromPv = 0;
                }
                batteryFromGrid = (toBattery - batteryFromDcPv - batteryFromPv) || 0;
                batteryFromInverter = (toBattery - batteryFromDcPv) || 0;
                loadFromGrid = (toLoad - (loadFromPv + loadFromDcPv + loadFromBattery)) || 0;
            }
            
            const drImport = sums[drDispatchedImportKey] || 0;
            const drExport = sums[drDispatchedExportKey] || 0;
            const energyFlowDifferenceForDr = (drImport - drExport) || 0;
            const energyFlowDifferenceForBattery = (toBattery - fromBattery) || 0;
            const energyFlowDifferenceForInverter = (toInverter - fromInverter) || 0;
            const energyFlowDifferenceForGrid = (fromGrid - toGrid) || 0;

            const gridFromInverter = fromInverter - loadFromInverter;
            const gridFromDcPv = toGrid - gridFromPV - gridFromBattery;

            const inverterFromGrid = toGrid - loadFromGrid;
            const inverterFromDcPv = fromInverter - inverterFromBattery;

            setData({
                fromPv, toLoad, toHw, toBattery, fromBattery, toGrid, fromGrid, loadFromPv, loadFromBattery,
                loadFromGrid, hwFromPv, hwFromBattery, hwFromGrid, batteryFromPv, batteryFromGrid, gridFromPV,
                gridFromBattery, drImport, drExport, energyFlowDifferenceForDr, energyFlowDifferenceForBattery,
                energyFlowDifferenceForGrid, fromDcPv, toInverter, fromInverter, loadFromInverter, batteryFromDcPv, energyFlowDifferenceForInverter,
                inverterFromBattery, batteryFromInverter, gridFromDcPv, gridFromInverter, inverterFromDcPv, inverterFromGrid, loadFromDcPv, inverterFromPv
            });
        } else {
            const fromPv = sums[pvKey] || 0;
            const fromDcPv = sums[dcPvKey] || 0;
            const loadFromInverter = sums[loadFromInverterKey] || 0;
            const batteryFromDcPv = sums[batteryFromDcPvKey] || 0;
            const inverterFromBattery = sums[inverterFromBatteryKey] || 0;
            const inverterFromPv = sums[inverterFromPvKey] || 0;
            const fromInverter = sums[inverterDischargingKey] || 0;
            const toInverter = sums[inverterChargingKey] || 0;
            const toLoad = sums[loadKey] || 0;
            const loadFromPv = sums[loadFromPvKey] || 0;
            const loadFromBattery = sums[loadFromBatteryKey] || 0;
            const toGrid = sums[gridOutgoingKey] || 0;
            const fromGrid = sums[gridIncomingKey] || 0;
            const toHw = sums[hwHeatingKey] || 0;
            const hwFromPv = sums[hwFromPvKey] || 0;
            const hwFromBattery = sums[hwFromBatteryKey] || 0;
            const toBattery = sums[batteryChargingKey] || 0;
            const fromBattery = sums[batteryDischargingKey] || 0;
            const batteryFromPv = sums[batteryFromPvKey] || 0;

            let loadFromGrid = (toLoad - loadFromPv - loadFromBattery) || 0;
            if (isDC) {
                loadFromGrid = (toLoad - loadFromPv - loadFromInverter) || 0;
            }
            const loadFromDcPv = loadFromInverter - loadFromBattery;

            let batteryFromGrid = (toBattery - batteryFromPv) || 0;
            let batteryFromInverter =  0;

            const hwFromGrid = (toHw - hwFromPv - hwFromBattery) || 0;

            const inverterFromGrid = fromGrid - loadFromGrid;
            const inverterFromDcPv = fromInverter - inverterFromBattery;
            let gridFromBattery = (fromBattery - (hwFromBattery || 0) - loadFromBattery) || 0;
            let gridFromPV = (fromPv - loadFromPv - (hwFromPv || 0) - batteryFromPv) || 0;
            
            if (isDC) {
                gridFromPV = (fromPv - loadFromPv - (hwFromPv || 0) - inverterFromPv) || 0;
                batteryFromGrid = (toBattery - batteryFromDcPv - batteryFromPv) || 0;
                batteryFromInverter = (toBattery - batteryFromDcPv) || 0;
                gridFromBattery = (inverterFromBattery - (hwFromBattery || 0) - loadFromBattery) || 0;
            }

            const gridFromInverter = fromInverter -loadFromInverter;
            const gridFromDcPv = toGrid - gridFromPV - gridFromBattery;

            const drImport = sums[drDispatchedImportKey] || 0;
            const drExport = sums[drDispatchedExportKey] || 0;

            const energyFlowDifferenceForDr = (drImport - drExport) || 0;
            const energyFlowDifferenceForBattery = (toBattery - fromBattery) || 0;
            const energyFlowDifferenceForInverter = (toInverter - fromInverter) || 0;
            const energyFlowDifferenceForGrid = (fromGrid - toGrid) || 0;
            
            setData({
                fromPv, toLoad, loadFromPv, loadFromBattery, loadFromGrid, toHw, hwFromPv, hwFromBattery, hwFromGrid,
                toBattery, fromBattery, batteryFromPv, batteryFromGrid, toGrid, fromGrid, gridFromPV, gridFromBattery,
                drImport, drExport, energyFlowDifferenceForDr, energyFlowDifferenceForBattery,
                energyFlowDifferenceForGrid, fromDcPv, toInverter, fromInverter, loadFromInverter, batteryFromDcPv, energyFlowDifferenceForInverter,
                inverterFromBattery, inverterFromDcPv, inverterFromGrid, gridFromDcPv, gridFromInverter, batteryFromInverter, loadFromDcPv, inverterFromPv
            })
        }
    }

    useEffect(() => {
        if (facility && Object.hasOwn(facility, 'batterySystem')) {
            if ((facility.batterySystem?.meteringType?.id & 0x40) > 0 ) {
                setIsDC(true);
            } else {
                setIsDC(false);
            }
        }
    }, [facility])

    useEffect(() => {
        let isKwh = false;
        let factor = conversionFactor[timeFrame];
        if (timeFrames.includes(timeFrame)) {
            isKwh = true;
            factor = 1;
        } else if (timeFrame === TIME_FRAMES.select) {
            if (dates.start === dates.end) {
                if (records.length <= 48) {
                    isKwh = true;
                    factor = 1;
                }
            } else {
                isKwh = true;
                factor = 1;
            }
        }

        if (!isKwh && timeFrame === TIME_FRAMES.select) {
            factor = conversionFactor[TIME_FRAMES.twenty_four_hours];
        }

        const sums = {};
        const keysToIgnore = ['druId', 'timestampDay', 'timestampSec', 'timestampMsec', 'frequencyHz', 'soc'];
        for (let i = 0; i < records.length; i++) {
            for (let key in records[i]) {
                if (keysToIgnore.includes(key)) continue;
                sums[key] = (sums[key] || 0) + ((records[i][key] || 0) / factor);
            }
        }

        calculateFromSums(sums, isKwh);
    }, [records, timeFrame, isDC])

    return (
        <ul className="summary-table">
            <li className={facility.hasOwnProperty('batterySystem') ? "even" : "odd"}>
                <span className="strong">From PV</span>
                <span className="em">{(data.fromPv).toFixed(2) + ' kWh'}</span>
            </li>
            {isDC ? (
				<li className={!facility.hasOwnProperty('batterySystem') ? 'even' : 'odd'}>
					<span className="strong">DC From DC PV</span>
					<span className="em">{data.fromDcPv.toFixed(2) + ' kWh'}</span>
				</li>
			) : null}
            {facility.hasOwnProperty('batterySystem') &&
                <li className="even">
                    <span className="strong">{`${isDC? 'DC ': ''}From Battery`}</span>
                    <span className="em">{(data.fromBattery).toFixed(2) + ' kWh'}</span>
                </li>
            }
            {isDC ? (
                <>
				<li className={!facility.hasOwnProperty('batterySystem') ? 'even' : 'odd'}>
					<span className="strong">From Inverter</span>
					<span className="em">{data.fromInverter.toFixed(2) + ' kWh'}</span>
				</li>
                <li className="from">
                    <span className="strong">From DC PV</span>
                    <span className="em">{(data.inverterFromDcPv).toFixed(2) + ' kWh'}</span>
                </li>
                <li className="from">
                    <span className="strong">From Battery</span>
                    <span className="em">{(data.inverterFromBattery).toFixed(2) + ' kWh'}</span>
                </li>
                </>
			) : null}
            <li className={facility.hasOwnProperty('batterySystem') ? 'even' : 'odd'}>
                <span className="strong">From Grid</span>
                <span className="em">{(data.fromGrid).toFixed(2) + ' kWh'}</span>
            </li>
            <li className="odd">
                <span className="strong">To Load</span>
                <span className="em">{(data.toLoad).toFixed(2) + ' kWh'}</span>
            </li>
            <li className="from">
                <span className="strong">From PV</span>
                <span className="em">{(data.loadFromPv).toFixed(2) + ' kWh'}</span>
            </li>
            {facility.hasOwnProperty('batterySystem') && isDC &&
                <li className="from">
                    <span className="strong">From DC PV</span>
                    <span className="em">{(data.loadFromDcPv).toFixed(2) + ' kWh'}</span>
                </li>
            }
            {facility.hasOwnProperty('batterySystem') &&
                <li className="from">
                    <span className="strong">From Battery</span>
                    <span className="em">{(data.loadFromBattery).toFixed(2) + ' kWh'}</span>
                </li>
            }
            
            <li className="from">
                <span className="strong">From Grid</span>
                <span className="em">{(data.loadFromGrid).toFixed(2) + ' kWh'}</span>
            </li>
            {facility.hasOwnProperty('hotWaterTank') &&
                <>
                    <li className="odd">
                        <span className="strong">To Hot Water</span>
                        <span className="em">{(data.toHw).toFixed(2) + ' kWh'}</span>
                    </li>
                    <li className="from">
                        <span className="strong">From PV</span>
                        <span className="em">{(data.hwFromPv).toFixed(2) + ' kWh'}</span>
                    </li>
                    {facility.hasOwnProperty('batterySystem') &&
                        <li className="from">
                            <span className="strong">From Battery</span>
                            <span className="em">{(data.hwFromBattery).toFixed(2) + ' kWh'}</span>
                        </li>
                    }
                    <li className="from">
                        <span className="strong">From Grid</span>
                        <span className="em">{(data.hwFromGrid).toFixed(2) + ' kWh'}</span>
                    </li>
                </>
            }
            {isDC ? (<>
                <li className={'odd'}>
					<span className="strong">To Inverter</span>
					<span className="em">{data.toInverter.toFixed(2) + ' kWh'}</span>
				</li>
                
                <li className="from">
                    <span className="strong">From PV</span>
                    <span className="em">{(data.inverterFromPv).toFixed(2) + ' kWh'}</span>
                </li>
                <li className="from">
                    <span className="strong">From Grid</span>
                    <span className="em">{(data.inverterFromGrid).toFixed(2) + ' kWh'}</span>
                </li>
                </>
			) : null}
            {facility.hasOwnProperty('batterySystem') &&
                <>
                    <li className="odd">
                        <span className="strong">{`${isDC? 'DC ': ''}To Battery`}</span>
                        <span className="em">{(data.toBattery).toFixed(2) + ' kWh'}</span>
                    </li>
                    {facility.hasOwnProperty('batterySystem') && isDC &&
                        <li className="from">
                            <span className="strong">From DC PV</span>
                            <span className="em">{(data.batteryFromDcPv).toFixed(2) + ' kWh'}</span>
                        </li>
                    }
                    <li className="from">
                        <span className="strong">From PV</span>
                        <span className="em">{(data.batteryFromPv).toFixed(2) + ' kWh'}</span>
                    </li>
                    <li className="from">
                        <span className="strong">From Grid</span>
                        <span className="em">{(data.batteryFromGrid).toFixed(2) + ' kWh'}</span>
                    </li>
                </>
            }

            <li className="odd">
                <span className="strong">To Grid</span>
                <span className="em">{(data.toGrid).toFixed(2) + ' kWh'}</span>
            </li>
            <li className="from">
                <span className="strong">From PV</span>
                <span className="em">{(data.gridFromPV).toFixed(2) + ' kWh'}</span>
            </li>
            {facility.hasOwnProperty('batterySystem') && isDC &&
                <li className="from">
                    <span className="strong">From DC PV</span>
                    <span className="em">{(data.gridFromDcPv).toFixed(2) + ' kWh'}</span>
                </li>
            }
            {facility.hasOwnProperty('batterySystem') &&
                <li className="from">
                    <span className="strong">From Battery</span>
                    <span className="em">{(data.gridFromBattery).toFixed(2) + ' kWh'}</span>
                </li>
            }

            <li className="odd">
                <span className="strong">DR Dispatched High</span>
                <span className="em">{(data.drImport).toFixed(2) + ' kWh'}</span>
            </li>
            <li className="even">
                <span className="strong">DR Dispatched Low</span>
                <span className="em">{(data.drExport).toFixed(2) + ' kWh'}</span>
            </li>
            <li className="odd">
                <span className="strong">DR Dispatched High - Low</span>
                <span className="em">{((data.energyFlowDifferenceForDr)).toFixed(2) + ' kWh'}</span>
            </li>
            {facility.hasOwnProperty('batterySystem') &&
                <li className="even">
                    <span className="strong">{`${isDC? 'DC ': ''}To Battery - From Battery`}</span>
                    <span className="em">{(data.energyFlowDifferenceForBattery).toFixed(2) + ' kWh'}</span>
                </li>
            }
            {facility.hasOwnProperty('batterySystem') && isDC &&
                <li className="odd">
                    <span className="strong">To Inverter - From Inverter</span>
                    <span className="em">{(data.energyFlowDifferenceForInverter).toFixed(2) + ' kWh'}</span>
                </li>
            }
            <li className={facility.hasOwnProperty('batterySystem') ? "even" : "odd"}>
                <span className="strong">From Grid - To Grid</span>
                <span className="em">{(data.energyFlowDifferenceForGrid).toFixed(2) + ' kWh'}</span>
            </li>
        </ul>
    );
}

export default SummaryTable;