/* Copyright Levelise Ltd 2021-2022 */
import React, { useState, useContext, useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import cx from 'classnames';
import FleetContext from '../../../contexts/FleetContext';
import {
    TIME_FRAMES,
    HOT_WATER,
    HW_TEMP,
    HW_TEMP_HALF_HOUR_AND_DAY,
    all,
    resolutions,
    timestampDay,
    timestampSec
} from '../../../utils/constants';
import {
    CONTRACT_ANNOTATION,
    getTimeFormat,
    ldTemp,
    lqTemp,
    maxTemp,
    medianTemp,
    meanTemp,
    minTemp,
    udTemp,
    uqTemp,
    ambient,
    outlet,
    upTank7,
    upTank5,
    upTank3,
    upTank1,
    inlet,
    dataset,
    options,
    fleetChartTitles,
    xAxis,
    tooltipTitleCallbacks
} from '../../../utils/chart';


const x = { type: 'time' }
const x1 = null
const y = {
    stacked: false,
    ticks: {},
    label: 'Temperature (°C)',
    gridLines: { drawOnChartArea: true }
}
const y1 = null
const legendLabels = {
    font: { size: 10 }, 
    color: 'black',
    usePointStyle: true,
    boxWidth: 4,
    boxHeight: 4,
    padding: 8,
    generateLabels: function (chart) {
        let ls = ['Mean', 'Median', 'Full Range', 'Interdecile Range', 'Interquartile Range'];
        let fs = [meanTemp.backgroundColor, medianTemp.backgroundColor, maxTemp.backgroundColor,
        udTemp.backgroundColor, uqTemp.backgroundColor];
        let ss = [meanTemp.borderColor, medianTemp.borderColor, maxTemp.borderColor,
        udTemp.borderColor, uqTemp.borderColor];
        return ls.map((label, i) => {
            return {
                text: label,
                fillStyle: fs[i],
                hidden: false,
                lineCap: '',
                lineDash: [],
                lineDashOffset: 0,
                lineJoin: '',
                lineWidth: 1,
                strokeStyle: ss[i]
            }
        })
    }
}

const HwSensorTemperatureChart = ({ data, timezone, selectedTimezone, showPerformance }) => {
    const sensorTemperatureRef = useRef();
    const context = useContext(FleetContext);
    const [sensor, setSensor] = useState(outlet.type)
    const [chart, setChart] = useState({
        data: {
            labels: [],
            datasets: [
                dataset('line', meanTemp.type, meanTemp.backgroundColor, meanTemp.borderColor, 'y'),
                dataset('line', medianTemp.type, medianTemp.backgroundColor, medianTemp.borderColor, 'y'),

                dataset('line', minTemp.type, minTemp.backgroundColor, minTemp.borderColor, 'y', '+1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', ldTemp.type, ldTemp.backgroundColor, ldTemp.borderColor, 'y', '+1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', lqTemp.type, lqTemp.backgroundColor, lqTemp.borderColor, 'y', '+1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', uqTemp.type, uqTemp.backgroundColor, uqTemp.borderColor, 'y', false, 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', udTemp.type, udTemp.backgroundColor, udTemp.borderColor, 'y', '-1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', maxTemp.type, maxTemp.backgroundColor, maxTemp.borderColor, 'y', '-1', 0, 0, 0, 0, 0.1, 0, 0, 0),
            ]
        },
        options: options(fleetChartTitles.hwSensorTemperature[0], legendLabels, x, x1, y, y1)
    });

    const handleDataOnChange = (data) => {
        let resolution = data.resolution_temp;
        if (data.timeFrame !== TIME_FRAMES.select) {
            switch (data.timeFrame) {
                case TIME_FRAMES.fifteen_minutes:
                    resolution = resolutions.second;
                    break;
                case TIME_FRAMES.one_hour:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.six_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.twenty_four_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.one_week:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.twenty_one_days:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.three_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.twelve_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.thirty_six_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.all:
                    resolution = resolutions.week;
                    break;
                default:
                    break;
            }
        }

        switch (resolution) {
            case resolutions.week:
            case resolutions.day:
                populateChartByDay(data.temperature, resolution);
                break
            case resolutions.half_hour:
                populateChartByMinuteAndHalfHour(data.temperature, resolution);
                break
            case resolutions.minute:
                populateChartByMinuteAndHalfHour(data.temperature, resolution);
                break
            case resolutions.second:
                populateChartByMinuteAndHalfHour(data.temperature, resolutions.minute, data.updated);
                break;
            default:
                break;
        }
    }

    const populateChartByDay = (reports, resolution) => {
        const type = HOT_WATER[sensor];
        let labels = [];
        let datasets = chart.data.datasets.map(d => {
            d.data = [];
            return d;
        });

        if (!reports.length) return;
        for (let i = reports.length - 1; i >= 0; i--) {
            datasets = datasets.map(set => {
                const temperature = reports[i][all][type]
                set.data.push(temperature[HW_TEMP_HALF_HOUR_AND_DAY[set.label]]);
                return set;
            });

            labels.push(reports[i][timestampDay] * 86400 * 1000);
        }

        handleSetChart(labels, datasets, resolution, []);
    }

    const populateChartByMinuteAndHalfHour = (reports, resolution, update = false) => {
        const type = HOT_WATER[sensor];
        let labels = [];
        let datasets = chart.data.datasets.map(d => { d.data = []; return d });

        if (!reports.length) return;
        const isHalfHour = resolution === resolutions.half_hour;
        for (let i = reports.length - 1; i >= 0; i--) {
            datasets = datasets.map(set => {
                const temperature = reports[i][all][type]
                if (isHalfHour) set.data.push(temperature[HW_TEMP_HALF_HOUR_AND_DAY[set.label]]);
                else set.data.push(temperature[HW_TEMP[set.label]]);
                return set;
            });

            labels.push(reports[i][timestampSec] * 1000);
        }

        const end = reports[0][timestampSec];
        const start = labels[0] / 1000;
        const annotation = end - start <= 90000 ? CONTRACT_ANNOTATION(end, timezone) : [];
        handleSetChart(labels, datasets, resolution, annotation);
    }

    const getTimeFrame = () => {
        let timeFrame = data.timeFrame;
        if (timeFrame === TIME_FRAMES.select) {
            const { temperature, resolution_temp } = data;
            const reportsLen = temperature.length;
            const { thirty_six_months, twelve_months, two_months, fifteen_days, one_week, twenty_four_hours } = TIME_FRAMES;

            switch (resolution_temp) {
                case resolutions.day:
                    timeFrame = reportsLen <= 60 ? two_months : reportsLen <= 366 ? twelve_months : thirty_six_months;
                    break;
                case resolutions.half_hour:
                    timeFrame = reportsLen <= 50 ? twenty_four_hours : reportsLen <= 336
                        ? one_week : fifteen_days;
                    break;
                case resolutions.minute:
                    timeFrame = twenty_four_hours;
                    break;
                default:
                    break;
            }
        }

        return timeFrame;
    }

    const handleSetChart = (labels, datasets, resolution, annotation) => {
        const title = `${fleetChartTitles.hwSensorTemperature[0]} by ${resolution}` + (resolution === resolutions.day || resolution === resolutions.week ? ' in fleet timezone' : '');
        const timeFrame = getTimeFrame();
        const update = {
            data: { labels: labels, datasets: datasets },
            options: {
                ...chart.options,
                layout: { padding: { right: 15 } },
                plugins: {
                    ...chart.options.plugins,
                    annotation: { annotations: annotation },
                    title: { ...chart.options.plugins.title, text: title },
                    tooltip: {
                        ...chart.options.plugins.tooltip,
                        callbacks: {
                            ...tooltipTitleCallbacks(selectedTimezone),
                            label: function (tooltipItem) {
                                let label = tooltipItem.dataset.label || '';
                                if (label === medianTemp.type || label === meanTemp.type) {
                                    return `${label}: ${tooltipItem.formattedValue}`;
                                }

                                if (label === maxTemp.type) {
                                    let lower = Math.round(datasets[2].data[tooltipItem.dataIndex] * 1000) / 1000;
                                    let upper = Math.round(tooltipItem.formattedValue * 1000) / 1000;
                                    return `Full Range: ${lower} — ${upper}`;
                                }

                                if (label === udTemp.type) {
                                    let lower = Math.round(datasets[3].data[tooltipItem.dataIndex] * 1000) / 1000;
                                    let upper = Math.round(tooltipItem.formattedValue * 1000) / 1000;
                                    return `Interdecile Range: ${lower} — ${upper}`;
                                }

                                if (label === uqTemp.type) {
                                    let lower = Math.round(datasets[4].data[tooltipItem.dataIndex] * 1000) / 1000;
                                    let upper = Math.round(tooltipItem.formattedValue * 1000) / 1000;
                                    return `Interquartile Range: ${lower} — ${upper}`;
                                }
                            },
                        }
                    }
                },
                scales: {
                    ...chart.options.scales,
                    x: xAxis(timeFrame, getTimeFormat(resolution), selectedTimezone)
                }
            }
        };

        update.options.plugins.zoom.zoom.drag.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        update.options.plugins.zoom.zoom.pinch.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        setChart(update);
    }

    const handleOnChange = value => {
        setSensor(value);
        context.setSelectedSensor(value);
    }

    useEffect(() => {
        if (!!context.selectedSensor && sensor !== context.selectedSensor) setSensor(context.selectedSensor);
    }, [context.selectedSensor])

    useEffect(() => {
        if (data.updated) handleDataOnChange(data);
    }, [data.temperature])

    useEffect(() => {
        if (!!data.resolution_temp && data.timeFrame === TIME_FRAMES.select) handleDataOnChange(data);
    }, [data.resolution_temp, data.temperature])

    useEffect(() => {
        handleDataOnChange(data);
    }, [data.timeFrame, data.type, sensor, selectedTimezone])

    return (
        <div className={cx("fleet-all-hw-sensor-chart af-records-chart", !showPerformance ? 'fleet-records' : '')}>
            <Line
                id={fleetChartTitles.hwSensorTemperature[1]}
                ref={sensorTemperatureRef}
                data={chart.data}
                options={chart.options}
            />
            <div className="sensorType" onChange={e => handleOnChange(e.target.value)}>
                <div>
                    <input type="radio" id="all-outlet" value={outlet.type} name="all-sensor"
                        onChange={() => { }} checked={outlet.type === sensor} />
                    <label htmlFor='all-outlet'>{outlet.type}</label>
                </div>
                <div>
                    <input type="radio" id="all-upTank7" value={upTank7.type} name="all-sensor"
                        onChange={() => { }} checked={upTank7.type === sensor} />
                    <label htmlFor='all-upTank7'>{upTank7.type.slice(0, -5)}</label>
                </div>
                <div>
                    <input type="radio" id="all-upTank5" value={upTank5.type} name="all-sensor"
                        onChange={() => { }} checked={upTank5.type === sensor} />
                    <label htmlFor='all-upTank5'>{upTank5.type.slice(0, -5)}</label>
                </div>
                <div>
                    <input type="radio" id="all-upTank3" value={upTank3.type} name="all-sensor"
                        onChange={() => { }} checked={upTank3.type === sensor} />
                    <label htmlFor='all-upTank3'>{upTank3.type.slice(0, -5)}</label>
                </div>
                <div>
                    <input type="radio" id="all-upTank1" value={upTank1.type} name="all-sensor"
                        onChange={() => { }} checked={upTank1.type === sensor} />
                    <label htmlFor='all-upTank1'>{upTank1.type.slice(0, -5)}</label>
                </div>
                <div>
                    <input type="radio" id="all-inlet" value={inlet.type} name="all-sensor"
                        onChange={() => { }} checked={inlet.type === sensor} />
                    <label htmlFor='all-inlet'>{inlet.type}</label>
                </div>
                <div>
                    <input type="radio" id="all-ambient" value={ambient.type} name="all-sensor"
                        onChange={() => { }} checked={ambient.type === sensor} />
                    <label htmlFor='all-ambient'>{ambient.type}</label>
                </div>
            </div>
        </div>
    )
}

export default HwSensorTemperatureChart;