/* Copyright Levelise Ltd 2024 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './index.css';

const DeleteBtn = ({ onDeleteClick, style }) => {
     

    return (
        <div id='add-btn' className="delete" style={style} onClick={() => onDeleteClick()} data-cy="delete-button">
            <FontAwesomeIcon icon={faTrash} style={{fontSize: 11.5}} />
        </div>
    )
}

export default DeleteBtn;
