/* Copyright Levelise Ltd 2020-2022 */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { getUnixTime, subDays } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Chart } from 'chart.js';
import { getSinceAndBefore } from '../../utils/utils'
import FleetService from '../../services/fleet-service';
import FleetContext from '../../contexts/FleetContext';

import EnergyChart from './charts/energy';
import LoadChart from './charts/load';
import GenerationChart from './charts/generation';

import { CONTRACT_TIMEFRAME, TIME_FRAMES, fleet, timestampSec, timestampDay, resolutions } from '../../utils/constants';
import { filterDataByTimeframe } from '../../utils/utils';
import './index.css';


const FleetEnergyCharts = ({ view, energy, showDistinct, showPerformance }) => {
    const fleetContext = useContext(FleetContext);
    const intervalId = useRef();
    const hasFmData = useRef(null);
    const [fmData, setfmData] = useState({ hasFmData: false, reports: [] });
    const [data, setData] = useState({ type: '', timeFrame: '', reports: [], resolution: '', updated: false });

    const days = {
        [TIME_FRAMES.thirty_six_months]: 1096,
        [TIME_FRAMES.twelve_months]: 366,
        [TIME_FRAMES.three_months]: 91,
    }

    const half_hours = {
        [TIME_FRAMES.twenty_one_days]: 1440 * 60 * 21,
        [TIME_FRAMES.one_week]: 1440 * 60 * 7
    }

    const minutes = {
        [TIME_FRAMES.twenty_four_hours]: 1440 * 60,
        [TIME_FRAMES.twelve_hours]: 720 * 60,
        [TIME_FRAMES.six_hours]: 360 * 60,
        [TIME_FRAMES.one_hour]: 60 * 60,
        [TIME_FRAMES.fifteen_minutes]: 15 * 60,
    }

    const fetchRecords = async (resolution, since = null, before = null) => {
        if (fleetContext.currentDataType === fleet || fleetContext.currentDataType === "") {
            try {
                const res = await FleetService.getFleetRecords(resolution, since, before);
                return res;
            } catch (err) {
                fleetContext.setError(err);
                return await Promise.reject(err);
            }
        }
        try {
            const res_1 = await FleetService.getAggFacilityRecords(fleetContext.currentDataType, resolution, since, before);
            return res_1;
        } catch (err_1) {
            fleetContext.setError(err_1);
            return await Promise.reject(err_1);
        }
    }

    const doUpdate = () => {
        return !(hasFmData.current ||
            Object.values(Chart.instances).some(ci => ci.tooltip?._active?.length) ||
            Object.values(Chart.instances).some(ci => ci.getZoomLevel() > 1.0))
    }

    const handleTimeframeChange = () => {
        const type = fleetContext.currentDataType;
        const timeFrame = fleetContext.selectedTimeFrame;

        switch (timeFrame) {
            case TIME_FRAMES.all:
                Promise.resolve(fetchRecords(resolutions.week)).then(d => {
                    if (!!d) {
                        handleSetData(type, timeFrame, d, resolutions.week);
                    }
                })
                break;
            case TIME_FRAMES.thirty_six_months:
            case TIME_FRAMES.twelve_months:
            case TIME_FRAMES.three_months:
                Promise.resolve(fetchRecords(resolutions.day)).then(d => {
                    if (!!d) {
                        const filteredData = filterDataByTimeframe(d, days[timeFrame], timestampDay);
                        handleSetData(type, timeFrame, filteredData, resolutions.day);
                    }
                })
                break;
            case TIME_FRAMES.twenty_one_days:
            case TIME_FRAMES.one_week:
                Promise.all([fetchRecords(resolutions.half_hour)]).then(d => {
                    if (!!d) {
                        const filteredData = filterDataByTimeframe(d[0], half_hours[timeFrame], timestampSec);
                        handleSetData(type, timeFrame, filteredData);
                    }
                })
                break;
            case TIME_FRAMES.twenty_four_hours:
            case TIME_FRAMES.six_hours:
            case TIME_FRAMES.one_hour:
                Promise.all([fetchRecords(resolutions.minute)]).then(d => {
                    if (!!d) {
                        if (timeFrame !== TIME_FRAMES.twenty_four_hours) {
                            d[0] = filterDataByTimeframe(d[0], minutes[timeFrame], timestampSec);
                        }
                        handleSetData(type, timeFrame, d[0]);
                    }
                })

                intervalId.current = setInterval(() => {
                    if (doUpdate()) {
                        Promise.all([fetchRecords(resolutions.minute)]).then(d => {
                            if (!!d) {
                                if (timeFrame !== TIME_FRAMES.twenty_four_hours) {
                                    d[0] = filterDataByTimeframe(d[0], minutes[timeFrame], timestampSec);
                                }
                                handleSetData(type, timeFrame, d[0], '', true);
                            }
                        })
                    }
                }, 5 * 60000);
                break;
            case TIME_FRAMES.fifteen_minutes:
                let sinceRecordSec
                Promise.all([fetchRecords(resolutions.second)]).then(d => {
                    if (!!d) {
                        if (!!d[0].length) sinceRecordSec = d[0][0][timestampSec] + 1;
                        handleSetData(type, timeFrame, d[0]);
                    }
                })

                intervalId.current = setInterval(() => {
                    if (doUpdate()) {
                        Promise.all([fetchRecords(resolutions.second, sinceRecordSec)]).then(d => {
                            if (!!d && !!d[0].length) {
                                handleSetData(type, timeFrame, d[0], '', true);
                                sinceRecordSec = d[0][0][timestampSec] + 1;
                            }
                        });
                    }
                }, 6000);
                break;
            case TIME_FRAMES.select:
                const { startDate, endDate, timezone } = fleetContext;
                if (startDate && endDate) {
                    const [since, before, resolution] = getSinceAndBefore(startDate, endDate,timezone);
                    if (since < before) {
                        Promise.resolve(fetchRecords(resolution, since, before)).then(d => {
                            if (!!d) {
                                handleSetData(type, timeFrame, d, resolution);
                            }
                        })
                    }
                }
                break;
        }
    }

    const handleSetData = (type, timeFrame, reports, resolution = '', updated = false) => {
        setData({ type: type, timeFrame, reports, resolution, updated });
        if (fmData.hasFmData) {
            hasFmData.current = false;
            setfmData({ hasFmData: false, reports: [] });
        }
    }

    const handleFmData = (since) => {
        const allowedTimeFrames = [...CONTRACT_TIMEFRAME, TIME_FRAMES.select];
        if (allowedTimeFrames.indexOf(fleetContext.selectedTimeFrame) >= 0) {
            if (fleetContext.selectedTimeFrame === TIME_FRAMES.select) {
                const timestamp = getUnixTime(zonedTimeToUtc(subDays(new Date(), 15), fleetContext.timezone));
                if (data.resolution !== resolutions.minute || since < timestamp)
                    return;
            }

            Promise.all([fetchRecords(resolutions.second, since)]).then(d => {
                if (!!d) {
                    hasFmData.current = true;
                    setfmData({ hasFmData: true, reports: d[0] });
                }
            })
        }
    }

    const extractSelectedTimezone = () => {
        if (fleetContext.selectedTimezone.length) {
            const list = fleetContext.selectedTimezone.split(' | ');
            return list[list.length - 1].trim();
        }

        return "";
    }


    useEffect(() => {
        if (fmData.hasFmData) {
            hasFmData.current = false;
            setfmData({ hasFmData: false, reports: [] });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fleetContext.resetZoom])

    useEffect(() => {
        const { currentDataType, timezone } = fleetContext;
        if (view === energy && !!currentDataType && !!timezone) {
            clearInterval(intervalId.current);
            handleTimeframeChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        fleetContext.currentDataType,
        fleetContext.timezone,
        fleetContext.selectedTimeFrame,
        fleetContext.startDate,
        fleetContext.endDate
    ])

    useEffect(() => {
        return () => clearInterval(intervalId.current);
    }, [])

    return (
        <>
            <div className="left-graphs-wrapper af-view">
                <EnergyChart
                    fmData={fmData}
                    handleFmData={(timestamp) => handleFmData(timestamp)}
                    data={data}
                    showDistinct={showDistinct}
                    timezone={fleetContext.timezone}
                    selectedTimezone={extractSelectedTimezone()}
                    showPerformance={showPerformance}
                />
            </div>
            <div className="right-graphs-wrapper">
                <LoadChart
                    fmData={fmData}
                    handleFmData={(timestamp) => handleFmData(timestamp)}
                    data={data}
                    timezone={fleetContext.timezone}
                    selectedTimezone={extractSelectedTimezone()}
                    showPerformance={showPerformance}
                />
                <GenerationChart
                    fmData={fmData}
                    handleFmData={(timestamp) => handleFmData(timestamp)}
                    data={data}
                    timezone={fleetContext.timezone}
                    selectedTimezone={extractSelectedTimezone()}
                    showPerformance={showPerformance}
                />
            </div>
        </>
    )
}

export default FleetEnergyCharts;