/* Copyright Levelise Ltd 2021 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { download, loading, success } from '../../utils/constants';
import './index.css';

const DownloadBtn = ({ downloadState, clickDownload, style }) => {
    const className = downloadState === download
        ? download : downloadState === loading
            ? loading : success;

    const getDownLoadIcon = () => {
        if (downloadState === download)
            return <FontAwesomeIcon icon={faDownload} style={{fontSize: 11.5}} />
        if (downloadState === loading)
            return <FontAwesomeIcon icon={faSpinner} style={{fontSize: 11.5}} spin />
        if (downloadState === success)
            return <FontAwesomeIcon icon={faCheck} style={{fontSize: 11.5}} />
    }

    return (
        <div id='download-btn' className={className} style={style} onClick={() => clickDownload()}>
            {getDownLoadIcon()}
        </div>
    )
}

export default DownloadBtn;
