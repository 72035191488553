/* Copyright Levelise Ltd 2024 */
import * as React from "react";

const PVSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={57}
    height={63}
    viewBox="0 0 57 63"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#010101"
        d="M53.661 63H3.338C1.494 63 0 61.619 0 59.926V36.65c0-.348.306-.63.684-.63.378 0 .684.282.684.63v23.275c0 1.003.887 1.819 1.976 1.819h50.323c1.09 0 1.976-.816 1.976-1.82V28.653c0-1.003-.887-1.819-1.976-1.819H3.338c-1.089 0-1.975.816-1.975 1.82v4.722c0 .348-.307.63-.684.63-.378 0-.684-.282-.684-.63v-4.723c0-1.698 1.5-3.074 3.338-3.074h50.323c1.844 0 3.339 1.381 3.339 3.074v31.274c0 1.698-1.5 3.074-3.339 3.074h.005Z"
      />
      <path
        fill="#010101"
        d="M12.976 60.792c-.378 0-.684-.282-.684-.63v-31.02c0-.348.306-.63.684-.63.378 0 .684.282.684.63v31.02c0 .348-.306.63-.684.63ZM6.885 60.792c-.378 0-.684-.282-.684-.63v-31.02c0-.348.306-.63.684-.63.377 0 .684.282.684.63v31.02c0 .348-.307.63-.684.63ZM19.172 60.792c-.378 0-.685-.282-.685-.63v-31.02c0-.348.307-.63.685-.63.377 0 .684.282.684.63v31.02c0 .348-.307.63-.684.63ZM25.367 60.792c-.378 0-.684-.282-.684-.63v-31.02c0-.348.306-.63.684-.63.377 0 .684.282.684.63v31.02c0 .348-.307.63-.684.63ZM31.562 60.792c-.378 0-.684-.282-.684-.63v-31.02c0-.348.306-.63.684-.63.378 0 .684.282.684.63v31.02c0 .348-.306.63-.684.63ZM37.757 60.792c-.377 0-.684-.282-.684-.63v-31.02c0-.348.307-.63.684-.63.378 0 .684.282.684.63v31.02c0 .348-.306.63-.684.63ZM43.953 54.009c-.378 0-.684-.283-.684-.63V29.14c0-.347.306-.63.684-.63.377 0 .684.283.684.63V53.38c0 .347-.307.63-.684.63ZM50.148 60.792c-.378 0-.684-.282-.684-.63v-31.02c0-.348.306-.63.684-.63.378 0 .684.282.684.63v31.02c0 .348-.306.63-.684.63Z"
      />
      <path
        fill="#010101"
        d="M53.831 33.496H3.371c-.377 0-.684-.282-.684-.63 0-.348.307-.63.684-.63h50.455c.377 0 .684.282.684.63 0 .348-.307.63-.684.63h.005ZM53.831 39.201H3.371c-.377 0-.684-.282-.684-.63 0-.348.307-.63.684-.63h50.455c.377 0 .684.282.684.63 0 .348-.307.63-.684.63h.005ZM53.831 44.906H3.371c-.377 0-.684-.282-.684-.63 0-.347.307-.63.684-.63h50.455c.377 0 .684.283.684.63 0 .348-.307.63-.684.63h.005ZM42.782 56.317H3.372c-.378 0-.685-.282-.685-.63 0-.348.307-.63.684-.63h39.41c.378 0 .685.282.685.63 0 .348-.307.63-.684.63ZM53.831 56.317h-6.545c-.378 0-.684-.282-.684-.63 0-.348.306-.63.684-.63h6.545c.378 0 .684.282.684.63 0 .348-.306.63-.684.63ZM53.831 50.556H3.371c-.377 0-.684-.282-.684-.63 0-.348.307-.63.684-.63h50.455c.377 0 .684.282.684.63 0 .348-.307.63-.684.63h.005Z"
      />
      <path
        fill="#fff"
        d="M50.312 20.11c0-3.004-2.145-5.554-5.095-6.426 1.423-2.535.98-5.73-1.33-7.858-2.293-2.106-5.736-2.525-8.477-1.245C34.43 1.921 31.693 0 28.475 0c-3.218 0-5.916 1.895-6.917 4.526-2.72-1.21-6.092-.776-8.352 1.3-2.271 2.092-2.73 5.227-1.39 7.747C8.893 14.46 6.78 17 6.78 19.979c0 2.978 2.145 5.554 5.095 6.426-1.417 2.535-.974 5.735 1.335 7.862 1.38 1.27 3.219 1.97 5.172 1.97a7.768 7.768 0 0 0 3.3-.72c.986 2.656 3.722 4.571 6.935 4.571 3.212 0 5.91-1.89 6.912-4.516.98.434 2.058.666 3.18.666 1.953 0 3.792-.701 5.177-1.976 2.277-2.092 2.736-5.227 1.39-7.747 2.923-.882 5.035-3.422 5.035-6.405Z"
      />
      <path
        fill="#61B466"
        d="M28.596 27.377c-4.307 0-7.81-3.225-7.81-7.192 0-3.966 3.503-7.192 7.81-7.192 4.307 0 7.81 3.226 7.81 7.192 0 3.967-3.503 7.192-7.81 7.192Zm0-11.99c-2.873 0-5.205 2.152-5.205 4.793s2.337 4.793 5.205 4.793S33.8 22.821 33.8 20.18c0-2.64-2.336-4.793-5.204-4.793ZM21.087 14.283c-.334 0-.668-.116-.92-.352l-2.703-2.49a1.13 1.13 0 0 1 0-1.694 1.378 1.378 0 0 1 1.839 0l2.703 2.49a1.13 1.13 0 0 1 0 1.694 1.361 1.361 0 0 1-.919.352ZM36.006 14.283c-.334 0-.668-.116-.92-.352a1.13 1.13 0 0 1 0-1.694l2.704-2.49a1.378 1.378 0 0 1 1.84 0 1.13 1.13 0 0 1 0 1.694l-2.705 2.49a1.361 1.361 0 0 1-.919.352ZM18.383 30.694c-.334 0-.667-.116-.92-.353a1.13 1.13 0 0 1 0-1.694l2.704-2.49a1.378 1.378 0 0 1 1.84 0 1.13 1.13 0 0 1 0 1.694l-2.704 2.49a1.361 1.361 0 0 1-.92.353ZM38.71 30.694c-.334 0-.668-.116-.92-.353l-2.703-2.49a1.13 1.13 0 0 1 0-1.693 1.378 1.378 0 0 1 1.838 0l2.704 2.49a1.13 1.13 0 0 1 0 1.693 1.361 1.361 0 0 1-.92.353ZM17.93 21.178h-3.826c-.717 0-1.303-.534-1.303-1.2 0-.665.58-1.199 1.303-1.199h3.825c.717 0 1.303.534 1.303 1.2 0 .665-.58 1.2-1.303 1.2ZM28.475 11.466c-.717 0-1.302-.534-1.302-1.2V6.745c0-.66.58-1.2 1.302-1.2.723 0 1.303.534 1.303 1.2v3.523c0 .66-.58 1.199-1.303 1.199ZM28.618 34.544c-.717 0-1.303-.534-1.303-1.2v-3.522c0-.66.58-1.2 1.303-1.2.722 0 1.302.534 1.302 1.2v3.523c0 .66-.58 1.2-1.302 1.2ZM42.99 21.31h-3.826c-.717 0-1.303-.535-1.303-1.2 0-.666.58-1.2 1.303-1.2h3.825c.717 0 1.303.534 1.303 1.2 0 .665-.58 1.2-1.303 1.2Z"
      />
    </g>
  </svg>
)
export default PVSVG
