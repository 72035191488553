/* Copyright Levelise Ltd 2020 - 2024 */

export const customStyles = (type, hasRequired) => {
    const windowWidth = window.screen.width;
    const borderStyle = hasRequired && !type 
        ? '1px solid rgb(202, 0, 0)' : '1px solid grey';
    const _width = windowWidth <= 340 
        ? '290px' : windowWidth <= 380 
        ? '300px' : windowWidth <= 580 
        ? '318px' : '300px'; 
    return {
        menu: (provided, state) => ({
            ...provided,
            padding: 0,
            minWidth: _width,
            width: _width,
        }),
        container: (provided) => ({
            ...provided,
            minHeight: 1,
            width: 200,
            textAlign: 'left',
            border: 'none',
        }),
        control: (provided) => ({
            ...provided,
            minHeight: 25,
            height: 25,
            minWidth: _width,
            width: _width,
            border: borderStyle,
            boxShadow: 'none',
            '&:hover': {border: '1px solid grey'},
            '&:focus': {border: '1px solid grey'}
        }),
        input: (provided) => ({
            ...provided,
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            minHeight: 1,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            minHeight: 1,
            height: 8,
        }),
        clearIndicator: (provided) => ({
            ...provided,
            minHeight: 1,
        }),
        valueContainer: (provided) => ({
            ...provided,
            minHeight: 1,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        singleValue: (provided) => ({
            ...provided,
            minHeight: 0,
            paddingBottom: 0,
        }),
    };

}

export const customStylesForEdit = () => {
    const windowWidth = window.screen.width;
    const borderStyle = '1px solid grey';
    const _width = windowWidth <= 399 
    ? '200px' : '245px';

    return {
        menu: (provided, state) => ({
            ...provided,
            padding: 0,
            minWidth: _width,
            width: _width,
            maxHeight: 180,
            overflow: 'hidden'
        }),
        menuList: (provided, state) => ({
            ...provided,
            maxHeight: 180,
            overflowY: 'auto'
        }),
        container: (provided) => ({
            ...provided,
            minHeight: 1,
            width: _width,
            textAlign: 'left',
            border: 'none',
        }),
        control: (provided) => ({
            ...provided,
            minHeight: 23,
            height: 23,
            minWidth: _width,
            width: _width,
            border: borderStyle,
            boxShadow: 'none',
            '&:hover': {border: '1px solid grey'},
            '&:focus': {border: '1px solid grey'}
        }),
        input: (provided) => ({
            ...provided,
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            minHeight: 1,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            minHeight: 1,
            height: 8,
        }),
        clearIndicator: (provided) => ({
            ...provided,
            minHeight: 1,
        }),
        valueContainer: (provided) => ({
            ...provided,
            minHeight: 1,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        singleValue: (provided) => ({
            ...provided,
            minHeight: 0,
            paddingBottom: 0,
        }),
    };

}