/* Copyright Levelise Ltd 2024 */

import { startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const NUMBER_OF_POINTS_ON_CHART = 49;

export const mapSingleRateTariff = (ratePerKwh) => {
	const mappedData = [];

	if (!ratePerKwh && ratePerKwh !== 0) {
		return mappedData;
	}

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < NUMBER_OF_POINTS_ON_CHART; i++) {
		mappedData.push({ x: i / 2, y: ratePerKwh });
	}

	return mappedData;
};

export const mapImportData = (dayRate, nightRate, nightStart, nightEnd) => {
	const mappedData = [];

	const nightHrStart = nightStart / 60;
	const nightHrEnd = nightEnd / 60;

	if (nightHrStart < nightHrEnd) {
		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < NUMBER_OF_POINTS_ON_CHART; i++) {
			if (i / 2 >= nightHrStart && i / 2 < nightHrEnd) {
				mappedData.push({ x: i / 2, y: nightRate });
			} else {
				mappedData.push({ x: i / 2, y: dayRate });
			}
		}
	} else {
		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < NUMBER_OF_POINTS_ON_CHART; i++) {
			if (i / 2 >= nightHrEnd && i / 2 < nightHrStart) {
				mappedData.push({ x: i / 2, y: dayRate });
			} else {
				mappedData.push({ x: i / 2, y: nightRate });
			}
		}
	}

	return mappedData;
};

export const mapTypeTwoTariff = (tariff) => {
	let impTariff = [];

	if (
		Object.hasOwn(tariff, 'dayRatePerKwh') &&
		Object.hasOwn(tariff, 'nightRatePerKwh') &&
		Object.hasOwn(tariff, 'nightStartMinute') &&
		Object.hasOwn(tariff, 'nightEndMinute')
	) {
		if (
			!isNaN(tariff.dayRatePerKwh) &&
			!isNaN(tariff.nightRatePerKwh) &&
			!isNaN(tariff.nightStartMinute) &&
			!isNaN(tariff.nightEndMinute)
		) {
			impTariff = mapImportData(
				tariff.dayRatePerKwh,
				tariff.nightRatePerKwh,
				tariff.nightStartMinute,
				tariff.nightEndMinute
			);
		}
	}

	return impTariff;
};

export const mapThirtyMinuteTariff = (hhRatesPerKwh, todayStartTimestamp) => {
	const tariffRates = Array.from({ length: NUMBER_OF_POINTS_ON_CHART }, (_, index) => ({
		x: index / 2,
		y: null,
	}));

	if (
		hhRatesPerKwh &&
		Object.hasOwn(hhRatesPerKwh, 'timestampSec') &&
		Object.hasOwn(hhRatesPerKwh, 'ratePerKwh') &&
		hhRatesPerKwh?.timestampSec?.length > 0 &&
		hhRatesPerKwh?.ratePerKwh?.length > 0
	) {
		const timestampArray = hhRatesPerKwh?.timestampSec;
		const tariffRatesArray = hhRatesPerKwh?.ratePerKwh;

		if (timestampArray.length > 0 && tariffRatesArray.length > 0) {
			for (let i = 0; i < timestampArray.length; i++) {
				if (timestampArray[i] >= todayStartTimestamp) {
					const timeDifference = (timestampArray[i] - todayStartTimestamp) / 60;
					// get index of tariff based on the time difference
					const index = timeDifference / 30;

					if (tariffRates[index] && Object.hasOwn(tariffRates[index], 'y')) {
						tariffRates[index].y = tariffRatesArray[i];
					}
				}
			}
		}
	}

	return tariffRates;
};

export const getZonedTimestamp = (timestamp, tz = 'Europe/London') => {
	const date = new Date(timestamp);

	const zonedDate = utcToZonedTime(date, tz);

	return zonedDate.getTime();
};

export const getZonedDayStartTimestampSec = (tz) => {
	const date = new Date();
	const zonedDate = utcToZonedTime(date, tz);
	const startOfZonedDate = startOfDay(zonedDate);

	return startOfZonedDate / 1000;
};

export const mapTypeThreeTariff = (importTariffHalfHourly, timezone = 'Europe/London') => {
	let importTariffTemp = [];

	const todayStartTimestamp = getZonedDayStartTimestampSec(timezone);

	if (importTariffHalfHourly) {
		importTariffTemp = mapThirtyMinuteTariff(importTariffHalfHourly, todayStartTimestamp);
	}

	return importTariffTemp;
};

export const mapTypeFourTariff = (dailyRatesPerKwh) => {
	let impTariff = [];

	if (
		dailyRatesPerKwh &&
		Object.hasOwn(dailyRatesPerKwh, 'ratePerKwh') &&
		Object.hasOwn(dailyRatesPerKwh, 'startMinute') &&
		dailyRatesPerKwh.ratePerKwh?.length > 0 &&
		dailyRatesPerKwh.startMinute?.length > 0
	) {
		const { startMinute, ratePerKwh } = dailyRatesPerKwh;

		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < startMinute.length; i++) {
			let dataArr = [];
			if (i === startMinute.length - 1) {
				const arrayLength = (24 * 60 - startMinute[i]) / 30 + 1;

				dataArr = Array.from({ length: arrayLength }, (_, index) => ({
					x: (startMinute[i] + index * 30) / 60,
					y: ratePerKwh[i],
				}));
			} else {
				const arrayLength = (startMinute[i + 1] - startMinute[i]) / 30;

				dataArr = Array.from({ length: arrayLength }, (_, index) => ({
					x: (startMinute[i] + index * 30) / 60,
					y: ratePerKwh[i],
				}));
			}

			impTariff = [...impTariff, ...dataArr];
		}
	}

	return impTariff;
};

export const suppliersIdNameObject = {
    1: 'British Gas',
    2: 'Bulb',
    3: 'E.ON',
    4: 'EDF Energy',
	5: 'Octopus Energy',
    6: 'OVO Energy',
    7: 'Scottish Power',
    8: 'Shell Energy',
    9: 'Utilita',
    10: 'Utility Warehouse',
    11: 'Co-op Energy',
    12: 'Ecotricity',
    13: 'Good Energy',
    14: 'Green Energy UK',
    15: 'Octopus Energy',
    16: 'Outfox the Market',
    17: "Sainsbury's Energy",
    18: 'So Energy',
	50: 'Unknown',
    51: 'social Energy',
};
