/* Copyright Levelise Ltd 2021-2023 */
import React, { useState, useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import cx from 'classnames';
import {
    TIME_FRAMES,
    CONNECTED_DRUS,
    all,
    concentrator,
    timestampSec,
    timestampDay,
    resolutions,
    fleet,
    concentratorOaas
} from '../../../utils/constants';
import {
    CONTRACT_ANNOTATION,
    allConnected,
    allConcentrator,
    allConcentratorOaas,
    minConnected,
    maxConnected,
    meanConnected,
    minConcentratorConnected,
    maxConcentratorConnected,
    meanConcentratorConnected,
    minConcentratorOaasConnected,
    maxConcentratorOaasConnected,
    meanConcentratorOaasConnected,
    getTimeFormat,
    dataset,
    options,
    fleetChartTitles,
    xAxis,
    tooltipTitleCallbacks
} from '../../../utils/chart';


const x = { type: 'time' }
const x1 = null
const y = {
    stacked: false,
    ticks: {},
    label: 'Count',
    gridLines: { drawOnChartArea: true }
}
const y1 = null
const legendLabels = {
    font: { size: 10 }, 
    color: 'black',
    usePointStyle: true,
    boxWidth: 4,
    boxHeight: 4,
    padding: 8,
    generateLabels: (chart) => {
        const ls = [
            allConnected.type, allConcentratorOaas.type, allConcentrator.type,
            'Mean', 'Full Range',
            'Concentrator Oaas Mean', 'Full Range',
            'Concentrator Mean', 'Full Range',
        ];
        const fs = [
            allConnected.backgroundColor, allConcentratorOaas.backgroundColor, allConcentrator.backgroundColor,
            meanConnected.backgroundColor, maxConnected.backgroundColor,
            meanConcentratorOaasConnected.backgroundColor, maxConcentratorOaasConnected.backgroundColor,
            meanConcentratorConnected.backgroundColor, maxConcentratorConnected.backgroundColor,
        ];
        const ss = [
            allConnected.borderColor, allConcentratorOaas.borderColor, allConcentrator.borderColor,
            meanConnected.borderColor, maxConnected.borderColor,
            meanConcentratorOaasConnected.borderColor, maxConcentratorOaasConnected.borderColor,
            meanConcentratorConnected.borderColor, maxConcentratorConnected.borderColor,
        ];

        const labels = ls.map((label, i) => ({ text: label, fillStyle: fs[i], hidden: false, strokeStyle: ss[i] }));
        if (chart.data.datasets[0].hidden) labels[0].hidden = true;
        if (chart.data.datasets[1].hidden) labels[1].hidden = true;
        if (chart.data.datasets[2].hidden) labels[2].hidden = true;

        if (chart.data.datasets[3].hidden) labels[3].hidden = true;
        if (chart.data.datasets[6].hidden && chart.data.datasets[7].hidden) labels[4].hidden = true;

        if (chart.data.datasets[4].hidden) labels[5].hidden = true;
        if (chart.data.datasets[8].hidden && chart.data.datasets[9].hidden) labels[6].hidden = true;

        if (chart.data.datasets[5].hidden) labels[7].hidden = true;
        if (chart.data.datasets[10].hidden && chart.data.datasets[11].hidden) labels[8].hidden = true;
        return labels.filter(l => !l.hidden);
    },
}

const ConnectedChart = ({ fmData, handleFmData, data, timezone, selectedTimezone, aggregation, showPerformance }) => {
    const actualRadio = 'Actual Range',
        concentratorRadio = 'Concentrator Range',
        concentratorOaasRadio = 'Concentrator OaaS Range';
    const connectedRef = useRef();
    const [option, setOption] = useState(actualRadio);
    const [currentResolution, setCurrentResolution] = useState(resolutions.minute);
    const [chart, setChart] = useState({
        data: {
            labels: [],
            datasets: [
                dataset('line', allConnected.type, allConnected.backgroundColor, allConnected.borderColor, 'y'),
                dataset('line', allConcentratorOaas.type, allConcentratorOaas.backgroundColor, allConcentratorOaas.borderColor, 'y'),
                dataset('line', allConcentrator.type, allConcentrator.backgroundColor, allConcentrator.borderColor, 'y'),

                dataset('line', meanConnected.type, meanConnected.backgroundColor, meanConnected.borderColor, 'y'),
                dataset('line', meanConcentratorOaasConnected.type, meanConcentratorOaasConnected.backgroundColor, meanConcentratorOaasConnected.borderColor, 'y'),
                dataset('line', meanConcentratorConnected.type, meanConcentratorConnected.backgroundColor, meanConcentratorConnected.borderColor, 'y'),

                dataset('line', maxConnected.type, maxConnected.backgroundColor, maxConnected.borderColor, 'y', '+1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', minConnected.type, minConnected.backgroundColor, minConnected.borderColor, 'y', false, 0, 0, 0, 0, 0.1, 0, 0, 0),

                dataset('line', maxConcentratorOaasConnected.type, maxConcentratorOaasConnected.backgroundColor, maxConcentratorOaasConnected.borderColor, 'y', '+1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', minConcentratorOaasConnected.type, minConcentratorOaasConnected.backgroundColor, minConcentratorOaasConnected.borderColor, 'y', false, 0, 0, 0, 0, 0.1, 0, 0, 0),

                dataset('line', maxConcentratorConnected.type, maxConcentratorConnected.backgroundColor, maxConcentratorConnected.borderColor, 'y', '+1', 0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', minConcentratorConnected.type, minConcentratorConnected.backgroundColor, minConcentratorConnected.borderColor, 'y', false, 0, 0, 0, 0, 0.1, 0, 0, 0),

            ]
        },
        options: options(fleetChartTitles.connected[0], legendLabels, x, x1, y, y1)
    });

    const handleDataOnChange = (data) => {
        let resolution = data.resolution;
        if (data.timeFrame !== TIME_FRAMES.select) {
            switch (data.timeFrame) {
                case TIME_FRAMES.fifteen_minutes:
                    resolution = resolutions.second;
                    break;
                case TIME_FRAMES.one_hour:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.six_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.twenty_four_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.one_week:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.twenty_one_days:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.three_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.twelve_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.thirty_six_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.all:
                    resolution = resolutions.week;
                    break;
                default:
                    break;
            }
        }

        switch (resolution) {
            case resolutions.week:
            case resolutions.day:
                populateChartByDay(data.connected, resolution);
                break;
            case resolutions.half_hour:
                populateChartByMinuteAndHalfHour(data.connected, resolution);
                break;
            case resolutions.minute:
                populateChartByMinuteAndHalfHour(data.connected, resolution);
                break;
            case resolutions.second:
                populateChartBySecond(data.connected, resolution, data.updated);
                break;
            default:
                break;
        }
    }

    const updateDatasetHidden = (datasets, resolution) => {
        if (resolution === resolutions.second) {
            datasets[0].hidden = false;
            if (aggregation !== fleet) {
                datasets[1].hidden = false;
                datasets[2].hidden = false;
            } else {
                datasets[1].hidden = true;
                datasets[2].hidden = true;
            }
            datasets[3].hidden = true;
            datasets[4].hidden = true;
            datasets[5].hidden = true;
            datasets[6].hidden = true;
            datasets[7].hidden = true;
            datasets[8].hidden = true;
            datasets[9].hidden = true;
            datasets[10].hidden = true;
            datasets[11].hidden = true;
            return datasets
        }
        // else...
        datasets[0].hidden = true;
        datasets[1].hidden = true;
        datasets[2].hidden = true;
        datasets[3].hidden = true;
        datasets[4].hidden = true;
        datasets[5].hidden = true;
        datasets[6].hidden = true;
        datasets[7].hidden = true;
        datasets[8].hidden = true;
        datasets[9].hidden = true;
        datasets[10].hidden = true;
        datasets[11].hidden = true;
        if (aggregation !== fleet) {
            datasets[3].hidden = false;
            datasets[4].hidden = false;
            datasets[5].hidden = false;
            switch (option) {
                case concentratorOaasRadio:
                    datasets[8].hidden = false;
                    datasets[9].hidden = false;
                    break;
                case concentratorRadio:
                    datasets[10].hidden = false;
                    datasets[11].hidden = false;
                    break;
                default:
                    datasets[6].hidden = false;
                    datasets[7].hidden = false;
            }
        } else {
            datasets[3].hidden = false;
            datasets[6].hidden = false;
            datasets[7].hidden = false;
        }

        return datasets
    }

    const populateChartByDay = (reports, resolution) => {
        let labels = [];
        let datasets = chart.data.datasets.map(d => {
            d.data = [];
            return d;
        });

        if (!reports.length) return;
        for (let i = reports.length - 1; i >= 0; i--) {
            datasets[3].data.push(reports[i][all][CONNECTED_DRUS[meanConnected.type]]);
            datasets[6].data.push(reports[i][all][CONNECTED_DRUS[maxConnected.type]]);
            datasets[7].data.push(reports[i][all][CONNECTED_DRUS[minConnected.type]]);
            if (reports[i].hasOwnProperty(concentratorOaas)) {
                datasets[4].data.push(reports[i][concentratorOaas][CONNECTED_DRUS[meanConnected.type]]);
                datasets[8].data.push(reports[i][concentratorOaas][CONNECTED_DRUS[maxConnected.type]]);
                datasets[9].data.push(reports[i][concentratorOaas][CONNECTED_DRUS[minConnected.type]]);
            } else {
                datasets[4].data.push(null);
                datasets[8].data.push(null);
                datasets[9].data.push(null);
            }
            if (reports[i].hasOwnProperty(concentrator)) {
                datasets[5].data.push(reports[i][concentrator][CONNECTED_DRUS[meanConnected.type]]);
                datasets[10].data.push(reports[i][concentrator][CONNECTED_DRUS[maxConnected.type]]);
                datasets[11].data.push(reports[i][concentrator][CONNECTED_DRUS[minConnected.type]]);
            } else {
                datasets[5].data.push(null);
                datasets[10].data.push(null);
                datasets[11].data.push(null);
            }
            labels.push(reports[i][timestampDay] * 86400 * 1000);
        }

        handleSetChart(labels, updateDatasetHidden(datasets, resolution), resolution, []);
    }


    const populateChartByMinuteAndHalfHour = (reports, resolution) => {
        let labels = [];
        let datasets = chart.data.datasets.map(d => {
            d.data = [];
            return d;
        });

        if (!reports.length) return;
        for (let i = reports.length - 1; i >= 0; i--) {
            datasets[3].data.push(reports[i][all][CONNECTED_DRUS[meanConnected.type]]);
            datasets[6].data.push(reports[i][all][CONNECTED_DRUS[maxConnected.type]]);
            datasets[7].data.push(reports[i][all][CONNECTED_DRUS[minConnected.type]]);
            if (reports[i].hasOwnProperty(concentratorOaas)) {
                datasets[4].data.push(reports[i][concentratorOaas][CONNECTED_DRUS[meanConnected.type]]);
                datasets[8].data.push(reports[i][concentratorOaas][CONNECTED_DRUS[maxConnected.type]]);
                datasets[9].data.push(reports[i][concentratorOaas][CONNECTED_DRUS[minConnected.type]]);
            } else {
                datasets[4].data.push(null);
                datasets[8].data.push(null);
                datasets[9].data.push(null);
            }
            if (reports[i].hasOwnProperty(concentrator)) {
                datasets[5].data.push(reports[i][concentrator][CONNECTED_DRUS[meanConnected.type]]);
                datasets[10].data.push(reports[i][concentrator][CONNECTED_DRUS[maxConnected.type]]);
                datasets[11].data.push(reports[i][concentrator][CONNECTED_DRUS[minConnected.type]]);
            } else {
                datasets[5].data.push(null);
                datasets[10].data.push(null);
                datasets[11].data.push(null);
            }
            labels.push(reports[i][timestampSec] * 1000);
        }

        const end = reports[0][timestampSec];
        const start = reports[reports.length - 1][timestampSec];
        const annotation = end - start <= 90000 ? CONTRACT_ANNOTATION(end, timezone, true) : [];
        handleSetChart(labels, updateDatasetHidden(datasets, resolution), resolution, annotation);
    }

    const populateChartBySecond = (reports, resolution, update = false) => {
        let labels = [];
        let datasets = chart.data.datasets.map(d => { d.data = []; return d });

        if (!reports.length) return;
        for (let i = reports.length - 1; i >= 0; i--) {
            datasets[0].data.push(reports[i][all]);
            datasets[1].data.push(reports[i][concentratorOaas]);
            datasets[2].data.push(reports[i][concentrator]);
            labels.push(reports[i][timestampSec] * 1000);
        }

        const annotation = CONTRACT_ANNOTATION(reports[0][timestampSec], timezone);
        handleSetChart(labels, updateDatasetHidden(datasets, resolution), resolution, annotation);
    }

    const getTimeFrame = () => {
        let timeFrame = data.timeFrame;
        if (timeFrame === TIME_FRAMES.select) {
            const { connected, resolution } = data;
            const reportsLen = connected.length;
            const { thirty_six_months, twelve_months, two_months, fifteen_days, one_week, twenty_four_hours } = TIME_FRAMES;

            switch (resolution) {
                case resolutions.day:
                    timeFrame = reportsLen <= 60 ? two_months : reportsLen <= 366 ? twelve_months : thirty_six_months;
                    break;
                case resolutions.half_hour:
                    timeFrame = reportsLen <= 50 ? twenty_four_hours : reportsLen <= 336
                        ? one_week : fifteen_days;
                    break;
                case resolutions.minute:
                    timeFrame = twenty_four_hours;
                    break;
                default:
                    break;
            }
        }

        if (fmData.hasFmData) {
            return TIME_FRAMES.fifteen_minutes;
        }

        return timeFrame;
    }

    const handleSetChart = (labels, datasets, resolution, annotation) => {
        const title = `${fleetChartTitles.connected[0]} by ${resolution}` + (resolution === resolutions.day || resolution === resolutions.week ? ' in fleet timezone' : '');
        const timeFrame = getTimeFrame();
        const update = {
            data: { labels: labels, datasets: datasets },
            options: {
                ...chart.options,
                layout: { padding: { right: 12 } },
                plugins: {
                    ...chart.options.plugins,
                    annotation: { annotations: annotation },
                    title: { ...chart.options.plugins.title, text: title },
                    tooltip: {
                        ...chart.options.plugins.tooltip,
                        callbacks: {
                            ...tooltipTitleCallbacks(selectedTimezone),
                            label: function (tooltipItem) {
                                let label = tooltipItem.dataset.label || '';
                                switch (label) {
                                    case allConnected.type:
                                    case allConcentratorOaas.type:
                                    case allConcentrator.type:
                                    case meanConnected.type:
                                    case meanConcentratorConnected.type:
                                    case meanConcentratorOaasConnected.type:
                                        return `${label}: ${tooltipItem.formattedValue}`;
                                    case maxConnected.type:
                                        let lower = datasets[7].data[tooltipItem.dataIndex];
                                        let upper = tooltipItem.formattedValue;
                                        return `Full Range: ${!!lower ? lower.toLocaleString() : lower} — ${upper}`;
                                    case maxConcentratorOaasConnected.type:
                                        let lowerConcentratorOaas = datasets[9].data[tooltipItem.dataIndex];
                                        let upperConcentratorOaas = tooltipItem.formattedValue;
                                        return `Concentrator OaaS Range: ${!!lowerConcentratorOaas ? lowerConcentratorOaas.toLocaleString() : lowerConcentratorOaas} — ${upperConcentratorOaas}`;
                                    case maxConcentratorConnected.type:
                                        let lowerConcentrator = datasets[11].data[tooltipItem.dataIndex];
                                        let upperConcentrator = tooltipItem.formattedValue;
                                        return `Concentrator Range: ${!!lowerConcentrator ? lowerConcentrator.toLocaleString() : lowerConcentrator} — ${upperConcentrator}`;
                                    default:
                                        return
                                }
                            },
                        }
                    }
                },
                scales: {
                    ...chart.options.scales,
                    x: xAxis(timeFrame, getTimeFormat(resolution), selectedTimezone)
                },
                onClick: (event, items, chart) => {
                    if (!!items.length) handleFmData(parseInt(chart.data.labels[items[0].index] / 1000));
                }
            }
        };

        update.options.plugins.zoom.zoom.drag.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        update.options.plugins.zoom.zoom.pinch.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        setCurrentResolution(resolution);
        setChart(update);
    }

    useEffect(() => {
        if (fmData.hasFmData && !!fmData.connected.length) {
            populateChartBySecond(fmData.connected, resolutions.second)
        }
    }, [fmData.connected, selectedTimezone])

    useEffect(() => {
        if (data.updated) {
            handleDataOnChange(data);
        }
    }, [data.connected])

    useEffect(() => {
        if (!!data.resolution && data.timeFrame === TIME_FRAMES.select) {
            handleDataOnChange(data);
        }
    }, [data.resolution, data.connected])

    useEffect(() => {
        if (!fmData.hasFmData) {
            handleDataOnChange(data);
        }
    }, [data.timeFrame, data.type, fmData.hasFmData, selectedTimezone])

    useEffect(() => {
        if (aggregation !== fleet) {
            const ch = connectedRef.current;
            ch.data.datasets[6].hidden = true;
            ch.data.datasets[7].hidden = true;
            ch.data.datasets[8].hidden = true;
            ch.data.datasets[9].hidden = true;
            ch.data.datasets[10].hidden = true;
            ch.data.datasets[11].hidden = true;
            switch (option) {
                case concentratorOaasRadio:
                    ch.data.datasets[8].hidden = false;
                    ch.data.datasets[9].hidden = false;
                    break;
                case concentratorRadio:
                    ch.data.datasets[10].hidden = false;
                    ch.data.datasets[11].hidden = false;
                    break;
                default:
                    ch.data.datasets[6].hidden = false;
                    ch.data.datasets[7].hidden = false;
            }
            ch.update();
        }
    }, [option])

    return (
        <div
            className={cx("fleet-all-connected-chart af-records-chart", !showPerformance ? 'fleet-records' : '')}
            style={{ paddingBottom: currentResolution !== resolutions.second && aggregation !== fleet ? 8 : 0 }}>
            <Line
                id={fleetChartTitles.connected[1]}
                ref={connectedRef}
                data={chart.data}
                options={chart.options}
            />
            {currentResolution !== resolutions.second && aggregation !== fleet &&
                <div className="connectedType" onChange={e => setOption(e.target.value)}>
                    <div>
                        <input type="radio" id="all-connected" value={actualRadio} name="all-connected"
                            onChange={() => { }} checked={option === actualRadio} />
                        <label htmlFor='all-connected'>{actualRadio}</label>
                    </div>
                    <div>
                        <input type="radio" id="concentrator-oaas-connected" value={concentratorOaasRadio} name="concentrator-oaas-connected"
                            onChange={() => { }} checked={option === concentratorOaasRadio} />
                        <label htmlFor='concentrator-oaas-connected'>{concentratorOaasRadio}</label>
                    </div>
                    <div>
                        <input type="radio" id="concentrator-connected" value={concentratorRadio} name="concentrator-connected"
                            onChange={() => { }} checked={option === concentratorRadio} />
                        <label htmlFor='concentrator-connected'>{concentratorRadio}</label>
                    </div>
                </div>
            }
        </div>
    )
}

export default ConnectedChart;
