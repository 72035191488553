/* Copyright Levelise Ltd 2024 */
import React from 'react';
import './index.css';

function CustomerBankingDetailsSection({ bankingInfo, containerStyle }) {
	return (
		<div className="tab-container-customer" data-cy="banking_details_container">
			<div className='customer-banking-detail-con'>
				<div className="customer-screen-headers">
					<h2>Banking details</h2>
				</div>
				<ul className="customer-info-container" style={containerStyle}>
					<li>
						<span className="strong customer">Account Holder</span>
						<span className="em customer">{bankingInfo?.accountHolderName || '—'}</span>
					</li>
					<li>
						<span className="strong customer">Account Number</span>
						<span className="em customer">{bankingInfo?.accountNumber || '—'}</span>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default CustomerBankingDetailsSection;
