/* Copyright Levelise Ltd 2019-2023 */

export const handleError = error => {
    if (error.status === 401) {
        if (!!error.error && error.error === "invalid_grant")
            return true;
        // else...
        return false;
    }

    return [403, 500].includes(error.status) ? true : false;
}