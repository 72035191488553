/* Copyright Levelise Ltd 2019 */
import React, { Component } from 'react';

const UserContext = React.createContext({
    user: {},
    error: null,
    setUser: () => {},
    clearUser: () => {},
    setError: () => {},
    clearError: () => {},
})

export default UserContext

export class UserProvider extends Component {
    state = {
        user: {},
        error: null
    }

    setError = error => this.setState({ error });
    clearError = () => this.setState({ error: null });
    setUser = user => this.setState({user});

    clearUser = () => {
        this.setUser({});
        this.clearError();
    }

    render(){
        const contextValue = {
            user: this.state.user,
            error: this.state.error,
            setUser: this.setUser,
            clearUser: this.clearUser,
            setError: this.setError,
            clearError: this.clearError,
        }
        return (
            <UserContext.Provider value={contextValue}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}