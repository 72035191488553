/* Copyright Levelise Ltd 2019-2024 */
import React, { useState, useContext, useEffect, useRef, useMemo, useCallback } from 'react';
import cx from 'classnames';
import Select, { components } from 'react-select';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import config from '../../config';
import FleetContext from '../../contexts/FleetContext';
import FleetStatusContext from '../../contexts/FleetStatusContext';
import FleetService from '../../services/fleet-service';
import UserService from '../../services/user-service';
import { hasPermission } from '../../utils/utils';
import {
	PERMISSIONS,
	STATUS,
	COUNT,
	SEVERITY,
	fleet,
	INSTALLER_USER,
	STATUS_OPTIONS,
	download,
	loading,
	success,
	INACTIVE,
	RECONNECTED_CODE,
	RECENTLY_DISCONNECTED_CODE,
	DISCONNECTED_CODE,
	SEVERITY_COLOR,
} from '../../utils/constants';
import { GSP_GROUP, SETTLEMENT_METHOD } from '../../utils/specification';
import DownloadBtn from '../DownloadBtn/index';
import { customStyles } from './customStyle';
import './index.css';

const severityHighest = 'Highest severity',
	severityAllOccurrences = 'All occurrences ';

const Status = () => {
	const fleetContext = useContext(FleetContext);
	const fleetStatusContext = useContext(FleetStatusContext);

	const [downloadState, setDownloadState] = useState(download);

	const [showDownloadBtn, setShowDownloadBtn] = useState(false);

	const [dropdownUpdated, setDropdownUpdated] = useState(false);

	const [regions, setRegions] = useState([]);

	const [settlementMethods, setSettlementMethods] = useState([]);

	const [meteringTypes, setMeteringTypes] = useState([]);
	const [systemTypes, setSystemTypes] = useState([]);
	const [systems, setSystems] = useState([]);
	const [batteries, setBatteries] = useState([]);

	const [needUpdate, setNeedUpdate] = useState(false);

	const ref = useRef(null);

	const setSeverityLevel = (val) => {
		fleetStatusContext.setSeverityLevel(val);
	};

	const setOptimizationLevel = (val) => {
		fleetStatusContext.setOptimizationLevel(val);
	};

	const setAggFacility = (val) => {
		fleetStatusContext.setAggFacility(val);
	};

	const setAllSystems = (val) => {
		fleetStatusContext.setAllSystems(val);
	};

	const setAllSystemTypes = (val) => {
		fleetStatusContext.setAllSystemTypes(val);
	};

	const setAllBatterySystems = (val) => {
		fleetStatusContext.setAllBatterySystems(val);
	};

	const setAllBatteryNumber = (val) => {
		fleetStatusContext.setAllBatteryNumbers(val);
	};

	const setBatterySystem = (val) => {
		fleetStatusContext.setBatterySystem(val);
	};

	const setSettlementMethod = (val) => {
		fleetStatusContext.setSettlementMethod(val);
	};

	const setRegion = (val) => {
		fleetStatusContext.setRegion(val);
	};

	const setMeteringType = (val) => {
		fleetStatusContext.setMeteringType(val);
	};

	const setSystemType = (val) => {
		fleetStatusContext.setSystemType(val);
	};

	const setSystem = (val) => {
		fleetStatusContext.setSystem(val);
	};

	const setBattery = (val) => {
		fleetStatusContext.setBattery(val);
	};

	const handleClickStatus = useCallback(
		(status, subStatus = '', drus = null) => {
			fleetStatusContext.setSelectedStatus(status);
			fleetStatusContext.setSelectedSubStatus(subStatus);
		},
		[fleetStatusContext.setSelectedStatus, fleetStatusContext.setSelectedSubStatus]
	);

	const setFaultStates = useCallback(
		(ok, unsafe, unoptimisable, diminished, faulty, glitchy, inactive) => {
			if (!ok && !unsafe && !unoptimisable && !diminished && !faulty && !glitchy && !inactive) {
				fleetStatusContext.setSelectedStatus('');
			}
			fleetStatusContext.setShowOk(ok);
			fleetStatusContext.setShowUnsafe(unsafe);
			fleetStatusContext.setShowUnoptimisable(unoptimisable);
			fleetStatusContext.setShowDiminished(diminished);
			fleetStatusContext.setShowFaulty(faulty);
			fleetStatusContext.setShowGlitchy(glitchy);
			fleetStatusContext.setShowInactive(inactive);
		},
		[fleetStatusContext.setShowOk, fleetStatusContext.setSelectedStatus]
	);

	const handleClickFaultStatus = useCallback(
		(status, subStatus = '', drus = null) => {
			handleClickStatus(status, subStatus, drus);
			switch (status) {
				case '':
					setFaultStates(false, false, false, false, false, false, false);
					break;
				case 'OK':
					setFaultStates(!fleetStatusContext.showOk, false, false, false, false, false, false);
					break;
				case 'Unsafe':
					setFaultStates(false, !fleetStatusContext.showUnsafe, false, false, false, false, false);
					break;
				case 'Unoptimisable':
					setFaultStates(false, false, !fleetStatusContext.showUnoptimisable, false, false, false, false);
					break;
				case 'Diminished':
					setFaultStates(false, false, false, !fleetStatusContext.showDiminished, false, false, false);
					break;
				case 'Faulty':
					setFaultStates(false, false, false, false, !fleetStatusContext.showFaulty, false, false);
					break;
				case 'Glitchy':
					setFaultStates(false, false, false, false, false, !fleetStatusContext.showGlitchy, false);
					break;
				case 'Inactive':
					setFaultStates(false, false, false, false, false, false, !fleetStatusContext.showInactive);
					break;
				default:
					break;
			}
		},
		[
			fleetStatusContext.showOk,
			handleClickStatus,
			setFaultStates,
			fleetStatusContext.showDiminished,
			fleetStatusContext.showFaulty,
			fleetStatusContext.showGlitchy,
			fleetStatusContext.showInactive,
			fleetStatusContext.showUnoptimisable,
			fleetStatusContext.showUnsafe,
		]
	);

	const getSelectedRegionDrus = useCallback(
		(option) => {
			return !!option && !!option.value ? fleetContext.regionDrus[option.value] : null;
		},
		[fleetContext.regionDrus]
	);

	const getSettlementDrus = useCallback(
		(option) => {
			if (!!option) {
				// When settlement method 6 is selected, return all of the drus that are on supply.
				let drus = [];
				if (option.value === 6) {
					if (fleetContext.settlementMethodDrus.hasOwnProperty(2))
						drus.push(...fleetContext.settlementMethodDrus[2]);
					if (fleetContext.settlementMethodDrus.hasOwnProperty(3))
						drus.push(...fleetContext.settlementMethodDrus[3]);
					if (fleetContext.settlementMethodDrus.hasOwnProperty(4))
						drus.push(...fleetContext.settlementMethodDrus[4]);
					if (fleetContext.settlementMethodDrus.hasOwnProperty(5))
						drus.push(...fleetContext.settlementMethodDrus[5]);
				} else if (fleetContext.settlementMethodDrus.hasOwnProperty(option.value)) {
					drus = fleetContext.settlementMethodDrus[option.value];
				}
				// else ...
				return drus;
			}
			// else ...
			return null;
		},
		[fleetContext.settlementMethodDrus]
	);

	const getMeteringTypeDrus = useCallback(
		(option) => {
			let drus = [];
			if (option && fleetContext.meteringTypes && fleetContext.batterySystemDrus) {
				const { bsIds } = fleetContext.meteringTypes[option.value];
				for (let i = 0; i < bsIds.length; i++) {
					const bsId = bsIds[i];
					if (Object.hasOwn(fleetContext.batterySystemDrus, bsId)) {
						drus = [...drus, ...fleetContext.batterySystemDrus[bsId]];
					}
				}

				return drus;
			}
			// else ...
			return null;
		},
		[fleetContext.meteringTypes, fleetContext.batterySystemDrus]
	);

	const getInactiveDrus = (status, key, inactiveDrus) => {
		if (!!status && typeof status[key] !== 'undefined') {
			return status[key].filter((dru) => inactiveDrus.indexOf(dru) > -1);
		}
		// else ...
		return [];
	};

	const getBsDrus = useCallback(() => {
		let bsDrus = null;
		if (!!fleetStatusContext?.batterySystem && !!fleetStatusContext?.batterySystem.value) {
			const bsIds = fleetStatusContext?.batterySystem.value.split(' ');
			bsDrus = [];
			if (!!fleetContext.batterySystemDrus && !!bsIds.length) {
				if (bsIds[0] === '-1') {
					bsDrus = Object.keys(fleetContext.batterySystemDrus).reduce((list, bs) => {
						if (bs === '0' || bs.split(' ').length > 1) return list;
						else return [...list, ...fleetContext.batterySystemDrus[bs]];
					}, []);
				} else {
					bsIds.forEach((bsId) => bsDrus.push(...fleetContext.batterySystemDrus[bsId]));
				}
			}
		}

		return bsDrus;
	}, [fleetContext.batterySystemDrus, fleetStatusContext.batterySystem]);

	const getAllDrus = useCallback(() => {
		const [ALL, OAAS, _] = STATUS_OPTIONS;
		const statusType =
			fleetStatusContext.optimizationLevel === ALL
				? 'combined'
				: fleetStatusContext.optimizationLevel === OAAS
				? 'oaas'
				: 'unoptimised';
		const bsDrus = getBsDrus();
		const selectedRegionDrus = getSelectedRegionDrus(fleetStatusContext?.region);
		const settlementDrus = getSettlementDrus(fleetStatusContext?.settlementMethod);
		const meteringTypeDrus = getMeteringTypeDrus(fleetStatusContext.meteringType);
		let drus = [];
		if (fleetContext.status?.hasOwnProperty(statusType)) {
			if (fleetContext.currentDataType === fleet && !!Object.keys(fleetContext.status).length)
				drus = getDrusFromStatus(fleetContext.status[statusType]);
			if (fleetContext.currentDataType !== fleet && !!Object.keys(fleetContext.afStatus).length)
				drus = getDrusFromStatus(fleetContext.afStatus[statusType]);

			if (!!drus.length) {
				if (!!bsDrus) drus = drus.filter((dru) => bsDrus.indexOf(dru) > -1);
				if (!!selectedRegionDrus) drus = drus.filter((dru) => selectedRegionDrus.indexOf(dru) > -1);
				if (!!settlementDrus) drus = drus.filter((dru) => settlementDrus.indexOf(dru) > -1);
				if (!!meteringTypeDrus) drus = drus.filter((dru) => meteringTypeDrus.indexOf(dru) > -1);
				drus = [...new Set(drus)];
			}
		}

		return drus;
	}, [
		fleetContext.afStatus,
		fleetContext.currentDataType,
		fleetStatusContext.optimizationLevel,
		fleetStatusContext?.region,
		fleetStatusContext?.settlementMethod,
		fleetStatusContext?.meteringType,
		fleetContext.status,
		getBsDrus,
		getSelectedRegionDrus,
		getSettlementDrus,
		getMeteringTypeDrus,
	]);

	const getSeverestDrus = useCallback(
		(status, severity) => {
			severity = severity.toLocaleLowerCase();
			if (fleetStatusContext.severityLevel === severityAllOccurrences) {
				const drus = [];
				for (const [code, info] of Object.entries(fleetContext.faultCodes)) {
					if (info.level.toLocaleLowerCase() === severity) {
						drus.push(...(status?.faultCode[code] || []));
					}
				}
				return drus;
			}
			// else...
			return status?.severestFault[severity] || [];
		},
		[fleetContext.faultCodes, fleetStatusContext.severityLevel]
	);

	const getDrusByKey = useCallback(
		(status, key1, key2, bsDrus, selectedRegionDrus, settlementDrus, meteringTypeDrus) => {
			if (!status || !Object.keys(status).length) return [];
			// else ...
			let drus = [];
			switch (key1) {
				case 'faultCode':
					drus = Object.values(status[key1]).reduce((list, drus) => [...list, ...drus], []);
					break;
				case 'severestFault':
					drus = getSeverestDrus(status, key2);
					break;
				case 'inactive':
					if (key2) {
						drus = status[key2];
					} else {
						drus = Object.keys(INACTIVE).reduce((drus, inactiveType) => [...status[inactiveType], ...drus], []);
					}
					break;
				default:
					drus = status[key1];
			}

			if (key1 === 'disconnected') {
				if (!!bsDrus) drus = drus.filter((dru) => bsDrus.indexOf(dru['druId']) > -1);
				if (!!selectedRegionDrus) drus = drus.filter((dru) => selectedRegionDrus.indexOf(dru['druId']) > -1);
				if (!!settlementDrus) drus = drus.filter((dru) => settlementDrus.indexOf(dru['druId']) > -1);
				if (!!meteringTypeDrus) drus = drus.filter((dru) => meteringTypeDrus.indexOf(dru['druId']) > -1);
			} else {
				if (!!bsDrus) drus = drus.filter((dru) => bsDrus.indexOf(dru) > -1);
				if (!!selectedRegionDrus) drus = drus.filter((dru) => selectedRegionDrus.indexOf(dru) > -1);
				if (!!settlementDrus) drus = drus.filter((dru) => settlementDrus.indexOf(dru) > -1);
				if (!!meteringTypeDrus) drus = drus.filter((dru) => meteringTypeDrus.indexOf(dru) > -1);
			}

			return [...new Set(drus)];
		},
		[getSeverestDrus]
	);

	const inactiveContent = useCallback(
		(status, bsDrus, selectedRegionDrus, settlementDrus, meteringTypeDrus) => {
			const inactive = COUNT['inactiveCount'];
			const inactiveDrus = getDrusByKey(
				status,
				'inactive',
				'',
				bsDrus,
				selectedRegionDrus,
				settlementDrus,
				meteringTypeDrus
			);
			return (
				<>
					<li
						key="inactiveCount"
						style={{ color: SEVERITY_COLOR.Inactive.backgroundSeverity }}
						className={
							fleetStatusContext.selectedStatus === inactive &&
							fleetStatusContext.selectedSubStatus === ''
								? `selected-status li-item`
								: 'li-item'
						}
						onClick={() => handleClickFaultStatus('', '', getAllDrus())}
					>
						<span>
							<FontAwesomeIcon size="sm" icon={faChevronLeft} />
							&nbsp;&nbsp;{inactive}
						</span>
						<span>{inactiveDrus.length}</span>
					</li>
					<ul className="status-scroll">
						{Object.keys(INACTIVE).map((key, index) => {
							const drus = getInactiveDrus(status, key, inactiveDrus);
							return (
								<li
									key={index}
									style={{ color: SEVERITY_COLOR.Inactive.backgroundSeverity }}
									onClick={() => handleClickStatus(inactive, INACTIVE[key], drus)}
									className={cx(
										'sub-list',
										fleetStatusContext.selectedSubStatus === INACTIVE[key]
											? 'selected-status li-item'
											: 'li-item'
									)}
								>
									<span>{INACTIVE[key]} </span>
									<span>{drus.length}</span>
								</li>
							);
						})}
						{/* {Array(9)
						.fill()
						.map((val, idx) => (
							<li key={`${val}-${idx}`} onClick={() => {}} className="blank-row">
								<span>&nbsp;</span>
								<span>&nbsp;</span>
							</li>
						))} */}
						<li key={`lastItemForInactive`} className="blank-row">
							<span>&nbsp;</span>
							<span>&nbsp;</span>
						</li>
					</ul>
				</>
			);
		},
		[
			fleetStatusContext.selectedStatus,
			fleetStatusContext.selectedSubStatus,
			getAllDrus,
			getDrusByKey,
			handleClickFaultStatus,
			handleClickStatus,
		]
	);

	const okContent = useCallback(
		(status, bsDrus, selectedRegionDrus, settlementDrus, meteringTypeDrus) => {
			const okDrus = getDrusByKey(status, 'ok', '', bsDrus, selectedRegionDrus, settlementDrus, meteringTypeDrus);
			const notedDrus = getDrusByKey(
				status,
				'severestFault',
				'noted',
				bsDrus,
				selectedRegionDrus,
				settlementDrus,
				meteringTypeDrus
			);
			const ok = COUNT['okCount'];
			const noIssues = 'noIssues',
				noted = 'noted';
			const faults = [];
			for (const [code, info] of Object.entries(fleetContext.faultCodes)) {
				let drus = [];
				if (fleetStatusContext.severityLevel === severityAllOccurrences) {
					if (info.level.toLocaleLowerCase() === 'noted')
						drus = getFaultCodeDrus(status?.faultCode[code] || [], notedDrus);
				} else {
					drus = getFaultCodeDrus(status?.faultCode[code] || [], notedDrus);
				}
				if (!!drus.length) faults.push([info.description, drus]);
			}

			return (
				<>
					<li
						key="okCount"
						style={{ color: SEVERITY_COLOR.OK.backgroundSeverity }}
						className={
							fleetStatusContext.selectedStatus === ok && fleetStatusContext.selectedSubStatus === ''
								? `selected-status li-item`
								: 'li-item'
						}
						onClick={() => handleClickFaultStatus('', '', getAllDrus())}
					>
						<span>
							<FontAwesomeIcon size="sm" icon={faChevronLeft} />
							&nbsp;&nbsp;{ok}
						</span>
						<span>{okDrus.length + notedDrus.length}</span>
					</li>
					<li
						key={noIssues}
						style={{ color: SEVERITY_COLOR.OK.backgroundSeverity }}
						onClick={() => handleClickStatus(ok, noIssues, okDrus)}
						className={cx(
							'sub-list',
							fleetStatusContext.selectedSubStatus === noIssues ? 'selected-status' : 'li-item'
						)}
					>
						<span>No issues noted</span>
						<span>{okDrus.length}</span>
					</li>
					{faults.map((fault, idx) => (
						<li
							key={idx}
							style={{ color: SEVERITY_COLOR.Noted.backgroundSeverity }}
							onClick={() => handleClickStatus(SEVERITY[noted], fault[0], fault[1])}
							className={cx(
								'sub-list',
								fleetStatusContext.selectedSubStatus === fault[0] ? 'selected-status' : 'li-item'
							)}
						>
							<span>{fault[0]} </span>
							<span>{fault[1].length}</span>
						</li>
					))}
					{/* {faults.length <= 11 &&
					Array(11 - faults.length)
						.fill()
						.map((val, idx) => (
							<li key={`${val}-${idx}`} onClick={() => {}} className="blank-row">
								<span>&nbsp;</span>
								<span>&nbsp;</span>
							</li>
						))} */}
					{/* <li key={`lastItemForOkStatus`} onClick={() => {}} className="blank-row">
								<span>&nbsp;</span>
								<span>&nbsp;</span>
							</li> */}
				</>
			);
		},
		[
			fleetContext.faultCodes,
			fleetStatusContext.severityLevel,
			fleetStatusContext.selectedStatus,
			fleetStatusContext.selectedSubStatus,
			getAllDrus,
			getDrusByKey,
			handleClickFaultStatus,
			handleClickStatus,
		]
	);

	const severityKey = useCallback(() => {
		if (fleetStatusContext.showUnsafe) return 'unsafe';
		if (fleetStatusContext.showUnoptimisable) return 'unoptimisable';
		if (fleetStatusContext.showDiminished) return 'diminished';
		if (fleetStatusContext.showFaulty) return 'faulty';
		if (fleetStatusContext.showGlitchy) return 'glitchy';
		return '';
	}, [
		fleetStatusContext.showDiminished,
		fleetStatusContext.showFaulty,
		fleetStatusContext.showGlitchy,
		fleetStatusContext.showUnoptimisable,
		fleetStatusContext.showUnsafe,
	]);

	const faultCodesContent = useCallback(
		(status, bsDrus, selectedRegionDrus, settlementDrus, meteringTypeDrus) => {
			if (!!status) {
				const severityLevel = severityKey();
				if (severityLevel === '') {
					return;
				}

				const severestDrus = getSeverestDrus(status, severityLevel);
				let faultCodeDrus = getDrusByKey(
					status,
					'faultCode',
					'',
					bsDrus,
					selectedRegionDrus,
					settlementDrus,
					meteringTypeDrus
				);
				faultCodeDrus = faultCodeDrus.filter((dru) => severestDrus.indexOf(dru) > -1); // TODO:

				const faults = [];
				const otherFaults = [];
				for (const [code, info] of Object.entries(fleetContext.faultCodes)) {
					const drus = getFaultCodeDrus(status?.faultCode[code] || [], faultCodeDrus);
					if (!!drus.length) {
						if (info.level === SEVERITY[severityLevel]) faults.push([info, drus]);
						else if (fleetStatusContext.severityLevel === severityHighest) otherFaults.push([info, drus]);
					}
				}

				let faultCodeCount = faults.length;
				if (!!otherFaults.length) {
					faultCodeCount += otherFaults.length + 1;
				}
				faults.sort((a, b) => b[0].severity - a[0].severity);
				otherFaults.sort((a, b) => b[0].severity - a[0].severity);
				return (
					<>
						<li
							key={severityLevel}
							style={{ color: SEVERITY_COLOR[SEVERITY[severityLevel]].backgroundSeverity }}
							className={
								fleetStatusContext.selectedStatus === SEVERITY[severityLevel] &&
								fleetStatusContext.selectedSubStatus === ''
									? 'selected-status li-item'
									: 'li-item'
							}
							onClick={() => handleClickFaultStatus('', '', getAllDrus())}
						>
							<span>
								<FontAwesomeIcon size="sm" icon={faChevronLeft} />
								&nbsp;&nbsp;{SEVERITY[severityLevel]}
							</span>
							<span>{faultCodeDrus.length}</span>
						</li>
						<ul className="status-scroll">
							{faults.map((fault, idx) => (
								<li
									key={idx}
									style={{ color: SEVERITY_COLOR[SEVERITY[severityLevel]].backgroundSeverity }}
									onClick={() =>
										handleClickStatus(SEVERITY[severityLevel], fault[0].description, fault[1])
									}
									className={cx(
										'sub-list',
										fleetStatusContext.selectedSubStatus === fault[0].description
											? 'selected-status li-item'
											: 'li-item'
									)}
								>
									<span>{fault[0].description} </span>
									<span>{fault[1].length}</span>
								</li>
							))}
							{!!otherFaults.length && (
								<li key="other-fault" onClick={() => {}} className="other-fault">
									<span>Other faults with {SEVERITY[severityLevel]}</span>
									<span>&nbsp;</span>
								</li>
							)}
							{otherFaults.map((fault, idx) => (
								<li
									key={idx}
									style={{ color: SEVERITY_COLOR[fault[0].level].backgroundSeverity }}
									onClick={() =>
										handleClickStatus(SEVERITY[severityLevel], fault[0].description, fault[1])
									}
									className={cx(
										'sub-list',
										fleetStatusContext.selectedSubStatus === fault[0].description
											? 'selected-status li-item'
											: 'li-item'
									)}
								>
									<span>{fault[0].description} </span>
									<span>{fault[1].length}</span>
								</li>
							))}

							{/* {faultCodeCount <= 12 &&
							Array(12 - faultCodeCount)
								.fill()
								.map((val, idx) => (
									<li key={`${val}-${idx}`} onClick={() => {}} className="blank-row">
										<span>&nbsp;</span>
										<span>&nbsp;</span>
									</li>
								))} */}
							<li key={`lastValueForStatus`} className="blank-row">
								<span>&nbsp;</span>
								<span>&nbsp;</span>
							</li>
						</ul>
					</>
				);
			}
		},
		[
			fleetContext.faultCodes,
			fleetStatusContext.severityLevel,
			fleetStatusContext.selectedStatus,
			fleetStatusContext.selectedSubStatus,
			getAllDrus,
			getDrusByKey,
			getSeverestDrus,
			handleClickFaultStatus,
			handleClickStatus,
			severityKey,
		]
	);

	const listContent = useCallback(
		(status, bsDrus) => {
			const selectedRegionDrus = getSelectedRegionDrus(fleetStatusContext?.region);
			const settlementDrus = getSettlementDrus(fleetStatusContext?.settlementMethod);
			const meteringTypeDrus = getMeteringTypeDrus(fleetStatusContext?.meteringType);

			if (fleetStatusContext.showOk) {
				return okContent(status, bsDrus, selectedRegionDrus, settlementDrus, meteringTypeDrus);
			}
			// else ...
			if (
				fleetStatusContext.showUnsafe ||
				fleetStatusContext.showUnoptimisable ||
				fleetStatusContext.showDiminished ||
				fleetStatusContext.showFaulty ||
				fleetStatusContext.showGlitchy
			) {
				return faultCodesContent(status, bsDrus, selectedRegionDrus, settlementDrus, meteringTypeDrus);
			}
			// else ...
			if (fleetStatusContext.showInactive) {
				return inactiveContent(status, bsDrus, selectedRegionDrus, settlementDrus, meteringTypeDrus);
			}
			// else ...
			return Object.keys(COUNT).map((key, index) => {
				let drus = [];
				switch (key) {
					case 'okCount':
						drus = [
							...getDrusByKey(
								status,
								key.slice(0, -5),
								'',
								bsDrus,
								selectedRegionDrus,
								settlementDrus,
								meteringTypeDrus
							),
							...getDrusByKey(
								status,
								'severestFault',
								'noted',
								bsDrus,
								selectedRegionDrus,
								settlementDrus,
								meteringTypeDrus
							),
						];
						return (
							<li
								key={index}
								style={{ color: SEVERITY_COLOR.OK.backgroundSeverity }}
								className={
									fleetStatusContext.selectedStatus === COUNT[key]
										? `selected-status li-item`
										: 'li-item'
								}
								onClick={() => handleClickFaultStatus(COUNT[key], '', drus)}
							>
								<span>
									<FontAwesomeIcon size="sm" icon={faChevronRight} />
									&nbsp;{COUNT[key]}
								</span>
								<span>{drus.length}</span>
							</li>
						);
					case 'severestFaultCount':
						return Object.keys(SEVERITY).map((k, i) => {
							if (k === 'noted') return;
							// else...
							const severityDrus = getDrusByKey(
								status,
								key.slice(0, -5),
								k,
								bsDrus,
								selectedRegionDrus,
								settlementDrus,
								meteringTypeDrus
							);
							return (
								<li
									key={index * (i + 1)}
									style={{ color: SEVERITY_COLOR[SEVERITY[k]].backgroundSeverity }}
									className={
										fleetStatusContext.selectedStatus === SEVERITY[k]
											? `selected-status li-item`
											: 'li-item'
									}
									onClick={() => handleClickFaultStatus(SEVERITY[k], '', severityDrus)}
								>
									<span>
										<FontAwesomeIcon size="sm" icon={faChevronRight} />
										&nbsp;{SEVERITY[k]}
									</span>
									<span>{severityDrus.length}</span>
								</li>
							);
						});
					case 'inactiveCount':
						drus = getDrusByKey(
							status,
							key.slice(0, -5),
							'',
							bsDrus,
							selectedRegionDrus,
							settlementDrus,
							meteringTypeDrus
						);
						return (
							<li
								key={index}
								style={{ color: SEVERITY_COLOR.Inactive.backgroundSeverity }}
								className={
									fleetStatusContext.selectedStatus === COUNT[key]
										? `selected-status li-item`
										: 'li-item'
								}
								onClick={() => handleClickFaultStatus(COUNT[key], '', drus)}
							>
								<span>
									<FontAwesomeIcon size="sm" icon={faChevronRight} />
									&nbsp;{COUNT[key]}
								</span>
								<span>{drus.length}</span>
							</li>
						);
					default:
						drus = getDrusByKey(
							status,
							key.slice(0, -5),
							'',
							bsDrus,
							selectedRegionDrus,
							settlementDrus,
							meteringTypeDrus
						);
						return (
							<li
								key={key}
								className={
									fleetStatusContext.selectedStatus === COUNT[key]
										? `selected-status li-item`
										: 'li-item'
								}
								onClick={() => handleClickStatus(COUNT[key], '', drus)}
							>
								<span>{COUNT[key]}</span>
								<span>{drus.length}</span>
							</li>
						);
				}
			});
		},
		[
			fleetStatusContext.region,
			fleetStatusContext.settlementMethod,
			fleetStatusContext.meteringType,
			fleetStatusContext.showOk,
			fleetStatusContext.selectedStatus,
			fleetStatusContext.showDiminished,
			fleetStatusContext.showFaulty,
			fleetStatusContext.showGlitchy,
			fleetStatusContext.showInactive,
			fleetStatusContext.showUnoptimisable,
			fleetStatusContext.showUnsafe,
			faultCodesContent,
			getDrusByKey,
			getSelectedRegionDrus,
			getSettlementDrus,
			getMeteringTypeDrus,
			handleClickFaultStatus,
			handleClickStatus,
			inactiveContent,
			okContent,
		]
	);

	const getReconnectedAndDisconnectedDrus = (
		status,
		bsDrus,
		selectedRegionDrus,
		settlementDrus,
		meteringTypeDrus
	) => {
		if (!status || !Object.keys(status).length) return [[], [], []];
		// else...
		let wasDisconnectedDrus = status?.faultCode[DISCONNECTED_CODE] || [];
		let recentlyDisconnectedDrus = status?.faultCode[RECENTLY_DISCONNECTED_CODE] || [];
		let reconnectedDrus = status?.faultCode[RECONNECTED_CODE] || [];
		if (!!bsDrus) {
			wasDisconnectedDrus = wasDisconnectedDrus.filter((dru) => bsDrus.indexOf(dru) > -1);
			recentlyDisconnectedDrus = recentlyDisconnectedDrus.filter((dru) => bsDrus.indexOf(dru) > -1);
			reconnectedDrus = reconnectedDrus.filter((dru) => bsDrus.indexOf(dru) > -1);
		}
		if (!!selectedRegionDrus) {
			wasDisconnectedDrus = wasDisconnectedDrus.filter((dru) => selectedRegionDrus.indexOf(dru) > -1);
			recentlyDisconnectedDrus = recentlyDisconnectedDrus.filter((dru) => selectedRegionDrus.indexOf(dru) > -1);
			reconnectedDrus = reconnectedDrus.filter((dru) => selectedRegionDrus.indexOf(dru) > -1);
		}
		if (!!settlementDrus) {
			wasDisconnectedDrus = wasDisconnectedDrus.filter((dru) => settlementDrus.indexOf(dru) > -1);
			recentlyDisconnectedDrus = recentlyDisconnectedDrus.filter((dru) => settlementDrus.indexOf(dru) > -1);
			reconnectedDrus = reconnectedDrus.filter((dru) => settlementDrus.indexOf(dru) > -1);
		}
		if (!!meteringTypeDrus) {
			wasDisconnectedDrus = wasDisconnectedDrus.filter((dru) => meteringTypeDrus.indexOf(dru) > -1);
			recentlyDisconnectedDrus = recentlyDisconnectedDrus.filter((dru) => meteringTypeDrus.indexOf(dru) > -1);
			reconnectedDrus = reconnectedDrus.filter((dru) => meteringTypeDrus.indexOf(dru) > -1);
		}
		return [reconnectedDrus, recentlyDisconnectedDrus, wasDisconnectedDrus];
	};

	const getFaultCodeDrus = (bsFaultCodeDrus, faultCodeDrus) => {
		if (!!faultCodeDrus.length) {
			return bsFaultCodeDrus.filter((dru) => faultCodeDrus.indexOf(dru) > -1);
		}
		// else ...
		return [];
	};

	const isInstaller = () => {
		if (UserService.hasUser() && hasPermission(INSTALLER_USER)) {
			return 'installer';
		}

		return '';
	};

	const handleClickDownload = () => {
		if (downloadState === download) {
			setDownloadState(loading);
			handleFetchCsv();
		}
	};

	const handleFetchCsv = async () => {
		try {
			const response = await FleetService.getDruStatusesAsCsv();
			if (!response.ok) throw new Error(response);

			const filename = 'status.csv';
			const blob = await response.blob();
			const csvFile = new Blob(['\ufeff', blob], { type: 'text/csv;charset=windows-1252;' });

			saveAs(csvFile, filename);
		} catch (error) {
			setDownloadState(download);
		} finally {
			setDownloadState(success);
			setTimeout(() => setDownloadState(download), 2000);
		}
	};

	const getTotal = () => {
		if (
			!fleetStatusContext.showOk &&
			!fleetStatusContext.showUnsafe &&
			!fleetStatusContext.showUnoptimisable &&
			!fleetStatusContext.showDiminished &&
			!fleetStatusContext.showFaulty &&
			!fleetStatusContext.showGlitchy &&
			!fleetStatusContext.showInactive
		) {
			const drus = getAllDrus();
			return (
				<li
					key="total"
					onClick={() => handleClickStatus('', '', drus)}
					className={fleetStatusContext.selectedStatus === '' ? 'selected-status' : ''}
				>
					<span>
						<strong>Total</strong>
					</span>
					<span>
						<strong>{drus.length}</strong>
					</span>
				</li>
			);
		}
	};

	const getConnectionList = () => {
		if (
			!fleetStatusContext.showOk &&
			!fleetStatusContext.showUnsafe &&
			!fleetStatusContext.showUnoptimisable &&
			!fleetStatusContext.showDiminished &&
			!fleetStatusContext.showFaulty &&
			!fleetStatusContext.showGlitchy &&
			!fleetStatusContext.showInactive
		) {
			const [ALL, OAAS, _] = STATUS_OPTIONS;
			const statusType =
				fleetStatusContext.optimizationLevel === ALL
					? 'combined'
					: fleetStatusContext.optimizationLevel === OAAS
					? 'oaas'
					: 'unoptimised';
			const status =
				fleetContext.currentDataType === fleet
					? fleetContext.status[statusType]
					: fleetContext.afStatus[statusType];
			const selectedRegionDrus = getSelectedRegionDrus(fleetStatusContext?.region);
			const settlementDrus = getSettlementDrus(fleetStatusContext?.settlementMethod);
			const meteringTypeDrus = getMeteringTypeDrus(fleetStatusContext?.meteringType);
			const bsDrus = getBsDrus();
			const [reconnectedDrus, recentlyDisconnectedDrus, wasDisconnectedDrus] = getReconnectedAndDisconnectedDrus(
				status,
				bsDrus,
				selectedRegionDrus,
				settlementDrus,
				meteringTypeDrus
			);
			const recoveringDrus = getDrusByKey(
				status,
				'recovering',
				'',
				bsDrus,
				selectedRegionDrus,
				settlementDrus,
				meteringTypeDrus
			);
			return (
				<>
					<li key="separator-row" className="connection-status-separator" onClick={() => {}}>
						<span>
							<strong>
								<em>Connection status</em>
							</strong>
						</span>
						<span>&nbsp;</span>
					</li>
					<li
						key={'recently-disconnected-count'}
						className={
							fleetStatusContext.selectedStatus === 'Recently Disconnected'
								? `selected-status connection-status li-item`
								: 'connection-status li-item'
						}
						onClick={() => handleClickFaultStatus('Recently Disconnected', '', recentlyDisconnectedDrus)}
					>
						<span>
							<em>Recently Disconnected</em>
						</span>
						<span>{recentlyDisconnectedDrus.length}</span>
					</li>
					<li
						key={'reconnected-count'}
						className={
							fleetStatusContext.selectedStatus === 'Reconnected'
								? `selected-status connection-status li-item`
								: 'connection-status li-item'
						}
						onClick={() => handleClickFaultStatus('Reconnected', '', reconnectedDrus)}
					>
						<span>
							<em>Reconnected (of {wasDisconnectedDrus.length})</em>
						</span>
						<span>{reconnectedDrus.length}</span>
					</li>
					<li
						key={'recovering-count'}
						className={
							fleetStatusContext.selectedStatus === 'Recovering'
								? `selected-status connection-status li-item`
								: 'connection-status li-item'
						}
						onClick={() => handleClickStatus('Recovering', '', recoveringDrus)}
					>
						<span>
							<em>Recovering</em>
						</span>
						<span>{recoveringDrus.length}</span>
					</li>
				</>
			);
		}
	};

	const getStatusList = useMemo(() => {
		const [ALL, OAAS, _] = STATUS_OPTIONS;
		const statusType =
			fleetStatusContext.optimizationLevel === ALL
				? 'combined'
				: fleetStatusContext.optimizationLevel === OAAS
				? 'oaas'
				: 'unoptimised';
		const bsDrus = getBsDrus();
		if (fleetContext.status.hasOwnProperty(statusType)) {
			return fleetContext.currentDataType === fleet
				? listContent(fleetContext.status[statusType], bsDrus)
				: listContent(fleetContext.afStatus[statusType], bsDrus);
		} else {
			return listContent({}, bsDrus);
		}
	}, [
		fleetContext.afStatus,
		fleetContext.currentDataType,
		fleetStatusContext.optimizationLevel,
		fleetContext.status,
		getBsDrus,
		listContent,
	]);

	const renderStatus = () => {
		return (
			<>
				{getStatusList}
				{getTotal()}
				{getConnectionList()}
			</>
		);
	};

	const getDrusFromStatus = (status) => {
		const drus = [];
		for (let key in status) {
			const value = status[key];
			if (typeof value === 'object' && value !== null) {
				switch (key) {
					case 'disconnected':
						const disconnectedDrus = value.map((dru) => dru['druId']);
						drus.push(...disconnectedDrus);
						break;
					case 'faultCode':
						const faultCodeDrus = Object.values(value).reduce((list, drus) => [...list, ...drus], []);
						drus.push(...faultCodeDrus);
						break;
					case 'severestFault':
						break;
					case 'severestFaultCount':
						break;
					default:
						drus.push(...value);
				}
			}
		}

		return [...new Set(drus)];
	};

	const getFilteredDrus = () => {
		const [ALL, OAAS, _] = STATUS_OPTIONS;
		const _key =
			fleetStatusContext.optimizationLevel === ALL
				? 'combined'
				: fleetStatusContext.optimizationLevel === OAAS
				? 'oaas'
				: 'unoptimised';
		const bsDrus = getBsDrus();
		if (fleetContext.status.hasOwnProperty(_key)) {
			let drus = null;
			if (fleetContext.currentDataType === fleet && !!Object.keys(fleetContext.status).length)
				drus = getDrusFromStatus(fleetContext.status[_key]);
			if (fleetContext.currentDataType !== fleet && !!Object.keys(fleetContext.afStatus).length)
				drus = getDrusFromStatus(fleetContext.afStatus[_key]);

			if (!!drus && !!bsDrus) drus = drus.filter((dru) => bsDrus.indexOf(dru) > -1);

			return drus;
		}

		return null;
	};

	const setRegionOptions = (drusToInclude = null) => {
		const { regionDrus } = fleetContext;
		const drus = getFilteredDrus();
		const options = [];

		Object.keys(regionDrus).forEach((region) => {
			if (!!drus) {
				let intersect = [drus, regionDrus[region]].reduce((a, c) => a.filter((i) => c.includes(i)));
				if (!!drusToInclude)
					intersect = [intersect, drusToInclude].reduce((a, c) => a.filter((i) => c.includes(i)));
				if (!intersect.length) return;
			}

			if (region.startsWith('_')) {
				options.push({ value: region, label: GSP_GROUP[region[1]] });
			} else if (region === 'Undefined') {
				options.unshift({ value: region, label: region });
			} else {
				options.push({ value: region, label: region });
			}
		});

		setRegions(options);
		if (options.length === 1) setRegion(options[0]);
	};

	const setSettlementMethodOptions = () => {
		const options = Object.keys(fleetContext?.settlementMethodDrus)
			.sort((a, b) => a - b)
			.map((id) => ({ value: id, label: Number(id) === 0 ? 'No settlement method (0)' : SETTLEMENT_METHOD[id] }));
		if (!!options.length) {
			const lowestOnSupplyMethodIndex = options.findIndex((option) => option.value > 1);
			if (lowestOnSupplyMethodIndex >= 0) {
				options.splice(lowestOnSupplyMethodIndex, 0, { value: 6, label: `On supply (>1)` });
			}
		}

		setSettlementMethods(options);
	};

	const getIntersection = (arr1, arr2) => {
		const set2 = new Set(arr2);

		const intersection = arr1.filter((value) => set2.has(value));

		return intersection;
	};

	const updateStates = (allSystemList, bsMeteringTypes, typeOptions, systemOptions, batteryOptions) => {
		setAllSystems(allSystemList);
		setMeteringTypes(bsMeteringTypes);
		setSystemTypes(typeOptions);
		setSystems(systemOptions);
		setBatteries(batteryOptions);
		setAllSystemTypes(typeOptions);
		setAllBatterySystems(systemOptions);
		setAllBatteryNumber(batteryOptions);

		if (bsMeteringTypes.length === 1) {
			setMeteringType(bsMeteringTypes[0]);
		} else {
			setMeteringType(null);
		}

		if (typeOptions.length === 1 && typeOptions[0].value !== '0') {
			setSystemType(typeOptions[0]);
			if (systemOptions.length === 1) {
				setSystem(systemOptions[0]);
				if (batteryOptions.length === 1) {
					setBattery(batteryOptions[0]);
				} else {
					setBattery(null);
				}
			} else {
				setSystem(null);
				setBattery(null);
			}
		} else {
			if (!typeOptions.some((option) => option.value === fleetStatusContext?.systemType?.value)) {
				setSystemType(null);
			}

			if (!systemOptions.some((option) => option.value === fleetStatusContext?.system?.value)) {
				setSystem(null);
			}

			if (!batteryOptions.some((option) => option.value === fleetStatusContext?.battery?.value)) {
				setBattery(null);
			}
		}
	};

	const setOptions = (regionOption = null, settlementOption = null, meteringType = null) => {
		const { batterySystems, batterySystemDrus, currentDataType, afStatus, status } = fleetContext;

		setDropdownUpdated(!dropdownUpdated);
		const isFleet = currentDataType === 'Fleet';
		const drus = isFleet ? getDrus(status['combined']) : getDrus(afStatus['combined']);
		const existingBsIds = [];

		for (let bsId in batterySystemDrus) {
			const bsDrus = batterySystemDrus[bsId];
			const regionalDrus = getSelectedRegionDrus(regionOption);
			const settlementDrus = getSettlementDrus(settlementOption);
			const meteringTypeDrus = getMeteringTypeDrus(meteringType);
			let intersect = drus;

			if (!!regionalDrus) {
				intersect = getIntersection(regionalDrus, intersect);
			}

			if (!!settlementDrus) {
				intersect = getIntersection(settlementDrus, intersect);
			}

			if (!!meteringTypeDrus) {
				intersect = getIntersection(meteringTypeDrus, intersect);
			}

			if (bsDrus.some((dru) => intersect.includes(dru))) {
				existingBsIds.push(bsId);
			}

			setRegionOptions(intersect);
		}

		const bsMeteringTypes = [];
		const meteringTypeKeys = Object.keys(fleetContext.meteringTypes);

		meteringTypeKeys.forEach((id) => {
			const bsIds = fleetContext.meteringTypes[id].bsIds;

			if (bsIds.some((id) => existingBsIds.includes(`${id}`))) {
				bsMeteringTypes.push({ value: id, label: fleetContext.meteringTypes[id].name });
			}
		});

		const allSystemList = Object.keys({ ...batterySystems })
			.filter((id) => id !== '0' && existingBsIds.includes(id))
			.map((id) => extractBs(id, batterySystems));

		const btIds = allSystemList.reduce((acc, curr) => {
			acc[curr.type] = (acc[curr.type] || '') + ' ' + curr.id;
			return acc;
		}, {});

		const systemTypeOptions = Object.keys(btIds)
			.map((bt) => ({ value: btIds[bt].trim(), label: bt }))
			.sort((a, b) => (a.label > b.label ? 1 : -1));

		const typeOptions = existingBsIds.includes('0')
			? [{ value: '0', label: 'No battery system' }, ...systemTypeOptions]
			: systemTypeOptions;
		const systemOptions = getSubOptions(allSystemList, { value: existingBsIds.join(' ') }, 'system');
		const batteryOptions = getSubOptions(allSystemList, { value: existingBsIds.join(' ') }, 'batteryN');

		updateStates(allSystemList, bsMeteringTypes, typeOptions, systemOptions, batteryOptions);
	};

	const getSubOptions = (list, item, target, includeId = false) => {
		let options = [];
		if (!!item && item.value !== '0') {
			const bsIds = item.value.split(' ');
			const sysIds = list.reduce((acc, curr) => {
				if (bsIds.includes(curr.id)) acc[curr[target]] = (acc[curr[target]] || '') + ' ' + curr.id;
				return acc;
			}, {});

			options = Object.keys(sysIds)
				.map((key) => ({
					value: sysIds[key].trim(),
					label: includeId ? `${key} (${sysIds[key].trim()})` : key,
				}))
				.sort((a, b) => (a.label > b.label ? 1 : -1));
		}

		return options;
	};

	const extractBs = (bsId, batterySystems) => {
		const bs = batterySystems[bsId];
		const inverterBattery = bs.split(' - '); // [inverter, battery]
		const systemArr = bs.split(' ');
		let st = systemArr[0];
		let ss = '';
		let bn = '1';

		if (systemArr[0].toLocaleLowerCase().includes('sim')) {
			st = systemArr[0] + ' ' + systemArr[1];
		}
		if (systemArr[0].toLocaleLowerCase().includes('goodwe')) {
			st = `${systemArr[0]} / Pand`;
		}
		if (systemArr[0].toLocaleLowerCase().includes('fox') && !systemArr[0].toLocaleLowerCase().includes('ess')) {
			st = `${systemArr[0]} ESS`;
		}

		if (inverterBattery.length === 2) {
			const bs = inverterBattery[1].split(' x '); // [number of battery, battery type]
			if (bs.length === 2) {
				ss = inverterBattery[0] + ' - ' + bs[1];
				bn = bs[0];
			} else {
				ss = inverterBattery[0] + ' - ' + bs[0];
			}
		} else {
			ss = inverterBattery[0];
		}

		return { id: bsId, type: st, system: ss, batteryN: bn };
	};

	const getDrus = (status) => {
		const lookUpKeys = ['ok', 'noFacility', 'retired', 'unregistered', 'faultCode', 'disconnected', 'recovering'];
		const drus = [];
		for (let key in status) {
			if (!lookUpKeys.includes(key)) continue;

			switch (key) {
				case 'faultCode':
					const faultCodeDrus = Object.keys(status[key]).reduce(
						(drus, code) => [...drus, ...status[key][code]],
						[]
					);
					drus.push(...faultCodeDrus);
					break;
				case 'disconnected':
					const disconnectedDrus = status[key].reduce((drus, dru) => [...drus, dru['druId']], []);
					drus.push(...disconnectedDrus);
					break;
				default:
					drus.push(...status[key]);
			}
		}

		return [...new Set(drus)];
	};

	const getBtOptions = (sys, batt) => {
		if (batt && sys)
			return fleetStatusContext.allSystemTypes.filter(
				(option) =>
					sys.label.includes(option.label.split(' ')[0]) &&
					batt.value.split(' ').some((value) => option.value.includes(value))
			);
		if (sys)
			return fleetStatusContext.allSystemTypes.filter((option) => sys.label.includes(option.label.split(' ')[0]));
		if (batt)
			return fleetStatusContext.allSystemTypes.filter((option) =>
				batt.value.split(' ').some((value) => option.value.includes(value))
			);
	};

	const ClearIndicator = (props) => {
		return (
			<components.ClearIndicator {...props}>
				<FontAwesomeIcon icon={faTimes} size={'1x'} />
			</components.ClearIndicator>
		);
	};

	useEffect(() => {
		setNeedUpdate(!needUpdate);
		let gspGroup = fleetStatusContext.region;
		let settlementMethod = fleetStatusContext.settlementMethod;
		let meteringType = fleetStatusContext.meteringType;

		if (
			fleetStatusContext.aggFacility !== fleetContext.currentDataType &&
			fleetContext.batterySystemDrus &&
			fleetContext.regionDrus &&
			fleetContext.batterySystems &&
			fleetContext.meteringTypes
		) {
			setAggFacility(fleetContext.currentDataType);
			setSettlementMethod(null);

			setRegion(null);
			setMeteringType(null);
			setBatterySystem(null);
			setSystemType(null);
			setSystem(null);
			setBattery(null);

			gspGroup = null;
			settlementMethod = null;
			meteringType = null;

			if (fleetContext.currentDataType === fleet) {
				fleetContext.setCurrentDrus(getAllDrus());
			}

			setFaultStates(false, false, false, false, false, false, false, false);
			fleetStatusContext.setSelectedStatus('');
			fleetStatusContext.setSelectedSubStatus('');
			setOptimizationLevel('ALL');
			setSeverityLevel(severityHighest);
		}
		setOptions(gspGroup, settlementMethod, meteringType);
		// setRegionOptions();
	}, [
		fleetContext.status,
		fleetContext.afStatus,
		fleetContext.currentDataType,
		fleetContext.batterySystemDrus,
		fleetContext.regionDrus,
		fleetContext.batterySystems,
		fleetContext.meteringTypes,
	]);

	useEffect(() => {
		setSettlementMethodOptions();
	}, [fleetContext.settlementMethodDrus]);

	useEffect(() => {
		setShowDownloadBtn(hasPermission(PERMISSIONS.CAN_ACCESS_AF));
	}, []);

	useEffect(() => {
		if (!fleetStatusContext?.systemType && !fleetStatusContext?.system && !fleetStatusContext?.battery) {
			setSystemTypes(fleetStatusContext.allSystemTypes);
			setSystems(fleetStatusContext.allBatterySystems);
			setBatteries(fleetStatusContext.allBatteryNumbers);
			setBatterySystem(null);
		}
	}, [needUpdate, fleetStatusContext?.systemType, fleetStatusContext?.system, fleetStatusContext?.battery]);

	useEffect(() => {
		if (fleetStatusContext?.systemType) {
			let btOptions = [...fleetStatusContext.allSystemTypes];
			let bsOptions = [...fleetStatusContext.allBatterySystems];
			let bnOptions = [...fleetStatusContext.allBatteryNumbers];
			if (fleetStatusContext?.systemType && fleetStatusContext?.system && fleetStatusContext?.battery) {
				btOptions = getBtOptions(fleetStatusContext?.system, fleetStatusContext?.battery);
				bsOptions = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.systemType, 'system');
				bnOptions = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.system, 'batteryN');
			} else if (fleetStatusContext?.systemType && !fleetStatusContext?.system && !fleetStatusContext?.battery) {
				bsOptions = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.systemType, 'system');
				bnOptions = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.systemType, 'batteryN');
			} else if (fleetStatusContext?.systemType && fleetStatusContext?.system && !fleetStatusContext?.battery) {
				btOptions = fleetStatusContext.allSystemTypes.filter((option) =>
					fleetStatusContext?.system.label.includes(option.label.split(' ')[0])
				);
				bsOptions = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.systemType, 'system');
				bnOptions = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.system, 'batteryN');
			} else if (fleetStatusContext?.systemType && !fleetStatusContext?.system && fleetStatusContext?.battery) {
				const options1 = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.systemType, 'system');
				const options2 = getSubOptions(
					fleetStatusContext.allSystems,
					fleetStatusContext?.battery,
					'system'
				).map((option) => option.label);
				btOptions = getBtOptions(fleetStatusContext?.system, fleetStatusContext?.battery);
				bsOptions = options1.filter((option) => options2.includes(option.label));
			}

			if (bsOptions.length === 1 && !fleetStatusContext?.system) setSystem(bsOptions[0]);
			else if (
				!!fleetStatusContext?.system &&
				!bsOptions.some((option) => option.value === fleetStatusContext?.system.value)
			)
				setSystem(bsOptions.length === 1 ? bsOptions[0] : null);

			if (bnOptions.length === 1 && !fleetStatusContext?.battery) setBattery(bnOptions[0]);
			else if (
				!!fleetStatusContext?.battery &&
				!bnOptions.some((option) => option.value === fleetStatusContext?.battery.value)
			)
				setBattery(bnOptions.length === 1 ? bnOptions[0] : null);

			setSystemTypes(btOptions);
			setSystems(bsOptions);
			setBatteries(bnOptions);
			if (fleetStatusContext?.battery) setBatterySystem(fleetStatusContext?.battery);
			else if (fleetStatusContext?.system) setBatterySystem(fleetStatusContext?.system);
			else setBatterySystem(fleetStatusContext?.systemType);
		} else {
			if (fleetStatusContext?.battery) setBatterySystem(fleetStatusContext?.battery);
			else setBatterySystem(fleetStatusContext?.system);
		}
	}, [needUpdate, fleetStatusContext?.systemType]);

	useEffect(() => {
		if (fleetStatusContext?.system) {
			let btOptions = [...fleetStatusContext.allSystemTypes];
			let bsOptions = [...fleetStatusContext.allBatterySystems];
			let bnOptions = [...fleetStatusContext.allBatteryNumbers];

			if (fleetStatusContext?.systemType && fleetStatusContext?.system && fleetStatusContext?.battery) {
				btOptions = getBtOptions(fleetStatusContext?.system, fleetStatusContext?.battery);
				bsOptions = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.systemType, 'system');
				bnOptions = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.system, 'batteryN');
			} else if (fleetStatusContext?.systemType && fleetStatusContext?.system && !fleetStatusContext?.battery) {
				btOptions = getBtOptions(fleetStatusContext?.system, fleetStatusContext?.battery);
				bsOptions = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.systemType, 'system');
				bnOptions = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.system, 'batteryN');
			} else if (!fleetStatusContext?.systemType && fleetStatusContext?.system && !fleetStatusContext?.battery) {
				btOptions = getBtOptions(fleetStatusContext?.system, fleetStatusContext?.battery);
				bnOptions = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.system, 'batteryN');
			} else if (!fleetStatusContext?.systemType && fleetStatusContext?.system && fleetStatusContext?.battery) {
				btOptions = getBtOptions(fleetStatusContext?.system, fleetStatusContext?.battery);
				bsOptions = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.battery, 'system');
				bnOptions = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.system, 'batteryN');
			}

			setSystemTypes(btOptions);
			setSystems(bsOptions);
			setBatteries(bnOptions);

			if (btOptions.length === 1 && !fleetStatusContext?.systemType) setSystemType(btOptions[0]);
			else if (
				!!fleetStatusContext?.systemType &&
				!btOptions.some((option) => option.value === fleetStatusContext?.systemType.value)
			)
				setSystemType(btOptions.length === 1 ? btOptions[0] : null);

			if (bnOptions.length === 1 && !fleetStatusContext?.battery) setBattery(bnOptions[0]);
			else if (
				!!fleetStatusContext?.battery &&
				!bnOptions.some((option) => option.value === fleetStatusContext?.battery.value)
			)
				setBattery(bnOptions.length === 1 ? bnOptions[0] : null);

			if (fleetStatusContext?.battery) setBatterySystem(fleetStatusContext?.battery);
			else setBatterySystem(fleetStatusContext?.system);
		} else {
			if (fleetStatusContext?.battery || fleetStatusContext?.systemType)
				setSystems(
					getSubOptions(
						fleetStatusContext.allSystems,
						fleetStatusContext?.battery || fleetStatusContext?.systemType,
						'system'
					)
				);
			if (!fleetStatusContext?.battery && fleetStatusContext?.systemType)
				setBatteries(getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.systemType, 'batteryN'));
			if (fleetStatusContext?.battery) setBatterySystem(fleetStatusContext?.battery);
			else setBatterySystem(fleetStatusContext?.systemType);
		}
	}, [needUpdate, fleetStatusContext?.system]);

	useEffect(() => {
		if (fleetStatusContext?.battery) {
			let btOptions = [...fleetStatusContext.allSystemTypes];
			let bsOptions = [...fleetStatusContext.allBatterySystems];
			let bnOptions = [...fleetStatusContext.allBatteryNumbers];

			if (fleetStatusContext?.systemType && fleetStatusContext?.system && fleetStatusContext?.battery) {
				btOptions = getBtOptions(fleetStatusContext?.system, fleetStatusContext?.battery);
				bsOptions = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.systemType, 'system');
				bnOptions = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.system, 'batteryN');
			} else if (fleetStatusContext?.systemType && !fleetStatusContext?.system && fleetStatusContext?.battery) {
				const options1 = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.systemType, 'system');
				const options2 = getSubOptions(
					fleetStatusContext.allSystems,
					fleetStatusContext?.battery,
					'system'
				).map((option) => option.label);
				btOptions = getBtOptions(fleetStatusContext?.system, fleetStatusContext?.battery);
				bsOptions = options1.filter((option) => options2.includes(option.label));
			} else if (!fleetStatusContext?.systemType && !fleetStatusContext?.system && fleetStatusContext?.battery) {
				btOptions = getBtOptions(fleetStatusContext?.system, fleetStatusContext?.battery);
				bsOptions = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.battery, 'system');
			} else if (!fleetStatusContext?.systemType && fleetStatusContext?.system && fleetStatusContext?.battery) {
				btOptions = getBtOptions(fleetStatusContext?.system, fleetStatusContext?.battery);
				bsOptions = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.battery, 'system');
				bnOptions = getSubOptions(fleetStatusContext.allSystems, fleetStatusContext?.system, 'batteryN');
			}

			setSystemTypes(btOptions);
			setSystems(bsOptions);
			setBatteries(bnOptions);

			if (btOptions.length === 1 && !fleetStatusContext?.systemType) setSystemType(btOptions[0]);
			else if (
				!!fleetStatusContext?.systemType &&
				!btOptions.some((option) => option.value === fleetStatusContext?.systemType.value)
			)
				setSystemType(btOptions.length === 1 ? btOptions[0] : null);

			if (bsOptions.length === 1 && !fleetStatusContext?.system) setSystem(bsOptions[0]);
			else if (
				!!fleetStatusContext?.system &&
				!bsOptions.some((option) => option.value === fleetStatusContext?.system.value)
			)
				setSystem(bsOptions.length === 1 ? bsOptions[0] : null);

			setBatterySystem(fleetStatusContext?.battery);
		} else {
			if (fleetStatusContext?.system || fleetStatusContext?.systemType)
				setBatteries(
					getSubOptions(
						fleetStatusContext.allSystems,
						fleetStatusContext?.system || fleetStatusContext?.systemType,
						'batteryN'
					)
				);
			if (fleetStatusContext?.system) setBatterySystem(fleetStatusContext?.system);
			else setBatterySystem(fleetStatusContext?.systemType);
		}
	}, [needUpdate, fleetStatusContext?.battery]);

	useEffect(() => {
		const [ALL, OAAS, _] = STATUS_OPTIONS;
		const statusType =
			fleetStatusContext.optimizationLevel === ALL
				? 'combined'
				: fleetStatusContext.optimizationLevel === OAAS
				? 'oaas'
				: 'unoptimised';
		const status =
			fleetContext.currentDataType === fleet
				? fleetContext.status[statusType]
				: fleetContext.afStatus[statusType];
		const selectedRegionDrus = getSelectedRegionDrus(fleetStatusContext?.region);
		const settlementDrus = getSettlementDrus(fleetStatusContext?.settlementMethod);
		const meteringTypeDrus = getMeteringTypeDrus(fleetStatusContext?.meteringType);

		const bsDrus = getBsDrus();
		let drus = [];
		switch (fleetStatusContext.selectedStatus) {
			case 'OK':
				drus = getDrusByKey(status, 'ok', '', bsDrus, selectedRegionDrus, settlementDrus, meteringTypeDrus);
				break;
			case 'Noted':
			case 'Glitchy':
			case 'Faulty':
			case 'Diminished':
			case 'Unoptimisable':
			case 'Unsafe':
				const severestDrus = getSeverestDrus(status, fleetStatusContext.selectedStatus);
				drus = getDrusByKey(
					status,
					'faultCode',
					'',
					bsDrus,
					selectedRegionDrus,
					settlementDrus,
					meteringTypeDrus
				);
				drus = drus.filter((dru) => severestDrus.indexOf(dru) > -1);
				if (!!fleetStatusContext.selectedSubStatus)
					for (const [code, info] of Object.entries(fleetContext.faultCodes)) {
						if (info.description === fleetStatusContext.selectedSubStatus) {
							drus = getFaultCodeDrus(status?.faultCode[code] || [], drus);
							break;
						}
					}
				break;
			case 'Recently Disconnected':
			case 'Reconnected':
				const [reconnectedDrus, recentlyDisconnectedDrus, _] = getReconnectedAndDisconnectedDrus(
					status,
					bsDrus,
					selectedRegionDrus,
					settlementDrus,
					meteringTypeDrus
				);
				if (fleetStatusContext.selectedStatus === 'Recently Disconnected') drus = recentlyDisconnectedDrus;
				else drus = reconnectedDrus;
				break;
			case 'Recovering':
				drus = getDrusByKey(
					status,
					'recovering',
					'',
					bsDrus,
					selectedRegionDrus,
					settlementDrus,
					meteringTypeDrus
				);
				break;
			case 'Inactive':
				if (!!fleetStatusContext.selectedSubStatus)
					drus = getDrusByKey(
						status,
						'inactive',
						STATUS[fleetStatusContext.selectedSubStatus],
						bsDrus,
						selectedRegionDrus,
						settlementDrus,
						meteringTypeDrus
					);
				else
					drus = getDrusByKey(
						status,
						'inactive',
						'',
						bsDrus,
						selectedRegionDrus,
						settlementDrus,
						meteringTypeDrus
					);
				break;
			default:
				drus = getAllDrus();
		}

		fleetContext.setCurrentDrus(drus);
	}, [
		needUpdate,
		fleetStatusContext.batterySystem,
		fleetStatusContext.meteringType,
		fleetStatusContext?.region,
		fleetStatusContext?.settlementMethod,
		fleetStatusContext.optimizationLevel,
		fleetStatusContext.severityLevel,
		fleetStatusContext.selectedStatus,
		fleetStatusContext.selectedSubStatus,
		getSelectedRegionDrus,
		getSettlementDrus,
		getMeteringTypeDrus,
		getBsDrus,
		getDrusByKey,
		getSeverestDrus,
		getAllDrus,
	]);

	const handleSetSettlementMethod = (value) => {
		setSettlementMethod(value);
		if (value === null) {
			if (fleetStatusContext?.systemType) setSystemType(null);
			if (fleetStatusContext?.system) setSystem(null);
			if (fleetStatusContext?.battery) setBattery(null);
			if (fleetStatusContext?.region) setRegion(null);
			if (fleetStatusContext?.meteringType) {
				setMeteringType(null);
			}

			setOptions(null, value);
		} else {
			if (
				!fleetStatusContext?.systemType &&
				!fleetStatusContext?.system &&
				!fleetStatusContext?.battery &&
				!fleetStatusContext?.region &&
				!fleetStatusContext?.meteringType
			)
				setOptions(null, value);
		}
	};

	const handleSetRegion = (value) => {
		setRegion(value);
		if (!value) {
			if (fleetStatusContext?.systemType) setSystemType(null);
			if (fleetStatusContext?.system) setSystem(null);
			if (fleetStatusContext?.battery) setBattery(null);
			if (fleetStatusContext?.meteringType) {
				setMeteringType(null);
			}
			setOptions(value, fleetStatusContext?.settlementMethod);
		} else {
			if (
				!fleetStatusContext?.systemType &&
				!fleetStatusContext?.system &&
				!fleetStatusContext?.battery &&
				!fleetStatusContext.meteringType
			)
				setOptions(value, fleetStatusContext?.settlementMethod);
		}
	};

	const handleSetMeteringType = (value) => {
		setMeteringType(value);
		if (!value) {
			if (fleetStatusContext?.systemType) setSystemType(null);
			if (fleetStatusContext?.system) setSystem(null);
			if (fleetStatusContext?.battery) setBattery(null);

			setOptions(fleetStatusContext.region, fleetStatusContext?.settlementMethod, value);
		} else {
			if (!fleetStatusContext?.systemType && !fleetStatusContext?.system && !fleetStatusContext?.battery) {
				setOptions(fleetStatusContext.region, fleetStatusContext?.settlementMethod, value);
			}
		}
	};

	const handleSetSystemType = (value) => {
		if (value === null && fleetStatusContext?.system && fleetStatusContext?.battery && systemTypes.length <= 1) {
			setSystem(null);
			setBattery(null);
		}
		setSystemType(value);
	};

	const handleSetSystem = (value) => {
		if (value === null) {
			if (
				fleetStatusContext?.systemType &&
				fleetStatusContext?.battery &&
				batteries.length <= 1 &&
				systems.length <= 1
			) {
				setSystemType(null);
				setBattery(null);
			} else if (fleetStatusContext?.battery && batteries.length <= 1) {
				setBattery(null);
			}
		}
		setSystem(value);
	};

	const handleSetBattery = (value) => {
		if (value === null) {
			if (
				fleetStatusContext?.systemType &&
				fleetStatusContext?.system &&
				batteries.length <= 1 &&
				systems.length <= 1
			) {
				setSystemType(null);
				setSystem(null);
			} else if (fleetStatusContext?.system && systems.length <= 1) {
				setSystem(null);
			}
		}
		setBattery(value);
	};

	const getRegionPlaceholder = () => {
		const url = config.REST_URI;
		if (url.includes('yallara') || url.includes('dodo')) {
			return 'State';
		}
		return 'GSP Group';
	};

	return (
		<div ref={ref} key="status" className={cx('status-wrapper', isInstaller())}>
			<div
				className="fleet-table-title status"
				style={{
					borderBottomWidth: '1px',
					borderBottomColor: 'rgba(187, 187, 187, 0.33)',
					borderBottomStyle: 'solid',
				}}
			>
				<div style={{ marginTop: 2 }}>
					<h2>
						{fleetContext.currentDataType !== fleet
							? fleetContext.currentDataType?.length > 16
								? fleetContext.currentDataType?.slice(0, 13) + '...'
								: fleetContext.currentDataType
							: fleet}{' '}
						status
					</h2>
					{showDownloadBtn && fleetContext.currentDataType === fleet && (
						<DownloadBtn downloadState={downloadState} clickDownload={handleClickDownload} />
					)}
				</div>
			</div>
			<div className="status-body">
				<div className="fleet-table-title status">
					<div className="status-selects-wrapper">
						<Select
							components={{ ClearIndicator }}
							styles={customStyles()}
							isSearchable={true}
							value={fleetStatusContext?.settlementMethod}
							onChange={(value) => handleSetSettlementMethod(value)}
							placeholder="Settlement method"
							options={settlementMethods}
							isClearable
						/>
						<Select
							components={{ ClearIndicator }}
							styles={customStyles()}
							isSearchable={true}
							value={fleetStatusContext?.region}
							onChange={(value) => handleSetRegion(value)}
							placeholder={getRegionPlaceholder()}
							options={regions}
							isClearable
						/>
						<Select
							components={{ ClearIndicator }}
							styles={customStyles()}
							isSearchable={true}
							value={fleetStatusContext?.meteringType}
							onChange={(value) => handleSetMeteringType(value)}
							placeholder="Metering type"
							options={meteringTypes}
							isClearable
						/>
						<Select
							components={{ ClearIndicator }}
							styles={customStyles()}
							isSearchable={true}
							value={fleetStatusContext?.systemType}
							onChange={(value) => handleSetSystemType(value)}
							placeholder="Manufacturer"
							options={systemTypes}
							isClearable
						/>
						<Select
							components={{ ClearIndicator }}
							styles={customStyles()}
							isSearchable={true}
							value={fleetStatusContext?.system}
							onChange={(value) => handleSetSystem(value)}
							placeholder={'Battery system'}
							options={systems}
							isClearable
						/>
						<Select
							components={{ ClearIndicator }}
							styles={customStyles()}
							isSearchable={true}
							value={fleetStatusContext?.battery}
							onChange={(value) => handleSetBattery(value)}
							placeholder={'Number of batteries'}
							options={batteries}
							isClearable
						/>
					</div>
				</div>
				<ul className="status-select-options severity-options">
					<li
						id="highest-severity"
						className={
							fleetStatusContext.severityLevel === severityHighest
								? 'selected-status-option'
								: 'unselected-status-option'
						}
						onClick={() => setSeverityLevel(severityHighest)}
					>
						{severityHighest.toLocaleUpperCase()}
					</li>
					<li
						id="all-severity"
						className={
							fleetStatusContext.severityLevel === severityAllOccurrences
								? 'selected-status-option'
								: 'unselected-status-option'
						}
						onClick={() => setSeverityLevel(severityAllOccurrences)}
					>
						{severityAllOccurrences.toLocaleUpperCase()}
					</li>
				</ul>
				<ul className="status-select-options">
					<li
						id="all-status"
						className={
							fleetStatusContext.optimizationLevel === STATUS_OPTIONS[0]
								? 'selected-status-option'
								: 'unselected-status-option'
						}
						onClick={() => setOptimizationLevel(STATUS_OPTIONS[0])}
					>
						{STATUS_OPTIONS[0]}
					</li>
					<li
						id="oaas-status"
						className={
							fleetStatusContext.optimizationLevel === STATUS_OPTIONS[1]
								? 'selected-status-option'
								: 'unselected-status-option'
						}
						onClick={() => setOptimizationLevel(STATUS_OPTIONS[1])}
					>
						{STATUS_OPTIONS[1]}
					</li>
					<li
						id="non-oaas-status"
						className={
							fleetStatusContext.optimizationLevel === STATUS_OPTIONS[2]
								? 'selected-status-option'
								: 'unselected-status-option'
						}
						onClick={() => setOptimizationLevel(STATUS_OPTIONS[2])}
					>
						{STATUS_OPTIONS[2]}
					</li>
				</ul>
				<ul className="status">{renderStatus()}</ul>
			</div>
		</div>
	);
};

export default Status;
