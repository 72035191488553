/* Copyright Levelise Ltd 2021-2022 */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import cx from 'classnames';
import FleetContext from '../../../contexts/FleetContext';
import {
    TIME_FRAMES,
    HOT_WATER,
    resolutions,
    timestampDay,
    timestampSec
} from '../../../utils/constants';
import {
    CONTRACT_ANNOTATION,
    maxTemperature,
    getTimeFormat,
    ambient,
    outlet,
    upTank7,
    upTank5,
    upTank3,
    upTank1,
    inlet,
    dataset,
    options,
    fleetChartTitles,
    xAxis,
    tooltipTitleCallbacks
} from '../../../utils/chart';


const x = { type: 'time' }
const x1 = null
const y = {
    stacked: false,
    ticks: {},
    label: 'Temperature (°C)',
    gridLines: { drawOnChartArea: true }
}
const y1 = null
const legendLabels = {
    font: { size: 10 }, 
    color: 'black',
    usePointStyle: true,
    boxWidth: 4,
    boxHeight: 4,
    padding: 8,
}

const HwMedianTemperatureTankChart = ({ data, timezone, selectedTimezone, showPerformance }) => {
    const context = useContext(FleetContext);
    const hwMedianTemperatureTankRef = useRef();
    const [chart, setChart] = useState({
        data: {
            labels: [],
            datasets: [
                dataset('line', ambient.type, ambient.backgroundColor, ambient.borderColor, 'y'),
                dataset('line', outlet.type, outlet.backgroundColor, outlet.borderColor, 'y'),
                dataset('line', upTank7.type, upTank7.backgroundColor, upTank7.borderColor, 'y'),
                dataset('line', upTank5.type, upTank5.backgroundColor, upTank5.borderColor, 'y'),
                dataset('line', upTank3.type, upTank3.backgroundColor, upTank3.borderColor, 'y'),
                dataset('line', upTank1.type, upTank1.backgroundColor, upTank1.borderColor, 'y'),
                dataset('line', inlet.type, inlet.backgroundColor, inlet.borderColor, 'y'),
            ]
        },
        options: options(fleetChartTitles.hwMedianTemperatureTank[0], legendLabels, x, x1, y, y1)
    });

    const handleDataOnChange = (data) => {
        let resolution = data.resolution_temp;
        if (data.timeFrame !== TIME_FRAMES.select) {
            switch (data.timeFrame) {
                case TIME_FRAMES.fifteen_minutes:
                    resolution = resolutions.second;
                    break;
                case TIME_FRAMES.one_hour:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.six_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.twenty_four_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.one_week:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.twenty_one_days:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.three_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.twelve_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.thirty_six_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.all:
                    resolution = resolutions.week;
                    break;
                default:
                    break;
            }
        }

        switch (resolution) {
            case resolutions.week:
            case resolutions.day:
                populateChartByDay(data.temperature, resolution, context.selectedHwType);
                break
            case resolutions.half_hour:
                populateChartByMinuteAndHalfHour(data.temperature, resolution, context.selectedHwType);
                break
            case resolutions.minute:
                populateChartByMinuteAndHalfHour(data.temperature, resolution, context.selectedHwType);
                break
            case resolutions.second:
                populateChartByMinuteAndHalfHour(data.temperature, resolutions.minute, context.selectedHwType, data.updated);
                break;
            default:
                break;
        }
    }

    const populateChartByDay = (reports, resolution, type) => {
        const hw = context.hwSpecList.find(hw => hw.id === type);
        const maxTemp = typeof hw !== 'undefined' ? hw.maxTankTempC : null;
        let labels = [];
        let datasets = chart.data.datasets.map(d => {
            d.data = [];
            return d;
        });

        if (!reports.length) return;
        for (let i = reports.length - 1; i >= 0; i--) {
            datasets = datasets.map(set => {
                if (reports[i]['byType'].hasOwnProperty(type)) {
                    const temperature = reports[i]['byType'][type][HOT_WATER[set.label]];
                    set.data.push(temperature.median);
                } else {
                    set.data.push(null);
                }
                return set;
            });

            labels.push(reports[i][timestampDay] * 86400 * 1000);
        }

        const limit = maxTemperature(maxTemp);
        handleSetChart(type, labels, datasets, resolution, limit);
    }

    const populateChartByMinuteAndHalfHour = (reports, resolution, type, update = false) => {
        const hw = context.hwSpecList.find(hw => hw.id === type);
        const maxTemp = typeof hw !== 'undefined' ? hw.maxTankTempC : null;
        let labels = [];
        let datasets = chart.data.datasets.map(d => { d.data = []; return d });

        if (!reports.length) return;

        for (let i = reports.length - 1; i >= 0; i--) {
            datasets = datasets.map(set => {
 
                if (reports[i]['byType'].hasOwnProperty(type)) {
                    const temperature = reports[i]['byType'][type][HOT_WATER[set.label]];
                    set.data.push(temperature.median);
                } else {
                    set.data.push(null);
                }
                return set;
            });

            labels.push(reports[i][timestampSec] * 1000);
        }

        const end = reports[0][timestampSec];
        const start = labels[0] / 1000;
        const annotation = end - start <= 90000 ? CONTRACT_ANNOTATION(end, timezone) : [];
        const limit = maxTemperature(maxTemp);
        handleSetChart(type, labels, datasets, resolution, { ...annotation, ...limit });
    }

    const getTimeFrame = () => {
        let timeFrame = data.timeFrame;
        if (timeFrame === TIME_FRAMES.select) {
            const { temperature, resolution_temp } = data;
            const reportsLen = temperature.length;
            const { thirty_six_months, twelve_months, two_months, fifteen_days, one_week, twenty_four_hours } = TIME_FRAMES;

            switch (resolution_temp) {
                case resolutions.day:
                    timeFrame = reportsLen <= 60 ? two_months : reportsLen <= 366 ? twelve_months : thirty_six_months;
                    break;
                case resolutions.half_hour:
                    timeFrame = reportsLen <= 50 ? twenty_four_hours : reportsLen <= 336
                        ? one_week : fifteen_days;
                    break;
                case resolutions.minute:
                    timeFrame = twenty_four_hours;
                    break;
                default:
                    break;
            }
        }

        return timeFrame;
    }

    const handleSetChart = (type, labels, datasets, resolution, annotation) => {
        const timeFrame = getTimeFrame();
        let title = !!type ? `${context.hwTanks[type]} ${fleetChartTitles.hwMedianTemperatureTank[0]}` : fleetChartTitles.hwMedianTemperatureTank[0];
        title += resolution === resolutions.day || resolution === resolutions.week ? ` by ${resolution} in fleet timezone` : ` by ${resolution}`;
        const update = {
            data: { labels: labels, datasets: datasets },
            options: {
                ...chart.options,
                layout: { padding: { right: 12 } },
                plugins: {
                    ...chart.options.plugins,
                    annotation: { annotations: annotation },
                    title: { ...chart.options.plugins.title, text: title },
                    tooltip: { ...chart.options.plugins.tooltip, callbacks: tooltipTitleCallbacks(selectedTimezone) }
                },
                scales: {
                    ...chart.options.scales,
                    x: xAxis(timeFrame, getTimeFormat(resolution), selectedTimezone)
                }
            }
        };

        update.options.plugins.zoom.zoom.drag.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        update.options.plugins.zoom.zoom.pinch.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        setChart(update);
    }

    useEffect(() => {
        if (!!data.resolution_temp && data.timeFrame === TIME_FRAMES.select) handleDataOnChange(data);
    }, [data.resolution_temp, data.temperature])

    useEffect(() => {
        if (data.updated) handleDataOnChange(data);
    }, [data.temperature])

    useEffect(() => {
        handleDataOnChange(data);
    }, [data.timeFrame, data.type, context.selectedHwType, selectedTimezone])

    return (
        <div className={cx("fleet-hw-median-chart af-records-chart", !showPerformance ? 'fleet-records' : '')}>
            <Line
                id={fleetChartTitles.hwMedianTemperatureTank[1]}
                ref={hwMedianTemperatureTankRef}
                data={chart.data}
                options={chart.options}
            />
        </div>
    )
}

export default HwMedianTemperatureTankChart;
