/* Copyright Levelise Ltd 2024 */
import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSadTear } from '@fortawesome/free-regular-svg-icons';
import TokenService from '../../services/token-service';
import UserService from '../../services/user-service';
import IdleService from '../../services/idle-service';
import FleetContext from '../../contexts/FleetContext';
import FacilityContext from '../../contexts/FacilityContext';
import UserContext from '../../contexts/UserContext';
import FleetStatusContext from '../../contexts/FleetStatusContext';

const Error = () => {
    const fleetContext = useContext(FleetContext)
    const facilityContext = useContext(FacilityContext)
    const userContext = useContext(UserContext)
    const fleetStatusContext = useContext(FleetStatusContext);

    const clearData = () => {
        fleetContext.clearState();
        facilityContext.clearState();
        fleetStatusContext.clearState();
        userContext.clearUser();
        TokenService.clearAuthToken();
        UserService.removeUser();
        UserService.removeUserDetail();
        TokenService.clearCallbackBeforeExpiry();
        IdleService.unRegisterIdleResets();
    }
    useEffect(() => {
        clearData();
    }, [])

    return (
        <div id="error-page">
            <FontAwesomeIcon icon={faSadTear} size="10x"/>
            <h1>Unexpectedly logged out!</h1>
            <p>Please try again after a few moments.</p> 
            <p>
                &#10094;&#10094;
                <Link to="/" onClick={() => clearData()}>Go to login page</Link>
            </p>
        </div>
    )
}

export default Error;