/* Copyright Levelise Ltd 2019-2024 */
import React, { useContext, useEffect, useState } from 'react';
import { SpecInput, SpecLabel } from '../Form/index';
import Select from 'react-select';
import FleetContext from '../../contexts/FleetContext';
import { PERMISSIONS, PV_NET_CAPACITY_SHOULD_BE_IN_WATTS } from '../../utils/constants';
import { hasPermission, getOptions } from '../../utils/utils';
import { SETTLEMENT_METHOD } from '../../utils/specification';
import { useNavigate } from 'react-router-dom';
import { customStylesForEdit } from '../SpecificationForm/customStyles';
import SwitchComponent from '../reusableInputComponents/SwitchComponent';
import InputWithErrorMessageAndUnit from '../reusableInputComponents/InputWithErrorMessageAndUnit';
import ExpandableListItem from '../ExpandableLIstItem';

const CHANGE_DRU_ID = 'change_dru_id';
const CHANGE_BATTERY_SYSTEM = 'change_battery_system';
const CHANGE_PV_NET_CAPACITY = 'change_pv_net_capacity';
const CHANGE_RS485 = 'change_RS485';
const CHANGE_HAS_3_PHASE_METERING = 'change_has_3_phase_metering';
const CHANGE_HAS_GRID_METERING = 'change_has_grid_metering';
const CHANGE_INVERT_PV = 'change_invert_pv';
const CHANGE_INVERT_GRID = 'change_invert_grid';
const CHANGE_INVERT_DRU_INVERTER = 'change_invert_dru_inverter';
const CHANGE_INVERT_DRU_GRID = 'change_invert_dru_grid';
const CHANGE_INVERT_DRU_HW = 'change_invert_dru_hot_water';

const getPorts = () => {
	return { 1: '1', 2: '2' };
};

const extractManufacturerAndBS = (bs) => {
	const inverterBattery = bs.split(' - '); // [inverter, battery]
	const systemArr = bs.split(' ');
	let manufacturer = systemArr[0];
	let bn = '1';
	let inverter = '';

	if (systemArr[0].toLocaleLowerCase().includes('sim')) {
		manufacturer = `${systemArr[0]} ${systemArr[1]}`;
	}

	if (systemArr[0].toLocaleLowerCase().includes('goodwe')) {
		manufacturer = `${systemArr[0]} / Pand`;
	}

	if (systemArr[0].toLocaleLowerCase().includes('fox') && !systemArr[0].toLocaleLowerCase().includes('ess')) {
		manufacturer = `${systemArr[0]} ESS`;
	}

	if (inverterBattery.length >= 2) {
		const batteryWithCount = inverterBattery.slice(1).join(' - ').split(' x '); // [number of battery, battery]
		if (batteryWithCount.length >= 2) {
			bn = batteryWithCount[0];
		}
		inverter = inverterBattery[0];
	} else {
		inverter = inverterBattery[0];
	}

	return { manufacturerRes: manufacturer, batterySystem: bs, inverter, batteryCount: bn };
};

/**
 * @param batterySystemsArray
 * @returns {
 *  {
 *
 * 	meteringTypeId: {
 * 		name: '',
 *    	manufacturer: {
 * 			inverter: {
 * 				count: {
 *          		'1': 'manufacturer inverter 1 - 1 x battery'
 *          	}
 * 			}
 * 		}
 * 	}
 *
 *  }
 * }
 *
 *  takes battery system array and returns an object in the above structure
 *  */
const getBatterySystemsObjectStructure = (batterySystemsArray) => {
	const batterySystemsObject = {};

	if (!batterySystemsArray || !batterySystemsArray?.length) {
		return Promise.resolve(batterySystemsObject);
	}

	batterySystemsArray.forEach((batterySys) => {
		if (batterySys?.name) {
			const { manufacturerRes, batterySystem, inverter, batteryCount } = extractManufacturerAndBS(
				batterySys?.name
			);

			const countObj = {};
			countObj[batterySys.id] = batterySystem;

			const inverterObj = {};
			inverterObj[batteryCount] = countObj;

			const manufacturerObj = {};
			manufacturerObj[inverter] = inverterObj;

			const meteringTypeObj = {};
			meteringTypeObj[manufacturerRes] = manufacturerObj;
			meteringTypeObj['name'] = batterySys?.meteringType?.name;

			if (Object.hasOwn(batterySystemsObject, batterySys?.meteringType?.id)) {
				if (Object.hasOwn(batterySystemsObject[batterySys?.meteringType?.id], manufacturerRes)) {
					if (Object.hasOwn(batterySystemsObject[batterySys?.meteringType?.id][manufacturerRes], inverter)) {
						if (
							Object.hasOwn(
								batterySystemsObject[batterySys?.meteringType?.id][manufacturerRes][inverter],
								batteryCount
							)
						) {
							// add battery system
							batterySystemsObject[batterySys?.meteringType?.id][manufacturerRes][inverter][batteryCount][
								batterySys.id
							] = batterySystem;
						} else {
							// add count
							batterySystemsObject[batterySys?.meteringType?.id][manufacturerRes][inverter][
								batteryCount
							] = countObj;
						}
					} else {
						// add inverter
						batterySystemsObject[batterySys?.meteringType?.id][manufacturerRes][inverter] = inverterObj;
					}
				} else {
					// add manufacturer
					batterySystemsObject[batterySys?.meteringType?.id][manufacturerRes] = manufacturerObj;
				}
			} else {
				// add metering type
				batterySystemsObject[batterySys?.meteringType?.id] = meteringTypeObj;
			}
		}
	});

	return Promise.resolve(batterySystemsObject);
};

const FacilityInfoSpecifications = ({
	editing,
	facilityContext,
	customer,
	facility,
	state,
	dispatch,
	pvNetCapacityError,
	setPvNetCapacityError,
	druIdError,
	setDruIdError
}) => {
	const navigate = useNavigate();

	const fleetContext = useContext(FleetContext);

	const [hasCustomer, setHasCustomer] = useState(false);
	const [reversedMetering, setReversedMetering] = useState([]);

	const [meteringType, setMeteringType] = useState(null);
	const [manufacturer, setManufacturer] = useState(null);
	const [inverter, setInverter] = useState(null);
	const [batteryCount, setBatteryCount] = useState(null);

	const [meteringTypes, setMeteringTypes] = useState([]);
	const [manufacturers, setManufacturers] = useState([]);
	const [inverters, setInverters] = useState([]);
	const [batteryCounts, setBatteryCounts] = useState([]);
	const [batterySystems, setBatterySystems] = useState([]);
	const [batterySystemObject, setBatterySystemObject] = useState(null);

	const onSeeMorePressed = () => {
		if (customer?.customerId) {
			navigate(`/customer/${customer.customerId}`);
		}
	};

	const getSettlementMethodDescription = (settlementMethod) => {
		if (typeof settlementMethod === 'string' || settlementMethod instanceof String) {
			// TODO: Remove this condition when '/fleet/facility/<facility_name>' endpoint
			//       switches the return value for settlementMethod from description to id.
			const id = Object.keys(SETTLEMENT_METHOD).find((key) => SETTLEMENT_METHOD[key].includes(settlementMethod));
			return !!id ? `${settlementMethod} (${id})` : settlementMethod;
		} else {
			return SETTLEMENT_METHOD.hasOwnProperty(settlementMethod)
				? SETTLEMENT_METHOD[settlementMethod]
				: settlementMethod;
		}
	};

	const zeroPad = (input, size) => {
		input = input.toString();
		while (input.length < size) {
			input = '0' + input;
		}
		return input;
	};

	const getFacilityCode = (id) => {
		if (!id || id <= 0) {
			return 'None';
		} else {
			let name = [...zeroPad(id, 7)].reverse().join('');
			let hex = zeroPad((10000000 - parseInt(name)).toString(16).toUpperCase(), 6);
			return `A${hex.slice(0, 2)}-${hex.slice(2)}`;
		}
	};

	const setBatterySystem = (val) => {
		dispatch({ type: CHANGE_BATTERY_SYSTEM, payload: val });
	};

	const handleMeteringTypeChange = (val) => {
		setMeteringType(val);
	};

	const handleSetManufacturer = (val) => {
		setManufacturer(val);
	};

	const handleInverterChange = (val) => {
		setInverter(val);
	};

	const handleBatteryCountChange = (val) => {
		setBatteryCount(val);
	};

	const onPVNetCapacityChange = (e) => {
		if (!isNaN(e.target.value)) {
			if (e.target.value < 100) {
				setPvNetCapacityError(PV_NET_CAPACITY_SHOULD_BE_IN_WATTS);
			} else {
				setPvNetCapacityError('');
			}
		}

		if (!e.target.value && !facilityContext.facility?.pvNetCapacityW) {
			setPvNetCapacityError('');
		}

		dispatch({ type: CHANGE_PV_NET_CAPACITY, payload: parseInt(e.target.value) });
	};

	const handleDruChange = (e) => {
		setDruIdError('');
		dispatch({ type: CHANGE_DRU_ID, payload: e.target.value });
	}

	useEffect(() => {
		if (facilityContext?.facility?.batterySystem?.name) {
			const batteryValues = extractManufacturerAndBS(facilityContext.facility.batterySystem.name);

			setMeteringType({
				value: facilityContext.facility.batterySystem.meteringType.id,
				label: facilityContext.facility.batterySystem.meteringType.name,
			});
			setManufacturer({ value: batteryValues.manufacturerRes, label: batteryValues.manufacturerRes });
			setInverter({ value: batteryValues.inverter, label: batteryValues.inverter });
			setBatteryCount({ value: batteryValues.batteryCount, label: batteryValues.batteryCount });
			setBatterySystem({
				value: facilityContext.facility.batterySystem.id,
				label: `${facilityContext.facility.batterySystem.name} (${facilityContext.facility.batterySystem.id})`,
			});
		}
	}, [facilityContext?.facility, editing]);

	useEffect(() => {
		setHasCustomer(customer && !!Object.keys(customer).length);
	}, [customer]);

	useEffect(() => {
		if (facilityContext?.facility) {
			dispatch({ type: CHANGE_DRU_ID, payload: facilityContext.facility.druId });

			const reversedMeteringArr = [
				[facilityContext.facility?.invertPv, 'PV'],
				[facilityContext.facility?.invertGrid, 'Grid'],
				[facilityContext.facility?.invertDruInverter, 'DRU Inverter'],
				[facilityContext.facility?.invertDruGrid, 'DRU Grid'],
				[facilityContext.facility?.invertDruHotWater, 'DRU Hot Water'],
			].filter((m) => m[0]);

			setReversedMetering(reversedMeteringArr);

			dispatch({ type: CHANGE_INVERT_PV, payload: facilityContext.facility?.invertPv });
			dispatch({ type: CHANGE_INVERT_GRID, payload: facilityContext.facility?.invertGrid });
			dispatch({
				type: CHANGE_INVERT_DRU_INVERTER,
				payload: facilityContext.facility?.invertDruInverter,
			});
			dispatch({ type: CHANGE_INVERT_DRU_GRID, payload: facilityContext.facility?.invertDruGrid });
			dispatch({ type: CHANGE_INVERT_DRU_HW, payload: facilityContext.facility?.invertDruHotWater });

			dispatch({ type: CHANGE_PV_NET_CAPACITY, payload: facilityContext.facility?.pvNetCapacityW });
			dispatch({
				type: CHANGE_RS485,
				payload: {
					value: facilityContext.facility?.rs485,
					label: facilityContext.facility?.rs485,
				},
			});
			dispatch({ type: CHANGE_HAS_3_PHASE_METERING, payload: facilityContext.facility?.has3PhaseMetering });
			dispatch({ type: CHANGE_HAS_GRID_METERING, payload: facilityContext.facility?.hasGridMeter });

			setPvNetCapacityError('');
		}
	}, [facilityContext?.facility, editing]);

	useEffect(() => {
		if (editing) {
			async function getManufacturers() {
				const { batterySystemSpecList } = fleetContext;

				if (batterySystemSpecList) {
					const batterySystemsObject = await getBatterySystemsObjectStructure(batterySystemSpecList);

					setBatterySystemObject(batterySystemsObject);
				}
			}

			getManufacturers();
		}
	}, [editing, fleetContext]);

	// set metering types
	useEffect(() => {
		if (batterySystemObject) {
			const meteringTypeIds = Object.keys(batterySystemObject);
			const options = meteringTypeIds.map((id) => ({ value: id, label: batterySystemObject[id]?.name || '' }));

			if (meteringType && !meteringTypeIds.includes(`${meteringType.value}`)) {
				setManufacturer(null);
				setInverter(null);
				setBatteryCount(null);
				dispatch({ type: CHANGE_BATTERY_SYSTEM, payload: '' });

				setInverters([]);
				setBatteryCounts([]);
				setBatterySystems([]);
				setManufacturers([]);
			}

			setMeteringTypes(options);
		}
	}, [batterySystemObject, editing]);

	// set manufacturer list
	useEffect(() => {
		if (meteringType && batterySystemObject) {
			if (batterySystemObject[meteringType.value]) {
				const manuList = Object.keys(batterySystemObject[meteringType.value]).filter((key) => key !== 'name');
				manuList.sort((a, b) => a.localeCompare(b));

				const options = getOptions(manuList);

				if (manufacturer && !manuList.includes(manufacturer.value)) {
					setManufacturer(null);
					setInverter(null);
					setBatteryCount(null);
					dispatch({ type: CHANGE_BATTERY_SYSTEM, payload: '' });

					setInverters([]);
					setBatteryCounts([]);
					setBatterySystems([]);
				}

				if (options.length === 1) {
					setManufacturer(options[0]);
				}

				setManufacturers(options);
			}
		}
	}, [meteringType, batterySystemObject, editing]);

	// set inverter list
	useEffect(() => {
		if (manufacturer && meteringType && batterySystemObject) {
			if (
				batterySystemObject[meteringType.value] &&
				batterySystemObject[meteringType.value][manufacturer.value]
			) {
				const inverterKeys = Object.keys(batterySystemObject[meteringType.value][manufacturer.value]);
				inverterKeys.sort((a, b) => a.localeCompare(b));

				const options = getOptions(inverterKeys);

				if (inverter && !inverterKeys.includes(inverter.value)) {
					setInverter(null);
					setBatteryCount(null);
					dispatch({ type: CHANGE_BATTERY_SYSTEM, payload: '' });

					setBatteryCounts([]);
					setBatterySystems([]);
				}

				if (options.length === 1) {
					setInverter(options[0]);
				}

				setInverters(options);
			}
		}
	}, [manufacturer, meteringType, batterySystemObject, editing]);

	// set battery count list
	useEffect(() => {
		if (inverter && manufacturer && meteringType && batterySystemObject) {
			if (
				batterySystemObject[meteringType.value] &&
				batterySystemObject[meteringType.value][manufacturer.value] &&
				batterySystemObject[meteringType.value][manufacturer.value][inverter.value]
			) {
				const countKeys = Object.keys(
					batterySystemObject[meteringType.value][manufacturer.value][inverter.value]
				);
				countKeys.sort((a, b) => a.localeCompare(b));

				const options = getOptions(countKeys);

				if (batteryCount && !countKeys.includes(batteryCount.value)) {
					setBatteryCount(null);

					dispatch({ type: CHANGE_BATTERY_SYSTEM, payload: '' });

					setBatterySystems([]);
				}

				if (options.length === 1) {
					setBatteryCount(options[0]);
				}

				setBatteryCounts(options);
			}
		}
	}, [inverter, manufacturer, meteringType, batterySystemObject, editing]);

	useEffect(() => {
		if (batteryCount && inverter && manufacturer && meteringType && batterySystemObject) {
			if (
				batterySystemObject[meteringType.value] &&
				batterySystemObject[meteringType.value][manufacturer.value] &&
				batterySystemObject[meteringType.value][manufacturer.value][inverter.value] &&
				batterySystemObject[meteringType.value][manufacturer.value][inverter.value][batteryCount.value]
			) {
				const options = getOptions(
					batterySystemObject[meteringType.value][manufacturer.value][inverter.value][batteryCount.value],
					true
				);

				if (
					options &&
					state.batterySystem &&
					!options.find((ele) => Number(ele.value) === Number(state.batterySystem?.value))
				) {
					dispatch({ type: CHANGE_BATTERY_SYSTEM, payload: '' });
				}

				if (options.length === 1) {
					dispatch({ type: CHANGE_BATTERY_SYSTEM, payload: options[0] });
				}

				setBatterySystems(options);
			}
		}
	}, [batteryCount, inverter, manufacturer, meteringType, batterySystemObject, editing]);

	if (!Object.keys(facilityContext.facility).length) {
		return;
	}

	return (
		<>
			<li>
				<span className="strong specification">Name </span>
				<span className="em specification">{facility.name}</span>
			</li>
			<li>
				<span className="strong specification">Facility ID </span>
				<span className="em specification">{facility.id ? facility.id : '—'}</span>
			</li>
			<li>
				<span className="strong specification">Facility Code </span>
				<span className="em specification">{facility.id ? getFacilityCode(facility.id) : '—'}</span>
			</li>
			<li>
				<span className="strong specification">Current DRU ID </span>
				<span className="em specification">
					{!editing ? facilityContext.facility.druId : null}
					{editing ? (
						<InputWithErrorMessageAndUnit
							id="druId"
							type="number"
							name="druId"
							className="form-right"
							value={state.druId}
							onChange={handleDruChange}
							placeholder="DRU ID"
							style={{ height: 23, maxWidth: 150 }}
							errorMessage={druIdError}
						/>
					) : null}
					</span>
			</li>
			{hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER) ? (
				<li>
					<span className="strong specification">Customer </span>
					<span className="em specification">
						{hasCustomer && customer?.name ? customer.name : ''}{' '}
						{hasCustomer && customer?.surname ? customer.surname : ''}{' '}
						{hasCustomer ? (
							<span
								style={{
									color: 'blue',
									textDecoration: 'underline',
									cursor: 'pointer',
									fontSize: 10,
									whiteSpace: 'nowrap',
								}}
								onClick={onSeeMorePressed}
							>
								See more
							</span>
						) : (
							'—'
						)}
					</span>
				</li>
			) : null}
			<li>
				<span className="strong specification">Timezone </span>
				<span className="em specification">{facilityContext.facility.timezone}</span>
			</li>
			<li>
				<span className="strong specification">PV Net Capacity </span>
				<span className="em specification">
					{!editing
						? facilityContext?.facility && Object.hasOwn(facilityContext.facility, 'pvNetCapacityW')
							? facilityContext.facility.pvNetCapacityW
							: '—'
						: null}
					{editing ? (
						<InputWithErrorMessageAndUnit
							id="pvNetCapacityW"
							type="number"
							name="pvNetCapacityW"
							className="form-right"
							value={state.pvNetCapacityW}
							onChange={onPVNetCapacityChange}
							placeholder="PV Net Capacity in W"
							style={{ height: 23, maxWidth: 150 }}
							errorMessage={pvNetCapacityError}
							unit="W"
						/>
					) : null}
					{!editing && facilityContext?.facility && Object.hasOwn(facilityContext.facility, 'pvNetCapacityW')
						? ' W'
						: ''}
				</span>
			</li>
			<li>
				<span className="strong specification">Settlement Method </span>
				<span className="em specification">
					{facilityContext.facility.settlementMethod
						? getSettlementMethodDescription(facilityContext.facility.settlementMethod)
						: '—'}
				</span>
			</li>
			{facilityContext.facility.hasOwnProperty('batterySystem') && (
				<>
					{!editing ? (
						<ExpandableListItem
							title="Battery System"
							collapsedValueDisplay={facilityContext.facility.batterySystem.id}
						>
							<>
								<li>
									<span className="strong specification">Metering Type</span>
									<span className="em specification">{meteringType?.label || ''}</span>
								</li>
								<li>
									<span className="strong specification">Manufacturer</span>
									<span className="em specification">{manufacturer?.label || ''}</span>
								</li>
								<li>
									<span className="strong specification">Inverter</span>
									<span className="em specification">{inverter?.label || ''}</span>
								</li>
								<li>
									<span className="strong specification">Battery count</span>
									<span className="em specification">{batteryCount?.label || ''}</span>
								</li>
								<li>
									<span className="strong specification">Battery System</span>
									<span className="em specification">
										{facilityContext.facility.batterySystem.name}
									</span>
								</li>
							</>
						</ExpandableListItem>
					) : (
						<li>
							<span className="strong specification">Battery System</span>
							<span className="em specification">
								{!editing ? facilityContext.facility.batterySystem.name : null}
								{editing ? (
									<>
										<div>
											<Select
												id="meteringTypes"
												name="meteringTypes"
												className="form-right"
												styles={customStylesForEdit()}
												value={meteringType}
												onChange={handleMeteringTypeChange}
												placeholder="Select metering type"
												options={meteringTypes}
											/>
										</div>
										<div
											style={{
												marginTop: 6,
											}}
										>
											<Select
												id="manufacturers"
												name="manufacturers"
												className="form-right"
												styles={customStylesForEdit()}
												value={manufacturer}
												onChange={handleSetManufacturer}
												placeholder="Select manufacturer"
												options={manufacturers}
											/>
										</div>
										<div
											style={{
												marginTop: 6,
											}}
										>
											<Select
												id="inverters"
												name="inverters"
												className="form-right"
												styles={customStylesForEdit()}
												value={inverter}
												onChange={handleInverterChange}
												placeholder="Select inverter"
												options={inverters}
											/>
										</div>
										<div
											style={{
												marginTop: 6,
											}}
										>
											<Select
												id="batteryCount"
												name="batteryCount"
												className="form-right"
												styles={customStylesForEdit()}
												value={batteryCount}
												onChange={handleBatteryCountChange}
												placeholder="Select battery count"
												options={batteryCounts}
											/>
										</div>
										<div
											style={{
												marginTop: 6,
											}}
										>
											<Select
												id="batterySystem"
												name="batterySystem"
												className="form-right"
												styles={customStylesForEdit()}
												value={state.batterySystem}
												onChange={setBatterySystem}
												placeholder="Select Battery System"
												options={batterySystems}
											/>
										</div>
									</>
								) : null}
							</span>
						</li>
					)}
				</>
			)}
			{facilityContext.facility.hasOwnProperty('hotWaterTank') && (
				<li>
					<span className="strong specification">HW Tank </span>
					<span className="em specification">{facilityContext.facility.hotWaterTank.name}</span>
				</li>
			)}
			{facilityContext.facility.hasOwnProperty('evChargePointId') && (
				<li>
					<span className="strong specification">EV Charge Point ID </span>
					<span className="em specification">{facilityContext.facility.evChargePointId}</span>
				</li>
			)}
			{facilityContext.facility.hasOwnProperty('druNetwork') && (
				<li>
					<span className="strong specification">DRU Setup </span>
					<span className="em specification">
						{`${facilityContext.facility.druNetwork.name}${
							facilityContext.facility.hasOwnProperty('batteryLocation')
								? ' (' + facilityContext.facility.batteryLocation.name + ')'
								: ''
						}`}
					</span>
				</li>
			)}
			<li>
				<span className="strong specification">RS-485 Ports</span>
				<span className="em specification">
					{}
					{!editing
						? facilityContext.facility.hasOwnProperty('rs485')
							? facilityContext.facility.rs485
							: '—'
						: null}
					{editing ? (
						<Select
							id="ports"
							name="ports"
							className="form-right"
							styles={customStylesForEdit()}
							value={state.rs485}
							onChange={(val) => {
								dispatch({ type: CHANGE_RS485, payload: val });
							}}
							placeholder="-- Select RS-485 port(s) --"
							options={getOptions(getPorts())}
						/>
					) : null}
				</span>
			</li>
			<li>
				<span className="strong specification">Has 3-Phase Metering </span>
				<span className="em specification">
					{!editing ? (facilityContext.facility.has3PhaseMetering ? ' Yes' : ' No') : null}
					{editing ? (
						<SwitchComponent
							onChange={(value) => dispatch({ type: CHANGE_HAS_3_PHASE_METERING, payload: value })}
							controlState={state.has3PhaseMetering}
							values={[
								{ value: true, label: 'Yes' },
								{ value: false, label: 'No' },
							]}
						/>
					) : null}
				</span>
			</li>
			<li>
				<span className="strong specification">Has Grid Metering </span>
				<span className="em specification">
					{!editing ? (facilityContext.facility.hasGridMeter ? 'Yes' : 'No') : null}
					{editing ? (
						<SwitchComponent
							onChange={(value) => dispatch({ type: CHANGE_HAS_GRID_METERING, payload: value })}
							controlState={state.hasGridMeter}
							values={[
								{ value: true, label: 'Yes' },
								{ value: false, label: 'No' },
							]}
						/>
					) : null}
				</span>
			</li>
			<li>
				<span className="strong specification">Data Collection Allowed (DSR Allowed) </span>
				<span className="em specification">
					{facilityContext.facility.dataCollectionAllowed ? 'Yes' : 'No'}
					{facilityContext.facility.drAllowed ? ' (Yes)' : ' (No)'}
				</span>
			</li>
			<li>
				<span className="strong specification">Reversed Metering </span>
				<span className="em specification">
					{!editing
						? reversedMetering.length === 0
							? '—'
							: reversedMetering.map((m) => m[1]).join(', ')
						: null}
					{editing ? (
						<div className="form-right metering-types invert-selects-grid-container">
							<div className="reversed-metering-col1">
								<div className="invert-pv-wrapper">
									<SpecInput
										type="checkbox"
										id="invertPv"
										name="invertPv"
										className="invert-pv"
										onChange={() => dispatch({ type: CHANGE_INVERT_PV, payload: !state.invertPv })}
										checked={state.invertPv}
									/>
									<SpecLabel className="invert-pv-label" htmlFor="invertPv">
										Reversed PV
									</SpecLabel>
								</div>
								<div className="invert-grid-wrapper">
									<SpecInput
										type="checkbox"
										id="invertGrid"
										name="invertGrid"
										className="invert-grid"
										onChange={() =>
											dispatch({ type: CHANGE_INVERT_GRID, payload: !state.invertGrid })
										}
										checked={state.invertGrid}
									/>
									<SpecLabel className="invert-grid-label" htmlFor="invertGrid">
										Reversed grid
									</SpecLabel>
								</div>
							</div>
							<div className="reversed-metering-col2">
								<div className="invert-dru-inverter-wrapper">
									<SpecInput
										type="checkbox"
										id="invertDruInverter"
										name="invertDruInverter"
										className="invert-dru-inverter"
										onChange={() =>
											dispatch({
												type: CHANGE_INVERT_DRU_INVERTER,
												payload: !state.invertDruInverter,
											})
										}
										checked={state.invertDruInverter}
									/>
									<SpecLabel className="invert-dru-inverter-label" htmlFor="invertDruInverter">
										Reversed DRU inverter
									</SpecLabel>
								</div>
								<div className="invert-dru-grid-wrapper">
									<SpecInput
										type="checkbox"
										id="invertDruGrid"
										name="invertDruGrid"
										className="invert-dru-grid"
										onChange={() =>
											dispatch({ type: CHANGE_INVERT_DRU_GRID, payload: !state.invertDruGrid })
										}
										checked={state.invertDruGrid}
									/>
									<SpecLabel className="invert-dru-grid-label" htmlFor="invertDruGrid">
										Reversed DRU grid
									</SpecLabel>
								</div>
								<div className="invert-dru-hw-wrapper">
									<SpecInput
										type="checkbox"
										id="invertDruHw"
										name="invertDruHw"
										className="invert-dru-hw"
										onChange={() =>
											dispatch({ type: CHANGE_INVERT_DRU_HW, payload: !state.invertDruHotWater })
										}
										checked={state.invertDruHotWater}
									/>
									<SpecLabel className="invert-dru-hw-label" htmlFor="invertDruHw">
										Reversed DRU hot water
									</SpecLabel>
								</div>
							</div>
						</div>
					) : null}
				</span>
			</li>
			<li>
				<span className="strong specification">Balancing Services Unit </span>
				<span className="em specification">
					{facilityContext.facility.aggregatedFacility
						? facilityContext.facility.aggregatedFacility.name
						: '—'}
				</span>
			</li>
			<li>
				<span className="strong specification">Dispatch Contract </span>
				<span className="em specification">
					{facilityContext.facility.aggregatedFacility
						? `${facilityContext.facility.aggregatedFacility.dispatch} ${facilityContext.facility.aggregatedFacility.unit}`
						: '—'}
				</span>
			</li>
		</>
	);
};

export default FacilityInfoSpecifications;
