/* Copyright Levelise Ltd 2024 */
import React from 'react';
import TabContainer from '../TabContainer';
import CustomerInfoTab from '../CustomerInfoTab';

function OldCustomerTabCoT({ customer }) {
	return (
		<TabContainer title="Old customer">
			<CustomerInfoTab customer={customer} noAddress />
		</TabContainer>
	);
}

export default OldCustomerTabCoT;
