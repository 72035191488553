/* Copyright Levelise Ltd 2019-2024 */
import React, { useState, useContext, useEffect, useRef, useReducer } from 'react';
import { getUnixTime } from 'date-fns';
import { Button } from '../Form/index';
import { useAlert } from 'react-alert';
import FacilityContext from '../../contexts/FacilityContext';
import FleetContext from '../../contexts/FleetContext';
import FleetService from '../../services/fleet-service';
import Popup from '../Popup/index';
import {
	EXPORT_LIMIT_ERROR,
	IMPORT_LIMIT_ERROR,
	PERMISSIONS,
	PV_NET_CAPACITY_SHOULD_BE_IN_WATTS,
	SEVERITY_COLOR,
} from '../../utils/constants';
import { hasPermission, formatTimestamp } from '../../utils/utils';
import { GSP_GROUP, GSP_GROUP_ID } from '../../utils/specification';
import './index.css';
import cx from 'classnames';
import UserService from '../../services/user-service';
import GridSpecifications from '../GridSpecifications';
import FacilityInfoSpecifications from '../FacilityInfoSpecifications';

const uk_g83_g98 = 'G83 / G98',
	uk_g59_g99 = 'G59 / G99',
	as_4777 = 'AS4777';

const CHANGE_DNO = 'change_dno';
const CHANGE_GSP = 'change_gsp';
const CHANGE_CONNECTION_TYPE = 'change_connection_type';
const CHANGE_IMPORT_LIMIT_W = 'change_import_limit_w';
const CHANGE_EXPORT_LIMIT_W = 'change_export_limit_w';
const CHANGE_SUPPLY_PHASES = 'change_supply_phases';
const RESET_STATE = 'reset_state';

const CHANGE_DRU_ID = 'change_dru_id';
const CHANGE_BATTERY_SYSTEM = 'change_battery_system';
const CHANGE_PV_NET_CAPACITY = 'change_pv_net_capacity';
const CHANGE_RS485 = 'change_RS485';
const CHANGE_HAS_3_PHASE_METERING = 'change_has_3_phase_metering';
const CHANGE_HAS_GRID_METERING = 'change_has_grid_metering';
const CHANGE_INVERT_PV = 'change_invert_pv';
const CHANGE_INVERT_GRID = 'change_invert_grid';
const CHANGE_INVERT_DRU_INVERTER = 'change_invert_dru_inverter';
const CHANGE_INVERT_DRU_GRID = 'change_invert_dru_grid';
const CHANGE_INVERT_DRU_HW = 'change_invert_dru_hot_water';

function reducer(state, action) {
	// eslint-disable-next-line default-case
	switch (action.type) {
		case CHANGE_DNO: {
			return { ...state, dno: action.payload };
		}
		case CHANGE_GSP: {
			return { ...state, gspGroup: action.payload };
		}
		case CHANGE_CONNECTION_TYPE: {
			return { ...state, connectionType: action.payload };
		}
		case CHANGE_IMPORT_LIMIT_W: {
			return { ...state, importLimitW: action.payload };
		}
		case CHANGE_EXPORT_LIMIT_W: {
			return { ...state, exportLimitW: action.payload };
		}
		case CHANGE_SUPPLY_PHASES: {
			return { ...state, supplyPhases: action.payload };
		}
		case RESET_STATE: {
			return {
				dno: null,
				gspGroup: null,
				connectionType: '',
				importLimitW: '',
				exportLimitW: '',
				supplyPhases: null,
			};
		}
	}
	throw Error('Unknown action.');
}

function infoReducer(state, action) {
	// eslint-disable-next-line default-case
	switch (action.type) {
		case CHANGE_DRU_ID: {
			return { ...state, druId: action.payload };
		}
		case CHANGE_BATTERY_SYSTEM: {
			return { ...state, batterySystem: action.payload };
		}
		case CHANGE_INVERT_PV: {
			return { ...state, invertPv: action.payload };
		}
		case CHANGE_PV_NET_CAPACITY: {
			return { ...state, pvNetCapacityW: action.payload };
		}
		case CHANGE_RS485: {
			return { ...state, rs485: action.payload };
		}
		case CHANGE_HAS_3_PHASE_METERING: {
			return { ...state, has3PhaseMetering: action.payload };
		}
		case CHANGE_HAS_GRID_METERING: {
			return { ...state, hasGridMeter: action.payload };
		}
		case CHANGE_INVERT_GRID: {
			return { ...state, invertGrid: action.payload };
		}
		case CHANGE_INVERT_DRU_INVERTER: {
			return { ...state, invertDruInverter: action.payload };
		}
		case CHANGE_INVERT_DRU_GRID: {
			return { ...state, invertDruGrid: action.payload };
		}
		case CHANGE_INVERT_DRU_HW: {
			return { ...state, invertDruHotWater: action.payload };
		}
		case RESET_STATE: {
			return {
				batterySystem: '',
				pvNetCapacityW: '',
				rs485: 1,
				has3PhaseMetering: false,
				hasGridMeter: false,
				invertPv: false,
				invertGrid: false,
				invertDruInverter: false,
				invertDruGrid: false,
				invertDruHotWater: false,
			};
		}
	}
	throw Error('Unknown action.');
}

const getGridConnectionTypeId = (dno, timeZone, connectionType, gspGroup) => {
	const value = parseInt(dno.value);
	if (timeZone.toLowerCase().includes('london')) {
		if (connectionType === uk_g59_g99) {
			return !!gspGroup.value && value > 23
				? GSP_GROUP_ID[gspGroup.value][0] * 100 + value
				: value < 10
				? 2
				: 5900 + value;
		}
		if (connectionType === uk_g83_g98) {
			return !!gspGroup.value && value > 23
				? GSP_GROUP_ID[gspGroup.value][1] * 100 + value
				: value < 10
				? 1
				: 8300 + value;
		}
	}

	if (timeZone.toLowerCase().includes('australia')) {
		if (connectionType === as_4777) {
			return 4700 + value;
		}
	}

	return null;
};

const getChangedItemsForInfoSpec = (facilityObject, editedState) => {
	if (facilityObject && editedState) {
		const keyArr = [
			'invertPv',
			'invertGrid',
			'invertDruInverter',
			'invertDruGrid',
			'invertDruHotWater',
			'pvNetCapacityW',
			'has3PhaseMetering',
			'hasGridMeter',
		];

		const changedKeys = keyArr.filter((ele) => {
			if (Object.hasOwn(facilityObject, ele)) {
				if (facilityObject[ele] !== editedState[ele]) {
					return true;
				}

				if (facilityObject[ele] === editedState[ele]) {
					return false;
				}
			}

			if (!Object.hasOwn(facilityObject, ele) && editedState[ele]) {
				return true;
			}

			return false;
		});

		return changedKeys;
	}

	return [];
};

const getChangedItems = (facilityObject, editedState) => {
	const obj = {};
	if (facilityObject && editedState) {
		// get gridConnectionTypeId
		if (
			Object.hasOwn(editedState, 'gspGroup') &&
			Object.hasOwn(editedState, 'connectionType') &&
			Object.hasOwn(editedState, 'dno') &&
			editedState.gspGroup &&
			Object.hasOwn(editedState.gspGroup, 'value') &&
			editedState.dno &&
			Object.hasOwn(editedState.dno, 'value') &&
			(facilityObject?.gspGroup !== editedState.gspGroup.value ||
				facilityObject?.gridConnection?.connectionType !== editedState.connectionType)
		) {
			const timeZone = facilityObject?.timezone || 'Europe/London';

			const gridConnectionId = getGridConnectionTypeId(
				editedState?.dno,
				timeZone,
				editedState.connectionType,
				editedState.gspGroup
			);

			if (gridConnectionId) {
				obj.gridConnectionId = gridConnectionId;
			}
		}

		if (
			Object.hasOwn(editedState, 'gspGroup') &&
			editedState.gspGroup &&
			Object.hasOwn(editedState.gspGroup, 'value') &&
			editedState.dno &&
			Object.hasOwn(editedState.dno, 'value') &&
			facilityObject?.gspGroup !== editedState.gspGroup.value
		) {
			if (GSP_GROUP[editedState.gspGroup.value]) {
				obj.gspGroup = editedState.gspGroup.value;
			}
		}

		// supply phase
		if (
			Object.hasOwn(editedState, 'supplyPhases') &&
			editedState.supplyPhases &&
			Object.hasOwn(editedState.supplyPhases, 'value') &&
			facilityObject?.supplyPhases !== parseInt(editedState.supplyPhases.value)
		) {
			const supplyPhases = parseInt(editedState.supplyPhases.value);
			if (!isNaN(supplyPhases)) {
				obj.supplyPhases = supplyPhases;
			}
		}

		// importLimitW
		if (
			Object.hasOwn(editedState, 'importLimitW') &&
			facilityObject?.importLimitW !== parseInt(editedState.importLimitW)
		) {
			const importLimit = parseInt(editedState.importLimitW);
			if (!isNaN(importLimit)) {
				obj.importLimitW = importLimit;
			}
		}

		// exportLimitW
		if (
			Object.hasOwn(editedState, 'exportLimitW') &&
			facilityObject?.exportLimitW !== parseInt(editedState.exportLimitW)
		) {
			const exportLimit = parseInt(editedState.exportLimitW);
			if (!isNaN(exportLimit)) {
				obj.exportLimitW = exportLimit;
			}
		}
	}

	return obj;
};

const FacilitySpecification = ({ druId }) => {
	const Alert = useAlert();

	const information = 'Info',
		grid = 'Grid',
		status = 'Status',
		issues = 'Issues',
		kick = 'kick',
		restart = 'restart';
	const severityList = ['Unsafe', 'Unoptimisable', 'Diminished', 'Faulty', 'Glitchy', 'Noted'];
	const facilityContext = useContext(FacilityContext);
	const fleetContext = useContext(FleetContext);
	const [view, setView] = useState(information);
	const [command, setCommand] = useState('');
	const [confirm, setConfirm] = useState(false);
	const [facility, setFacility] = useState({});
	const [customer, setCustomer] = useState({});
	const [disabled, setDisabled] = useState(false);
	const [disabledDoneBtn, setDisabledDoneBtn] = useState(false);
	const [height, setHeight] = useState(0);
	const [editing, setEditing] = useState(false);
	const [editingInfo, setEditingInfo] = useState(false);
	const [loading, setLoading] = useState(false);

	const [druIdError, setDruIdError] = useState('');
	const [pvNetCapacityError, setPvNetCapacityError] = useState('');
	const [importLimitError, setImportLimitError] = useState('');
	const [exportLimitError, setExportLimitError] = useState('');

	const [confirmDruChange, setConfirmDruChange] = useState(false);
	const [requestBody, setRequestBody] = useState(null);

	const [state, dispatch] = useReducer(reducer, {
		dno: null,
		gspGroup: null,
		connectionType: '',
		importLimitW: '',
		exportLimitW: '',
		supplyPhases: null,
	});

	const [infoState, dispatchInfoChange] = useReducer(infoReducer, {
		druId: '',
		batterySystem: '',
		pvNetCapacityW: '',
		rs485: 1,
		has3PhaseMetering: false,
		hasGridMeter: false,
		invertPv: false,
		invertGrid: false,
		invertDruInverter: false,
		invertDruGrid: false,
		invertDruHotWater: false,
	});

	const ref = useRef(null);

	const handleClickConfirm = () => {
		if (!!command && facility.hasOwnProperty('name')) {
			FleetService.setFacilityCommand(facility.name, command)
				.then(() => {
					handleClickReset();
					setDisabled(true); // Disable command btns on successful request
					setTimeout(() => setDisabled(false), 180000); // Enable command btns after 3 mins
				})
				.catch(() => {
					handleClickReset();
				});
		}
	};

	const handleClickReset = () => {
		setCommand('');
		setConfirm(false);
	};

	const handleClickCommand = (cmd) => {
		setCommand(cmd);
		setConfirm(true);
	};

	const handleEditClick = (tab) => {
		if (tab === information) {
			setEditingInfo(true);
		} else {
			setEditing(true);
		}
	};

	const handleCancelClick = (tab) => {
		if (tab === information) {
			setEditingInfo(false);
		} else {
			setEditing(false);
		}
	};

	const handleUpdateFacility = async (body) => {
		if (
			hasPermission(PERMISSIONS.CAN_CONTROL_DRU) &&
			body &&
			Object.keys(body).length > 0 &&
			facility &&
			facility?.name
		) {
			setLoading(true);
			try {
				const msg = await FleetService.setFacility(facility.name, body);
				if (msg) {
					const updatedFacility = await FleetService.getFacility(facility.name);
					if (updatedFacility) {
						facilityContext.setFacility(updatedFacility);
					}
					setDisabled(true); // Disable command btns on successful request
					setTimeout(() => setDisabled(false), 10000); // Enable command btns after 10 seconds
					setEditing(false);
					setEditingInfo(false);
					setConfirmDruChange(false);
				}

				setLoading(false);
			} catch (err) {
				Alert.show(err?.message);
				setLoading(false);
			}
		}
	};

	const handleEditGrid = () => {
		const reqBody = getChangedItems(facilityContext.facility, state);

		if (
			reqBody &&
			Object.hasOwn(reqBody, 'importLimitW') &&
			(isNaN(reqBody.importLimitW) || reqBody.importLimitW < 100)
		) {
			setImportLimitError(IMPORT_LIMIT_ERROR);
			return;
		}

		if (
			reqBody &&
			Object.hasOwn(reqBody, 'exportLimitW') &&
			(isNaN(reqBody.exportLimitW) || reqBody.exportLimitW < 100)
		) {
			setExportLimitError(EXPORT_LIMIT_ERROR);
			return;
		}

		handleUpdateFacility(reqBody);
	};

	const validateDruId = async (dru) => {
		// check length
		if (dru.length !== 8) {
			setDruIdError('DRU ID is invalid.');
			return false;
		}

		// check if dru is in use or retired
		try {
			const res = await FleetService.getFacilityByDruId(dru);
			if (res && Object.hasOwn(res, 'name')) {
				setDruIdError('DRU ID is in use.');
				return false;
			}
		} catch (error) {
			if (error?.status !== 404) {
				setDruIdError('Something went wrong, please try again.');
				return false;
			}
		}

		try {
			const druStatus = await FleetService.getDruStatuses();
			if (druStatus) {
				let combined = {};
				if (druStatus.hasOwnProperty('unoptimised') && druStatus.hasOwnProperty('oaas')) {
					const oaas = druStatus['oaas'];
					const unoptimised = druStatus['unoptimised'];
					combined = {
						noFacility: [...unoptimised.noFacility, ...oaas.noFacility],
					};
				}

				// check if dru is in no facility list
				if (!combined.noFacility.includes(Number(dru))) {
					setDruIdError('This DRU is unknown.');
					return false;
				}
			}
		} catch (error) {
			setDruIdError('Something went wrong, please try again.');
			return false;
		}

		setDruIdError('');
		return true;
	};

	const handleConfirmDruChange = () => {
		handleUpdateFacility(requestBody);
	};

	const handleResetDruChange = () => {
		setRequestBody(null);
		setConfirmDruChange(false);
		setEditingInfo(false);
	};

	const handleEditInformation = async () => {
		const reqBody = {};
		if (
			infoState &&
			Object.hasOwn(infoState, 'druId') &&
			Number(infoState.druId) !== facilityContext.facility.druId
		) {
			const isDruValid = await validateDruId(infoState.druId);
			if (!isDruValid) {
				return;
			}

			reqBody.druId = Number(infoState.druId);
		}

		if (infoState.batterySystem && Object.hasOwn(infoState.batterySystem, 'value')) {
			const id = parseInt(infoState.batterySystem.value);
			if (!isNaN(id) && id !== facilityContext?.facility?.batterySystem?.id) {
				reqBody.batterySystemId = id;
			}
		}

		if (infoState?.rs485) {
			const value = parseInt(infoState.rs485.value);
			if (!isNaN(value) && value !== facilityContext?.facility?.rs485) {
				reqBody.rs485 = value;
			}
		}

		const changedItems = getChangedItemsForInfoSpec(facilityContext.facility, infoState);
		if (changedItems.length > 0) {
			changedItems.forEach((ele) => {
				reqBody[ele] = infoState[ele];
			});
		}

		if (
			reqBody &&
			Object.hasOwn(reqBody, 'pvNetCapacityW') &&
			(isNaN(reqBody.pvNetCapacityW) || reqBody.pvNetCapacityW < 100)
		) {
			setPvNetCapacityError(PV_NET_CAPACITY_SHOULD_BE_IN_WATTS);
			return;
		}

		if (reqBody && Object.hasOwn(reqBody, 'druId')) {
			setRequestBody(reqBody);
			setConfirmDruChange(true);
		} else if (Object.keys(reqBody).length > 0) {
			await handleUpdateFacility(reqBody);
		}
	};

	const handleDoneClick = (tab) => {
		if (tab === grid) {
			handleEditGrid();
		}

		if (tab === information) {
			handleEditInformation();
		}
	};

	const commandBtns = () => {
		return (
			<div className={cx('command-btn-wrapper')}>
				{!(editing || editingInfo) ? (
					<>
						{view === grid || view === information ? (
							<Button className={'btn-kick btn-kick-hover'} onClick={() => handleEditClick(view)}>
								Edit
							</Button>
						) : null}
						<Button
							className={disabled ? 'btn-kick' : 'btn-kick btn-kick-hover'}
							disabled={disabled}
							onClick={() => handleClickCommand(kick)}
						>
							Kick
						</Button>
						<Button
							className={disabled ? 'btn-restart' : 'btn-restart btn-restart-hover'}
							disabled={disabled}
							onClick={() => handleClickCommand(restart)}
						>
							Restart
						</Button>
					</>
				) : null}
				{(editing || editingInfo) && (view === grid || view === information) ? (
					<>
						<Button className={'btn-kick btn-kick-hover'} onClick={() => handleCancelClick(view)}>
							Cancel
						</Button>
						<Button
							className={'btn-kick btn-kick-hover btn-success'}
							onClick={() => handleDoneClick(view)}
							disabled={loading || disabledDoneBtn}
						>
							Done
						</Button>
					</>
				) : null}
			</div>
		);
	};

	const systemStatus = () => {
		if (Object.keys(facilityContext.status).length) {
			const list = facilityContext.selectedTimezone.split(' | ');
			const selectedTimezone = list[list.length - 1].trim();
			return (
				<>
					<li>
						<span className="strong status">DRU Start Time</span>
						<span className="em status">
							{!!facilityContext.status.swStartTimestampSec
								? formatTimestamp(
										facilityContext.status.swStartTimestampSec,
										selectedTimezone,
										'dd/MM/yyyy HH:mm:ss'
								  )
								: '—'}
						</span>
					</li>
					<li>
						<span className="strong status">DRU Registration Time</span>
						<span className="em status">
							{!!facilityContext.status.registrationTimestampSec
								? formatTimestamp(
										facilityContext.status.registrationTimestampSec,
										selectedTimezone,
										'dd/MM/yyyy HH:mm:ss'
								  )
								: '—'}
						</span>
					</li>
					<li>
						<span className="strong status">Update Server Signed Time</span>
						<span className="em status">
							{!!facilityContext.status.usSignedTimestampSec
								? formatTimestamp(
										facilityContext.status.usSignedTimestampSec,
										selectedTimezone,
										'dd/MM/yyyy HH:mm:ss'
								  )
								: '—'}
						</span>
					</li>
					<li>
						<span className="strong status">Update Server Noted Time</span>
						<span className="em status">
							{!!facilityContext.status.usNotedTimestampSec
								? formatTimestamp(
										facilityContext.status.usNotedTimestampSec,
										selectedTimezone,
										'dd/MM/yyyy HH:mm:ss'
								  )
								: '—'}
						</span>
					</li>
					<li>
						<span className="strong status">Operating Mode</span>
						<span className="em status">
							{!!facilityContext.status.operatingMode ? facilityContext.status.operatingMode : '—'}
						</span>
					</li>
					<li>
						<span className="strong status">MAC Address</span>
						<span className="em status">
							{!!facilityContext.status.macAddress ? facilityContext.status.macAddress : '—'}
						</span>
					</li>
					<li>
						<span className="strong status">Software Version</span>
						<span className="em status">
							{!!facilityContext.status.swVersion ? facilityContext.status.swVersion : '—'}
						</span>
					</li>
					<li>
						<span className="strong status">DRU OS</span>
						<span className="em status">
							{facilityContext.status?.druOs ? facilityContext.status.druOs : '—'}
						</span>
					</li>
					<li>
						<span className="strong status">Memory Usage</span>
						<span className="em status">
							{!!facilityContext.status.memoryPercent ? facilityContext.status.memoryPercent + '%' : '—'}
						</span>
					</li>
					<li>
						<span className="strong status">CPU Usage</span>
						<span className="em status">
							{!!facilityContext.status.cpuPercent ? facilityContext.status.cpuPercent + '%' : '—'}
						</span>
					</li>
					<li>
						<span className="strong status">Used Disk Space</span>
						<span className="em status">
							{!!facilityContext.status.diskUsedPercent
								? facilityContext.status.diskUsedPercent + '%'
								: '—'}
						</span>
					</li>
					<li>
						<span className="strong status">Lifetime Charge</span>
						<span className="em status">
							{!!facilityContext.status.batteryChargeKwh
								? facilityContext.status.batteryChargeKwh + 'kWh'
								: '—'}
						</span>
					</li>
					<li>
						<span className="strong status">Lifetime Discharge</span>
						<span className="em status">
							{!!facilityContext.status.batteryDischargeKwh
								? facilityContext.status.batteryDischargeKwh + 'kWh'
								: '—'}
						</span>
					</li>
					<li>
						<span className="strong status">Battery Serial</span>
						<span className="em status">
							{!!facilityContext.status.batterySerial ? facilityContext.status.batterySerial : '—'}
						</span>
					</li>
					<li>
						<span className="strong status">Inverter Registration Number</span>
						<span className="em status">
							{!!facilityContext.status.inverterRegistrationNumber
								? facilityContext.status.inverterRegistrationNumber
								: '—'}
						</span>
					</li>
					<li>
						<span className="strong status">Inverter Serial</span>
						<span className="em status">
							{!!facilityContext.status.inverterSerial ? facilityContext.status.inverterSerial : '—'}
						</span>
					</li>
					<li>
						<span className="strong status">Inverter Version</span>
						<span className="em status">
							{!!facilityContext.status.inverterVersion ? facilityContext.status.inverterVersion : '—'}
						</span>
					</li>
					<li>
						<span className="strong status">Inverter Status</span>
						<span className="em status">
							{!!facilityContext.status.inverterStatus ? facilityContext.status.inverterStatus : '—'}
						</span>
					</li>
				</>
			);
		}
	};

	const getSeverityFaults = () => {
		const faults = [];
		if (
			fleetContext.status &&
			!!Object.keys(fleetContext.status).length &&
			!!fleetContext.faultCodes &&
			!!Object.keys(fleetContext.faultCodes).length
		) {
			const faultCodeDrus = fleetContext.status.combined.faultCode;
			const faultCodes = fleetContext.faultCodes;
			const severityFaultInfo = {};
			Object.keys(faultCodeDrus).forEach((code) => {
				if (
					!!faultCodes &&
					!!faultCodes.hasOwnProperty(code) &&
					faultCodeDrus[code].indexOf(Number(druId)) >= 0
				) {
					const level = faultCodes[code].level;
					severityFaultInfo[level] = severityFaultInfo.hasOwnProperty(level)
						? [...severityFaultInfo[level], faultCodes[code]]
						: [faultCodes[code]];
				}
			});

			severityList.forEach((level) => {
				if (!!severityFaultInfo && severityFaultInfo.hasOwnProperty(level)) {
					const sortedInfo = severityFaultInfo[level].sort((a, b) => b.severity - a.severity);
					faults.push([level, sortedInfo]);
				}
			});

			if (faults.length === 0) {
				const druId = facilityContext.facility.druId;
				if (fleetContext.status.combined.retired.includes(druId)) {
					faults.push(['Inactive', [{ description: 'Removed from the facility' }]]);
				} else if (fleetContext.status.combined.noFacility.includes(druId)) {
					faults.push(['Inactive', [{ description: "Haven't had an associated facility" }]]);
				} else if (fleetContext.status.combined.unregistered.includes(druId)) {
					faults.push(['Inactive', [{ description: 'Not registered' }]]);
				}
			}
		}
		return faults;
	};

	const severityFaultCount = () => {
		const faults = getSeverityFaults();
		if (!faults && !faults.length) return 0;
		// else ..
		return getSeverityFaults().reduce((count, fault) => count + 1 + fault[1].length, 0);
	};

	const getSeverityColour = (severity) => {
		let color;

		switch (severity) {
			case 'Ok':
				color = SEVERITY_COLOR.OK.backgroundSeverity;
				break;
			case 'Noted':
				color = SEVERITY_COLOR.OK.backgroundSeverity;
				break;
			case 'Glitchy':
				color = SEVERITY_COLOR.Glitchy.backgroundSeverity;
				break;
			case 'Faulty':
				color = SEVERITY_COLOR.Faulty.backgroundSeverity;
				break;
			case 'Diminished':
				color = SEVERITY_COLOR.Diminished.backgroundSeverity;
				break;
			case 'Unoptimisable':
				color = SEVERITY_COLOR.Unoptimisable.backgroundSeverity;
				break;
			case 'Unsafe':
				color = SEVERITY_COLOR.Unsafe.backgroundSeverity;
				break;
			case 'Inactive':
				color = SEVERITY_COLOR.Inactive.backgroundSeverity;
				break;
			default:
				color = '#000';
		}

		return { color: color };
	};

	const severityFaults = () => {
		const faults = getSeverityFaults();
		if (!faults && !faults.length) return;
		// else ..
		const ret = [];
		for (let i = 0; i < faults.length; i++) {
			const fault = faults[i];

			const textStyle = getSeverityColour(fault[0]);
			ret.push(
				<li key={`severity${i}`}>
					<span className="strong severity-name" style={textStyle}>
						{fault[0]}
					</span>
					<span className="em severity"></span>
				</li>,
				...fault[1].map((info, j) => (
					<li key={`severity${i}${j}`}>
						<span className="em severity-description" style={textStyle}>
							{info.description}
						</span>
						<span className="em severity-description"></span>
					</li>
				))
			);
		}
		return ret;
	};

	const listSlots = (n = 0) => {
		const fill = parseInt((height - n * 24) / 24);
		if (fill > 0) {
			const className = view === information ? 'specification' : 'status';
			return Array(fill)
				.fill()
				.map((_, i) => (
					<li key={`slot-${i}`} style={{ height: 24 }}>
						<span className={`strong ${className}`}>&nbsp;</span>
						<span className={`em ${className}`}>&nbsp;</span>
					</li>
				));
		}
	};

	const handleFetchSystems = () => {
		if (UserService.hasUser() && hasPermission(PERMISSIONS.CAN_ACCESS_AF)) {
			FleetService.getBatterySystems()
				.then((res) => {
					const batterySystems = res.reduce(
						(acc, curr) => {
							acc[curr.id] = curr.name;
							return acc;
						},
						{ 0: '-- None --' }
					);
					fleetContext.setBatterySystemSpecsList(res);
					fleetContext.setBatterySystems(batterySystems);
				})
				.catch(fleetContext.setError);
		}
	};

	const text = (cmd) => {
		return (
			<>
				{'Are you sure you want to '}
				<strong>{cmd}</strong>
				{cmd === restart ? ' DRU' : ' the update server?'}
			</>
		);
	};

	const handleViewChange = (tab) => {
		if (tab !== grid && editing) {
			setEditing(false);
		}

		if (tab !== information && editingInfo) {
			setEditingInfo(false);
		}

		setView(tab);
	};

	useEffect(() => {
		if (druId && !fleetContext.tokenUpdating) {
			const timestamp = getUnixTime(new Date()) - 1800; // Current epoch time - 30mins
			const registrationTimestamp = facilityContext.status.registrationTimestampSec;

			if (fleetContext.status.hasOwnProperty('combined')) {
				const drus = fleetContext.status['combined'].disconnected.map((disconnected) => disconnected.druId);
				if (drus.includes(druId) || timestamp <= registrationTimestamp) {
					sessionStorage.setItem('disconnected', '1');
					setDisabled(true);
				} else {
					sessionStorage.removeItem('disconnected');
				}
			} else {
				setDisabled(sessionStorage.getItem('disconnected') === '1');
			}

			FleetService.getFacilityByDruId(druId)
				.then((res) => {
					setFacility(res);
					if (hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER)) {
						FleetService.getCustomerByFacility(res.name).then(setCustomer).catch(facilityContext.setError);
					}
				})
				.catch(facilityContext.setError);
		}
	}, [druId, fleetContext.tokenUpdating]);

	useEffect(() => {
		if (!fleetContext.tokenUpdating) {
			if (
				!fleetContext.batterySystems ||
				(fleetContext.batterySystems && Object.keys(fleetContext.batterySystems).length === 0)
			) {
				handleFetchSystems();
			}
		}
	}, [fleetContext.tokenUpdating]);

	useEffect(() => {
		if (view === grid) {
			const body = getChangedItems(facilityContext.facility, state);
			if (body && Object.keys(body).length === 0) {
				setDisabledDoneBtn(true);
			} else {
				setDisabledDoneBtn(false);
			}

			if (
				body &&
				((Object.hasOwn(body, 'importLimitW') && importLimitError) ||
					(Object.hasOwn(body, 'exportLimitW') && exportLimitError))
			) {
				setDisabledDoneBtn(true);
			}
		}

		if (view === information) {
			const changedState = getChangedItemsForInfoSpec(facilityContext.facility, infoState);

			if (
				facilityContext?.facility?.druId &&
				infoState?.druId &&
				Number(infoState.druId) !== facilityContext.facility.druId
			) {
				setDisabledDoneBtn(false);
			} else if (
				facilityContext?.facility?.batterySystem?.id &&
				infoState.batterySystem?.label &&
				facilityContext.facility.batterySystem.id !== parseInt(infoState.batterySystem?.value)
			) {
				setDisabledDoneBtn(false);
			} else if (facilityContext.facility?.rs485 !== parseInt(infoState.rs485?.value)) {
				setDisabledDoneBtn(false);
			} else if (changedState.length > 0) {
				setDisabledDoneBtn(false);
			} else {
				setDisabledDoneBtn(true);
			}

			if (changedState.includes('pvNetCapacityW') && pvNetCapacityError) {
				setDisabledDoneBtn(true);
			}
		}
	}, [
		editing,
		editingInfo,
		infoState,
		infoState.batterySystem,
		infoState.pvNetCapacityW,
		infoState.rs485,
		infoState.has3PhaseMetering,
		infoState.hasGridMeter,
		infoState.invertPv,
		infoState.invertGrid,
		infoState.invertDruInverter,
		infoState.invertDruGrid,
		infoState.invertDruHotWater,
		facilityContext?.facility,
		state,
		view,
		pvNetCapacityError,
		importLimitError,
		exportLimitError,
	]);

	useEffect(() => {
		setHeight(ref.current.clientHeight);
	});

	return (
		<div className="facility-specification-wrapper">
			{hasPermission(PERMISSIONS.CAN_CONTROL_DRU) && confirm && (
				<Popup
					type="command"
					text={text(command)}
					onConfirm={handleClickConfirm}
					confirm={'Yes'}
					onClose={handleClickReset}
					close={'No'}
				/>
			)}
			{hasPermission(PERMISSIONS.CAN_CONTROL_DRU) && confirmDruChange && (
				<Popup
					type="command"
					text={`Are you sure you want to change the DRU of this facility to ${requestBody?.druId}?`}
					onConfirm={handleConfirmDruChange}
					confirm={'Yes'}
					onClose={handleResetDruChange}
					close={'No'}
					disableConfirm={loading}
				/>
			)}
			<h2 className="info-status-header">
				<span
					id="fac-info"
					className={view === information ? 'fac-view' : ''}
					onClick={() => handleViewChange(information)}
				>
					{information}
				</span>
				<span id="fac-grid" className={view === grid ? 'fac-view' : ''} onClick={() => handleViewChange(grid)}>
					{grid}
				</span>
				<span
					id="fac-status"
					className={view === status ? 'fac-view' : ''}
					onClick={() => handleViewChange(status)}
				>
					{status}
				</span>
				<span
					id="fac-issues"
					className={view === issues ? 'fac-view' : ''}
					onClick={() => handleViewChange(issues)}
				>
					{issues}
				</span>
			</h2>
			{hasPermission(PERMISSIONS.CAN_CONTROL_DRU) && commandBtns()}
			<ul ref={ref} className={cx('facility-specifications scroll-fac-spec ')}>
				{view === information && (
					<FacilityInfoSpecifications
						editing={editingInfo}
						facilityContext={facilityContext}
						customer={customer}
						facility={facility}
						state={infoState}
						dispatch={dispatchInfoChange}
						pvNetCapacityError={pvNetCapacityError}
						setPvNetCapacityError={setPvNetCapacityError}
						druIdError={druIdError}
						setDruIdError={setDruIdError}
					/>
				)}
				{view === grid && (
					<GridSpecifications
						editing={editing}
						facilityContext={facilityContext}
						state={state}
						dispatch={dispatch}
						importLimitError={importLimitError}
						setImportLimitError={setImportLimitError}
						exportLimitError={exportLimitError}
						setExportLimitError={setExportLimitError}
					/>
				)}
				{view === status && systemStatus()}
				{view === issues && severityFaults()}
				{view === information && listSlots(17)}
				{view === grid && listSlots(7)}
				{view === status && listSlots(17)}
				{view === issues && listSlots(severityFaultCount())}
			</ul>
		</div>
	);
};

export default FacilitySpecification;
