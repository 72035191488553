/* Copyright Levelise Ltd 2019-2020 */
import React from 'react';
import cx from 'classnames';
import './index.css';


export function Input({className, ...props}){
    return (
        <input className={cx('Input', className)} {...props}/>
    )
}

export function Button({className, ...props}){
    return (
        <button className={cx('Button', className)} {...props}/>
    )
}

export function SpecLabel({ className, ...props }) {
    return (
        <label className={cx('SpecLabel', className)} {...props} />
    )
}

export function SpecInput({className, ...props}){
    return (
        <input className={cx('SpecInput', className)} {...props}/>
    )
}

export function SpecSelectInput({className, ...props}){
    return (
        <>
            <input 
                list={props.id}
                name={props.name} 
                className={cx('SelectData', className)}
                placeholder={props.defaultValue}
                required={props.required}
            />
            <datalist
                id={props.id}
                defaultValue=""
                className="Data-list"
            >
                <option value="" disabled hidden className="default-option">
                    {props.defaultValue}
                </option>
                {props.options 
                && props.options.map(option => {
                    return (
                        <option key={option} value={option} label={option}>
                            {option}
                        </option>
                        );
                    })
                }
            </datalist>
        </>
    )
}