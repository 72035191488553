/* Copyright Levelise Ltd 2024 */
import React from 'react';
import cx from 'classnames';
import './index.css';
import { Button, SpecInput } from '../Form';
import { colors } from '../../utils/chart';

const PopupWithInput = ({
	message,
	type,
	placeholder,
	value,
	setValue,
	showError,
	errorMessage,
	onCancelClick,
	cancelButtonLabel,
	onPrimaryButtonClicked,
	primaryButtonDisabled,
	primaryButtonLabel,
}) => {
	return (
		<div className="popup">
			<div className={cx('popup-inner', type)}>
				<p style={{ fontSize: 14, marginLeft: 10 }}>{message}</p>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							alignSelf: 'center',
						}}
					>
						<SpecInput
							id="popup_input_pass"
							name="popupinputpass"
							type={type || 'password'}
							placeholder={placeholder || 'Password'}
							value={value}
							onChange={(e) => setValue(e.target.value)}
						/>
						{showError ? (
							<p
								style={{
									fontSize: 9,
									color: colors.errorRed,
									margin: 0,
								}}
							>
								{errorMessage}
							</p>
						) : null}
					</div>
				</div>
				<div className="popup-btn-wrapper">
					<Button className={'btn-cancel command'} onClick={onCancelClick}>
						{cancelButtonLabel}
					</Button>
					<Button
						className={`btn-confirm command`}
						onClick={onPrimaryButtonClicked}
						disabled={primaryButtonDisabled}
					>
						{primaryButtonLabel}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default PopupWithInput;
