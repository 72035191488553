/* Copyright Levelise Ltd 2024 */
import React from 'react';
import { Button, SpecInput } from '../Form';
import './index.css'

function CustomerSearchComponent({
	customerIds,
	onCustomerIdsChange,
	customerSearchTerm,
	onCustomerSearchTermChange,
	onClearClicked,
	onSearchClicked,
	druSearchInput,
	onDrusSearchTermChange,
	showDruSearch,
	disableCustomerId,
	disableSearchInput,
	disableDruId,
	loading,
}) {
	return (
		<div className="search-inputs-container">
			<div className="label-input-container c-o-t">
				<label htmlFor="idInput">Old Customer ID</label>
				<SpecInput
					name="idInput"
					type="text"
					placeholder="Customer ID e.g. 12"
					value={customerIds}
					onChange={onCustomerIdsChange}
					disabled={disableCustomerId}
				/>
			</div>
			<div className="label-input-container c-o-t">
				<label htmlFor="searchInput">Customer Info</label>
				<SpecInput
					name="searchInput"
					type="text"
					placeholder="Name/Address/Email/Phone"
					value={customerSearchTerm}
					onChange={onCustomerSearchTermChange}
					disabled={disableSearchInput}
				/>
			</div>
			{showDruSearch ? (
				<div className="label-input-container c-o-t">
					<label htmlFor="druInput">DRU ID</label>
					<SpecInput
						name="druInput"
						type="number"
						placeholder="790..."
						value={druSearchInput}
						onChange={onDrusSearchTermChange}
						disabled={disableDruId}
					/>
				</div>
			) : null}
			<div className="customer-search-button-container c-o-t">
				<Button className="configure-btn reset" onClick={onClearClicked}>
					Clear
				</Button>
				<Button
					className={'configure-btn submit'}
					disabled={loading || !(customerIds || customerSearchTerm || druSearchInput)}
					onClick={onSearchClicked}
				>
					Search
				</Button>
			</div>
		</div>
	);
}

export default CustomerSearchComponent;
