/* Copyright Levelise Ltd 2024 */
import * as React from 'react';
const HotWaterTank = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={86} height={91} viewBox='0 0 85 91' fill="none" {...props}>
		<mask id="a" fill="#fff">
			<rect width={85} height={90.461} rx={2} />
		</mask>
		<rect width={85} height={90.461} fill="#fff" stroke="#6695C4" strokeWidth={6} mask="url(#a)" rx={2} />
		<path
			stroke="#6695C4"
			strokeWidth={3}
			d="M73.894 8.773c.002 0-.006.009-.027.026a.124.124 0 0 1 .027-.026Zm-1.689.373c.2.06.382.12.545.176a12.93 12.93 0 0 1-.545.177c-1.531.46-3.821.894-6.74 1.265-5.816.74-13.896 1.202-22.85 1.202-8.956 0-17.125-.462-23.031-1.202-2.964-.372-5.3-.806-6.867-1.268-.202-.059-.384-.117-.55-.174.166-.056.348-.114.55-.174 1.566-.461 3.903-.896 6.867-1.267 5.906-.74 14.076-1.202 23.03-1.202 8.955 0 17.035.462 22.85 1.202 2.92.37 5.21.805 6.741 1.265ZM10.973 8.76c.001 0 .012.009.03.027-.024-.017-.032-.027-.03-.027Zm0 1.125c-.002 0 .006-.01.03-.028a.129.129 0 0 1-.03.028Zm62.894-.039c.02.017.029.025.027.026a.124.124 0 0 1-.027-.026Z"
		/>
		<path stroke="#6695C4" strokeLinecap="round" strokeWidth={3} d="M10.625 9.668V76.65M74.375 9.668V76.65" />
		<path stroke="#6695C4" strokeWidth={3} d="M10.625 76.65c21.959 11.478 41.438 12.153 63.75 0" />
		<path
			stroke="#6695C4"
			strokeDasharray="6 6"
			strokeWidth={3}
			d="M10.625 59.387c21.959 11.477 41.438 12.152 63.75 0M10.625 42.814c21.959 11.477 41.438 12.152 63.75 0M10.625 24.86c21.959 11.477 41.438 12.152 63.75 0"
		/>
		<rect width={6.375} height={6.215} x={4.958} y={66.983} fill="#6695C4" rx={2} />
		<rect width={6.375} height={6.215} x={74.375} y={16.573} fill="#6695C4" rx={2} />
	</svg>
);
export default HotWaterTank;
