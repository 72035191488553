/* Copyright Levelise Ltd 2024 */
import * as React from "react";

const LoadHouseSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={87}
    height={77}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#64B664"
        d="M28.309 49.856c.674 1.031 1.872 1.647 3.206 1.647h8.714L38.133 63.31c-.269 1.523.758 3.012 2.447 3.546.41.13.83.192 1.243.192 1.255 0 2.446-.578 3.139-1.594 0-.001.004-.004.004-.006L58.598 45.39c.713-1.052.762-2.367.125-3.35-.649-.997-1.875-1.593-3.28-1.593h-8.651l2.102-12.118v-.005c.268-1.576-.74-3.092-2.4-3.605-1.66-.515-3.598.125-4.508 1.485L28.352 46.631s0 .002-.002.003c-.68 1.018-.695 2.223-.041 3.222Zm26.996-6.097L41.94 63.423 44.304 50.1l.004-.039.011-.088.006-.088.002-.039c0-.011 0-.023-.002-.034a.865.865 0 0 0-.006-.098l-.007-.07a.81.81 0 0 0-.017-.093l-.017-.073a1.043 1.043 0 0 0-.024-.081c-.01-.026-.017-.052-.026-.078a1.142 1.142 0 0 0-.03-.072 1.059 1.059 0 0 0-.037-.08c-.01-.021-.024-.044-.035-.065l-.046-.078-.043-.062a1.08 1.08 0 0 0-.106-.132 1.732 1.732 0 0 0-.12-.124l-.06-.053-.073-.06a2.26 2.26 0 0 0-.143-.098l-.067-.04-.083-.045-.076-.038c-.028-.013-.056-.024-.084-.035l-.09-.035a1.827 1.827 0 0 0-.182-.054c-.025-.006-.05-.01-.075-.017a1.186 1.186 0 0 0-.11-.02l-.039-.006-.044-.004-.103-.01a35.047 35.047 0 0 1-.098-.004l-.047-.002H31.695l13.42-20.106-2.392 13.77c-.083.478.077.963.435 1.33.359.366.883.576 1.434.576h10.713v.005Z"
      />
      <path
        fill="#000"
        d="M72.246 17.323c-1.063 0-1.925-.755-1.925-1.687V3.376h-11.39v2.942c0 .933-.862 1.687-1.925 1.687-1.062 0-1.924-.756-1.924-1.687V2.569c0-1.416 1.315-2.569 2.93-2.569h13.23c1.617 0 2.932 1.153 2.932 2.57v13.066c0 .933-.862 1.687-1.925 1.687h-.003ZM71.933 77H15.118c-2.738 0-4.963-1.952-4.963-4.35V33.042c0-.933.862-1.687 1.925-1.687 1.062 0 1.924.755 1.924 1.687v39.606c0 .54.5.977 1.114.977h56.815c.615 0 1.115-.438 1.115-.977V33.043c0-.933.862-1.687 1.924-1.687 1.063 0 1.925.755 1.925 1.687v39.606c0 2.4-2.227 4.351-4.964 4.351Z"
      />
      <path
        fill="#000"
        d="M1.926 33.171c-.61 0-1.213-.255-1.586-.729-.604-.769-.383-1.819.494-2.348L39.97 6.466c2.129-1.285 4.987-1.285 7.116 0l39.083 23.597c.875.53 1.096 1.58.494 2.348-.604.767-1.802.96-2.677.433L44.901 9.245a2.72 2.72 0 0 0-2.748 0L3.02 32.874a2.1 2.1 0 0 1-1.09.297h-.003Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h87v77H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default LoadHouseSVG
