/* Copyright Levelise Ltd 2020-2022 */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Chart } from 'chart.js';
import { getSinceAndBefore } from '../../utils/utils'
import FleetService from '../../services/fleet-service';
import FleetContext from '../../contexts/FleetContext';

import HwFlowChart from './charts/hwFlow';
import HwFlowByTankChart from './charts/hwFlowByTank';
import HwMedianTemperatureChart from './charts/hwMedianTemperature';
import HwMedianTemperatureTankChart from './charts/hwMedianTemperatureByTank';
import HwSensorTemperatureChart from './charts/hwSensorTemperature';
import HwSensorTemperatureByTankTypeChart from './charts/hwSensorTemperatureByTank';

import { CONTRACT_TIMEFRAME, TIME_FRAMES, fleet, timestampSec, timestampDay, resolutions } from '../../utils/constants';
import { filterDataByTimeframe } from '../../utils/utils';
import './index.css';


const FleetHotWaterChart = ({ view, hw_tank, showPerformance }) => {
    const fleetContext = useContext(FleetContext);
    const intervalId = useRef();
    const [currentTimeFrame, setCurrentTimeFrame] = useState('');
    const [currentType, setCurrentType] = useState('');
    const [data, setData] = useState({
        type: '',
        timeFrame: '',
        flow: [],
        temperature: [],
        resolution_flow: '',
        resolution_temp: '',
        updated: false,
    });
    const days = {
        [TIME_FRAMES.thirty_six_months]: 1096,
        [TIME_FRAMES.twelve_months]: 366,
        [TIME_FRAMES.three_months]: 91,
    }

    const half_hours = {
        [TIME_FRAMES.twenty_one_days]: 1440 * 60 * 21,
        [TIME_FRAMES.one_week]: 1440 * 60 * 7
    }

    const minutes = {
        [TIME_FRAMES.twenty_four_hours]: 1440 * 60,
        [TIME_FRAMES.twelve_hours]: 720 * 60,
        [TIME_FRAMES.six_hours]: 360 * 60,
        [TIME_FRAMES.one_hour]: 60 * 60,
        [TIME_FRAMES.fifteen_minutes]: 15 * 60,
    }

    const setHwSystemTypes = (reports) => {
        const typeIds = [];
        const { selectedTimeFrame, currentDataType } = fleetContext;
        const hasTimeFrameChange = !currentTimeFrame || currentTimeFrame !== selectedTimeFrame;
        if (!!reports && (hasTimeFrameChange || currentDataType !== currentType)) {
            setCurrentTimeFrame(selectedTimeFrame);
            setCurrentType(currentDataType);
            for (let i = reports.length - 1; i >= 0; i--) {
                Object.keys(reports[i]['byType']).forEach(typeId => {
                    if (!typeIds.includes(typeId)) typeIds.push(typeId);
                })
            }

            if (!!typeIds.length) {
                const systemSet = new Set([...fleetContext.hwTypes, ...typeIds])
                fleetContext.setHwTypes([...systemSet]);
            }
        }
    }

    const fetchFlow = async (resolution, since = null, before = null) => {
        if (fleetContext.currentDataType === fleet || fleetContext.currentDataType === "") {
            try {
                const res = await FleetService.getFleetFlow(resolution, since, before);
                return res;
            } catch (err) {
                fleetContext.setError(err);
                return await Promise.reject(err);
            }
        }
        try {
            const res_1 = await FleetService.getAggFacilityFlow(fleetContext.currentDataType, resolution, since, before);
            return res_1;
        } catch (err_1) {
            fleetContext.setError(err_1);
            return await Promise.reject(err_1);
        }
    }

    const fetchTemperature = async (resolution, since = null, before = null) => {
        if (fleetContext.currentDataType === fleet || fleetContext.currentDataType === "") {
            try {
                const res = await FleetService.getFleetHwTemperature(resolution, since, before);
                return res;
            } catch (err) {
                fleetContext.setError(err);
                return await Promise.reject(err);
            }
        }
        try {
            const res_1 = await FleetService.getAggFacilityHwTemperature(fleetContext.currentDataType, resolution, since, before);
            return res_1;
        } catch (err_1) {
            fleetContext.setError(err_1);
            return await Promise.reject(err_1);
        }
    }

    const doUpdate = () => {
        return !(Object.values(Chart.instances).some(ci => ci.tooltip?._active?.length) ||
            Object.values(Chart.instances).some(ci => ci.getZoomLevel() > 1.0));
    }

    const handleTimeframeChange = () => {
        const type = fleetContext.currentDataType;
        const timeFrame = fleetContext.selectedTimeFrame;

        switch (timeFrame) {
            case TIME_FRAMES.all:
                Promise.all([fetchFlow(resolutions.week), fetchTemperature(resolutions.week)]).then(d => {
                    if (!!d) {
                        handleSetData(type, timeFrame, d[0], d[1], resolutions.week, resolutions.week);
                    }
                })
                break;
            case TIME_FRAMES.thirty_six_months:
            case TIME_FRAMES.twelve_months:
            case TIME_FRAMES.three_months:
                Promise.all([fetchFlow(resolutions.day), fetchTemperature(resolutions.day)]).then(d => {
                    if (!!d) {
                        const filteredFlow = filterDataByTimeframe(d[0], days[timeFrame], timestampDay);
                        const filteredTemp = filterDataByTimeframe(d[1], days[timeFrame], timestampDay);
                        handleSetData(type, timeFrame, filteredFlow, filteredTemp, resolutions.day, resolutions.day);
                    }
                })
                break;
            case TIME_FRAMES.twenty_one_days:
            case TIME_FRAMES.one_week:
                Promise.all([fetchFlow(resolutions.half_hour), fetchTemperature(resolutions.half_hour)]).then(d => {
                    if (!!d) {
                        const filteredFlow = filterDataByTimeframe(d[0], half_hours[timeFrame], timestampSec);
                        const filteredTemp = filterDataByTimeframe(d[1], half_hours[timeFrame], timestampSec);
                        handleSetData(type, timeFrame, filteredFlow, filteredTemp);
                    }
                })
                break;
            case TIME_FRAMES.twenty_four_hours:
            case TIME_FRAMES.six_hours:
            case TIME_FRAMES.one_hour:
                Promise.all([fetchFlow(resolutions.half_hour), fetchTemperature(resolutions.minute)]).then(d => {
                    if (!!d) {
                        d[0] = filterDataByTimeframe(d[0], minutes[timeFrame], timestampSec);
                        d[1] = filterDataByTimeframe(d[1], minutes[timeFrame], timestampSec);
                        handleSetData(type, timeFrame, d[0], d[1]);
                    }
                })
    
                intervalId.current = setInterval(() => {
                    if (doUpdate()) {
                        Promise.all([fetchFlow(resolutions.half_hour), fetchTemperature(resolutions.minute)]).then(d => {
                            if (!!d) {
                                d[0] = filterDataByTimeframe(d[0], minutes[timeFrame], timestampSec);
                                d[1] = filterDataByTimeframe(d[1], minutes[timeFrame], timestampSec);
                                handleSetData(type, timeFrame, d[0], d[1], '', '', true);
                            }
                        })
                    }
                }, 5 * 60000);
                break;
            case TIME_FRAMES.fifteen_minutes:
                Promise.all([fetchTemperature(resolutions.minute)]).then(d => {
                    if (!!d) {
                        d[0] = filterDataByTimeframe(d[0], minutes[timeFrame], timestampSec);
                        handleSetData(type, timeFrame, [], d[0]);
                    }
                })
    
                intervalId.current = setInterval(() => {
                    if (doUpdate()) {
                        Promise.all([fetchTemperature(resolutions.minute)]).then(d => {
                            if (!!d && !!d[0].length) {
                                d[0] = filterDataByTimeframe(d[0], minutes[timeFrame], timestampSec);
                                handleSetData(type, timeFrame, [], d[0], '', '', true);
                            }
                        })
                    }
                }, 60000);
                break;
            case TIME_FRAMES.select:
                const { startDate, endDate, timezone } = fleetContext;
                if (startDate && endDate) {
                    const [since, before, resolution_flow, resolution_temp] = getSinceAndBefore(startDate, endDate, timezone, resolutions.half_hour);
                    if (since < before) {
                        Promise.all([fetchFlow(resolution_flow, since, before),
                        fetchTemperature(resolution_temp, since, before)]).then(d => {
                            if (!!d) {
                                handleSetData(type, timeFrame, d[0] || [], d[1] || [], resolution_flow, resolution_temp);
                            }
                        })
                    }
                }
                break;
        }
    }

    const handleSetData = (type, timeFrame, flow, temperature, resolution_flow = '', resolution_temp = '', updated = false) => {
        setHwSystemTypes([...flow, ...temperature]);
        setData({ type, timeFrame, flow, temperature, resolution_flow, resolution_temp, updated });
    }

    const extractSelectedTimezone = () => {
        if (fleetContext.selectedTimezone.length) {
            const list = fleetContext.selectedTimezone.split(' | ');
            return list[list.length - 1].trim();
        }

        return "";
    }


    useEffect(() => {
        const { currentDataType, timezone } = fleetContext;
        if (view === hw_tank && !!currentDataType && !!timezone) {
            clearInterval(intervalId.current);
            handleTimeframeChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        fleetContext.currentDataType,
        fleetContext.timezone,
        fleetContext.selectedTimeFrame,
        fleetContext.startDate,
        fleetContext.endDate
    ])

    useEffect(() => {
        return () => clearInterval(intervalId.current);
    }, [])

    return (
        <>
            <div className="left-graphs-wrapper">
                <HwFlowChart
                    data={data}
                    timezone={fleetContext.timezone}
                    selectedTimezone={extractSelectedTimezone()}
                    showPerformance={showPerformance}
                />
                <HwMedianTemperatureChart
                    data={data}
                    timezone={fleetContext.timezone}
                    selectedTimezone={extractSelectedTimezone()}
                    showPerformance={showPerformance}
                />
                <HwSensorTemperatureChart
                    data={data}
                    timezone={fleetContext.timezone}
                    selectedTimezone={extractSelectedTimezone()}
                    showPerformance={showPerformance}
                />
            </div>
            <div className="right-graphs-wrapper">
                <HwFlowByTankChart
                    data={data}
                    timezone={fleetContext.timezone}
                    selectedTimezone={extractSelectedTimezone()}
                    showPerformance={showPerformance}
                />
                <HwMedianTemperatureTankChart
                    data={data}
                    timezone={fleetContext.timezone}
                    selectedTimezone={extractSelectedTimezone()}
                    showPerformance={showPerformance}
                />
                <HwSensorTemperatureByTankTypeChart
                    data={data}
                    timezone={fleetContext.timezone}
                    selectedTimezone={extractSelectedTimezone()}
                    showPerformance={showPerformance}
                />
            </div>
        </>
    )
}

export default FleetHotWaterChart;