/* Copyright Levelise Ltd 2024 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { loading, success, upload } from '../../utils/constants';
import './index.css';

const UploadBtn = ({ uploadState, onUploadClick, style }) => {
    const className = uploadState === upload
        ? upload : uploadState === loading
            ? loading : success;

    const getUploadIcon = () => {
        if (uploadState === upload)
            return <FontAwesomeIcon icon={faDownload} style={{fontSize: 11.5}} rotation={180} />
        if (uploadState === loading)
            return <FontAwesomeIcon icon={faSpinner} style={{fontSize: 11.5}} spin />
        if (uploadState === success)
            return <FontAwesomeIcon icon={faCheck} style={{fontSize: 11.5}} />
    }

    return (
        <div id='upload-btn' className={className} style={style} onClick={() => onUploadClick()}>
            {getUploadIcon()}
        </div>
    )
}

export default UploadBtn;
