/* Copyright Levelise Ltd 2024 */
import React from 'react';

const FleetIcon = () => {
  return (
    <svg width="35" height="20" xmlns='http://www.w3.org/2000/svg'>
        <polyline points='1,4 6,0.5 11,4' stroke='#64B665' fill='none' strokeWidth={0.6}/>
        <polyline points='1.5,4 1.5,8 10.5,8 10.5,4' stroke='#64B665' fill='none' strokeWidth={0.6}/>
        <line x1="6" y1="6" x2="6" y2="8" stroke="#64B665" strokeWidth={0.6}/>

        <polyline points='16,4 21,0.5 26,4' stroke='#64B665' fill='none' strokeWidth={0.6}/>
        <polyline points='16.5,4 16.5,8 25.5,8 25.5,4' stroke='#64B665' fill='none' strokeWidth={0.6}/>
        <line x1="21" y1="6" x2="21" y2="8" stroke="#64B665" strokeWidth={0.6} />

        <polyline points='8.5,15.5 13.5,12 18.5,15.5' stroke='#64B665' fill='none' strokeWidth={0.6}/>
        <polyline points='9,15.5 9,19.5 18,19.5 18,15.5' stroke='#64B665' fill='none' strokeWidth={0.6}/>
        <line x1="13.5" y1="17.5" x2="13.5" y2="19.5" stroke="#64B665" strokeWidth={0.6} />

        <polyline points='23.5,15.5 28.5,12 33.5,15.5' stroke='#64B665' fill='none' strokeWidth={0.6} />
        <polyline points='24,15.5 24,19.5 33,19.5 33,15.5' stroke='#64B665' fill='none' strokeWidth={0.6} />
        <line x1="28.5" y1="17.5" x2="28.5" y2="19.5" stroke="#64B665" strokeWidth={0.6} />

        
        <line x1="6" y1="8" x2="6" y2="10" stroke="black" strokeWidth={0.6}/>
        <line x1="21" y1="8" x2="21" y2="10" stroke="black" strokeWidth={0.6}/>
        <line x1="13.5" y1="10" x2="13.5" y2="12" stroke="black" strokeWidth={0.6}/>
        <line x1="28.5" y1="10" x2="28.5" y2="12" stroke="black" strokeWidth={0.6}/>

        <line x1="0" y1="10" x2="35" y2="10" stroke="black" strokeWidth={0.6}/>
    </svg>
  );
}

export default FleetIcon;