/* Copyright Levelise Ltd 202s */
import React, { useState, useEffect, useContext } from 'react';
import { saveAs } from 'file-saver';
import FleetService from '../../services/fleet-service';
import FacilityContext from '../../contexts/FacilityContext';
import SummaryChart from './chart';
import SummaryTable from './table';
import DownloadBtn from '../DownloadBtn/index';
import { formatTimestamp } from '../../utils/utils'
import {
    TIME_FRAMES,
    DOWNLOAD_TIMEFRAME,
    resolutions,
    timestampSec,
    timestampDay,
    download,
    loading,
    success
} from '../../utils/constants';
import './index.css';

const Summary = ({ facilityName }) => {
    const chart = 'Summary', energy = 'Energy';
    const facilityContext = useContext(FacilityContext);
    const [view, setView] = useState(energy);
    const [showDownload, setShowDownLoad] = useState(false);
    const [downloadState, setDownloadState] = useState(download);
    const [scale, setScale] = useState('');
    const [summaryData, setSummaryData] = useState({
        facility: facilityContext.facility,
        records: facilityContext.records,
        timeFrame: facilityContext.timeFrame,
        dates: facilityContext.dates,
    })

    const handleFetchCsv = async (resolution, since, before) => {
        let format, start, end;
        switch (resolution) {
            case resolutions.week:
                format = 'yyyy-MM-dd';
                start = formatTimestamp(since, facilityContext.facility.timezone, format);
                end = formatTimestamp(before - 7*86400, facilityContext.facility.timezone, format);
                break;
            case resolutions.day:
                format = 'yyyy-MM-dd';
                start = formatTimestamp(since, facilityContext.facility.timezone, format);
                end = formatTimestamp(before - 86400, facilityContext.facility.timezone, format);
                break;
            default:
                format = 'yyyy-MM-dd\'T\'HH:mm';
                start = formatTimestamp(since, facilityContext.facility.timezone, format);
                end = formatTimestamp(before - 1, facilityContext.facility.timezone, format);
        }

        try {
            const response = await FleetService.getFacilityRecordsAsCsv(facilityName, resolution, since, before)
            if (!response.ok)
                throw new Error(response);

            var resolutionStr = 'half_hourly';
            if (resolution === resolutions.day) resolutionStr = 'daily';
            if (resolution === resolutions.week) resolutionStr = 'weekly';

            const filename = `${facilityName}_${resolutionStr}_records_from_${start}_to_${end}.csv`
            const blob = await response.blob();
            const csvFile = new Blob(['\ufeff', blob], { type: 'text/csv;charset=windows-1252;' });

            saveAs(csvFile, filename);
        } catch (error) {
            setDownloadState(download);
        } finally {
            setDownloadState(success);
            setTimeout(() => setDownloadState(download), 2000);
        }
    }

    const handleClickDownload = () => {
        const { timeFrame, records } = facilityContext;
        if (downloadState === download) {
            setDownloadState(loading);

            if (DOWNLOAD_TIMEFRAME.includes(timeFrame) && records.length >= 2) {
                const record0 = records[0];
                const record1 = records[records.length - 1];

                if (record0.hasOwnProperty(timestampDay)) {
                    if (timeFrame === TIME_FRAMES.all) {
                        handleFetchCsv(resolutions.week, record0[timestampDay] * 86400, ((record1[timestampDay] + 7) * 86400));
                    } else {
                        handleFetchCsv(resolutions.day, record0[timestampDay] * 86400, ((record1[timestampDay] + 1) * 86400));
                    }
                }

                if (record0.hasOwnProperty(timestampSec)) {
                    handleFetchCsv(resolutions.half_hour, record0[timestampSec], record1[timestampSec] + 1)
                }
            }
        }
    }

    useEffect(() => {
        const { records, timeFrame, dates } = facilityContext;
        const timeScale = timeFrame !== TIME_FRAMES.select
            ? timeFrame : dates.start === dates.end
                ? dates.start : `${dates.start} - ${dates.end}`;
        if (timeScale !== scale) {
            setScale(timeScale);
        }

        if (!!records.length) {
            if (DOWNLOAD_TIMEFRAME.includes(timeFrame) && !!records.length) {
                if (TIME_FRAMES.select === timeFrame && dates.start === dates.end) {
                    setShowDownLoad(false);
                } else {
                    setShowDownLoad(true);
                }
            } else {
                setShowDownLoad(false);
            }
        }
    }, [facilityContext.timeFrame, facilityContext.dates])

    useEffect(() => {
        setSummaryData({
            facility: facilityContext.facility,
            records: facilityContext.records,
            timeFrame: facilityContext.timeFrame,
            dates: facilityContext.dates,
        })
    }, [facilityContext.facility, facilityContext.records, facilityContext.timeFrame, facilityContext.dates])

    return (
        <div className='summary'>
            <h2 className='summary-header'>
                <span id='summary-data' className={view === energy ? 'summary-view' : ''} onClick={() => setView(energy)}>
                    {energy}
                </span>
                <span id='summary-chart' className={view === chart ? 'summary-view' : ''} onClick={() => setView(chart)}>
                    {chart}
                </span>
                <span id='summary-scale' style={{ right: showDownload ? 23 : 10, fontSize: 11 }}>
                    {scale}
                </span>
                {showDownload && <DownloadBtn style={{marginTop: '4px'}} downloadState={downloadState} clickDownload={handleClickDownload} />}
            </h2>
            {view === energy &&
                <SummaryTable
                    facility={summaryData.facility}
                    records={summaryData.records}
                    timeFrame={summaryData.timeFrame}
                    dates={summaryData.dates}
                />
            }
            {view === chart &&
                <SummaryChart
                    facility={summaryData.facility}
                    records={summaryData.records}
                    timeFrame={summaryData.timeFrame}
                    dates={summaryData.dates}
                />
            }
        </div>
    )
}

export default Summary