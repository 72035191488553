/* Copyright Levelise Ltd 2024 */
import React, { Component } from 'react';

const FleetStatusContext = React.createContext({
	settlementMethod: null,
	region: null,
	meteringType: null,
	systemType: null,
	system: null,
	battery: null,
	batterySystem: null,

	allSystems: [],
	allSystemTypes: [],
	allBatterySystems: [],
	allBatteryNumbers: [],

	aggFacility: '',

	optimizationLevel: 'ALL',

	severityLevel: 'Highest severity',

	showOk: false,
	showUnsafe: false,
	showUnoptimisable: false,
	showDiminished: false,
	showFaulty: false,
	showGlitchy: false,
	showInactive: false,

	selectedStatus: '',
	selectedSubStatus: '',

	setSettlementMethod: () => {},
	setRegion: () => {},
	setMeteringType: () => {},
	setSystemType: () => {},
	setSystem: () => {},
	setBattery: () => {},
	setBatterySystem: () => {},

	setAllSystems: () => {},
	setAllSystemTypes: () => {},
	setAllBatterySystems: () => {},
	setAllBatteryNumbers: () => {},

	setAggFacility: () => {},

	setOptimizationLevel: () => {},

	setSeverityLevel: () => {},

	setShowOk: () => {},
	setShowUnsafe: () => {},
	setShowUnoptimisable: () => {},
	setShowDiminished: () => {},
	setShowFaulty: () => {},
	setShowGlitchy: () => {},
	setShowInactive: () => {},

	setSelectedStatus: () => {},
	setSelectedSubStatus: () => {},

	clearState: () => {},
});

export default FleetStatusContext;

export class FleetStatusProvider extends Component {
	state = {
		settlementMethod: null,
		region: null,
		meteringType: null,
		systemType: null,
		system: null,
		battery: null,
		batterySystem: null,

		allSystems: [],
		allSystemTypes: [],
		allBatterySystems: [],
		allBatteryNumbers: [],

		aggFacility: '',

		optimizationLevel: 'ALL',

		severityLevel: 'Highest severity',

		showOk: false,
		showUnsafe: false,
		showUnoptimisable: false,
		showDiminished: false,
		showFaulty: false,
		showGlitchy: false,
		showInactive: false,

		selectedStatus: '',
		selectedSubStatus: '',
	};

	setSettlementMethod = (val) => this.setState({ settlementMethod: val });

	setRegion = (val) => this.setState({ region: val });

	setMeteringType = (val) => this.setState({ meteringType: val });

	setSystemType = (val) => this.setState({ systemType: val });

	setSystem = (val) => this.setState({ system: val });

	setBattery = (val) => this.setState({ battery: val });

	setBatterySystem = (val) => this.setState({ batterySystem: val });

	setAllSystems = (val) => this.setState({ allSystems: val });
	setAllSystemTypes = (val) => this.setState({ allSystemTypes: val });
	setAllBatterySystems = (val) => this.setState({ allBatterySystems: val });
	setAllBatteryNumbers = (val) => this.setState({ allBatteryNumbers: val });

	setAggFacility = (val) => this.setState({ aggFacility: val });

	setOptimizationLevel = (val) => this.setState({ optimizationLevel: val });

	setSeverityLevel = (val) => this.setState({ severityLevel: val });

	setShowOk = (val) => this.setState({ showOk: val });
	setShowUnsafe = (val) => this.setState({ showUnsafe: val });
	setShowUnoptimisable = (val) => this.setState({ showUnoptimisable: val });
	setShowDiminished = (val) => this.setState({ showDiminished: val });
	setShowFaulty = (val) => this.setState({ showFaulty: val });
	setShowGlitchy = (val) => this.setState({ showGlitchy: val });
	setShowInactive = (val) => this.setState({ showInactive: val });

	setSelectedStatus = (val) => this.setState({ selectedStatus: val });
	setSelectedSubStatus = (val) => this.setState({ selectedSubStatus: val });

	clearState = () => {
		this.setState({
			settlementMethod: null,
			region: null,
			meteringType: null,
			systemType: null,
			system: null,
			battery: null,
			batterySystem: null,

			allSystems: [],
			allSystemTypes: [],
			allBatterySystems: [],
			allBatteryNumbers: [],

			aggFacility: '',

			optimizationLevel: 'ALL',

			severityLevel: 'Highest severity',

			showOk: false,
			showUnsafe: false,
			showUnoptimisable: false,
			showDiminished: false,
			showFaulty: false,
			showGlitchy: false,
			showInactive: false,

			selectedStatus: '',
			selectedSubStatus: '',
		});
	};

	render() {
		const contextValue = {
			settlementMethod: this.state.settlementMethod,
			region: this.state.region,
			meteringType: this.state.meteringType,
			systemType: this.state.systemType,
			system: this.state.system,
			battery: this.state.battery,
			batterySystem: this.state.batterySystem,

			allSystems: this.state.allSystems,
			allSystemTypes: this.state.allSystemTypes,
			allBatterySystems: this.state.allBatterySystems,
			allBatteryNumbers: this.state.allBatteryNumbers,

			aggFacility: this.state.aggFacility,

			optimizationLevel: this.state.optimizationLevel,

			severityLevel: this.state.severityLevel,

			showOk: this.state.showOk,
			showUnsafe: this.state.showUnsafe,
			showUnoptimisable: this.state.showUnoptimisable,
			showDiminished: this.state.showDiminished,
			showFaulty: this.state.showFaulty,
			showGlitchy: this.state.showGlitchy,
			showInactive: this.state.showInactive,

			selectedStatus: this.state.selectedStatus,
			selectedSubStatus: this.state.selectedSubStatus,

			setSettlementMethod: this.setSettlementMethod,
			setRegion: this.setRegion,
			setMeteringType: this.setMeteringType,
			setSystemType: this.setSystemType,
			setSystem: this.setSystem,
			setBattery: this.setBattery,
			setBatterySystem: this.setBatterySystem,

			setAllSystems: this.setAllSystems,
			setAllSystemTypes: this.setAllSystemTypes,
			setAllBatterySystems: this.setAllBatterySystems,
			setAllBatteryNumbers: this.setAllBatteryNumbers,

			setAggFacility: this.setAggFacility,

			setOptimizationLevel: this.setOptimizationLevel,

			setSeverityLevel: this.setSeverityLevel,

			setShowOk: this.setShowOk,
			setShowUnsafe: this.setShowUnsafe,
			setShowUnoptimisable: this.setShowUnoptimisable,
			setShowDiminished: this.setShowDiminished,
			setShowFaulty: this.setShowFaulty,
			setShowGlitchy: this.setShowGlitchy,
			setShowInactive: this.setShowInactive,

			setSelectedStatus: this.setSelectedStatus,
			setSelectedSubStatus: this.setSelectedSubStatus,

			clearState: this.clearState,
		};
		return <FleetStatusContext.Provider value={contextValue}>{this.props.children}</FleetStatusContext.Provider>;
	}
}
