/* Copyright Levelise Ltd 2024 */
import React from 'react';
import { Button, SpecInput } from '../Form';
import { colors } from '../../utils/chart';
import DeleteBtn from '../DeleteBtn';

function AddCustomerCreditsPopup({
	newCredits,
	errorMessage,
	loading,
	handleInputChange,
	handleDeleteRow,
	handleAddNewRow,
	handleCancelPopup,
	handleAddNewCredits,
}) {
	return (
		<div className="popup">
			<div className={`add-credits-popup-inner`}>
				<span style={{ fontSize: 14, textAlign: 'left', margin: 5 }}>Use the below table to add credits</span>
				<div style={{ height: '80%' }}>
					<div
						className="scrollableTable"
						style={{
							borderTopWidth: 0.1,
							borderTopColor: 'rgba(187, 187, 187, 0.33)',
							borderTopStyle: 'solid',
							paddingLeft: 2.5,
							paddingRight: 2.5,
						}}
					>
						<table border="0" cellPadding="2" cellSpacing="0" width="100%" data-cy="credits_table">
							<thead>
								<tr>
									<th className="table-col-1 col-name">No.</th>
									<th className="table-col-4 col-name" style={{ minWidth: '65px' }}>
										Credit date
									</th>
									<th className="table-col-2 col-name">Credit amount</th>
									{/* <th className="table-col-2 col-name">Charge amount</th> */}
									<th className="table-col-2 col-name">Reason code</th>
									<th className={`${newCredits.length > 1 ? 'table-col-3' : 'table-col-5'} col-name`}>Comment</th>
									{newCredits.length > 1 ? (
										<th className="table-col-1 col-name" style={{ zIndex: 1 }}></th>
									) : null}
								</tr>
							</thead>
							<tbody>
								{newCredits && newCredits?.length ? (
									<>
										{newCredits.map((credit, i) => {
											return (
												<tr key={i} width="100%">
													<td className="table-col-1 responsiveText">{i + 1}</td>
													<td className="table-col-4 responsiveText">
														<SpecInput
															className="date-input input-col credit-table-inputs"
															name="timestampDay"
															type="date"
															placeholder="DD/MM/YYYY"
															value={credit.timestampDay}
															onChange={(e) => handleInputChange(i, e)}
														/>
													</td>
													<td className="table-col-2 responsiveText">
														<SpecInput
															name="creditAmount"
															type="text"
															className="credit-table-inputs"
															value={credit.creditAmount}
															onChange={(e) => handleInputChange(i, e)}
                                                            style={{
                                                                minWidth: 60
                                                            }}
														/>
													</td>
													{/* <td className="table-col-2 responsiveText">
														<SpecInput
															name="chargeAmount"
															type="text"
															value={credit.chargeAmount}
															onChange={(e) => handleInputChange(i, e)}
															style={{ width: '90%' }}
														/>
													</td> */}
													<td className="table-col-2 responsiveText">
														<select
                                                            className='dropdown-input-credits credit-table-inputs'
															name="reasonCode"
															value={credit.reasonCode}
															onChange={(e) => handleInputChange(i, e)}
															style={{
																minWidth: 105,
																fontSize: '0.75rem',
																margin: 0,
															}}
														>
															<option value="" style={{ fontSize: '0.75rem' }}>
																Select
															</option>
                                                            <option value="Goodwill" style={{ fontSize: '0.75rem' }}>
																Goodwill
															</option>
                                                            <option value="FFR" style={{ fontSize: '0.75rem' }}>
																FFR
															</option>
															<option value="Trading" style={{ fontSize: '0.75rem' }}>
																Trading
															</option>
															<option
																value="Trading + FFR"
																style={{ fontSize: '0.75rem' }}
															>
																Trading + FFR
															</option>
															<option
																value="Export Credit"
																style={{ fontSize: '0.75rem' }}
															>
																Export Credit
															</option>
														</select>
													</td>
													<td className={`${newCredits.length > 1 ? 'table-col-3' : 'table-col-5'} responsiveText`}>
														<SpecInput
															name="comment"
															type="text"
															value={credit.comment}
															onChange={(e) => handleInputChange(i, e)}
                                                            className="credit-table-inputs"
                                                            style={{
                                                                minWidth: 120
                                                            }}
														/>
													</td>
													{newCredits.length > 1 ? (
														<td className="table-col-1 responsiveText">
															<DeleteBtn
																onDeleteClick={() => handleDeleteRow(i)}
																style={{ marginRight: 15 }}
															/>
														</td>
													) : null}
												</tr>
											);
										})}
									</>
								) : null}
							</tbody>
						</table>
					</div>
				</div>
				<div>
					<div>
						<Button className={'configure-btn submit'} onClick={handleAddNewRow} disabled={loading}>
							+ Add row
						</Button>
					</div>
                    <div style={{paddingTop: 10, paddingRight: 20,
							marginBottom: 10,}}>
                    <div style={{ width: '100%' }}>
						{errorMessage ? (
							<p style={{ color: colors.errorRed, textAlign: 'right', fontSize: '0.72rem' }}>
								{errorMessage}
							</p>
						) : null}
					</div>
					<div
						className="customer-search-button-container full-width"
					>
						<Button className="configure-btn reset" onClick={handleCancelPopup} disabled={loading}>
							Cancel
						</Button>
						<Button className={'configure-btn submit'} onClick={handleAddNewCredits} disabled={loading}>
							Submit
						</Button>
					</div>
                    </div>
				</div>
			</div>
		</div>
	);
}

export default AddCustomerCreditsPopup;
