/* Copyright Levelise Ltd 2022 - 2024 */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import FleetService from '../../services/fleet-service';
import TokenService from '../../services/token-service';
import OAuthService from '../../services/auth-service';
import IdleService from '../../services/idle-service';
import FleetContext from '../../contexts/FleetContext';
import UserService from '../../services/user-service';
import { generateState, generateCodeChallenge, generateCodeVerifier, hasPermission } from '../../utils/utils';
import { show, hide, PERMISSIONS } from '../../utils/constants';
import config from '../../config';
import './index.css';
import UserContext from '../../contexts/UserContext';
import FacilityConext from '../../contexts/FacilityContext';
import FleetStatusContext from '../../contexts/FleetStatusContext';

const routes = {
    1: '/fleet',
    2: '/fleet-customers',
    3: '/orders',
    4: '/user'
}

const AuthRoute = () => {
	const context = useContext(FleetContext);

	const facilityContext = useContext(FacilityConext);
	const userContext = useContext(UserContext);
	const fleetStatusContext = useContext(FleetStatusContext);

	const navigate = useNavigate();
	const location = useLocation();
	const [error, setError] = useState(null);

	const handleFetchFacility = () => {
		const druList = context.druList;
		if (!!druList.length) {
			return FleetService.getFacilityByDruId(druList[0])
				.then((res) => {
					return res.name;
				})
				.catch(setError);
		}
	};

	const handleFetchPreferences = (username) => {
		FleetService.getUserPreferences(username)
			.then((res) => {
				const preferences = {
					[config.status]: res[config.status] ? show : hide,
					[config.performance]: res[config.performance] ? show : hide,
					[config.battery]: res[config.battery] ? show : hide,
					[config.energyData]: res[config.energyData] ? show : hide,
					[config.logsPanel]: res[config.logsPanel] ? show : hide,
					[config.facilityInfo]: res[config.facilityInfo] ? show : hide,
					[config.facilityData]: res[config.facilityData],
					[config.aggFacilityData]: res[config.aggFacilityData],
					[config.fleetPageTimezone]: res[config.fleetPageTimezone],
					[config.facilityPageTimezone]: res[config.facilityPageTimezone],
					[config.initialPage]: res[config.initialPage],
					[config.showCreditsManagement]: res[config.showCreditsManagement] ? show : hide,
					[config.showForecasts]: res[config.showForecasts] ? show : hide,
				};

				UserService.setPreferences(preferences);
			})
			.catch(setError);
	};

	const handleFetchDrus = () => {
		return FleetService.getDruList()
			.then((res) => {
				context.setDruList(res);
				return res.length <= 1;
			})
			.catch(setError);
	};

	const handleFetchUserDetail = (username) => {
		return FleetService.getUserDetails(username)
			.then((res) => {
				if (res && Object.hasOwn(res, 'email')) {
					delete res.email;
				}
				if (res && Object.hasOwn(res, 'role')) {
					delete res.role;
				}
				
				UserService.saveUserDetail(res);
			})
			.catch(setError);
	};

	const onLoginSuccess = () => {
		if (error === null) {
			IdleService.setIdleCallback(() => {
				userContext.clearUser();
				context.clearState();
				facilityContext.clearState();
				fleetStatusContext.clearState();
				IdleService.unRegisterIdleResets();
				UserService.removePreferences();
				UserService.removeUser();
				UserService.removeUserDetail();
				TokenService.clearAuthToken();
				TokenService.clearCallbackBeforeExpiry();
				navigate('/auth');
			});

			IdleService.resetIdleTimer();

			IdleService.registerIdleTimerResets();
		}

		if (UserService.hasUser() && UserService.getUser().isRoleUser) {
			Promise.resolve(handleFetchFacility()).then((res) =>
				navigate((location.state || {}).from || `/facility/${res}`)
			);
		} else if (UserService.hasUser()) {
			let pageNumber = 1; // 1 = fleet page, 2 = fleet customers, 3 = orders page, 4 = user page
			if (UserService.hasPreferences()) {
				const preference = UserService.getPreferences();
				const page = preference[config.initialPage];

				if (
					hasPermission(PERMISSIONS.CAN_ACCESS_ORDERS) ||
					(hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER) && page !== 3)
				) {
					pageNumber = page;
				} else if (hasPermission(PERMISSIONS.CAN_ACCESS_DR_BATTERY)) {
					if (page === 1 || page === 4) {
						pageNumber = page;
					}
				}
			}

			const initialPage = routes[pageNumber] || '/fleet';

			navigate((location.state || {}).from || initialPage);
		}
	};

	const handleCheckToken = () => {
		OAuthService.postCheckToken()
			.then((res) => {
				const username = res['user_name'];
				const roles = res.authorities.map((auth) => (auth.includes('ROLE') ? auth.slice(5) : auth));
				Promise.all([handleFetchDrus(), handleFetchPreferences(username), handleFetchUserDetail(username)])
					.then((res) => UserService.saveUser({ username: username, role: roles, isRoleUser: res[0] }))
					.then(() => UserService.hasUser() && onLoginSuccess())
					.catch(setError);
			})
			.catch(setError);
	};

	const handleRedirectToAuthorization = () => {
		setError(null);
		TokenService.clearAuthToken();
		UserService.removeUser();
		UserService.removeUserDetail();
		TokenService.clearCallbackBeforeExpiry();
		IdleService.unRegisterIdleResets();

		const state = generateState();
		const codeVerifier = generateCodeVerifier();
		generateCodeChallenge(codeVerifier).then((challenge) => {
			TokenService.saveState(state);
			TokenService.saveCodeVerifier(codeVerifier);
			OAuthService.getAuth(window.location.origin + '/auth', challenge, state, 'write');
		});
	};

	useEffect(() => {
		if (error !== null) {
			handleRedirectToAuthorization();
		}
	}, [error]);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.has('code')) {
			const code = urlParams.get('code');
			const state = urlParams.get('state');
			const expectedState = TokenService.getState();
			const codeVerifier = TokenService.getCodeVerifier();
			if (state !== expectedState) {
				handleRedirectToAuthorization();
			} else {
				OAuthService.postToken(window.location.origin + '/auth', code, codeVerifier)
					.then(() => handleCheckToken())
					.then(() => TokenService.queueCallbackBeforeExpiry(() => OAuthService.postRefreshToken()))
					.catch(setError);
			}

			return;
		}
		// else...
		handleRedirectToAuthorization();
	}, []);

	return (
		<section className="auth-route">
			<span className="auth-loader"></span>
		</section>
	);
};

export default AuthRoute;
