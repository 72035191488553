/* Copyright Levelise Ltd 2021-2024 */
import React, { useContext } from 'react';
import { addDays } from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import FleetContext from '../../contexts/FleetContext';
import { getShortFormForContracts } from '../../utils/utils';

const Table = ({ table, contracts, isFs }) => {
	const context = useContext(FleetContext);
	const cellw = 21; // cell width in px
	const cellDiv = 60 * 4; // minutes * hours

	const contractBox = (contract, period) => {
		const type = getContractText(contract[period]['type']);
		const size = contract[period]['hasContract'];
		const start = contract[period]['start'];
		const isSet = contract[period]['isSet'];
		const width = (cellw / cellDiv) * size;
		const style = cellStyle(period, width, start, isSet);
		return (
			<>
				<span
					className="contract-type"
					style={{ color: isSet ? 'black' : period === '5' || period === '6' ? '#82cac6' : 'black' }}
				>
					{type}
				</span>
				<span className="contract-box" style={style}></span>
			</>
		);
	};

	const cellStyle = (period, width, start, isSet) => {
		const boxw = Math.abs(width);
		const boxwPercent = (boxw * 100) / cellw;
		const ml = width < 0 ? cellw - boxw : 0;
		const mr = width < 0 ? 0 : cellw - boxw;
		const br = start ? '2px solid rgb(38, 0, 255)' : '';

		return {
			1: {
				marginLeft: `${ml}px`,
				marginRight: `${mr}px`,
				width: `${boxwPercent}%`,
				background: isSet ? `rgba(0,255,0,0.07)` : `rgb(100,182,100)`,
				border: isSet ? `rgba(0,255,0,0.07)` : `rgb(100,182,100)`,
				borderLeft: br,
			},
			2: {
				marginLeft: `${ml}px`,
				marginRight: `${mr}px`,
				width: `${boxwPercent}%`,
				background: isSet ? `rgba(0,255,0,0.07)` : `rgb(100,182,100)`,
				border: isSet ? `rgba(0,255,0,0.07)` : `rgb(100,182,100)`,
				borderLeft: br,
			},
			3: {
				marginLeft: `${ml}px`,
				marginRight: `${mr}px`,
				width: `${boxwPercent}%`,
				background: isSet ? 'rgba(255,255,140,0.07)' : `rgba(255, 215,50)`,
				border: isSet ? 'rgba(255,255,140,0.07)' : `rgb(255, 215,50)`,
				borderLeft: br,
			},
			4: {
				marginLeft: `${ml}px`,
				marginRight: `${mr}px`,
				width: `${boxwPercent}%`,
				background: isSet ? 'rgba(255,255,140,0.07)' : `rgb(255, 215,50)`,
				border: isSet ? 'rgba(255,255,140,0.07)' : `rgb(255, 215,50)`,
				borderLeft: br,
			},
			5: {
				marginLeft: `${ml}px`,
				marginRight: `${mr}px`,
				width: `${boxwPercent}%`,
				background: isSet ? `rgba(255,0,0,0.07)` : 'rgb(168,25,22)',
				border: isSet ? `rgba(255,0,0,0.07)` : 'rgb(168,25,22)',
				borderLeft: br,
				color: '#fff',
			},
			6: {
				marginLeft: `${ml}px`,
				marginRight: `${mr}px`,
				width: `${boxwPercent}%`,
				background: isSet ? 'rgba(255,140,0,0.07)' : `rgb(232,96,53)`,
				border: isSet ? 'rgba(255,140,0,0.07)' : `rgb(232,96,53)`,
				borderLeft: br,
			},
		}[period];
	};

	const getDays = () => {
		const days = [];
		const today = utcToZonedTime(new Date(), context.timezone);
		for (let index = 0; index < contracts.length; index++) {
			const date = addDays(today, index);
			const day = format(date, 'EEE', { timeZone: context.timezone });
			days.push(day);
		}

		return days;
	};

	const getContractText = (ct) => {
		return ct ? getShortFormForContracts(ct) : '';
	};

	const headers = () => {
		return (
			<tr className="contract-header">
				<th>EFA</th>
				<th className="contract-cell">1</th>
				<th className="contract-cell">2</th>
				<th className="contract-cell">3</th>
				<th className="contract-cell">4</th>
				<th className="contract-cell">5</th>
				<th className="contract-cell">6</th>
			</tr>
		);
	};

	const dataCells = () => {
		const days = getDays();
		return days.map((day, index) => {
			if (!!day) {
				return (
					<tr key={day + index}>
						<th>{day}</th>
						<td className="contract-cell">{contractBox(contracts[index], '1')}</td>
						<td className="contract-cell">{contractBox(contracts[index], '2')}</td>
						<td className="contract-cell">{contractBox(contracts[index], '3')}</td>
						<td className="contract-cell">{contractBox(contracts[index], '4')}</td>
						<td className="contract-cell">{contractBox(contracts[index], '5')}</td>
						<td className="contract-cell">{contractBox(contracts[index], '6')}</td>
					</tr>
				);
			}
		});
	};

	return (
		<table className={`${table} ${isFs ? 'fs-contract' : ''}`}>
			<thead>{headers()}</thead>
			<tbody>{dataCells()}</tbody>
		</table>
	);
};

export default Table;
