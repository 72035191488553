/* Copyright Levelise Ltd 2024 */
import React from 'react';
import { hasPermission } from '../../utils/utils';
import { PERMISSIONS } from '../../utils/constants';
import AddBtn from '../AddBtn';
import { useNavigate } from 'react-router-dom';

function DrusListHeader({ title, searchTerm, onSearchTermChange, classname, searchInputId }) {
	const navigate = useNavigate();

	const onAddDruClicked = () => {
		navigate('/configure-facility');
	};

	return (
		<div className={`fleet-table-title ${classname || ''}`}>
			<h2>{title}</h2>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					flexGrow: 1,
					alignItems: 'center',
				}}
			>
				<input
					id={searchInputId}
					name="term"
					type="text"
					value={searchTerm}
					onChange={(e) => onSearchTermChange(e)}
					autoComplete="off"
					placeholder="Search..."
				/>
				{hasPermission(PERMISSIONS.CAN_CREATE_FACILITY) ? <AddBtn testTag="configure-facility-add-button" onAddClick={onAddDruClicked} style={{ marginLeft: 6}} /> : null}
			</div>
		</div>
	);
}

export default DrusListHeader;
