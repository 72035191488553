/* Copyright Levelise Ltd 2020-2023 */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import FacilityContext from '../../../contexts/FacilityContext';
import {
    CONTRACT_ANNOTATION,
    disconnected,
    valueState,
    loadState,
    pvState,
    pvMeterState,
    hotWaterMeterState,
    hotWaterTankState,
    batteryState,
    inverterState,
    frequencyMeterState,
    powerMeterState,
    batteryForceChargingState,
    lowBatteryTemperature,
    highBatteryTemperature,
    minBatteryTemperature,
    maxBatteryTemperature,
    getTimeFormat,
    dataset,
    options,
    xAxis,
    facilityChartTitles,
    tooltipTitleCallbacks
} from '../../../utils/chart';
import {
    BATTERY,
    TIME_FRAMES,
    timestampDay,
    timestampSec,
    timestampMsec,
    resolutions
} from '../../../utils/constants';


const x = { type: 'time' }
const x1 = null
const y = {
    stacked: false,
    ticks: {},
    label: 'DRU State',
    gridLines: { drawOnChartArea: true }
}
const y1 = null
const y2 = null
const legendLabels = {
    font: { size: 10 },
    color: 'black',
    usePointStyle: true,
    boxWidth: 4,
    boxHeight: 4,
    padding: 8,
}

const batteryLabels = {
    '0': 'Disconnected', '1': 'Connected',
    '3': 'Faulty', '4': 'Value - OK',
    '6': 'Faulty', '7': 'Unknown', '8': 'Load - OK',
    '10': 'Reversed', '11': 'Faulty Meter', '12': 'Unknown', '13': 'PV - OK',
    '15': 'Inverter Comms Faulty', '16': 'Faulty', '17': 'Force Charging', '18': 'Too Cold', '19': 'Too Hot', '20': 'Battery - OK',
    '22': 'Inverter Comms Faulty', '23': 'Faulty', '24': 'Inverter - OK',
    '26': 'Faulty', '27': 'Frequency Meter - OK',
    '29': 'Power Comms Faulty', '30': 'Faulty', '31': 'Power Meter - OK',
};

const hwLabels = {
    '0': 'Disconnected', '1': 'Connected',
    '3': 'Faulty', '4': 'Value - OK',
    '6': 'Faulty', '7': 'Unknown', '8': 'Load - OK',
    '10': 'Reversed', '11': 'Faulty Meter', '12': 'Unknown', '13': 'PV - OK',
    '15': 'Faulty Meter', '16': 'Faulty Tank', '17': 'Hot Water - OK',
    '19': 'Faulty', '20': 'Frequency Meter - OK',
    '22': 'Power Comms Faulty', '23': 'Faulty', '24': 'Power Meter - OK'
};

const hwBatteryLabels = {
    '0': 'Disconnected', '1': 'Connected',
    '3': 'Faulty', '4': 'Value - OK',
    '6': 'Faulty', '7': 'Unknown', '8': 'Load - OK',
    '10': 'Reversed', '11': 'Faulty Meter', '12': 'Unknown', '13': 'PV - OK',
    '15': 'Faulty Meter', '16': 'Faulty Tank', '17': 'Hot Water - OK',
    '19': 'Inverter Comms Faulty', '20': 'Faulty', '21': 'Force Charging', '22': 'Too Cold', '23': 'Too Hot', '24': 'Battery - OK',
    '26': 'Inverter Comms Faulty', '27': 'Faulty', '28': 'Inverter - OK',
    '30': 'Faulty', '31': 'Frequency Meter - OK',
    '33': 'Power Comms Faulty', '34': 'Faulty', '35': 'Power Meter - OK',
};

const DruStateChart = ({ height, fmData, handleFmData, data, timezone, selectedTimezone }) => {
    const druStateRef = useRef();
    const context = useContext(FacilityContext);
    const [chart, setChart] = useState({
        data: {
            labels: [],
            datasets: [
                dataset('line', disconnected.type, disconnected.backgroundColor, disconnected.borderColor, 'y'),
                dataset('line', valueState.type, valueState.backgroundColor, valueState.borderColor, 'y'),
                dataset('line', loadState.type, loadState.backgroundColor, loadState.borderColor, 'y'),
                dataset('line', pvState.type, pvState.backgroundColor, pvState.borderColor, 'y'),
                dataset('line', pvMeterState.type, pvMeterState.backgroundColor, pvMeterState.borderColor, 'y'),
                dataset('line', hotWaterMeterState.type, hotWaterMeterState.backgroundColor, hotWaterMeterState.borderColor, 'y'),
                dataset('line', hotWaterTankState.type, hotWaterTankState.backgroundColor, hotWaterTankState.borderColor, 'y'),
                dataset('line', highBatteryTemperature.type, highBatteryTemperature.backgroundColor, highBatteryTemperature.borderColor, 'y'),
                dataset('line', lowBatteryTemperature.type, lowBatteryTemperature.backgroundColor, lowBatteryTemperature.borderColor, 'y'),
                dataset('line', batteryForceChargingState.type, batteryForceChargingState.backgroundColor, batteryForceChargingState.borderColor, 'y'),
                dataset('line', batteryState.type, batteryState.backgroundColor, batteryState.borderColor, 'y'),
                dataset('line', inverterState.type, inverterState.backgroundColor, inverterState.borderColor, 'y'),
                dataset('line', frequencyMeterState.type, frequencyMeterState.backgroundColor, frequencyMeterState.borderColor, 'y'),
                dataset('line', powerMeterState.type, powerMeterState.backgroundColor, powerMeterState.borderColor, 'y'),
            ]
        },
        options: options('', legendLabels, x, x1, y, y1, y2, true, false)
    });

    const handleDataOnChange = (data) => {
        let resolution = data.resolution;
        if (data.timeFrame !== TIME_FRAMES.select) {
            switch (data.timeFrame) {
                case TIME_FRAMES.fifteen_minutes:
                    resolution = resolutions.second;
                    break;
                case TIME_FRAMES.one_hour:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.six_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.twenty_four_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.one_week:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.twenty_one_days:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.three_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.twelve_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.thirty_six_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.all:
                    resolution = resolutions.week;
                    break;
                default:
                    break;
            }
        }

        switch (resolution) {
            case resolutions.week:
            case resolutions.day:
                populateChartByDay(data.dailyReports, resolution);
                break
            case resolutions.half_hour:
                populateChartByHalfHour(data.halfHourlyReports, resolution);
                break
            case resolutions.minute:
                populateChartByMinute(data.minutelyReports, resolution, data.updated);
                break
            case resolutions.second:
                populateChartBySecond(data.bySecondReports, data.minutelyReports, resolution, data.updated);
                break;
            default:
                break;
        }
    }

    const populateChartByDay = (reports, resolution) => {
        if (!reports.length) return;

        const hasHotWater = !!context.facility.hasOwnProperty('hotWaterTank');
        const hasBattery = !!context.facility.hasOwnProperty('batterySystem');
        const labels = [];
        const datasets = chart.data.datasets.map(d => { d.data = []; return d });
        const start = reports[0][timestampDay];
        const end = reports[reports.length - 1][timestampDay];
        const increment = resolution === resolutions.week ? 7 : 1
        let i = 0;
        for (let timestamp = start; timestamp <= end; timestamp += increment) {
            if (timestamp === reports[i][timestampDay]) {
                if (reports[i].hasOwnProperty('druState')) {
                    const type = !!context.facility.batterySystem
                        ? context.facility.batterySystem.id
                        : null;

                    const loadAlive = getLoadState(reports[i].loadKwh ? reports[i].loadKwh * 1000 : reports[i].loadKwh);
                    const pvAlive = getPvState(reports[i].pvKwh ? reports[i].pvKwh * 1000 : reports[i].pvKwh, type);
                    const states = getStates(reports[i]);
                    datasets[1].data.push(states.valuesOkay);
                    datasets[2].data.push(loadAlive);
                    datasets[3].data.push(pvAlive);
                    datasets[4].data.push(states.pvMeterAlive);
                    if (hasHotWater) {
                        datasets[5].data.push(states.hotWaterMeterAlive);
                        datasets[6].data.push(states.hotWaterTankAlive);
                    }
                    if (hasBattery) {
                        datasets[7].data.push(states.highTemp);
                        datasets[8].data.push(states.lowTemp);
                        datasets[9].data.push(states.forceCharging);
                        datasets[10].data.push(states.batteryAlive);
                        datasets[11].data.push(states.inverterAlive);
                    }
                    datasets[12].data.push(states.frequencyMeterAlive);
                    datasets[13].data.push(states.powerMeterAlive);
                } else {
                    datasets[1].data.push(null);
                    datasets[2].data.push(null);
                    datasets[3].data.push(null);
                    datasets[4].data.push(null);
                    datasets[5].data.push(null);
                    datasets[6].data.push(null);
                    datasets[7].data.push(null);
                    datasets[8].data.push(null);
                    datasets[9].data.push(null);
                    datasets[10].data.push(null);
                    datasets[11].data.push(null);
                    datasets[12].data.push(null);
                    datasets[13].data.push(null);
                };

                datasets[0].data.push(getDisconnected(false));
                i++;
            } else {
                datasets[0].data.push(getDisconnected(true));
                datasets[1].data.push(null);
                datasets[2].data.push(null);
                datasets[3].data.push(null);
                datasets[4].data.push(null);
                datasets[5].data.push(null);
                datasets[6].data.push(null);
                datasets[7].data.push(null);
                datasets[8].data.push(null);
                datasets[9].data.push(null);
                datasets[10].data.push(null);
                datasets[11].data.push(null);
                datasets[12].data.push(null);
                datasets[13].data.push(null);
            }

            labels.push(timestamp * 86400 * 1000);
        }

        datasets[5].hidden = !hasHotWater;
        datasets[6].hidden = !hasHotWater;
        datasets[7].hidden = !hasBattery;
        datasets[8].hidden = !hasBattery;
        datasets[9].hidden = !hasBattery;
        datasets[10].hidden = !hasBattery;
        datasets[13].hidden = !hasBattery && !hasHotWater;
        const timeFormat = getTimeFormat(resolution);
        handleSetChart(labels, datasets, timeFormat, []);
    }

    const populateChartByHalfHour = (reports, resolution) => {
        if (!reports.length) return;

        const hasHotWater = !!context.facility.hasOwnProperty('hotWaterTank');
        const hasBattery = !!context.facility.hasOwnProperty('batterySystem');
        const labels = [];
        const datasets = chart.data.datasets.map(d => { d.data = []; return d });
        const start = reports[0][timestampSec];
        const end = reports[reports.length - 1][timestampSec];
        const type = !!context.facility.batterySystem ? context.facility.batterySystem.id : null;
        let i = 0;
        for (let timestamp = start; timestamp <= end; timestamp += 1800) {
            if (timestamp === reports[i][timestampSec]) {
                datasets[2].data.push(getLoadState(reports[i].loadKwh ? reports[i].loadKwh * 1000 : reports[i].loadKwh));
                datasets[3].data.push(getPvState(reports[i].pvKwh ? reports[i].pvKwh * 1000 : reports[i].pvKwh, type));
                if (reports[i].hasOwnProperty('druState')) {
                    const states = getStates(reports[i]);
                    datasets[1].data.push(states.valuesOkay);
                    datasets[4].data.push(states.pvMeterAlive);
                    if (hasHotWater) {
                        datasets[5].data.push(states.hotWaterMeterAlive);
                        datasets[6].data.push(states.hotWaterTankAlive);
                    }
                    if (hasBattery) {
                        datasets[7].data.push(states.highTemp);
                        datasets[8].data.push(states.lowTemp);
                        datasets[9].data.push(states.forceCharging);
                        datasets[10].data.push(states.batteryAlive);
                        datasets[11].data.push(states.inverterAlive);
                    }
                    datasets[12].data.push(states.frequencyMeterAlive);
                    datasets[13].data.push(states.powerMeterAlive);
                } else {
                    datasets[1].data.push(null);
                    datasets[4].data.push(null);
                    datasets[5].data.push(null);
                    datasets[6].data.push(null);
                    datasets[7].data.push(null);
                    datasets[8].data.push(null);
                    datasets[9].data.push(null);
                    datasets[10].data.push(null);
                    datasets[11].data.push(null);
                    datasets[12].data.push(null);
                    datasets[13].data.push(null);
                };

                datasets[0].data.push(getDisconnected(false));
                i++;
            } else {
                datasets[0].data.push(getDisconnected(true));
                datasets[1].data.push(null);
                datasets[2].data.push(null);
                datasets[3].data.push(null);
                datasets[4].data.push(null);
                datasets[5].data.push(null);
                datasets[6].data.push(null);
                datasets[7].data.push(null);
                datasets[8].data.push(null);
                datasets[9].data.push(null);
                datasets[10].data.push(null);
                datasets[11].data.push(null);
                datasets[12].data.push(null);
                datasets[13].data.push(null);
            }

            labels.push(timestamp * 1000);
        }

        datasets[5].hidden = !hasHotWater;
        datasets[6].hidden = !hasHotWater;
        datasets[7].hidden = !hasBattery;
        datasets[8].hidden = !hasBattery;
        datasets[9].hidden = !hasBattery;
        datasets[10].hidden = !hasBattery;
        datasets[13].hidden = !hasBattery && !hasHotWater;

        const annotation = reports.length <= 50 ? CONTRACT_ANNOTATION(end, timezone) : [];
        const timeFormat = getTimeFormat(resolution);
        handleSetChart(labels, datasets, timeFormat, annotation);
    }

    const populateChartByMinute = (reports, resolution, update = false) => {
        if (!reports.length) return;

        const hasHotWater = !!context.facility.hasOwnProperty('hotWaterTank');
        const hasBattery = !!context.facility.hasOwnProperty('batterySystem');
        const labels = update ? [...chart.data.labels] : [];
        const datasets = update ? [...chart.data.datasets] : chart.data.datasets.map(d => { d.data = []; return d });
        const start = reports[0][timestampSec];
        const end = reports[reports.length - 1][timestampSec];
        const type = !!context.facility.batterySystem ? context.facility.batterySystem.id : null;
        let i = 0;
        for (let timestamp = start; timestamp <= end; timestamp += 60) {
            if (update) {
                labels.shift();
                datasets[0].data.shift();
                datasets[1].data.shift();
                datasets[2].data.shift();
                datasets[3].data.shift();
                datasets[4].data.shift();
                datasets[5].data.shift();
                datasets[6].data.shift();
                datasets[7].data.shift();
                datasets[8].data.shift();
                datasets[9].data.shift();
                datasets[10].data.shift();
                datasets[11].data.shift();
                datasets[12].data.shift();
                datasets[13].data.shift();
            };

            if (timestamp === reports[i][timestampSec]) {
                const loadAlive = getLoadState(reports[i].loadW);
                const pvAlive = getPvState(reports[i].pvW, type);
                datasets[2].data.push(loadAlive);
                
                datasets[3].data.push(pvAlive);
                if (reports[i].hasOwnProperty('druState')) {
                    const states = getStates(reports[i]);
                    datasets[1].data.push(states.valuesOkay);
                    datasets[4].data.push(states.pvMeterAlive);
                    if (hasHotWater) {
                        datasets[5].data.push(states.hotWaterMeterAlive);
                        datasets[6].data.push(states.hotWaterTankAlive);
                    }
                    if (hasBattery) {
                        datasets[7].data.push(states.highTemp);
                        datasets[8].data.push(states.lowTemp);
                        datasets[9].data.push(states.forceCharging);
                        datasets[10].data.push(states.batteryAlive);
                        datasets[11].data.push(states.inverterAlive);
                    }
                    datasets[12].data.push(states.frequencyMeterAlive);
                    
                    datasets[13].data.push(states.powerMeterAlive);
                } else {
                    datasets[1].data.push(null);
                    datasets[4].data.push(null);
                    datasets[5].data.push(null);
                    datasets[6].data.push(null);
                    datasets[7].data.push(null);
                    datasets[8].data.push(null);
                    datasets[9].data.push(null);
                    datasets[10].data.push(null);
                    datasets[11].data.push(null);
                    datasets[12].data.push(null);
                    datasets[13].data.push(null);
                };

                datasets[0].data.push(getDisconnected(false));
                i++;
            } else {
                datasets[0].data.push(getDisconnected(true));
                datasets[1].data.push(null);
                datasets[2].data.push(null);
                datasets[3].data.push(null);
                datasets[4].data.push(null);
                datasets[5].data.push(null);
                datasets[6].data.push(null);
                datasets[7].data.push(null);
                datasets[8].data.push(null);
                datasets[9].data.push(null);
                datasets[10].data.push(null);
                datasets[11].data.push(null);
                datasets[12].data.push(null);
                datasets[13].data.push(null);
            }

            labels.push(timestamp * 1000);
        }

        datasets[5].hidden = !hasHotWater;
        datasets[6].hidden = !hasHotWater;
        datasets[7].hidden = !hasBattery;
        datasets[8].hidden = !hasBattery;
        datasets[9].hidden = !hasBattery;
        datasets[10].hidden = !hasBattery;
        datasets[13].hidden = !hasBattery && !hasHotWater
        const annotation = CONTRACT_ANNOTATION(end, timezone);
        const timeFormat = getTimeFormat(resolution);
        handleSetChart(labels, datasets, timeFormat, annotation);
    }

    const mergeRecords = (bySecondReports, byMinuteReports) => {
        for (let i = 0; i < bySecondReports.length; i++) {
            const timestampMin = parseInt(bySecondReports[i][timestampMsec] / 60000);
            const index = byMinuteReports.findIndex(record => record[timestampSec] / 60 === timestampMin);
            if (index > -1) {
                bySecondReports[i][BATTERY[minBatteryTemperature.type]] = byMinuteReports[index][BATTERY[minBatteryTemperature.type]];
                bySecondReports[i][BATTERY[maxBatteryTemperature.type]] = byMinuteReports[index][BATTERY[maxBatteryTemperature.type]];
            }
        }
        return bySecondReports
    }

    const populateChartBySecond = (bySecondReports, byMinuteReports, resolution, update = false) => {
        if (!bySecondReports.length) return;

        const hasHotWater = !!context.facility.hasOwnProperty('hotWaterTank');
        const hasBattery = !!context.facility.hasOwnProperty('batterySystem');
        const [labels, datasets] = getLabelsAndDatasets(byMinuteReports, update);

        const start = bySecondReports[0][timestampMsec];
        const end = bySecondReports[bySecondReports.length - 1][timestampMsec];
        const type = !!context.facility.batterySystem ? context.facility.batterySystem.id : null;
        let i = 0;
        bySecondReports = mergeRecords(bySecondReports, byMinuteReports);
        for (let timestamp = start; timestamp <= end; timestamp += 1000) {
            const currTimestampSec = parseInt(timestamp / 1000);
            if (currTimestampSec === parseInt(bySecondReports[i][timestampMsec] / 1000)) {

                const loadAlive = getLoadState(bySecondReports[i].loadW);
                const pvAlive = getPvState(bySecondReports[i].pvW, type);
                datasets[2].data.push(loadAlive);
                datasets[3].data.push(pvAlive);
                if (bySecondReports[i].hasOwnProperty('druState')) {
                    const states = getStates(bySecondReports[i])
                    datasets[1].data.push(states.valuesOkay);
                    datasets[4].data.push(states.pvMeterAlive);
                    if (hasHotWater) {
                        datasets[5].data.push(states.hotWaterMeterAlive);
                        datasets[6].data.push(states.hotWaterTankAlive);
                    }
                    if (hasBattery) {
                        datasets[7].data.push(states.highTemp);
                        datasets[8].data.push(states.lowTemp);
                        datasets[9].data.push(states.forceCharging);
                        datasets[10].data.push(states.batteryAlive);
                        datasets[11].data.push(states.inverterAlive);
                    }
                    datasets[12].data.push(states.frequencyMeterAlive);

                    datasets[13].data.push(states.powerMeterAlive);
                } else {
                    datasets[1].data.push(null);
                    datasets[4].data.push(null);
                    datasets[5].data.push(null);
                    datasets[6].data.push(null);
                    datasets[7].data.push(null);
                    datasets[8].data.push(null);
                    datasets[9].data.push(null);
                    datasets[10].data.push(null);
                    datasets[11].data.push(null);
                    datasets[12].data.push(null);
                    datasets[13].data.push(null);
                };

                datasets[0].data.push(getDisconnected(false));
                i++;
            } else if (currTimestampSec - 1 === parseInt(bySecondReports[i][timestampMsec] / 1000)) {
                const loadAlive = getLoadState(bySecondReports[i].loadW);
                const pvAlive = getPvState(bySecondReports[i].pvW, type);
                datasets[2].data[datasets[2].data.length - 1] = loadAlive;
                datasets[3].data[datasets[3].data.length - 1] = pvAlive;
                if (bySecondReports[i].hasOwnProperty('druState')) {
                    const states = getStates(bySecondReports[i])
                    datasets[1].data[datasets[1].data.length - 1] = states.valuesOkay;
                    datasets[4].data[datasets[4].data.length - 1] = states.pvMeterAlive;
                    if (hasHotWater) {
                        datasets[5].data[datasets[5].data.length - 1] = states.hotWaterMeterAlive;
                        datasets[6].data[datasets[6].data.length - 1] = states.hotWaterTankAlive;
                    }
                    if (hasBattery) {
                        datasets[7].data[datasets[7].data.length - 1] = states.highTemp;
                        datasets[8].data[datasets[8].data.length - 1] = states.lowTemp;
                        datasets[9].data[datasets[9].data.length - 1] = states.forceCharging;
                        datasets[10].data[datasets[10].data.length - 1] = states.batteryAlive;
                        datasets[11].data[datasets[11].data.length - 1] = states.inverterAlive;
                    }
                    datasets[12].data[datasets[12].data.length - 1] = states.frequencyMeterAlive;
                    datasets[13].data[datasets[13].data.length - 1] = states.powerMeterAlive;
                } else {
                    datasets[1].data[datasets[1].data.length - 1] = null;
                    datasets[4].data[datasets[4].data.length - 1] = null;
                    datasets[5].data[datasets[5].data.length - 1] = null;
                    datasets[6].data[datasets[6].data.length - 1] = null;
                    datasets[7].data[datasets[7].data.length - 1] = null;
                    datasets[8].data[datasets[8].data.length - 1] = null;
                    datasets[9].data[datasets[9].data.length - 1] = null;
                    datasets[10].data[datasets[10].data.length - 1] = null;
                    datasets[11].data[datasets[11].data.length - 1] = null;
                    datasets[12].data[datasets[12].data.length - 1] = null;
                    datasets[13].data[datasets[13].data.length - 1] = null;
                };

                datasets[0].data[datasets[0].data.length - 1] = getDisconnected(false);
                timestamp -= 1000;
                i++;
                continue;
            } else {
                datasets[0].data.push(getDisconnected(true));
                datasets[1].data.push(null);
                datasets[2].data.push(null);
                datasets[3].data.push(null);
                datasets[4].data.push(null);
                datasets[5].data.push(null);
                datasets[6].data.push(null);
                datasets[7].data.push(null);
                datasets[8].data.push(null);
                datasets[9].data.push(null);
                datasets[10].data.push(null);
                datasets[11].data.push(null);
                datasets[12].data.push(null);
                datasets[13].data.push(null);
            }

            if (update) {
                labels.shift();
                datasets[0].data.shift();
                datasets[1].data.shift();
                datasets[2].data.shift();
                datasets[3].data.shift();
                datasets[4].data.shift();
                datasets[5].data.shift();
                datasets[6].data.shift();
                datasets[7].data.shift();
                datasets[8].data.shift();
                datasets[9].data.shift();
                datasets[10].data.shift();
                datasets[11].data.shift();
                datasets[12].data.shift();
                datasets[13].data.shift();
            };

            labels.push(timestamp);
        }

        datasets[5].hidden = !hasHotWater;
        datasets[6].hidden = !hasHotWater;
        datasets[7].hidden = !hasBattery;
        datasets[8].hidden = !hasBattery;
        datasets[9].hidden = !hasBattery;
        datasets[10].hidden = !hasBattery;
        datasets[13].hidden = !hasBattery && !hasHotWater;

        const annotation = CONTRACT_ANNOTATION(parseInt(end / 1000), timezone);
        const timeFormat = getTimeFormat(resolution);
        handleSetChart(labels, datasets, timeFormat, annotation);
    }

    const getLabelsAndDatasets = (byMinuteReports, update) => {
        const labels = update ? [...chart.data.labels] : [];
        const datasets = update ? [...chart.data.datasets] : chart.data.datasets.map(d => { d.data = []; return d });
        if (update && !!labels.length && !!byMinuteReports.length) {
            const hasHotWater = !!context.facility.hasOwnProperty('hotWaterTank');
            const hasBattery = !!context.facility.hasOwnProperty('batterySystem');
            const minAllowedTemp = context.facility.batterySystem.minTemperatureC;
            const maxAllowedTemp = context.facility.batterySystem.maxTemperatureC;
            for (let i = 0; i < labels.length; i++) {
                const timestampMin = parseInt(labels[i] / 60000);
                const index = byMinuteReports.findIndex(record => record[timestampSec] / 60 === timestampMin);
                if (index > -1) {
                    if (!!datasets[0].data[i]) {
                        const minTemperature = byMinuteReports[index][BATTERY[minBatteryTemperature.type]];
                        const maxTemperature = byMinuteReports[index][BATTERY[maxBatteryTemperature.type]];
                        if (hasBattery && !hasHotWater) {
                            datasets[7].data[i] = maxTemperature === null || maxTemperature === undefined
                                ? null : maxTemperature <= maxAllowedTemp ? 18.9 : 18;
                            datasets[8].data[i] = minTemperature === null || minTemperature === undefined
                                ? null : minTemperature >= minAllowedTemp ? 18.7 : 17;
                        }

                        if (hasBattery && hasHotWater) {
                            datasets[7].data[i] = maxTemperature === null || maxTemperature === undefined
                                ? null : maxTemperature <= maxAllowedTemp ? 22.9 : 22;
                            datasets[8].data[i] = minTemperature === null || minTemperature === undefined
                                ? null : minTemperature >= minAllowedTemp ? 22.7 : 21;
                        }
                    }
                }
            }
        }

        return [labels, datasets]
    }

    const getStates = (report) => {
        const hasHotWater = !!context.facility.hasOwnProperty('hotWaterTank');
        const hasBattery = !!context.facility.hasOwnProperty('batterySystem');

        const inverterCommsOk = (report.druState & (1 << 9)) === 0;
        const powerMeterCommsOk = (report.druState & (1 << 10)) === 0;

        if (!hasBattery && hasHotWater) {
            return {
                valuesOkay: (report.druState & 1) === 0 ? 4 : 3,
                pvMeterAlive: (report.druState & (1 << 1)) === 0 ? 13.1 : 11,
                hotWaterMeterAlive: (report.druState & (1 << 2)) === 0 ? 16.9 : 15,
                hotWaterTankAlive: (report.druState & (1 << 3)) === 0 ? 17.1 : 16,
                frequencyMeterAlive: (report.druState & (1 << 6)) === 0 ? 20 : 19,
                powerMeterAlive: !powerMeterCommsOk ? 22 : (report.druState & (1 << 7)) === 0 ? 24 : 23
            };
        }

        const battery = context.facility.batterySystem;
        const minAllowedTemp = battery.minTemperatureC;
        const maxAllowedTemp = battery.maxTemperatureC;
        const minTemperature = report[BATTERY[minBatteryTemperature.type]];
        const maxTemperature = report[BATTERY[maxBatteryTemperature.type]];
        if (hasBattery && !hasHotWater) {
            return {
                valuesOkay: (report.druState & 1) === 0 ? 4 : 3,
                pvMeterAlive: (report.druState & (1 << 1)) === 0 ? 13.1 : 11,
                batteryAlive: !inverterCommsOk ? 15 :(report.druState & (1 << 4)) === 0 ? 20.3 : 16,
                forceCharging: (report.druState & (1 << 8)) === 0 ? 20.1 : 17,
                highTemp: (maxTemperature === null || maxTemperature === undefined)
                    ? null : (maxTemperature <= maxAllowedTemp ? 19.9 : 19),
                lowTemp: (minTemperature === null || minTemperature === undefined)
                    ? null : (minTemperature >= minAllowedTemp ? 19.7 : 18),
                inverterAlive: !inverterCommsOk ? 22 : (report.druState & (1 << 5)) === 0 ? 24 : 23,
                frequencyMeterAlive: (report.druState & (1 << 6)) === 0 ? 27 : 26,
                powerMeterAlive: !powerMeterCommsOk ? 29 : (report.druState & (1 << 7)) === 0 ? 31 : 30,
            };
        }

        return {
            valuesOkay: (report.druState & 1) === 0 ? 4 : 3,
            pvMeterAlive: (report.druState & (1 << 1)) === 0 ? 13.1 : 11,
            hotWaterMeterAlive: (report.druState & (1 << 2)) === 0 ? 16.9 : 15,
            hotWaterTankAlive: (report.druState & (1 << 3)) === 0 ? 17.1 : 16,
            batteryAlive: !inverterCommsOk ? 19 : (report.druState & (1 << 4)) === 0 ? 24.3 : 20,
            forceCharging: (report.druState & (1 << 8)) === 0 ? 24.1 : 21,
            highTemp: (maxTemperature === null || maxTemperature === undefined)
                ? null : (maxTemperature <= maxAllowedTemp ? 23.9 : 23),
            lowTemp: (minTemperature === null || minTemperature === undefined)
                ? null : (minTemperature >= minAllowedTemp ? 23.7 : 22),
            inverterAlive: !inverterCommsOk ? 26 : (report.druState & (1 << 5)) === 0 ? 28 : 27,
            frequencyMeterAlive: (report.druState & (1 << 6)) === 0 ? 31 : 30,
            powerMeterAlive: !powerMeterCommsOk ? 33 : (report.druState & (1 << 7)) === 0 ? 35 : 34,
        };
    }

    const getDisconnected = (isDisconnected) => {
        return isDisconnected ? 0 : 1;
    }

    const getPvState = (pvW, type) => {
        if (pvW === null || pvW === undefined) {
            return null;
        }
        if (type === null) {
            return 12;
        }
        if (type < 20 || (30 <= type && type < 100)) {
            return pvW < -35 ? 10 : pvW > 35 ? 12.9 : 12;
        }

        return pvW < -150 ? 10 : pvW > 150 ? 12.9 : 12;
    }

    const getLoadState = (loadW) => {
        if (loadW === null || loadW === undefined) return null;
        return loadW < -50 ? 6 : loadW > 50 ? 8 : 7;
    }

    const getTimeFrame = () => {
        let timeFrame = data.timeFrame;
        if (timeFrame === TIME_FRAMES.select) {
            const { dailyReports, halfHourlyReports } = data;
            const { thirty_six_months, twelve_months, two_months, fifteen_days, one_week, twenty_four_hours } = TIME_FRAMES;

            switch (data.resolution) {
                case resolutions.day:
                    let dailyReportLen = dailyReports.length;
                    timeFrame = dailyReportLen <= 60 ? two_months : dailyReportLen <= 366 ? twelve_months : thirty_six_months;
                    break;
                case resolutions.half_hour:
                    let halfHourlyReportLen = halfHourlyReports.length;
                    timeFrame = halfHourlyReportLen <= 50 ? twenty_four_hours : halfHourlyReportLen <= 336
                        ? one_week : fifteen_days;
                    break;
                case resolutions.minute:
                    timeFrame = twenty_four_hours;
                    break;
                default:
                    break;
            }
        }

        if (fmData.hasFmData) {
            return TIME_FRAMES.fifteen_minutes;
        }

        return timeFrame;
    }

    const handleSetChart = (labels, datasets, time, annotation) => {
        const hasHotWater = !!context.facility.hasOwnProperty('hotWaterTank');
        const hasBattery = !!context.facility.hasOwnProperty('batterySystem');
        const timeFrame = getTimeFrame();
        const update = {
            data: { labels: labels, datasets: datasets },
            options: {
                ...chart.options,
                layout: { padding: { top: -8, right: 12 } },
                plugins: {
                    ...chart.options.plugins,
                    annotation: { annotations: annotation },
                    tooltip: {
                        ...chart.options.plugins.tooltip,
                        callbacks: {
                            ...chart.options.plugins.tooltip.callbacks,
                            ...tooltipTitleCallbacks(selectedTimezone),
                            beforeLabel: function (context) {
                                return
                            },
                            label: function (context) {
                                let label = context.dataset.label || '';
                                const valueKey = String(Math.round(context.formattedValue));
                                let value = hwBatteryLabels[valueKey];
                                if (label === '' ||
                                    label === pvMeterState.type ||
                                    label === batteryForceChargingState.type ||
                                    label === lowBatteryTemperature.type ||
                                    label === highBatteryTemperature.type ||
                                    label === hotWaterMeterState.type) return '';
                            
                                if (context.formattedValue === null) return `${label}: ${value}`;
                                if (!hasBattery && hasHotWater) value = hwLabels[valueKey];
                                if (hasBattery && !hasHotWater) value = batteryLabels[valueKey];
                                if (value.includes('OK')) value = 'OK';

                                switch (label) {
                                    case batteryState.type:
                                        const batteryFaults = [];
                                        const highTemp = context.chart.data.datasets[7].data[context.dataIndex];
                                        const lowTemp = context.chart.data.datasets[8].data[context.dataIndex];
                                        const forceChargingStateValue = context.chart.data.datasets[9].data[context.dataIndex];
                                        if (highTemp !== null && highTemp % 1 === 0)
                                            batteryFaults.push(highBatteryTemperature.type);
                                        if (lowTemp !== null && lowTemp % 1 === 0)
                                            batteryFaults.push(lowBatteryTemperature.type);
                                        if (forceChargingStateValue !== null && forceChargingStateValue % 1 === 0)
                                            batteryFaults.push(batteryForceChargingState.type);
                                        if (!!batteryFaults.length)
                                            if (value === 'OK') return `${label}: ${batteryFaults.join(', ')}`;
                                            else return `${label}: ${value}, ${batteryFaults.join(', ')}`;
                                    case hotWaterTankState.type:
                                        const hwStateValue = context.chart.data.datasets[5].data[context.dataIndex];
                                        if (hwStateValue !== null && hwStateValue % 1 === 0)
                                            if (value === 'OK') return `Hot Water: Faulty Meter`;
                                            else return `Hot Water: ${value}, Faulty Meter`;
                                    case pvState.type:
                                        const pvStateValue = context.chart.data.datasets[4].data[context.dataIndex];
                                        if (pvStateValue !== null && pvStateValue % 1 === 0)
                                            if (value === 'OK') return `PV: Faulty Meter`;
                                            else return `PV: ${value}, Faulty Meter`;
                                }

                                if (value === 'Disconnected' || value === 'Connected') return value;
                                return `${label}: ${value}`;
                            }
                        },
                        itemSort: function (a, b) {
                            return b.datasetIndex - a.datasetIndex
                        }
                    }
                },
                scales: {
                    ...chart.options.scales,
                    y: {
                        ...chart.options.scales.y,
                        min: 0,
                        max: hasHotWater && hasBattery ? 35 : hasBattery ? 31 : 24,
                        alignToPixels: true,
                        grid: { display: true, drawBorder: true, drawOnChartArea: true, drawTicks: true },
                        ticks: {
                            ...chart.options.scales.y.ticks,
                            font: { size: hasHotWater && hasBattery ? 8 : 9 },
                            stepSize: 1,
                            autoSkip: false,
                            callback: function (value, index) {
                                const valueStr = String(Math.round(value));
                                let label = "";
                                if (!hasBattery && hasHotWater) label = hwLabels[valueStr];
                                else if (hasBattery && !hasHotWater) label = batteryLabels[valueStr];
                                else label = hwBatteryLabels[valueStr];
                                return label;
                            },
                            color: (c) => {
                                switch (c['tick']['label']) {
                                    case 'Too Hot':
                                        return highBatteryTemperature.borderColor;
                                    case 'Too Cold':
                                        return lowBatteryTemperature.borderColor;
                                    case 'Force Charging':
                                        return batteryForceChargingState.borderColor;
                                    case 'Faulty Meter':
                                        return hotWaterMeterState.borderColor;
                                    case 'Faulty Tank':
                                        return hotWaterTankState.borderColor;
                                    default:
                                        if ((!!hasBattery && !hasHotWater && (c['tick']['value'] === 16 || c['tick']['value'] === 15)) ||
                                            (!!hasBattery && !!hasHotWater && (c['tick']['value'] === 20 || c['tick']['value'] === 19)))
                                            return batteryState.borderColor;
                                        else return 'black';
                                }
                            },
                            labelColor: function (context) {
                                return {};
                            },
                        }
                    },
                    x: xAxis(timeFrame, time, selectedTimezone)
                },
                onClick: (event, items, chart) => {
                    if (!!items.length) handleFmData(parseInt(chart.data.labels[items[0].index] / 1000));
                }
            }
        };

        update.options.plugins.zoom.zoom.drag.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        update.options.plugins.zoom.zoom.pinch.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        setChart(update);
    }

    useEffect(() => {
        if (fmData.hasFmData && !!fmData.bySecondReports.length) {
            populateChartBySecond(fmData.bySecondReports, fmData.byMinuteReports, resolutions.second)
        }
    }, [fmData.bySecondReports, selectedTimezone])

    useEffect(() => {
        if (!!data.resolution && data.timeFrame === TIME_FRAMES.select) {
            handleDataOnChange(data);
        }
    }, [data.resolution, data.minutelyReports, data.halfHourlyReports, data.dailyReports])

    useEffect(() => {
        if (data.updated) {
            handleDataOnChange(data);
        }
    }, [data.bySecondReports, data.minutelyReports])

    useEffect(() => {
        if (!fmData.hasFmData && !data.updated) {
            handleDataOnChange(data);
        }
    }, [data.timeFrame, fmData.hasFmData, selectedTimezone])

    return (
        <div className="dru-state-chart" style={{ height }}>
            <Line
                id={facilityChartTitles.druState[1]}
                ref={druStateRef}
                data={chart.data}
                options={chart.options}
            />
        </div>
    )
}

export default DruStateChart;