/* Copyright Levelise Ltd 2024 */
import * as React from 'react';

function BatteryLevelSVG({ batteryPercentage, fillColor, emptySpaceColor, textColor, ...props }) {
    const blackColor = '#000';
    const greyColor = '#ddd';
    const whiteColor = '#fff';

    const calculateRenderPercentage = () => {
        let fillPercentage = batteryPercentage;

        if (!batteryPercentage || isNaN(batteryPercentage) || batteryPercentage < 0) {
            fillPercentage = 0;
        } else if (batteryPercentage > 100) {
            fillPercentage = 100;
        }

        return `${(fillPercentage * 75) / 100}%`;
    };

    const getPercentageString = () => {
        if (batteryPercentage === null) {
            return '';
        }

        if (!batteryPercentage || isNaN(batteryPercentage) || batteryPercentage < 0) {
            return `${0}%`;
        }

        return `${Math.round(batteryPercentage)}%`;
    };

    const getXoffset = () => {
        let fillPercentage = batteryPercentage;

        if (!batteryPercentage || isNaN(batteryPercentage) || batteryPercentage < 0) {
            fillPercentage = 0;
        } else if (batteryPercentage > 100) {
            fillPercentage = 100;
        }

        return 9 + (38 * (1 - fillPercentage / 100));
    };

    const whiteBackgroundWidth = calculateRenderPercentage();
    const percentageString = getPercentageString();
    const xOffset = getXoffset();

    return (
        <svg width={50} height={25} viewBox="0 0 50 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_590_6550)">
                <path
                    d="M15.175 0H8.072C6.383 0 5.01 1.51 5.01 3.366v1.168H2.59C1.163 4.534 0 5.826 0 7.415v9.846c0 1.589 1.163 2.881 2.591 2.881h2.418v1.459C5.009 23.476 6.383 25 8.072 25h38.866C48.626 25 50 23.476 50 21.601V3.366C50 1.51 48.626 0 46.937 0h-22.8v2.588h22.8c.398 0 .709.341.709.778v18.235c0 .448-.318.811-.709.811H8.072c-.391 0-.709-.363-.709-.811V3.366c0-.437.312-.778.71-.778h7.102V0zM5.009 7.121v10.433H2.59c-.13 0-.237-.131-.237-.293V7.415c0-.162.106-.294.237-.294h2.418z"
                    fill="#000"
                />
                {/* <Path
                    d="M15.95 4.922v15.195c0 .497.368.9.82.9h28.75c.452 0 .819-.403.819-.9V4.922c0-.497-.367-.9-.82-.9H16.771c-.453 0-.82.403-.82.9zM16.352 1.294C16.352.579 15.825 0 15.175 0s-1.177.58-1.177 1.294c0 .714.527 1.294 1.177 1.294s1.177-.58 1.177-1.294zM25.359 1.294C25.359.579 24.832 0 24.18 0c-.65 0-1.177.58-1.177 1.294 0 .715.527 1.294 1.177 1.294s1.178-.58 1.178-1.294z"
                    fill={blackColor}
                /> */}
                <rect fill={emptySpaceColor || greyColor} x={9} y={3.9} width="75%" height="69%" />
                <rect fill={fillColor || blackColor} x={xOffset} y={4} width={whiteBackgroundWidth} height="68%" fillOpacity={1} />
                <text
                    x={percentageString.length > 3 ? '15' : percentageString.length < 3 ? '24' : '20'}
                    y="16"
                    fill={textColor || whiteColor}
                    fontSize={11}
                    fontWeight={500}
                >
                    {percentageString}
                </text>
            </g>
            <defs>
                <clipPath id="clip0_590_6550">
                    <path fill="#fff" transform="rotate(-90 12.5 12.5)" d="M0 0H25V50H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default BatteryLevelSVG;
