/* Copyright Levelise Ltd 2019 */
let _timeoutId;
let _idleCallback = null;
let _notIdleEvents = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
let _inactive_timeout = 60*60*1000; // 1hr

const IdleService = {
    setIdleCallback(idleCallback) {
        _idleCallback = idleCallback
    },

    resetIdleTimer(ev) {
        clearTimeout(_timeoutId)
        _timeoutId = setTimeout(_idleCallback, _inactive_timeout)
    },

    registerIdleTimerResets() {
        _notIdleEvents.forEach(event =>
            document.addEventListener(event, IdleService.resetIdleTimer, true)
        )
    },

    unRegisterIdleResets() {
        clearTimeout(_timeoutId)
        _notIdleEvents.forEach(event =>
            document.removeEventListener(event, IdleService.resetIdleTimer, true)
        )
    },
}

 export default IdleService