/* Copyright Levelise Ltd 2019-2024 */
import config from '../config'
import TokenService from './token-service';
import UserService from './user-service';
import IdleService from './idle-service';
import { createReqBody } from '../utils/utils';


const OAuthService = {
    saveToken(authObj) {
        const startTime = new Date().getTime();
        TokenService.clearAuthToken();
        TokenService.saveAuthToken(authObj);

        return new Promise((resolve, reject) => {
            (function tokenSaved() {
                let now = new Date().getTime();
                const newToken = TokenService.getAuthToken()['access_token'];

                if (newToken === authObj?.access_token) {
                    resolve(true);
                } else if (now - startTime > 1000) {
                    reject(new Error({message: 'Token save failed'}));
                } else {
                    setTimeout(() => {
                        tokenSaved();
                    }, 50);
                }
            })()
        })
    },
    getAuth(redirect_uri, challenge, state, scope) {
        const data = {
            client_id: process.env.REACT_APP_CLIENT_ID,
            response_type: 'code',
            code_challenge: challenge,
            code_challenge_method: 'S256',
            redirect_uri: redirect_uri,
            scope: scope,
            state: state,
        }
        window.location.assign(`${config.AUTH_URI}/auth/authorize?` + createReqBody(data));
    },

    postToken(redirect_uri, code, code_verifier) {
        const data = {
            client_id: process.env.REACT_APP_CLIENT_ID,
            grant_type: 'authorization_code',
            redirect_uri: redirect_uri,
            code: code,
            code_verifier: code_verifier,
        }

        const basicToken = TokenService.makeBasicAuthToken(
            process.env.REACT_APP_CLIENT_ID,
            process.env.REACT_APP_SECRET
        )

        return fetch(`${config.REST_URI}/oauth/token`, {
            method: 'POST',
            headers: {
                'Authorization': `Basic ${basicToken}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: createReqBody(data)
        })
            .then(res =>
                !res.ok
                    ? res.json().then(e => Promise.reject(e))
                    : res.json()
            )
            .then(res => {
                TokenService.saveAuthToken(res);
                return res;
            })
    },

    postEnroll() {
        return fetch(`${config.AUTH_URI}/auth/enroll`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${TokenService.getDataFromAuthToken("access_token")}`
            },
        })
    },

    postActivate(code1, code2) {
        return fetch(`${config.AUTH_URI}/auth/activate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${TokenService.getDataFromAuthToken("access_token")}`
            },
            body: createReqBody({ code1: code1, code2: code2 })
        }).then(res =>
            !res.ok
                ? res.json().then(e => Promise.reject(e))
                : res.json()
        )
    },

    postCheckToken() {
        const basicToken = TokenService.makeBasicAuthToken(
            process.env.REACT_APP_CLIENT_ID,
            process.env.REACT_APP_SECRET
        )

        return fetch(`${config.REST_URI}/oauth/check_token`, {
            method: 'POST',
            headers: {
                'Authorization': `Basic ${basicToken}`,
                "Content-type": "application/x-www-form-urlencoded; charset=utf-8"
            },
            body: createReqBody({ 'token': TokenService.getDataFromAuthToken("access_token") })
        })
            .then(res =>
                !res.ok
                    ? res.json().then(e => Promise.reject(e))
                    : res.json()
            )
    },

    postRefreshToken() {
        const data = {
            grant_type: 'refresh_token',
            refresh_token: TokenService.getDataFromAuthToken("refresh_token"),
            scope: 'write'
        }

        const basicToken = TokenService.makeBasicAuthToken(
            process.env.REACT_APP_CLIENT_ID,
            process.env.REACT_APP_SECRET
        )

        return fetch(`${config.REST_URI}/oauth/token`, {
            method: 'POST',
            headers: {
                'Authorization': `Basic ${basicToken}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: createReqBody(data)
        })
            .then(res =>
                !res.ok
                    ? res.json().then(e => Promise.reject(e))
                    : res.json()
            )
            .then(res => 
                this.saveToken(res)
            ).then(() => {
                TokenService.queueCallbackBeforeExpiry(() => {
                    OAuthService.postRefreshToken();
                });
            }).catch(() => {
                TokenService.clearAuthToken();
                UserService.removeUser();
                UserService.removeUserDetail();
                TokenService.clearCallbackBeforeExpiry();
                IdleService.unRegisterIdleResets();
                window.location.href = '/';
            })
    },

    postChangePassword(newPassword) {
        const body = {
            password: newPassword
        }

        return fetch(`${config.AUTH_URI}/auth/password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${TokenService.getDataFromAuthToken("access_token")}`
            },
            body: JSON.stringify(body)
        })
            .then(res =>
                !res.ok
                    ? res.json().then(e => Promise.reject(e))
                    : res.ok
            )
    }
}

export default OAuthService;