/* Copyright Levelise Ltd 2024 */
import * as React from "react";

const LowVoltagePaylon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={39}
    height={55}
    viewBox="0 0 40 55"
    fill="none"
    {...props}
  >
    <path stroke="black" strokeWidth={4} d="M20.429 0v55" />
    <path
      stroke="black"
      strokeWidth={4}
      d="M0-2h23.314"
      transform="rotate(.371 -1255.139 1194.755) skewX(-.101)"
    />
    <path stroke="black" strokeWidth={4} d="M1 17.097h37" />
    <path
      fill="black"
      d="M34.28 21.32a2 2 0 1 0 4 0h-4Zm0-4.514v4.514h4v-4.514h-4ZM.72 21.473a2 2 0 1 0 4.001 0h-4Zm0-4.515v4.515h4.001v-4.515h-4ZM27.395 9.862a2 2 0 1 0 4 0h-4Zm0-4.515v4.515h4V5.347h-4ZM27.517 21.473a2 2 0 1 0 4 0h-4Zm0-4.515v4.515h4v-4.515h-4ZM6.727 21.473a2 2 0 1 0 4 0h-4Zm0-4.515v4.515h4v-4.515h-4ZM7.605 9.862a2 2 0 1 0 4 0h-4Zm0-4.515v4.515h4V5.347h-4Z"
    />
  </svg>
)
export default LowVoltagePaylon;
