/* Copyright Levelise Ltd 2019-2021 */
import React, { useState, useEffect, useContext, useRef } from 'react';
import cx from 'classnames';
import Select from 'react-select';
import UserService from '../../services/user-service';
import FleetContext from '../../contexts/FleetContext';
import FleetEnergyCharts from './energy';
import FleetPerformanceChart from './performance';
import FleetBatteryChart from './battery';
import FleetHotWaterChart from './hot_water';
import { customStyles } from './customStyles';
import { distinct, show, fleet } from '../../utils/constants';
import './index.css';
import config from '../../config';

const LineChartsFleet = () => {
	const fleetContext = useContext(FleetContext);
	const energy = 'Energy',
		performance = 'Performance',
		battery = 'Battery system',
		hw_tank = 'HW system';
	const outsideRef = useRef();
	const [view, setView] = useState(performance);
	const [showDistinct, setShowDistinct] = useState(null);
	const [showPerformance, setShowPerformance] = useState(null);
	const [batteryOptions, setBatteryOptions] = useState([]);
	const [hwOptions, setHwOptions] = useState([]);

	const renderCurrentAggregatedFacilityName = () => {
		if (fleetContext.currentDataType !== '') {
			return <div className="selected-af">{fleetContext.currentDataType}</div>;
		} else {
			return '';
		}
	};

	const handleSetView = (currentView) => {
		if (view === battery) {
			fleetContext.setSelectedBatterySystemType('');
			fleetContext.setBatterySystemTypes([]);
		}

		setView(currentView);
	};

	const renderViewTabs = () => {
		return (
			<div className={view === battery || view === hw_tank ? 'fleet-chart-tabs select-view' : 'fleet-chart-tabs'}>
				{showPerformance && (
					<span
						id="performance-tab"
						className={view === performance ? 'fleet-chart-view' : ''}
						onClick={() => handleSetView(performance)}
					>
						{performance}
					</span>
				)}
				<span
					id="energy-tab"
					className={view === energy ? 'fleet-chart-view' : ''}
					onClick={() => handleSetView(energy)}
				>
					{energy}
				</span>
				<span
					id="battery-tab"
					className={view === battery ? 'fleet-chart-view' : ''}
					onClick={() => handleSetView(battery)}
				>
					{battery}
				</span>
				<span
					id="hw-tab"
					className={view === hw_tank ? 'fleet-chart-view' : ''}
					onClick={() => handleSetView(hw_tank)}
				>
					{hw_tank}
				</span>
			</div>
		);
	};

	const getOptions = (systemIds, systemObj) => {
		const options = systemIds.map((id) => {
			return { value: id, label: systemObj[id] };
		});

		return options.sort((a, b) => (a.label > b.label ? 1 : -1));
	};

	const renderBatteryTypes = () => {
		const { batterySystems, selectedBatterySystemType } = fleetContext;
		if (view === battery) {
			return (
				<div className="battery-systems-dropdown-con">
					<div className="battery-systems">
						<Select
							id="bs-type"
							name="bsType"
							className="bs-type"
							styles={customStyles()}
							isSearchable={false}
							value={
								!!selectedBatterySystemType
									? {
											value: selectedBatterySystemType,
											label: batterySystems[selectedBatterySystemType],
									  }
									: ''
							}
							onChange={(system) => fleetContext.setSelectedBatterySystemType(system.value)}
							placeholder="-- Select battery system --"
							options={batteryOptions}
						/>
					</div>
				</div>
			);
		}
	};

	const renderHwTypes = () => {
		const { hwTanks, selectedHwType } = fleetContext;
		if (view === hw_tank) {
			return (
				<div className="battery-systems-dropdown-con">
					<div className="hw-tanks">
						<Select
							id="hw-type"
							name="hwType"
							className="hw-type"
							styles={customStyles()}
							isSearchable={false}
							value={!!selectedHwType ? { value: selectedHwType, label: hwTanks[selectedHwType] } : ''}
							onChange={(system) => fleetContext.setSelectedHwType(system.value)}
							placeholder="-- Select HW tank --"
							options={hwOptions}
						/>
					</div>
				</div>
			);
		}
	};

	const renderCharts = () => {
		let _showPerformance = showPerformance;
		let _showDistinct = showDistinct;
		if (showPerformance === null) {
			const preference = UserService.getPreferences();
			if (!!preference) {
				_showPerformance = preference[config.performance] === show;
				_showDistinct = preference[config.aggFacilityData] === distinct;
				if (!_showPerformance) setView(energy);
				setShowPerformance(_showPerformance);
				setShowDistinct(_showDistinct);
			}
		}

		if (view === performance && _showPerformance) {
			return (
				<FleetPerformanceChart
					view={view}
					performance={performance}
					showDistinct={_showDistinct}
					showPerformance={_showPerformance}
				/>
			);
		} else if (view === energy) {
			return (
				<FleetEnergyCharts
					view={view}
					energy={energy}
					showDistinct={_showDistinct}
					showPerformance={_showPerformance}
				/>
			);
		} else if (view === battery) {
			return <FleetBatteryChart view={view} battery={battery} showPerformance={_showPerformance} />;
		} else if (view === hw_tank) {
			return <FleetHotWaterChart view={view} hw_tank={hw_tank} showPerformance={_showPerformance} />;
		}
	};

	useEffect(() => {
		const { batterySystems, batterySystemTypes, selectedBatterySystemType } = fleetContext;
		if (view === battery) {
			const options = getOptions(batterySystemTypes, batterySystems);
			setBatteryOptions(options);
			if (!selectedBatterySystemType && !!options.length) {
				fleetContext.setSelectedBatterySystemType(options[0].value);
			}
		}
	}, [fleetContext.batterySystemTypes]);

	useEffect(() => {
		const { hwTanks, hwTypes, selectedHwType } = fleetContext;
		if (view === hw_tank) {
			const options = getOptions(hwTypes, hwTanks);
			setHwOptions(options);
			if (!selectedHwType && !!options.length) {
				fleetContext.setSelectedHwType(options[0].value);
			}
		}
	}, [fleetContext.hwTypes]);

	useEffect(() => {
		const preference = UserService.getPreferences();
		if (!!preference) {
			const _showPerformance = preference[config.performance] === show;
			const _showDistinct = preference[config.aggFacilityData] === distinct;
			if (!_showPerformance) setView(energy);
			setShowPerformance(_showPerformance);
			setShowDistinct(_showDistinct);
		}
	}, []);

	return (
		<div className="fleet-line-graph">
			<div className="line-graph-header">
				{renderCurrentAggregatedFacilityName()}
				{renderViewTabs()}
				{renderBatteryTypes()}
				{renderHwTypes()}
			</div>
			<div
				ref={outsideRef}
				className={cx(
					'line-graphs-wrapper fleet-graphs-wrapper',
					fleetContext.currentDataType === fleet && view === performance ? 'fleet-performance' : ''
				)}
			>
				{renderCharts()}
			</div>
		</div>
	);
};

export default LineChartsFleet;
