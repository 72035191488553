/* Copyright Levelise Ltd 2019 */
import React, { Component } from 'react';
import NotFound from '../../components/NotFound/index';

class Route404 extends Component {
    render() {
        return <NotFound />
    }
}

export default Route404;

