/* Copyright Levelise Ltd 2019-2023 */
import React, { useContext, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import FleetContext from '../../contexts/FleetContext';
import FleetService from '../../services/fleet-service';
import Table from './table';
import { getContacts } from './contract';
import { combineStatus, detectBrowser } from '../../utils/utils';
import './index.css';
import { fleet } from '../../utils/constants';

const AggregatedFacilities = () => {
	const context = useContext(FleetContext);
	const [aggFacility, setAggFacility] = useState({ parent: '', aggFacilityName: '' });
	const [hoverAf, setHoverAf] = useState({ parent: '', afName: '' });

	const [selectedGroupName, setSelectedGroupName] = useState([]);

	const handleOnclick = ({ aggFacilityName, parent }) => {
		setAggFacility({ parent: parent, aggFacilityName: aggFacilityName });
		context.setCurrentDataType(aggFacilityName);
		context.setCurrentDataTypeParent(parent);
		context.setFacilities([]);
		handleStatus({});
		getFacilities(aggFacilityName);
		getAggFacilityDruStatuses(aggFacilityName);
	};

	const getFacilities = (aggFacility) => {
		FleetService.getFacilitiesWithinAggFacilities(aggFacility).then(context.setFacilities).catch(context.setError);
	};

	const getAggFacilityDruStatuses = (aggFacility) => {
		FleetService.getDruStatusesForAggFacility(aggFacility).then(handleStatus).catch(context.setError);
	};

	const handleStatus = (druStatus) => {
		const [drus, status] = combineStatus(druStatus);
		context.setAfStatus(status);
		context.setCurrentDrus(drus);
	};

	// creating and catching grouping function
	const groupAfs = useCallback((afs) => {
		let afGroups = [];

		if (afs && afs?.length > 0) {
			const groupedAfs = {}; // {name: , afs: []}

			afs.forEach((element) => {
				const group = element?.name.replace(/[0-9]/g, '');

				if (!groupedAfs[group]) {
					groupedAfs[group] = [element];
				} else {
					groupedAfs[group].push(element);
				}
			});

			// sort grouped items alphabetically
			for (const group in groupedAfs) {
				if (groupedAfs[group] && groupedAfs[group].length > 1) {
					const arr = groupedAfs[group];

					arr?.sort((a, b) => a.name.localeCompare(b.name));
					groupedAfs[group] = arr;
				}
			}

			const result = [];
			const others = [];

			for (const group in groupedAfs) {
				if (groupedAfs[group].length === 1) {
					others.push(...groupedAfs[group]);
				} else {
					result.push({ name: group, afs: groupedAfs[group] });
				}
			}

			// sort result alphabetically
			result.sort((a, b) => a.name.localeCompare(b.name));
			others.sort((a, b) => a.name.localeCompare(b.name));

			if (others.length > 0) {
				result.push({ name: 'Others', afs: others });
			}

			afGroups = result;
		}

		return afGroups;
	}, []);

	// grouping and catching the afs
	const groupedAfsData = useMemo(() => {
		const groupedAfs = groupAfs(context.aggFacilities);
		return groupedAfs;
	}, [context.aggFacilities, groupAfs]);

	// select groups handler
	const handleGroupClick = (groupName) => {
		if (selectedGroupName.includes(groupName)) {
			const filteredGroup = selectedGroupName?.filter((ele) => ele !== groupName);
			setSelectedGroupName(filteredGroup);
			return;
		}

		setSelectedGroupName((prevState) => [...prevState, groupName]);
	};

	const listContract = (af, subAf = null, isFs = false) => {
		const afContracts = !!af && !!af.contracts ? af.contracts : [];
		const subContracts = !!subAf && !!subAf.contracts ? subAf.contracts : [];
		if (!afContracts.length && !subContracts.length) {
			return;
		}

		const timezone = !!af && !!af.timezone ? af.timezone : 'Europe/London';
		const contracts = getContacts(afContracts, subContracts, timezone, isFs);
		return <Table table="af-contacts" contracts={contracts} isFs={isFs} />;
	};

	const handleHoverAf = ({ parent, afName }) => {
		setHoverAf({ parent: parent, afName: afName });
	};

	const getConstituentsListItems = (af, i) => {
		const constituentsListItems = [];

		if (af.hasOwnProperty('constituents') && af.name === aggFacility.parent) {
			for (let j = 0; j < af.constituents.length; j++) {
				const fs = af.constituents[j];
				constituentsListItems.push(
					<li
						key={af.name + fs.name + i + j}
						className={cx(
							'af-names fs-names',
							fs.name === aggFacility.aggFacilityName && af.name === aggFacility.parent
								? 'selected-aggFacility'
								: ''
						)}
						onMouseEnter={() => handleHoverAf({ parent: af.name, afName: fs.name })}
						onMouseLeave={() => setHoverAf({ parent: '', afName: '' })}
					>
						<div
							className="af-name fs-name"
							onClick={() => handleOnclick({ parent: af.name, aggFacilityName: fs.name })}
						>
							<span>
								<FontAwesomeIcon
									icon={faLevelUpAlt}
									rotation={90}
									style={{ width: 16, fontSize: 10, marginBottom: 1, color: '#444444' }}
								/>
								{fs.name}
							</span>
							<span>
								{!!fs.contracts.length ? `${fs.contracts[0].dispatch} ${fs.contracts[0].unit}` : '—'}
							</span>
						</div>
						{(fs.name === hoverAf.afName && af.name === hoverAf.parent) ||
						(fs.name === aggFacility.aggFacilityName && af.name === aggFacility.parent) ? (
							<div
								style={{
									height: 'auto',
									paddingBottom: 0,
									padding: 0,
								}}
							>
								{listContract(af, fs, true)}
							</div>
						) : null}
					</li>
				);
			}
		}

		return constituentsListItems;
	};

	const getGroupAFs = (afs) => {
		const listItems = [];
		for (let i = 0; i < afs.length; i++) {
			const af = afs[i];
			listItems.push(
				<li
					key={af.name + i}
					className={cx(
						'af-names',
						af.name === aggFacility.aggFacilityName && af.name === aggFacility.parent
							? 'selected-aggFacility'
							: ''
					)}
					onMouseEnter={() => handleHoverAf({ parent: af.name, afName: af.name })}
					onMouseLeave={() => setHoverAf({ parent: '', afName: '' })}
					style={{
						borderBottomWidth:
							af.name === hoverAf.afName || af.name === aggFacility.aggFacilityName ? 0 : 1,
					}}
				>
					<div
						className="af-name"
						onClick={() => handleOnclick({ parent: af.name, aggFacilityName: af.name })}
						style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}
					>
						<span
							style={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								display: 'flex',
								flex: 1,
								flexDirection: 'row',
								alignItems: 'baseline',
							}}
						>
							{af.hasOwnProperty('constituents') && af.constituents.length > 0 ? (
								<>
									{af.name === aggFacility.parent ? (
										<FontAwesomeIcon
											icon={faChevronDown}
											style={{ marginRight: 5, fontSize: 10, marginBottom: 1, color: '#444444' }}
										/>
									) : (
										<FontAwesomeIcon
											icon={faChevronRight}
											style={{ marginRight: 5, fontSize: 10, marginBottom: 1, color: '#444444' }}
										/>
									)}
								</>
							) : null}
							{af.name}
							{Object.hasOwn(af, 'parents') ? (
								<span
									style={{
										display: 'inline-block',
										fontSize: '0.52rem',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}
								>
									[{af.parents.toString().split(',').join(', ')}]
								</span>
							) : null}
						</span>
						<span
							style={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							{!!af.contracts.length ? `${af.contracts[0].dispatch} ${af.contracts[0].unit}` : '—'}
						</span>
					</div>
					{(af.name === hoverAf.afName && af.name === hoverAf.parent) ||
					(af.name === aggFacility.aggFacilityName && af.name === aggFacility.parent) ? (
						<div
							style={{
								height: 'auto',
								paddingBottom: 0,
								marginLeft: 5,
							}}
						>
							{listContract(af)}
						</div>
					) : null}
				</li>
			);

			const constituentsListItems = getConstituentsListItems(af, i);

			// if constituents exist wrap it in an ul and add it to the main af list
			if (constituentsListItems.length > 0) {
				listItems.push(
					<div key={af.name + i + 'constituents'} style={styles.constituentsListContainer}>
						<ul style={{ width: '97%', paddingLeft: 0 }}>{constituentsListItems}</ul>
					</div>
				);
			}
		}
		return listItems;
	};

	const listContent = () => {
		let listItems = [];

		if (groupedAfsData?.length > 0) {
			listItems = groupedAfsData.map((group, index) => {
				return (
					<li key={index + group?.name}>
						<div className="af-groupName" onClick={() => handleGroupClick(group?.name)}>
							<span>
								{selectedGroupName.includes(group.name) ? (
									<FontAwesomeIcon
										icon={faChevronDown}
										style={{ marginRight: 5, fontSize: 10, marginBottom: 1, color: '#444444' }}
									/>
								) : (
									<FontAwesomeIcon
										icon={faChevronRight}
										style={{ marginRight: 5, fontSize: 10, marginBottom: 1, color: '#444444' }}
									/>
								)}
								{group.name}
							</span>
						</div>
						{selectedGroupName.includes(group.name) ? (
							<div style={styles.afsInGroupContainer}>
								<ul style={{ height: 'auto', width: '100%', paddingLeft: 0 }} data-cy="afs-in-groups">
									{getGroupAFs(group?.afs)}
								</ul>
							</div>
						) : null}
					</li>
				);
			});
		}

		return listItems;
	};

	useEffect(() => {
		if (context.currentDataType.toLowerCase() === 'fleet') {
			setAggFacility({ parent: '', aggFacilityName: '' });
		}

		if (context.currentDataType.toLocaleLowerCase().trim() && context.currentDataType.toLowerCase() !== 'fleet') {
			const group = context.currentDataTypeParent.replace(/[0-9]/g, '');
			if (group) {
				let groupExists = false;
				if (groupedAfsData && groupedAfsData?.length > 0) {
					for (let i = 0; i < groupedAfsData.length; i++) {
						if (groupedAfsData[i].name === group) {
							groupExists = true;
							break;
						}
					}
				}

				setSelectedGroupName((prevState) => [...prevState, groupExists ? group : 'Others']);
			}
			setAggFacility({ parent: context.currentDataTypeParent, aggFacilityName: context.currentDataType });
		}
	}, [context.currentDataType, context.currentDataTypeParent]);

	// expand LEV-FRU group by default
	useEffect(() => {
		if (groupedAfsData && groupedAfsData?.length > 0) {
			setSelectedGroupName((prevState) => [...prevState, 'LEV-FRU']);
		}
	}, [groupedAfsData]);

	return (
		<div className="aggFacilities-wrapper">
			<div className="fleet-table-title">
				<h2>Balancing Services Units</h2>
			</div>
			<div
				onClick={() => context.setCurrentDataType(fleet)}
				className={cx(
					'fleet-table-title',
					'af-names',
					'af-groupName',
					'hoverEffectForFleet',
					context.currentDataType === fleet ? 'selected-aggFacility' : ''
				)}
			>
				<h2>Fleet</h2>
			</div>
			<ul className={cx('aggFacilities', context.showStatus ? 'aggFacilities-scroll-hidden' : '')}>
				{listContent()}
				{groupedAfsData?.length ? (
					<li key={'lastAFGroup'}>
						<div className="af-groupName">
							<span></span>
						</div>
					</li>
				) : null}
			</ul>
		</div>
	);
};

const styles = {
	afsInGroupContainer: {
		height: 'auto',
		width: '100%',
		justifyContent: 'flex-start',
		marginLeft: 10,
		paddingBottom: 0,
		marginBottom: 5,
	},
	constituentsListContainer: {
		height: 'auto',
		width: '100%',
		justifyContent: 'flex-start',
		marginLeft: 5,
		paddingBottom: 0,
		marginBottom: 5,
	},
};

export default AggregatedFacilities;
