/* Copyright Levelise Ltd 2019-2021 */
import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fromPv, fromBattery, fromGrid, toLoad, heating, toBattery, toGrid, dcPv, fromDcPvForSummary, loss } from '../../utils/chart';
import { TIME_FRAMES } from '../../utils/constants';

const SummaryChart = ({ facility, records, timeFrame, dates }) => {
    const timeFrames = [
        TIME_FRAMES.one_week,
        TIME_FRAMES.twenty_one_days,
        TIME_FRAMES.three_months,
        TIME_FRAMES.twelve_months,
        TIME_FRAMES.thirty_six_months,
        TIME_FRAMES.all,
    ];
    const conversionFactor = {
        [TIME_FRAMES.twenty_four_hours]: 60 * 1000,
        [TIME_FRAMES.six_hours]: 60 * 1000,
        [TIME_FRAMES.one_hour]: 60 * 1000,
        [TIME_FRAMES.fifteen_minutes]: 60 * 60 * 1000
    };

    const [isDC, setIsDC] = useState(false);

    const [chart, setChart] = useState({
        consumptionChart: {
            data: {
                labels: [fromPv.type, fromDcPvForSummary.type,  fromBattery.type, fromGrid.type],
                datasets: [{
                    backgroundColor: [
                        fromPv.borderColor,
                        fromDcPvForSummary.borderColor,
                        fromBattery.borderColor,
                        fromGrid.borderColor],
                    hoverBackgroundColor: [
                        fromPv.backgroundColor,
                        fromDcPvForSummary.backgroundColor,
                        fromBattery.backgroundColor,
                        fromGrid.backgroundColor],
                    data: []
                }]
            },
            options: {
                responsive: true,
                aspectRatio: 1.5,
                maintainAspectRatio: true,
                plugins: {
                    legend: {
                        display: true,
                        position: 'right',
                        align: 'end',
                        labels: {
                            font: { size: 10 }, 
                            color: 'black',
                            usePointStyle: true,
                            boxWidth: 7,
                            boxHeight: 7,
                            padding: 5,
                        }
                    },
                    title: {
                        display: true,
                        text: 'Total Consumption',
                        color: 'black'
                    },
                    lineTracker: false
                }
            }
        },
        productionChart: {
            data: {
                labels: [toBattery.type, toLoad.type, heating.type, toGrid.type, loss.type],
                datasets: [{
                    backgroundColor: [
                        toBattery.borderColor,
                        toLoad.borderColor,
                        heating.borderColor,
                        toGrid.borderColor,
                        loss.borderColor
                    ],
                    hoverBackgroundColor: [
                        toBattery.backgroundColor,
                        toLoad.backgroundColor,
                        heating.backgroundColor,
                        toGrid.backgroundColor,
                        loss.backgroundColor
                    ],
                    data: []
                }]
            },
            options: {
                responsive: true,
                aspectRatio: 1.5,
                maintainAspectRatio: true,
                plugins: {
                    legend: {
                        display: true,
                        position: 'right',
                        align: 'end',
                        labels: {
                            font: { size: 10 }, 
                            color: 'black',
                            usePointStyle: true,
                            boxWidth: 7,
                            boxHeight: 7,
                            padding: 8,
                        }
                    },
                    title: {
                        display: true,
                        text: `Total Production`,
                        color: 'black'
                    },
                    lineTracker: false
                }
            }
        }
    })

    const handleIsKwh = () => {
        if (timeFrames.includes(timeFrame)) {
            return true;
        } else if (timeFrame === TIME_FRAMES.select) {
            if (dates.start === dates.end) {
                if (records.length <= 48) return true;
                else return false;
            } else {
                return true;
            }
        }
    }

    const populateChartData = () => {
        const factor = handleIsKwh(timeFrame) ? 1 : timeFrame === TIME_FRAMES.select
            ? conversionFactor[TIME_FRAMES.twenty_four_hours]
            : conversionFactor[timeFrame];
        const loadKey = handleIsKwh(timeFrame) ? 'loadKwh' : 'loadW';
        const pvKey = handleIsKwh(timeFrame) ? 'pvKwh' : 'pvW';
        const dcPvKey = handleIsKwh(timeFrame) ? 'dcPvKwh' : 'dcPvW';
        const inverterChargingKey = handleIsKwh(timeFrame) ? 'inverterChargingKwh' : 'inverterChargingW';
        const inverterDischargingKey = handleIsKwh(timeFrame) ? 'inverterDischargingKwh' : 'inverterDischargingW';
        const inverterFromBatteryKey = handleIsKwh(timeFrame) ? 'inverterFromBatteryKwh' : 'inverterFromBatteryW';
        const inverterFromPvKey = handleIsKwh(timeFrame) ? 'inverterFromPvKwh' : 'inverterFromPvW';
        const loadFromInverterKey = handleIsKwh(timeFrame) ? 'loadFromInverterKwh' : 'loadFromInverterW';
        const loadFromPvKey = handleIsKwh(timeFrame) ? 'loadFromPvKwh' : 'loadFromPvW';
        const loadFromBatteryKey = handleIsKwh(timeFrame) ? 'loadFromBatteryKwh' : 'loadFromBatteryW';
        const hwHeatingKey = handleIsKwh(timeFrame) ? 'hwHeatingKwh' : 'hwHeatingW';
        const hwFromPvKey = handleIsKwh(timeFrame) ? 'hwFromPvKwh' : 'hwFromPvW';
        const hwFromBatteryKey = handleIsKwh(timeFrame) ? 'hwFromBatteryKwh' : 'hwFromBatteryKwh';
        const batteryChargingKey = handleIsKwh(timeFrame) ? 'batteryChargingKwh' : 'batteryChargingW';
        const batteryDischargingKey = handleIsKwh(timeFrame) ? 'batteryDischargingKwh' : 'batteryDischargingW';
        const batteryFromPvKey = handleIsKwh(timeFrame) ? 'batteryFromPvKwh' : 'batteryFromPvW';
        const batteryFromDcPvKey = handleIsKwh(timeFrame) ? 'batteryFromDcPvKwh' : 'batteryFromDcPvW';
        const gridIncomingKey = handleIsKwh(timeFrame) ? 'gridIncomingKwh' : 'gridIncomingW';
        const gridOutgoingKey = handleIsKwh(timeFrame) ? 'gridOutgoingKwh' : 'gridOutgoingW';
        const kwargs = {
            records, timeFrame, factor, loadKey, pvKey, dcPvKey, loadFromPvKey, loadFromBatteryKey, loadFromInverterKey, hwHeatingKey,
            hwFromPvKey, hwFromBatteryKey, batteryChargingKey, batteryDischargingKey, batteryFromPvKey,
            gridIncomingKey, gridOutgoingKey, inverterChargingKey, inverterDischargingKey, inverterFromPvKey, inverterFromBatteryKey,
            batteryFromDcPvKey
        };

        if (kwargs.timeFrame === TIME_FRAMES.fifteen_minutes) {
            const { totalConsumption, totalProduction, consumptionData, productionData } = calculateSummaryDataBySecond(kwargs);
            callSetChart(totalConsumption, totalProduction, consumptionData, productionData, factor);
        } else {
            const { totalConsumption, totalProduction, consumptionData, productionData } = calculateSummaryData(kwargs);
            callSetChart(totalConsumption, totalProduction, consumptionData, productionData, factor);
        }
    }

    const parseKwh = val => {
        const result = parseFloat(val)
        return isNaN(result) || result === 0 ? null : `${result} kWh`
    }

    const callSetChart = (totalConsumption, totalProduction, consumptionData, productionData, factor) => {
        const hasHw = facility.hasOwnProperty('hotWaterTank');
        const hasBattery = facility.hasOwnProperty('batterySystem')
        let productionLabels = [toBattery.type, toLoad.type, heating.type, toGrid.type, loss.type];
        let consumptionLabels = [fromPv.type, dcPv.type,  fromBattery.type, fromGrid.type];
        let productionColors = { backgroundColor: [
            toBattery.borderColor,
            toLoad.borderColor,
            heating.borderColor,
            toGrid.borderColor,
            loss.borderColor
        ],
        hoverBackgroundColor: [
            toBattery.backgroundColor,
            toLoad.backgroundColor,
            heating.backgroundColor,
            toGrid.backgroundColor,
            loss.backgroundColor
        ] };
        let consumptionColors = {backgroundColor: [
            fromPv.borderColor,
            dcPv.borderColor,
            fromBattery.borderColor,
            fromGrid.borderColor],
        hoverBackgroundColor: [
            fromPv.backgroundColor,
            dcPv.backgroundColor,
            fromBattery.backgroundColor,
            fromGrid.backgroundColor]};

        if (!hasHw) {
            productionLabels = productionLabels.filter((label) => label !== heating.type);
            productionColors = {
                backgroundColor: productionColors.backgroundColor.filter((color) => color !== heating.borderColor),
                hoverBackgroundColor: productionColors.hoverBackgroundColor.filter((color) => color !== heating.backgroundColor),
            }
            productionData.splice(2, 1)  // remove heating
        }

        if (!isDC) {
            consumptionLabels = consumptionLabels.filter((label) => label !== dcPv.type);
            consumptionColors = {
                backgroundColor: consumptionColors.backgroundColor.filter((color) => color !== dcPv.borderColor),
                hoverBackgroundColor: consumptionColors.hoverBackgroundColor.filter((color) => color !== dcPv.backgroundColor),
            }
            consumptionData.splice(1, 1)  // remove from dc pv value

            const indexOfLoss = productionLabels.indexOf(loss.type);
            productionLabels = productionLabels.filter((label) => label !== loss.type);
            productionColors = {
                backgroundColor: productionColors.backgroundColor.filter((color) => color !== loss.borderColor),
                hoverBackgroundColor: productionColors.hoverBackgroundColor.filter((color) => color !== loss.backgroundColor),
            }
            productionData.splice(indexOfLoss, 1)  // remove loss
        }

        if (!hasBattery) {
            consumptionLabels = consumptionLabels.filter((label) => label !== fromBattery.type);
            consumptionColors = {
                backgroundColor: consumptionColors.backgroundColor.filter((color) => color !== fromBattery.borderColor),
                hoverBackgroundColor: consumptionColors.hoverBackgroundColor.filter((color) => color !== fromBattery.backgroundColor),
            }
            
            if (consumptionData.length === 4) {
                consumptionData.splice(2, 1);  // remove from battery value
            } else if (consumptionData.length === 3) {
                consumptionData.splice(1, 1);
            }
        }

        if (isDC && consumptionLabels.includes(fromPv.type)) {
            consumptionLabels = consumptionLabels.filter((label) => label !== fromPv.type);
            
            consumptionColors = {
                backgroundColor: consumptionColors.backgroundColor.filter((color) => color !== fromPv.borderColor),
                hoverBackgroundColor: consumptionColors.hoverBackgroundColor.filter((color) => color !== fromPv.backgroundColor),
            }
            
            consumptionData.splice(0, 1)  // remove from pv value
        }
       
        setChart({
            consumptionChart: {
                data: {
                    labels: consumptionLabels,
                    datasets: [{
                        ...consumptionColors,
                        data: consumptionData.map(d => d.toFixed(2))
                    }]
                },
                options: {
                    ...chart.consumptionChart.options,
                    plugins: {
                        ...chart.consumptionChart.options.plugins,
                        title: {
                            ...chart.consumptionChart.options.plugins.title,
                            display: true,
                            text: `Total Consumption (${(totalConsumption / factor).toFixed(2)} kWh)`
                        },
                        tooltip: { callbacks: { label: callbackLabel } },

                        datalabels: {
                            display: true,
                            formatter: (val, ctx) => parseKwh(val),
                            backgroundColor: (context) => context.dataset.backgroundColor,
                            color: 'white',
                            borderRadius: 3,
                            anchor: 'center',
                            font: { size: 10 },
                            padding: { top: 2, right: 2, bottom: 1, left: 2 }
                        },

                    }
                },
            },
            productionChart: {
                data: {
                    labels: productionLabels,
                    datasets: [{
                        ...productionColors,
                        data: productionData.map(d => d.toFixed(2))
                    }]
                },
                options: {
                    ...chart.productionChart.options,
                    plugins: {
                        ...chart.productionChart.options.plugins,
                        title: {
                            ...chart.productionChart.options.plugins.title,
                            display: true,
                            text: `Total Production (${(totalProduction / factor).toFixed(2)} kWh)`
                        },
                        tooltip: { callbacks: { label: callbackLabel } },
                        datalabels: {
                            display: true,
                            formatter: (val, ctx) => parseKwh(val),
                            backgroundColor: (context) => context.dataset.backgroundColor,
                            color: 'white',
                            borderRadius: 3,
                            anchor: 'center',
                            font: { size: 10 },
                            padding: { top: 2, right: 2, bottom: 1, left: 2 }
                        },
                    }
                }
            }
        })
    }

    const callbackLabel = (context) => {
        const label = context.label || '';
        const currentValue = context.parsed;
        return `${label}: ${currentValue}kWh`;
    }

    const calculateSummaryDataBySecond = (kwargs) => {
        let totalConsumption = 0, totalProduction = 0;
        const consumptionData = [], productionData = [];
        kwargs.records.forEach(report => {
            const fromPv = report[kwargs.pvKey];
            const fromDcPv = report[kwargs.dcPvKey];
            const toLoad = report[kwargs.loadKey];
            const toHw = report[kwargs.hwHeatingKey];
            const toBattery = report[kwargs.batteryChargingKey];
            const fromBattery = report[kwargs.batteryDischargingKey];
            const toGridW = report[kwargs.gridOutgoingKey];
            const fromInverter = report[kwargs.inverterDischargingKey] || 0;
            const toInverter = report[kwargs.inverterChargingKey] || 0;
            const loadFromPv = Math.min(toLoad, fromPv);
            const batteryFromDcPv = Math.min(fromDcPv, toBattery);
            const loadFromBattery = Math.max(0, Math.min(toLoad - fromPv, fromBattery));
            const hwFromPv = Math.max(0, Math.min(toHw, fromPv - loadFromPv));
            const hwFromBattery = Math.max(0, Math.min(toHw - hwFromPv, fromBattery - loadFromBattery));
            const hwFromGrid = toHw - ((hwFromPv || 0) + (hwFromBattery || 0));
            let batteryFromPv = Math.max(0, Math.min(toBattery, fromPv - (loadFromPv + (hwFromPv || 0))));
            let gridFromPV = Math.max(0, Math.min(toGridW, fromPv - (loadFromPv + (hwFromPv || 0) + batteryFromPv)));
            const loadFromInverter = Math.min(toLoad - loadFromPv, fromInverter)
            const loadFromDcPv = loadFromInverter - loadFromBattery;

            let loadFromGrid = (toLoad - loadFromPv - loadFromBattery) || 0;
            if (isDC) {
                loadFromGrid = (toLoad - loadFromPv - loadFromInverter) || 0;
            }

            totalConsumption += (toLoad + (toHw || 0));

            consumptionData[0] = (consumptionData[0] || 0) + (loadFromPv + (hwFromPv || 0)) / kwargs.factor;
            consumptionData[1] = (consumptionData[1] || 0) + (loadFromDcPv || 0) / kwargs.factor;
            consumptionData[2] = (consumptionData[2] || 0) + (loadFromBattery + (hwFromBattery || 0)) / kwargs.factor;
            consumptionData[3] = (consumptionData[3] || 0) + (loadFromGrid + (hwFromGrid || 0)) / kwargs.factor;

            if (isDC) {
                totalProduction += fromDcPv;

                const inverterFromPv = Math.min(fromPv - loadFromPv, toInverter);

                gridFromPV = (fromPv - loadFromPv - inverterFromPv) || 0;

                const gridFromBattery = (toGridW - gridFromPV) || 0;
                const gridFromDcPv = toGridW - gridFromPV - gridFromBattery;

                const loss = fromDcPv - batteryFromDcPv - loadFromDcPv - gridFromDcPv;

                productionData[0] = (productionData[0] || 0) + (batteryFromDcPv / kwargs.factor); 
                productionData[1] = (productionData[1] || 0) + loadFromDcPv / kwargs.factor;
                productionData[2] = 0;
                productionData[3] = (productionData[3] || 0) + gridFromDcPv / kwargs.factor;
                productionData[4] = (productionData[4] || 0) + loss / kwargs.factor;
            } else {
                totalProduction += fromPv;

                productionData[0] = (productionData[0] || 0) + ((batteryFromPv / kwargs.factor));
                productionData[1] = (productionData[1] || 0) + loadFromPv / kwargs.factor;
                productionData[2] = (productionData[2] || 0) + hwFromPv / kwargs.factor;
                productionData[3] = (productionData[3] || 0) + gridFromPV / kwargs.factor; //
                productionData[4] = 0;
            }
        });

        return { totalConsumption, totalProduction, consumptionData, productionData }
    }

    const calculateSummaryData = (kwargs) => {
        let totalConsumption = 0, totalProduction = 0;
        const consumptionData = [], productionData = [];
        kwargs.records.forEach(report => {
            const fromPv = report[kwargs.pvKey];
            const fromDcPv = report[kwargs.dcPvKey];
            const toLoad = report[kwargs.loadKey];
            const loadFromPv = report[kwargs.loadFromPvKey];
            const loadFromBattery = report[kwargs.loadFromBatteryKey];
            const inverterFromBattery = report[kwargs.inverterFromBatteryKey] || 0;
            const inverterFromPv = report[kwargs.inverterFromPvKey] || 0;
            const toGridW = report[kwargs.gridOutgoingKey];
            const toHw = report[kwargs.hwHeatingKey];
            const hwFromPv = report[kwargs.hwFromPvKey];
            const hwFromBattery = report[kwargs.hwFromBatteryKey];
            const hwFromGrid = toHw - (hwFromPv || 0) - (hwFromBattery || 0);
            const batteryFromPv = report[kwargs.batteryFromPvKey] || 0;
            const batteryFromDcPv = report[kwargs.batteryFromDcPvKey] || 0;
            const loadFromInverter = report[kwargs.loadFromInverterKey] || 0;

            let loadFromGrid = (toLoad - loadFromPv - loadFromBattery) || 0;
            if (isDC) {
                loadFromGrid = (toLoad - loadFromPv - loadFromInverter) || 0;
            }

            const gridFromPV = fromPv - loadFromPv - (hwFromPv || 0) - batteryFromPv;
            const loadFromDcPv = loadFromInverter - loadFromBattery;

            totalConsumption += toLoad + (toHw || 0);

            consumptionData[0] = (consumptionData[0] || 0) + (loadFromPv + (hwFromPv || 0)) / kwargs.factor;
            consumptionData[1] = (consumptionData[1] || 0) + (loadFromDcPv || 0) / kwargs.factor;
            consumptionData[2] = (consumptionData[2] || 0) + (loadFromBattery + (hwFromBattery || 0)) / kwargs.factor;
            consumptionData[3] = (consumptionData[3] || 0) + (loadFromGrid + (hwFromGrid || 0)) / kwargs.factor;

            if (isDC) {
                totalProduction += fromDcPv;

                let gridFromPV = (fromPv - loadFromPv - inverterFromPv) || 0;
            
                const gridFromBattery = (inverterFromBattery - (hwFromBattery || 0) - loadFromBattery) || 0;
                const gridFromDcPv = toGridW - gridFromPV - gridFromBattery;

                const loss = fromDcPv - batteryFromDcPv - loadFromDcPv - gridFromDcPv;
               
                productionData[0] = (productionData[0] || 0) + (batteryFromDcPv / kwargs.factor); 
                productionData[1] = (productionData[1] || 0) + loadFromDcPv / kwargs.factor;
                productionData[2] = 0;
                productionData[3] = (productionData[3] || 0) + gridFromDcPv / kwargs.factor;
                productionData[4] = (productionData[4] || 0) + loss / kwargs.factor;
            } else {
                totalProduction += fromPv;

                productionData[0] = (productionData[0] || 0) + ((batteryFromPv / kwargs.factor));
                productionData[1] = (productionData[1] || 0) + loadFromPv / kwargs.factor;
                productionData[2] = (productionData[2] || 0) + hwFromPv / kwargs.factor;
                productionData[3] = (productionData[3] || 0) + gridFromPV / kwargs.factor; //
                productionData[4] = 0;
            }
        });

        return { totalConsumption, totalProduction, consumptionData, productionData }
    }

    useEffect(() => {
        if (facility && Object.hasOwn(facility, 'batterySystem')) {
            if ((facility.batterySystem?.meteringType?.id & 0x40) > 0 ) {
                setIsDC(true);
            } else {
                setIsDC(false);
            }
        }
    }, [facility])

    useEffect(() => {
        populateChartData();
    }, [records, timeFrame, isDC])

    return (
        <div className="summary-chart">
            <Pie
                id='facilityConsumption'
                width={document.width <= 1440 ? 140 : 140}
                data={chart.consumptionChart.data}
                options={chart.consumptionChart.options}
                plugins={[ChartDataLabels]}
            />
            <Pie
                id='facilityProduction'
                width={document.width <= 1440 ? 140 : 140}
                data={chart.productionChart.data}
                options={chart.productionChart.options}
                plugins={[ChartDataLabels]}
            />
        </div>
    )
}

export default SummaryChart;