/* Copyright Levelise Ltd 2024 */
import React, { useContext, useState } from 'react';
import { hasPermission } from '../../utils/utils';
import FleetConext from '../../contexts/FleetContext';
import { Button } from '../Form';
import { PERMISSIONS } from '../../utils/constants';
import CustomerTariff from '../CustomerTariff';
import CustomerConsentInformation from '../CustomerConsentInformation';

const CustomerConsentSection = ({
	customerConsent,
	handleAcceptEvidenceClick,
	handleWithdrawConsentClick,
	handleWithdrawDSRClick,
	editing,
	setEditing,
	facility,
	handleUploadBill,
	uploadState,
	setUploadState,
	downloadState,
	handleDownloadClick,
	customerImportTariff,
	customerExportTariff,
}) => {
	const fleetContext = useContext(FleetConext);
	const timezone = fleetContext?.selectedTimezone
		? fleetContext?.selectedTimezone === 'UTC'
			? 'UTC'
			: fleetContext?.selectedTimezone?.split(' | ')[1]
		: '';
	const selectedTimezone = timezone || 'Europe/London';

	const [view, setView] = useState('Consent');

	const onEditClick = () => {
		setEditing(true);
	};

	const handleViewChange = (tab) => {
		if (tab !== 'Consent' && editing) {
			setEditing(false);
		}

		setView(tab);
	};

	return (
		<div className="left-side-container">
			<div className="customer-screen-headers" style={{ paddingBottom: 1 }}>
				<h2>
					<span
						id="fac-status"
						className={view === 'Consent' ? 'fac-view' : ''}
						onClick={() => handleViewChange('Consent')}
						data-cy="consent-tab-button"
					>
						Consent
					</span>
					<span
						id="fac-status"
						className={view === 'Tariff' ? 'fac-view' : ''}
						onClick={() => handleViewChange('Tariff')}
						data-cy="tariff-tab-button"
					>
						Tariff
					</span>
				</h2>
				{hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT) &&
				((customerConsent && Object.hasOwn(customerConsent, 'consentId')) ||
					((!customerConsent || !Object.hasOwn(customerConsent, 'consentId')) &&
						facility &&
						Object.hasOwn(facility, 'drAllowed') &&
						facility.drAllowed)) &&
				view === 'Consent' ? (
					<span className="right-side-component">
						<div className="customer-edit-button-container">
							{editing ? (
								<>
									<Button
										className="buttons-customer"
										onClick={() => {
											setEditing(false);
										}}
										data-cy="consent-cancel-button"
									>
										Cancel
									</Button>
								</>
							) : (
								<Button
									className="buttons-customer"
									onClick={() => onEditClick()}
									data-cy="consent-edit-button"
								>
									Edit
								</Button>
							)}
						</div>
					</span>
				) : null}
			</div>

			{view === 'Consent' ? (
				<CustomerConsentInformation
					consent={customerConsent}
					timezone={selectedTimezone}
					editing={editing}
					handleAcceptEvidenceClick={handleAcceptEvidenceClick}
					handleWithdrawConsentClick={handleWithdrawConsentClick}
					handleWithdrawDSRClick={handleWithdrawDSRClick}
					uploadState={uploadState}
					downloadState={downloadState}
					onUploadSubmit={handleUploadBill}
					onDownloadClick={handleDownloadClick}
					setUploadState={setUploadState}
					facility={facility}
				/>
			) : null}
			{view === 'Tariff' ? (
				<CustomerTariff
					customerImportTariff={customerImportTariff}
					customerExportTariff={customerExportTariff}
					timezone={facility?.timezone || 'Europe/London'}
				/>
			) : null}
		</div>
	);
};

export default CustomerConsentSection;
