/* Copyright Levelise Ltd 2021-2022 */
import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import cx from 'classnames';
import {
    LOAD_FLEET,
    LOAD_FLEET_KWH,
    TIME_FRAMES,
    resolutions,
    timestampDay,
    timestampSec,
} from '../../../utils/constants';
import {
    CONTRACT_ANNOTATION,
    fromPv,
    fromBattery,
    fromFleet,
    fromGridStack,
    getTimeFormat,
    dataset,
    options,
    xAxis,
    fleetChartTitles,
    tooltipTitleCallbacks
} from '../../../utils/chart';


const x = { type: 'time' }
const x1 = null
const y = {
    stacked: true,
    ticks: {},
    label: 'Energy (kWh)',
    gridLines: { drawOnChartArea: true }
}
const y1 = null
const legendLabels = {
    font: { size: 10 }, 
    color: 'black',
    usePointStyle: true,
    boxWidth: 4,
    boxHeight: 4,
    padding: 8,
}

const LoadChart = ({ fmData, handleFmData, data, timezone, selectedTimezone, showPerformance }) => {
    const loadRef = useRef();
    const dataSetLabels = {
        pv: fromPv.type,
        battery: fromBattery.type,
        fleet: fromFleet.type,
        gridStack: fromGridStack.type
    }
    const [chart, setChart] = useState({
        data: {
            labels: [],
            datasets: [
                dataset('line', fromPv.type, fromPv.backgroundColor2, fromPv.backgroundColor, 'y', true),
                dataset('line', fromBattery.type, fromBattery.backgroundColor2, fromBattery.backgroundColor, 'y', '-1'),
                dataset('line', fromFleet.type, fromFleet.backgroundColor2, fromFleet.backgroundColor, 'y', '-1'),
                dataset('line', fromGridStack.type, fromGridStack.backgroundColor2, fromGridStack.backgroundColor, 'y', '-1'),
            ]
        },
        options: options(fleetChartTitles.load[0], legendLabels, x, x1, y, y1)
    });

    const handleDataOnChange = (data) => {
        let resolution = data.resolution;
        if (data.timeFrame !== TIME_FRAMES.select) {
            switch (data.timeFrame) {
                case TIME_FRAMES.fifteen_minutes:
                    resolution = resolutions.second;
                    break;
                case TIME_FRAMES.one_hour:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.six_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.twenty_four_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.one_week:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.twenty_one_days:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.three_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.twelve_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.thirty_six_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.all:
                    resolution = resolutions.week;
                    break;
                default:
                    break;
            }
        }

        switch (resolution) {
            case resolutions.week:
            case resolutions.day:
                populateChartWithRecordByDay(data.reports, resolution);
                break
            case resolutions.half_hour:
                populateChartWithRecordKwh(data.reports, resolution);
                break
            case resolutions.minute:
                populateChartWithRecordW(data.reports, resolution);
                break
            case resolutions.second:
                populateChartWithRecordW(data.reports, resolution, data.updated);
                break;
            default:
                break;
        }
    }

    const populateChartWithRecordByDay = (reports, resolution) => {
        const { fleet, gridStack } = dataSetLabels;
        let labels = [];
        let datasets = chart.data.datasets.map(d => { d.data = []; return d });

        if (!reports.length) return;
        for (let i = reports.length - 1; i >= 0; i--) {
            datasets = datasets.map(set => {
                if (set.label === gridStack || set.label === fleet) {
                    const load = reports[i]['loadKwh'];
                    const fromPv = reports[i]['loadFromPvKwh'];
                    const fromBattery = reports[i]['loadFromBatteryKwh'];
                    const fromFleet = Math.min(reports[i]['gridOutgoingKwh'], load - fromPv - fromBattery);
                    const fromGrid = load - fromPv - fromBattery - fromFleet;
                    if (set.label === gridStack) {
                        set.data.push(fromGrid === 0 ? 0.000001 : fromGrid);
                    } else {
                        set.data.push(fromFleet)
                    }
                } else {
                    set.data.push(reports[i][LOAD_FLEET_KWH[set.label]]);
                }

                return set;
            });

            labels.push(reports[i][timestampDay] * 86400 * 1000);
        }

        handleSetChart(labels, datasets, resolution, [])
    }

    const populateChartWithRecordKwh = (reports, resolution) => {
        const { fleet, gridStack } = dataSetLabels;
        let labels = [];
        let datasets = chart.data.datasets.map(d => { d.data = []; return d });

        if (!reports.length) return;
        for (let i = reports.length - 1; i >= 0; i--) {
            datasets = datasets.map(set => {
                if (set.label === gridStack || set.label === fleet) {
                    const load = reports[i]['loadKwh'];
                    const fromPv = reports[i]['loadFromPvKwh'];
                    const fromBattery = reports[i]['loadFromBatteryKwh'];
                    const fromFleet = Math.min(reports[i]['gridOutgoingKwh'], load - fromPv - fromBattery);
                    const fromGrid = load - fromPv - fromBattery - fromFleet;
                    if (set.label === gridStack) {
                        set.data.push(fromGrid === 0 ? 0.000001 : fromGrid);
                    } else {
                        set.data.push(fromFleet)
                    }
                } else {
                    set.data.push(reports[i][LOAD_FLEET_KWH[set.label]]);
                }

                return set;
            });

            labels.push(reports[i][timestampSec] * 1000);
        }

        const end = reports[0][timestampSec];
        const start = reports[reports.length - 1][timestampSec];
        const annotation = end - start <= 90000 ? CONTRACT_ANNOTATION(end, timezone) : [];
        handleSetChart(labels, datasets, resolution, annotation)
    }

    const populateChartWithRecordW = (reports, resolution, update = false) => {
        const { fleet, gridStack } = dataSetLabels;
        let labels = update ? [...chart.data.labels] : [];
        let datasets = update ? [...chart.data.datasets] : chart.data.datasets.map(d => { d.data = []; return d });

        if (!reports.length) return;
        for (let i = reports.length - 1; i >= 0; i--) {
            datasets = datasets.map(set => {
                if (update)
                    set.data.shift();

                if (set.label === gridStack || set.label === fleet) {
                    const load = reports[i]['loadW'];
                    const fromPv = reports[i]['loadFromPvW'];
                    const fromBattery = reports[i]['loadFromBatteryW'];
                    const fromFleet = Math.min(reports[i]['gridOutgoingW'], load - fromPv - fromBattery);
                    const fromGrid = load - fromPv - fromBattery - fromFleet;
                    if (set.label === gridStack) {
                        set.data.push(fromGrid === 0 ? 0.000001 : fromGrid / 1000);
                    } else {
                        set.data.push(fromFleet / 1000)
                    }
                } else {
                    set.data.push(reports[i][LOAD_FLEET[set.label]] / 1000);
                }

                return set;
            });

            if (update)
                labels.shift();

            labels.push(reports[i][timestampSec] * 1000);
        }

        const annotation = CONTRACT_ANNOTATION(reports[0][timestampSec], timezone);
        handleSetChart(labels, datasets, resolution, annotation)
    }

    const getTimeFrame = () => {
        let timeFrame = data.timeFrame;
        if (timeFrame === TIME_FRAMES.select) {
            const { reports, resolution } = data;
            const reportsLen = reports.length;
            const { thirty_six_months, twelve_months, two_months, fifteen_days, one_week, twenty_four_hours } = TIME_FRAMES;

            switch (resolution) {
                case resolutions.day:
                    timeFrame = reportsLen <= 60 ? two_months : reportsLen <= 366 ? twelve_months : thirty_six_months;
                    break;
                case resolutions.half_hour:
                    timeFrame = reportsLen <= 50 ? twenty_four_hours : reportsLen <= 336
                        ? one_week : fifteen_days;
                    break;
                case resolutions.minute:
                    timeFrame = twenty_four_hours;
                    break;
                default:
                    break;
            }
        }

        if (fmData.hasFmData) {
            return TIME_FRAMES.fifteen_minutes;
        }

        return timeFrame;
    }

    const handleSetChart = (labels, datasets, resolution, annotation) => {
        const title = `${fleetChartTitles.load[0]} by ${resolution}` + (resolution === resolutions.day || resolution === resolutions.week ? ' in fleet timezone' : '');
        let label = resolution === resolutions.day || resolution === resolutions.half_hour ? 'Energy (kWh)' : 'Power (kW)';
        const timeFrame = getTimeFrame();
        const update = {
            data: { labels: labels, datasets: datasets },
            options: {
                ...chart.options,
                layout: { padding: { right: 12 } },
                plugins: {
                    ...chart.options.plugins,
                    annotation: { annotations: annotation },
                    title: { ...chart.options.plugins.title, text: title },
                    tooltip: {
                        ...chart.options.plugins.tooltip,
                        callbacks: {
                            ...tooltipTitleCallbacks(selectedTimezone),
                            afterTitle: function (context) {
                                let total = 0;
                                for (let i = 0; i < context.length; i++)
                                    if (context[i].raw !== null && context[i].raw !== undefined)
                                        total += context[i].raw;
                                return "Total: " + Math.round(total * 1000) / 1000;
                            }
                        }
                    },
                },
                scales: {
                    ...chart.options.scales,
                    y: { ...chart.options.scales.y, title: { ...chart.options.scales.y.title, text: label } },
                    x: xAxis(timeFrame, getTimeFormat(resolution), selectedTimezone)
                },
                onClick: (event, items, chart) => {
                    if (!!items.length) handleFmData(parseInt(chart.data.labels[items[0].index] / 1000));
                }
            }
        };

        update.options.plugins.zoom.zoom.drag.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        update.options.plugins.zoom.zoom.pinch.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        setChart(update);
    }

    useEffect(() => {
        if (fmData.hasFmData && !!fmData.reports.length) {
            populateChartWithRecordW(fmData.reports, resolutions.second)
        }
    }, [fmData.reports, selectedTimezone])

    useEffect(() => {
        if (!fmData.hasFmData && data.updated) {
            handleDataOnChange(data);
        }
    }, [data.reports])

    useEffect(() => {
        if (!!data.resolution && data.timeFrame === TIME_FRAMES.select) {
            handleDataOnChange(data);
        }
    }, [data.resolution, data.reports])

    useEffect(() => {
        if (!fmData.hasFmData) {
            handleDataOnChange(data);
        }
    }, [data.timeFrame, data.type, fmData.hasFmData, selectedTimezone])

    useEffect(() => {
        if (!!loadRef && !!loadRef.current) loadRef.current.update();
    }, [chart])

    return (
        <div className={cx("fleet-load-chart af-records-chart", !showPerformance ? 'fleet-records' : '')}>
            <Line
                id={fleetChartTitles.load[1]}
                ref={loadRef}
                data={chart.data}
                options={chart.options}
            />
        </div>
    )
}

export default LoadChart;
