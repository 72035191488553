/* Copyright Levelise Ltd 2019-2020 */
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const RangePicker = ({ checked, handleDateRange, setStartDate, setEndDate, startDate, endDate }) => {
    useEffect(() => {
        if (startDate && endDate && !checked) {
            handleDateRange(startDate, endDate);
        }
    }, [startDate, endDate, checked])

    return (
        <div className="range-picker">
            <DatePicker
                className="date-picker-input"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="From date"
                dateFormat="dd/MM/yyyy"
            />
            <DatePicker
                className="date-picker-input"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="To date"
                dateFormat="dd/MM/yyyy"
            />
        </div>
    );
};

export default RangePicker;