/* Copyright Levelise Ltd 2024 */
import React from 'react';
import './index.css';

function TabContainer({
	title,
	children,
	containerStyle,
	withSearchInput,
	searchTerm,
	handleOnChange,
	rightCornerComponent,
	...props
}) {
	return (
		<div className="add-customer-form-with-header" style={containerStyle} {...props}>
			<div className="add-customer-form-header">
				<div className="specification-form-title">{title}</div>
				{withSearchInput ? (
					<input
						id="dru-search-field"
						name="term"
						type="text"
						value={searchTerm}
						onChange={(e) => handleOnChange(e)}
						autoComplete="off"
						placeholder="Search..."
					/>
				) : null}
				{rightCornerComponent ? (rightCornerComponent) : null}
			</div>
			{children}
		</div>
	);
}

export default TabContainer;
