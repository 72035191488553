/* Copyright Levelise Ltd 2020-2024 */

export const OPERATING_MODE = {
    'g100-testing': 'G100 test',
    'oaas-self-consumption': 'OaaS self-consumption',
    'self-consumption': 'Self-consumption'
}

export const SETTLEMENT_METHOD = {
    1: 'Not on supply (1)',
    2: 'Non-half-hourly import and export (2)',
    3: 'Half-hourly import, non-half-hourly export (3)',
    4: 'Half-hourly import and export (4)',
    5: 'Grid services only (5)'
}

export const DRU_NETWORK_CONNECTION_TYPE = {
    0: '-- None --',
    1: 'Powerline (1)',
    2: 'Direct wired connection (2)',
    3: 'Wi-Fi extender/repeater (3)',
    4: 'Wi-Fi bridge (4)',
    5: '4G/5G (5)',
    10: 'Other (10)'
}

export const BATTERY_LOCATION = {
    0: '-- None --',
    1:	'Garage (1)',
    2:	'Loft (2)',
    3:	'Conservatory (3)',
    4:	'Inside main house (4)',
    5:	'Laundry (5)',
    6:	'Shed/outbuilding (6)',
    9:	'Outside (9)',
    10:	'Carport (10)',
    11:	'Veranda/open covered extension (11)',
    12:	'Under house (12)',
    13:	'Exterior wall of house (13)',
    14:	'Exterior wall of outbuilding (14)',
}

export const AUS_GRID_CONNECTION_TYPE = {
    0: '-- None --',
    20: 'SA Power Networks (20)',
    30: 'Ergon Energy Corporation (30)',
    31: 'ENERGEX (31)',
    40: 'Essential Energy (40)',
    41: 'Ausgrid (41)',
    43: 'Endeavour Energy (43)',
    60: 'Jemena Electricity Networks (60)',
    61: 'CitiPower (61)',
    62: 'Powercor Australia (62)',
    63: 'Ausnet Services DNSP (63)',
    64: 'United Energy Distribution (64)',
    70: 'Evoenergy (70)',
    85: 'TasNetworks (85)',
}

export const DNO = {
    0: 'Unknown',
    10: 'UKPN – Eastern (10)',
    11: 'Western PD - East Midlands (11)',
    12: 'UKPN – London (12)',
    13: 'SPEN - SP Manweb (13)',
    14: 'Western PD - West Midlands (14)',
    15: 'Northern PG - Northeast (15)',
    16: 'Electricity North West (16)',
    17: 'SSE - Scottish Hydro EPD (17)',
    18: 'SPEN - SP Distribution (18)',
    19: 'UKPN - South Eastern (19)',
    20: 'SSE - Southern EPD (20)',
    21: 'Western PD - South Wales (21)',
    22: 'Western PD - South West (22)',
    23: 'Northern PG - Yorkshire (23)',
    24: 'Independent PN - Envoy BUUK (24)',
    25: 'ESP Electricity (25)',
    26: 'Last Mile Electricity (26)',
    27: 'The Electricity NC - GTC BUUK (27)',
    28: 'Utility Assets (28)',
    29: 'Harlaxton EN (29)',
    30: 'Leep EN (30)',
    31: 'UKPD (31)',
    32: 'Energy Assets Networks (32)',
    33: 'Eclipse Power Networks (33)',
    34: 'Mua Electricity (34)',
    35: 'Fulcrum Electricity Assets (35)',
    36: 'Vattenfall Network (36)',
    37: 'Optimal Power Networks (37)',
    38: 'Indigo Power (38)'
}

export const AUS_GRID_CONNECTION_TYPE_FOR_DNO = {
    4720: AUS_GRID_CONNECTION_TYPE[20],
    4730: AUS_GRID_CONNECTION_TYPE[30],
    4731: AUS_GRID_CONNECTION_TYPE[31],
    4740: AUS_GRID_CONNECTION_TYPE[40],
    4741: AUS_GRID_CONNECTION_TYPE[41],
    4743: AUS_GRID_CONNECTION_TYPE[43],
    4760: AUS_GRID_CONNECTION_TYPE[60],
    4761: AUS_GRID_CONNECTION_TYPE[61],
    4762: AUS_GRID_CONNECTION_TYPE[62],
    4763: AUS_GRID_CONNECTION_TYPE[63],
    4764: AUS_GRID_CONNECTION_TYPE[64],
    4770: AUS_GRID_CONNECTION_TYPE[70],
    4785: AUS_GRID_CONNECTION_TYPE[85],
}

export const G59_GRID_CONNECTION_TYPE_FOR_DNO = {
    5910: DNO[10],
    5911: DNO[11],
    5912: DNO[12],
    5913: DNO[13],
    5914: DNO[14],
    5915: DNO[15],
    5916: DNO[16],
    5917: DNO[17],
    5918: DNO[18],
    5919: DNO[19],
    5920: DNO[20],
    5921: DNO[21],
    5922: DNO[22],
    5923: DNO[23]
}

export const G59_GRID_CONNECTION_TYPE = {
    // ...G59_GRID_CONNECTION_TYPE_FOR_DNO
    5924: DNO[24],
    5925: DNO[25],
    5926: DNO[26],
    5927: DNO[27],
    5928: DNO[28],
    5929: DNO[29],
    5930: DNO[30],
    5931: DNO[31],
    5932: DNO[32],
    5933: DNO[33],
    5934: DNO[34],
    5935: DNO[35],
    5936: DNO[36],
    5937: DNO[37],
    5938: DNO[38],
}

export const G83_GRID_CONNECTION_TYPE_FOR_DNO = {
    8310: DNO[10],
    8311: DNO[11],
    8312: DNO[12],
    8313: DNO[13],
    8314: DNO[14],
    8315: DNO[15],
    8316: DNO[16],
    8317: DNO[17],
    8318: DNO[18],
    8319: DNO[19],
    8320: DNO[20],
    8321: DNO[21],
    8322: DNO[22],
    8323: DNO[23]
}

export const G83_GRID_CONNECTION_TYPE = {    
    // ...G83_GRID_CONNECTION_TYPE_FOR_DNO
    8324: DNO[24],
    8325: DNO[25],
    8326: DNO[26],
    8327: DNO[27],
    8328: DNO[28],
    8329: DNO[29],
    8330: DNO[30],
    8331: DNO[31],
    8332: DNO[32],
    8333: DNO[33],
    8334: DNO[34],
    8335: DNO[35],
    8336: DNO[36],
    8337: DNO[37],
    8338: DNO[38]
}

export const GRID_CONNECTION_TYPE_BY_ID = (id) => {    
    return {
        [`${id}24`]: DNO[24],
        [`${id}25`]: DNO[25],
        [`${id}26`]: DNO[26],
        [`${id}27`]: DNO[27],
        [`${id}28`]: DNO[28],
        [`${id}29`]: DNO[29],
        [`${id}30`]: DNO[30],
        [`${id}31`]: DNO[31],
        [`${id}32`]: DNO[32],
        [`${id}33`]: DNO[33],
        [`${id}34`]: DNO[34],
        [`${id}35`]: DNO[35],
        [`${id}36`]: DNO[36],
        [`${id}37`]: DNO[37],
        [`${id}38`]: DNO[38],
    }
}

export const GSP_GROUP = {
    'None' : '-- None --',
    'A': 'East England (_A)',
    'B': 'East Midlands (_B)',
    'C': 'London (_C)',
    'D': 'Merseyside, North Wales and Cheshire (_D)',
    'E': 'West Midlands (_E)',
    'F': 'North East England (_F)',
    'G': 'North West England (_G)',
    'H': 'Southern England (_H)',
    'J': 'South East England (_J)',
    'K': 'South Wales (_K)',
    'L': 'South West England (_L)',
    'M': 'Yorkshire (_M)',
    'N': 'South of Scotland (_N)',
    'P': 'North of Scotland (_P)'
}

export const GSP_GROUP_ID_NAME = {
    10: 'A',
    11: 'B',
    12: 'C',
    13: 'D',
    14: 'E',
    15: 'F',
    16: 'G',
    17: 'P',
    18: 'N',
    19: 'J',
    20: 'H',
    21: 'K',
    22: 'L',
    23: 'M'
}

export const GSP_GROUP_ID = { 
    // "G59/G99" or "G83/G98"
    'A': [60, 84],
    'B': [61, 85],
    'C': [62, 86],
    'D': [63, 87],
    'E': [64, 88],
    'F': [65, 89],
    'G': [66, 90],
    'P': [67, 91],
    'N': [68, 92],
    'J': [69, 93],
    'H': [70, 94],
    'K': [71, 95],
    'L': [72, 96],
    'M': [73, 97]
}


export const G59_DNO = (gsp) => {
    const id = GSP_GROUP_ID[gsp][0]
    return { ...G59_GRID_CONNECTION_TYPE_FOR_DNO, 
             ...GRID_CONNECTION_TYPE_BY_ID(id)};
}

export const G83_DNO = (gsp) => {
    const id = GSP_GROUP_ID[gsp][1]
    return { ...G83_GRID_CONNECTION_TYPE_FOR_DNO, 
             ...GRID_CONNECTION_TYPE_BY_ID(id)};
}

export const UK_G59_GRID_CONNECTION_TYPE = {
    ...G59_GRID_CONNECTION_TYPE,
    ...G59_GRID_CONNECTION_TYPE_FOR_DNO,
    ...GRID_CONNECTION_TYPE_BY_ID(60),
    ...GRID_CONNECTION_TYPE_BY_ID(61),
    ...GRID_CONNECTION_TYPE_BY_ID(62),
    ...GRID_CONNECTION_TYPE_BY_ID(63),
    ...GRID_CONNECTION_TYPE_BY_ID(64),
    ...GRID_CONNECTION_TYPE_BY_ID(65),
    ...GRID_CONNECTION_TYPE_BY_ID(66),
    ...GRID_CONNECTION_TYPE_BY_ID(67),
    ...GRID_CONNECTION_TYPE_BY_ID(68),
    ...GRID_CONNECTION_TYPE_BY_ID(69),
    ...GRID_CONNECTION_TYPE_BY_ID(70),
    ...GRID_CONNECTION_TYPE_BY_ID(71),
    ...GRID_CONNECTION_TYPE_BY_ID(72),
    ...GRID_CONNECTION_TYPE_BY_ID(73)
}

export const UK_G83_GRID_CONNECTION_TYPE = {
    ...G83_GRID_CONNECTION_TYPE,
    ...G83_GRID_CONNECTION_TYPE_FOR_DNO,
    ...GRID_CONNECTION_TYPE_BY_ID(84),
    ...GRID_CONNECTION_TYPE_BY_ID(85),
    ...GRID_CONNECTION_TYPE_BY_ID(86),
    ...GRID_CONNECTION_TYPE_BY_ID(87),
    ...GRID_CONNECTION_TYPE_BY_ID(88),
    ...GRID_CONNECTION_TYPE_BY_ID(89),
    ...GRID_CONNECTION_TYPE_BY_ID(90),
    ...GRID_CONNECTION_TYPE_BY_ID(91),
    ...GRID_CONNECTION_TYPE_BY_ID(92),
    ...GRID_CONNECTION_TYPE_BY_ID(93),
    ...GRID_CONNECTION_TYPE_BY_ID(94),
    ...GRID_CONNECTION_TYPE_BY_ID(95),
    ...GRID_CONNECTION_TYPE_BY_ID(96),
    ...GRID_CONNECTION_TYPE_BY_ID(97)
}


export const GRID_CONNECTION_TYPE = {
    ...AUS_GRID_CONNECTION_TYPE_FOR_DNO,
    ...UK_G59_GRID_CONNECTION_TYPE,
    ...UK_G83_GRID_CONNECTION_TYPE
}
