/* Copyright Levelise Ltd 2024 */
import React, { useState } from 'react';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.css';

function ExpandableListItem({className, childrenClassName, title, children, collapsedValueDisplay, expandByDefault = true}) {
    const [expand, setExpand] = useState(expandByDefault);
	
	return (
		<>
			<li className={className || ''} style={{ borderBottomWidth: expand ? 0 : 1 }}>
				<span
					className="strong expandable"
					onClick={() => setExpand(!expand)}
					style={{ cursor: 'pointer', fontSize: '0.8rem' }}
					data-cy="common-collapsable-list"
				>
					{expand ? (
						<FontAwesomeIcon
							icon={faChevronDown}
							style={{ marginRight: 5, fontSize: 10, marginBottom: 1, color: '#444444' }}
						/>
					) : (
						<FontAwesomeIcon
							icon={faChevronRight}
							style={{ marginRight: 5, fontSize: 10, marginBottom: 1, color: '#444444' }}
						/>
					)}
					{title}
				</span>
				<span className="em expandable">{!collapsedValueDisplay && collapsedValueDisplay !== 0 ? '' : collapsedValueDisplay} </span>
			</li>
			{expand ? (
				<ul className={`collapsable-list-common ${childrenClassName || ''}`}>
					{children}
				</ul>
			) : null}
		</>
	);
}

export default ExpandableListItem;
