/* Copyright Levelise Ltd 2024 */
import React from 'react';
import TabContainer from '../TabContainer';
import CustomerInfoTab from '../CustomerInfoTab';

function FacilityAddressCoT({ customer }) {
	return (
		<TabContainer title="Address">
			<CustomerInfoTab customer={customer} addressOnly />
		</TabContainer>
	);
}

export default FacilityAddressCoT;
