/* Copyright Levelise Ltd 2019-2024 */
import React, { useState, useEffect, useContext, useRef } from 'react';
import FleetContext from '../../contexts/FleetContext';
import { SEVERITY, SEVERITY_COLOR, STATUS } from '../../utils/constants';
import './index.css';
import DrusListHeader from '../DrusListHeader';
import ClickableListItem from '../ClickableListItem';

const Facilities = ({ facilities, directTo }) => {
	const context = useContext(FleetContext);
	const [show, setShow] = useState('druId');
	const selectOptions = ['druId', 'name', 'id'];
	const [searchTerm, setSearchTerm] = useState('');
	const [facilityList, setFacilityList] = useState([]);

	const ref = useRef(null);

	const includes = (facility) => {
		if (!searchTerm.length) return true;
		// else...
		const match = searchTerm.toLowerCase();
		const [druId, name, id, statuses, faults] = facility;

		if (show === selectOptions[0] && String(druId).includes(match)) return true;
		if (show === selectOptions[1] && name?.toLowerCase()?.includes(match)) return true;
		if (show === selectOptions[2] && String(id).includes(match)) return true;
		if (statuses.join(', ').toLowerCase().includes(match)) return true;
		if (
			faults
				.map((fault) => fault.description)
				.join(', ')
				.toLowerCase()
				.includes(match)
		)
			return true;
		return false;
	};

	const getFacilities = () => {
		return facilityList.filter((facility) => includes(facility));
	};

	const createList = () => {
		return getFacilities().map((facility, i) => {
			const [druId, name, id, statuses, faults] = facility;
			faults.sort((a, b) => b.severity - a.severity);
			return (
				<ClickableListItem className="facility-item" functionArg={name} clickHandler={directTo} key={i}>
					<span style={{ fontWeight: 600 }}>
						{show === selectOptions[0] &&
							`${druId}${!!statuses && !!statuses.length ? ' - ' + statuses.join(', ') : ''}`}
						{show === selectOptions[1] &&
							`${name}${!!statuses && !!statuses.length ? ' - ' + statuses.join(', ') : ''}`}
						{show === selectOptions[2] &&
							`${id}${!!statuses && !!statuses.length ? ' - ' + statuses.join(', ') : ''}`}
					</span>
					<span>
						{!!faults &&
							faults.map((fault, j) => (
								<span
									key={j}
									style={{
										display: 'list-item',
										paddingTop: 3,
										color: SEVERITY_COLOR[fault.level].backgroundSeverity,
									}}
								>
									{fault.description}
								</span>
							))}
					</span>
				</ClickableListItem>
			);
		});
	};

	const handleOnChange = (e) => {
		e.preventDefault();
		setSearchTerm(e.target.value);
	};

	const drusObj = (status) => {
		const faultCodes = context.faultCodes;
		const faultCodeDrus = status['faultCode'];
		const druObj = {};
		const drus = context.currentDrus;
		for (const [key, field] of Object.entries(STATUS)) {
			switch (key) {
				case 'Unsafe':
				case 'Unoptimisable':
				case 'Diminished':
				case 'Faulty':
				case 'Glitchy':
				case 'Noted':
					const severestFaultDrus = status['severestFault'][field];
					if (!!severestFaultDrus.length) {
						Object.keys(faultCodeDrus).forEach((faultCode) => {
							if (!!faultCodes.hasOwnProperty(faultCode)) {
								faultCodeDrus[faultCode].forEach((dru) => {
									if (
										(drus === null || drus.indexOf(Number(dru))) >= 0 &&
										severestFaultDrus.indexOf(Number(dru)) >= 0
									) {
										if (!!druObj[dru]) {
											druObj[dru].faults.push(faultCodes[faultCode]);
										} else {
											druObj[dru] = { statuses: [key], faults: [faultCodes[faultCode]] };
										}
									}
								});
							}
						});
					}
					break;
				case 'No Facility':
				case 'Retired':
				case 'Not registered':
					status[field].forEach((dru) => {
						if (drus === null || drus.indexOf(Number(dru)) >= 0) {
							if (!!druObj[dru]) {
								druObj[dru].faults.push({ severity: 0, level: 'Inactive', description: key });
							} else {
								druObj[dru] = {
									statuses: ['Inactive'],
									faults: [{ severity: 0, level: 'Inactive', description: key }],
								};
							}
						}
					});
					break;
				default:
					status[field].forEach((dru) => {
						if (drus === null || drus.indexOf(Number(dru)) >= 0) {
							if (!!druObj[dru]) {
								druObj[dru].statuses.push(key);
							} else {
								druObj[dru] = { statuses: [key], faults: [] };
							}
						}
					});
			}
		}

		return druObj;
	};

	const buildList = (druStatus) => {
		const list = JSON.parse(JSON.stringify(facilities));
		if (!context.showStatus || !Object.keys(druStatus).length) {
			return list
				.filter((facility) => druStatus.hasOwnProperty(facility.druId))
				.map((facility) => [facility.druId, facility.name, facility.id, [], []]);
		}
		// else...
		const severityLevels = Object.values(SEVERITY);
		return list
			.filter((facility) => druStatus.hasOwnProperty(facility.druId))
			.map((facility) => {
				const { statuses, faults } = druStatus[facility.druId];
				if (!!faults.length) {
					if (statuses.find((state) => severityLevels.includes(state)) === 'Noted') {
						const index = statuses.indexOf('Noted');
						if (index > -1) statuses.splice(index, 1);
						statuses.unshift('OK');
					}
				}

				return [facility.druId, facility.name, facility.id, statuses, faults];
			});
	};

	useEffect(() => {
		if (context.afStatus.hasOwnProperty('combined')) {
			const obj = drusObj(context.afStatus['combined']);
			const list = buildList(obj);
			setFacilityList(list);
		}
	}, [context.currentDrus]);

	return (
		<div className={context.showStatus ? 'list-wrapper' : 'list-wrapper-extended'}>
			<DrusListHeader
				classname="facility-list"
				title={show === selectOptions[0] ? 'DRUs' : 'Facilities'}
				searchTerm={searchTerm}
				onSearchTermChange={(e) => handleOnChange(e)}
				searchInputId="facility-search-field"
			/>
			<ul className="facility-select-options">
				<li
					id="dru"
					className={show === selectOptions[0] ? 'selected-option' : 'unselected-option'}
					onClick={() => setShow(selectOptions[0])}
				>
					DRU ID
				</li>
				<li
					id="name"
					className={show === selectOptions[1] ? 'selected-option' : 'unselected-option'}
					onClick={() => setShow(selectOptions[1])}
				>
					NAME
				</li>
				<li
					id="id"
					className={show === selectOptions[2] ? 'selected-option' : 'unselected-option'}
					onClick={() => setShow(selectOptions[2])}
				>
					ID
				</li>
				<li id="right-space"></li>
			</ul>
			<ul ref={ref} className={context.showStatus ? 'facilities' : 'facilities-extended'}>
				{createList()}
				<li
					key={'lastFacilityListItem'}
					className="facility-item"
					style={{
						minHeight: 26,
					}}
				>
					<span></span>
				</li>
			</ul>
		</div>
	);
};

export default Facilities;
