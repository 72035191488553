/* Copyright Levelise Ltd 2021-2022 */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import cx from 'classnames';
import FleetContext from '../../../contexts/FleetContext';
import {
    TIME_FRAMES,
    CONNECTED_DRUS,
    timestampSec,
    timestampDay,
    resolutions
} from '../../../utils/constants';
import {
    CONTRACT_ANNOTATION,
    allConnected,
    minConnected,
    maxConnected,
    meanConnected,
    getTimeFormat,
    dataset,
    options,
    fleetChartTitles,
    xAxis,
    tooltipTitleCallbacks
} from '../../../utils/chart';


const x = { type: 'time' }
const x1 = null
const y = {
    stacked: false,
    ticks: {},
    label: 'Count',
    gridLines: { drawOnChartArea: true }
}
const y1 = null
const legendLabels = {
    font: { size: 10 }, 
    color: 'black',
    usePointStyle: true,
    boxWidth: 4,
    boxHeight: 4,
    padding: 8,
    generateLabels: function (chart) {
        const allHidden = chart.data.datasets[0].hidden;
        if (allHidden) {
            const ls = ['Mean', 'Full Range'];
            const fs = [meanConnected.backgroundColor, maxConnected.backgroundColor];
            const ss = [meanConnected.borderColor, maxConnected.borderColor];
            return ls.map((label, i) => {
                return {
                    text: label,
                    fillStyle: fs[i],
                    hidden: false,
                    lineCap: '',
                    lineDash: [],
                    lineDashOffset: 0,
                    lineJoin: '',
                    lineWidth: 1,
                    strokeStyle: ss[i]
                }
            });
        }
        return [{
            text: allConnected.type,
            fillStyle: allConnected.backgroundColor,
            hidden: false,
            lineCap: '',
            lineDash: [],
            lineDashOffset: 0,
            lineJoin: '',
            lineWidth: 1,
            strokeStyle: allConnected.borderColor
        }];
    }
}

const ConnectedByBsChart = ({ fmData, handleFmData, data, timezone, selectedTimezone, showPerformance }) => {
    const context = useContext(FleetContext);
    const connectedBsRef = useRef();
    const [chart, setChart] = useState({
        data: {
            labels: [],
            datasets: [
                dataset('line', allConnected.type, allConnected.backgroundColor, allConnected.borderColor, 'y'),
                dataset('line', meanConnected.type, meanConnected.backgroundColor, meanConnected.borderColor, 'y'),
                dataset('line', maxConnected.type, maxConnected.backgroundColor, maxConnected.borderColor, 'y', '+1',
                    0, 0, 0, 0, 0.1, 0, 0, 0),
                dataset('line', minConnected.type, minConnected.backgroundColor, minConnected.borderColor, 'y', false,
                    0, 0, 0, 0, 0.1, 0, 0, 0),
            ]
        },
        options: options(fleetChartTitles.connectedBs[0], legendLabels, x, x1, y, y1)
    });

    const handleDataOnChange = (data) => {
        let resolution = data.resolution;
        if (data.timeFrame !== TIME_FRAMES.select) {
            switch (data.timeFrame) {
                case TIME_FRAMES.fifteen_minutes:
                    resolution = resolutions.second;
                    break;
                case TIME_FRAMES.one_hour:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.six_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.twenty_four_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.one_week:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.twenty_one_days:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.three_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.twelve_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.thirty_six_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.all:
                    resolution = resolutions.week;
                    break;
                default:
                    break;
            }
        }

        switch (resolution) {
            case resolutions.week:
            case resolutions.day:
                populateChartByDay(data.connected, resolution, context.selectedBatterySystemType);
                break;
            case resolutions.half_hour:
                populateChartByMinuteAndHalfHour(data.connected, resolution, context.selectedBatterySystemType);
                break;
            case resolutions.minute:
                populateChartByMinuteAndHalfHour(data.connected, resolution, context.selectedBatterySystemType);
                break;
            case resolutions.second:
                populateChartBySecond(data.connected, resolution, context.selectedBatterySystemType, data.updated);
                break;
            default:
                break;
        }
    }

    const populateChartByDay = (reports, resolution, type) => {
        let labels = [];
        let datasets = chart.data.datasets.map(d => {
            d.data = [];
            return d;
        });

        if (!reports.length) return;
        for (let i = reports.length - 1; i >= 0; i--) {
            datasets = datasets.map(set => {
                if (set.label === allConnected.type) {
                    set.hidden = true;
                } else {
                    set.hidden = false;
                    if (reports[i]['byType'].hasOwnProperty(type)) {
                        set.data.push(reports[i]['byType'][type][CONNECTED_DRUS[set.label]]);
                    } else {
                        set.data.push(null);
                    }
                }
                return set;
            });

            labels.push(reports[i][timestampDay] * 86400 * 1000);
        }

        handleSetChart(type, labels, datasets, resolution, []);
    }

    const populateChartByMinuteAndHalfHour = (reports, resolution, type) => {
        let labels = [];
        let datasets = chart.data.datasets.map(d => {
            d.data = [];
            return d;
        });

        if (!reports.length) return;
        for (let i = reports.length - 1; i >= 0; i--) {
            datasets = datasets.map(set => {
                if (set.label === allConnected.type) {
                    set.hidden = true;
                } else {
                    set.hidden = false;
                    if (reports[i]['byType'].hasOwnProperty(type)) {
                        set.data.push(reports[i]['byType'][type][CONNECTED_DRUS[set.label]]);
                    } else {
                        set.data.push(null);
                    }
                }
                return set;
            });

            labels.push(reports[i][timestampSec] * 1000);
        }

        const end = reports[0][timestampSec];
        const start = reports[reports.length - 1][timestampSec];
        const annotation = end - start <= 90000 ? CONTRACT_ANNOTATION(end, timezone, true) : [];
        handleSetChart(type, labels, datasets, resolution, annotation);
    }

    const populateChartBySecond = (reports, resolution, type, update = false) => {
        let labels = [];
        let datasets = chart.data.datasets.map(d => { d.data = []; return d });

        if (!reports.length) return;
        for (let i = reports.length - 1; i >= 0; i--) {
            datasets = datasets.map(set => {
                if (set.label !== allConnected.type) {
                    set.hidden = true;
                } else {
                    set.hidden = false;
                    if (reports[i]['byType'].hasOwnProperty(type)) {
                        set.data.push(reports[i]['byType'][type]);
                    } else {
                        set.data.push(null);
                    }
                }

                return set;
            });

            labels.push(reports[i][timestampSec] * 1000);
        }

        const annotation = CONTRACT_ANNOTATION(reports[0][timestampSec], timezone);
        handleSetChart(type, labels, datasets, resolution, annotation);
    }

    const getTimeFrame = () => {
        let timeFrame = data.timeFrame;
        if (timeFrame === TIME_FRAMES.select) {
            const { connected, resolution } = data;
            const reportsLen = connected.length;
            const { thirty_six_months, twelve_months, two_months, fifteen_days, one_week, twenty_four_hours } = TIME_FRAMES;

            switch (resolution) {
                case resolutions.day:
                    timeFrame = reportsLen <= 60 ? two_months : reportsLen <= 366 ? twelve_months : thirty_six_months;
                    break;
                case resolutions.half_hour:
                    timeFrame = reportsLen <= 50 ? twenty_four_hours : reportsLen <= 336
                        ? one_week : fifteen_days;
                    break;
                case resolutions.minute:
                    timeFrame = twenty_four_hours;
                    break;
                default:
                    break;
            }
        }

        if (fmData.hasFmData) {
            return TIME_FRAMES.fifteen_minutes;
        }

        return timeFrame;
    }

    const handleSetChart = (type, labels, datasets, resolution, annotation) => {
        const timeFrame = getTimeFrame();
        let title = !!type ? `${context.batterySystems[type]} ${fleetChartTitles.connectedBs[0]}` : fleetChartTitles.connectedBs[0];
        title += resolution === resolutions.day || resolution === resolutions.week ? ` by ${resolution} in fleet timezone` : ` by ${resolution}`;

        const update = {
            data: { labels: labels, datasets: datasets },
            options: {
                ...chart.options,
                layout: { padding: { right: 12 } },
                plugins: {
                    ...chart.options.plugins,
                    title: { ...chart.options.plugins.title, text: title },
                    annotation: { annotations: annotation },
                    tooltip: {
                        ...chart.options.plugins.tooltip,
                        callbacks: {
                            ...tooltipTitleCallbacks(selectedTimezone),
                            label: function (tooltipItem) {
                                let label = tooltipItem.dataset.label || '';
                                switch (label) {
                                    case allConnected.type:
                                    case meanConnected.type:
                                        return `${label}: ${tooltipItem.formattedValue}`;
                                    case maxConnected.type:
                                        let lower = datasets[3].data[tooltipItem.dataIndex];
                                        let upper = tooltipItem.formattedValue;
                                        return `Full Range: ${lower} — ${upper}`;
                                    default:
                                        return
                                }
                            },
                        }
                    }
                },
                scales: {
                    ...chart.options.scales,
                    x: xAxis(timeFrame, getTimeFormat(resolution), selectedTimezone)
                },
                onClick: (event, items, chart) => {
                    if (!!items.length) handleFmData(parseInt(chart.data.labels[items[0].index] / 1000));
                }
            }
        };

        update.options.plugins.zoom.zoom.drag.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        update.options.plugins.zoom.zoom.pinch.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        setChart(update);
    }

    useEffect(() => {
        if (fmData.hasFmData && !!fmData.connected.length) {
            populateChartBySecond(fmData.connected, resolutions.second, context.selectedBatterySystemType);
        }
    }, [fmData.connected, selectedTimezone])

    useEffect(() => {
        if (data.updated) {
            handleDataOnChange(data);
        }
    }, [data.connected])

    useEffect(() => {
        if (!!data.resolution && data.timeFrame === TIME_FRAMES.select) {
            handleDataOnChange(data);
        }
    }, [data.resolution, data.connected])

    useEffect(() => {
        if (!fmData.hasFmData) {
            handleDataOnChange(data);
        }
    }, [data.timeFrame, data.type, context.selectedBatterySystemType, fmData.hasFmData, selectedTimezone])

    return (
        <div className={cx("fleet-system-connected-chart af-records-chart", !showPerformance ? 'fleet-records' : '')}>
            <Line
                id={fleetChartTitles.connectedBs[1]}
                ref={connectedBsRef}
                data={chart.data}
                options={chart.options}
            />
        </div>
    )
}

export default ConnectedByBsChart;
