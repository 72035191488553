/* Copyright Levelise Ltd 2019-2024 */
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, SpecInput, SpecLabel } from '../Form';
import FleetService from '../../services/fleet-service';
import { convertArrayToObject, validateEmail } from '../../utils/utils';
import { ROLES } from '../../utils/constants';

const CustomerUsersList = ({
	users,
	customer,
	editing,
	onCheckboxClick,
	updateCustomerCheck,
	usersObject,
	handleEmailChanges,
}) => {
	const [user, setUser] = useState(null);
	const [otherUsers, setOtherUsers] = useState(null);
	const [installerUsers, setInstallerUsers] = useState(null);
	const [installerAdmins, setInstallerAdmins] = useState(null);
	const [admins, setAdmins] = useState(null);

	useEffect(() => {
		const userTemp = users?.filter((ele) => ele.email === customer?.email && ele.role === ROLES.user);
		setUser(userTemp?.length > 0 ? userTemp[0] : null);

		const otherUsersTemp = users?.filter(
			(ele) => ele.email !== customer?.email && ele.role === ROLES.user && !ele.username?.startsWith('D_')
		);
		setOtherUsers(otherUsersTemp?.length > 0 ? otherUsersTemp : null);

		const installerUsersTemp = users?.filter((ele) => ele.role === ROLES.installer);
		setInstallerUsers(installerUsersTemp?.length > 0 ? installerUsersTemp : null);

		const installerAdminsTemp = users?.filter((ele) => ele.role === ROLES.installerAdmin);
		setInstallerAdmins(installerAdminsTemp?.length > 0 ? installerAdminsTemp : null);

		const adminsTemp = users?.filter((ele) => ele.role === ROLES.admin);
		setAdmins(adminsTemp?.length > 0 ? adminsTemp : null);
	}, [users, customer]);

	return (
		<ul className="customer-info-container customer-users-container">
			{user ? (
				<li key={1}>
					<div className="facilites-inner-list-item">
						<span className="strong customer">User</span>
						<span className="em customer">
							{user?.forename || user?.surname ? user?.forename + ' ' + user?.surname : '—'}
						</span>
					</div>
					<div className="facilites-inner-list-item">
						<span className="strong customer"></span>
						<span className="em customer">
							{!editing ? (
								user && user?.email ? (
									user?.email
								) : (
									'—'
								)
							) : (
								<div className="email-input-checkbox-container">
									<SpecInput
										className="info-input-customer"
										name="idInput"
										type="email"
										placeholder="example@levelise.com"
										value={
											usersObject && usersObject[user?.username]?.email
												? usersObject[user?.username].email
												: ''
										}
										onChange={(e) => handleEmailChanges(e.target.value, user?.username)}
										style={{
											borderColor: validateEmail(
												usersObject && usersObject[user?.username]?.email
													? usersObject[user?.username].email
													: ''
											)
												? '#808080'
												: '#a81916',
										}}
									/>
									<div className="email-checkbox-lable-container">
										<SpecInput
											type="checkbox"
											id="updateCustomerCheckEmail"
											name="updateCustomerCheckEmail"
											className="update-user-email"
											onChange={onCheckboxClick}
											checked={updateCustomerCheck}
										/>
										<SpecLabel
											className="update-user-email-label"
											htmlFor="updateCustomerCheckEmail"
										>
											Update customer email
										</SpecLabel>
									</div>
								</div>
							)}
						</span>
					</div>
				</li>
			) : null}
			{otherUsers &&
				otherUsers.length > 0 &&
				otherUsers.map((otherUser, index) => (
					<li key={index + 'o'}>
						<div className="facilites-inner-list-item">
							<span className="strong customer">User</span>
							<span className="em customer">
								{otherUser?.forename || otherUser?.surname
									? otherUser?.forename + ' ' + otherUser?.surname
									: '—'}
							</span>
						</div>
						<div className="facilites-inner-list-item">
							<span className="strong customer"></span>
							<span className="em customer">
								{!editing ? (
									otherUser && otherUser?.email ? (
										otherUser?.email
									) : (
										'—'
									)
								) : (
									<div className="email-input-checkbox-container">
										<SpecInput
											className="info-input-customer"
											name="idInput"
											type="text"
											placeholder="example@example.com"
											value={
												usersObject && usersObject[otherUser?.username]?.email
													? usersObject[otherUser?.username].email
													: ''
											}
											onChange={(e) => handleEmailChanges(e.target.value, otherUser?.username)}
											style={{
												borderColor: validateEmail(
													usersObject && usersObject[otherUser?.username]?.email
														? usersObject[otherUser?.username].email
														: ''
												)
													? '#808080'
													: '#a81916',
											}}
										/>
									</div>
								)}
							</span>
						</div>
					</li>
				))}

			{installerUsers &&
				installerUsers.length > 0 &&
				installerUsers.map((installer, index) => (
					<li key={index + 'i'}>
						<div className="facilites-inner-list-item">
							<span className="strong customer">Installer</span>
							<span className="em customer">
								{installer?.forename || installer?.surname
									? installer?.forename + ' ' + installer?.surname
									: '—'}
							</span>
						</div>
						<div className="facilites-inner-list-item">
							<span className="strong customer"></span>
							<span className="em customer">{installer?.email ? installer.email : '—'}</span>
						</div>
					</li>
				))}

			{installerAdmins &&
				installerAdmins.length > 0 &&
				installerAdmins.map((installerAdmin, index) => (
					<li key={index + 'i'}>
						<div className="facilites-inner-list-item">
							<span className="strong customer">Installer Admin</span>
							<span className="em customer">
								{installerAdmin?.forename || installerAdmin?.surname
									? installerAdmin?.forename + ' ' + installerAdmin?.surname
									: '—'}
							</span>
						</div>
						<div className="facilites-inner-list-item">
							<span className="strong customer"></span>
							<span className="em customer">{installerAdmin?.email ? installerAdmin.email : '—'}</span>
						</div>
					</li>
				))}

			{admins &&
				admins.length > 0 &&
				admins.map((admin, index) => (
					<li key={index + 'i'}>
						<div className="facilites-inner-list-item">
							<span className="strong customer">Admin</span>
							<span className="em customer">
								{admin?.forename || admin?.surname ? admin?.forename + ' ' + admin?.surname : '—'}
							</span>
						</div>
					</li>
				))}
		</ul>
	);
};

const CustomerUsersSection = ({ customerUsers, customer, updateCustomerUsers, updateCustomer }) => {
	const [editing, setEditing] = useState(false);
	const [updateCustomerCheck, setUpdateCustomerCheck] = useState(false);
	const [usersObject, setUsersObject] = useState(null);
	const [loading, setLoading] = useState(false);

	const getUsersObject = (customerUsersArg) => {
		return convertArrayToObject(customerUsersArg, 'username');
	};

	const onCancelClick = () => {
		setInitialStates();

		setEditing(false);
	};

	const onEditClick = () => {
		setEditing(true);
	};

	const onCheckboxClick = () => {
		setUpdateCustomerCheck(!updateCustomerCheck);
	};

	const handleEmailChanges = (value, username) => {
		const obj = { ...usersObject };
		obj[username].email = value;
		setUsersObject(obj);
	};

	const handleUpdateUsers = async () => {
		if (!customerUsers || !usersObject) {
			return;
		}

		setLoading(true);

		for (const user in usersObject) {
			if (Object.hasOwn(usersObject, user)) {
				if (!validateEmail(usersObject[user]?.email)) {
					setLoading(false);
					return;
				}
			}
		}

		let newCustomerEmail;
		let formerCustomerEmail;
		const changedUsers = [];
		try {
			for (const user in usersObject) {
				if (Object.hasOwn(usersObject, user)) {
					const filteredUser = customerUsers.find((ele) => ele.username === user);
					if (
						filteredUser &&
						filteredUser.role === 'ROLE_USER' &&
						filteredUser?.email !== usersObject[user]?.email
					) {
						if (
							updateCustomerCheck &&
							filteredUser.email === customer?.email &&
							Object.hasOwn(customer, 'customerId')
						) {
							const res = await FleetService.updateCustomer(customer.customerId, {
								email: usersObject[user].email,
							});
							if (res) {
								formerCustomerEmail = customer.email;
								newCustomerEmail = usersObject[user].email;
							}
						}
						const updateUserRes = await FleetService.modifyUserEmail(user, {
							email: usersObject[user].email,
						});

						if (updateUserRes) {
							changedUsers.push(filteredUser);
						}
					}
				}
			}

			if (newCustomerEmail) {
				updateCustomer({ ...customer, email: newCustomerEmail });
			}

			if (changedUsers.length > 0) {
				updateCustomerUsers(Object.values(usersObject));
			}

			setLoading(false);
			setEditing(false);
		} catch (err) {
			let message = 'Something went wrong! Please try again.';
			// check changes
			if (newCustomerEmail && formerCustomerEmail) {
				try {
					const updateCustomerRes = await FleetService.updateCustomer(customer.customerId, {
						email: formerCustomerEmail,
					});
				} catch (error) {
					alert(
						'Something went wrong! Please make sure the customer email is set correctly. Please try again.'
					);
				}
			}

			if (changedUsers.length > 0) {
				try {
					for (let i = 0; i < changedUsers.length; i++) {
						const updateUserRes = await FleetService.modifyUserEmail(changedUsers[i].username, {
							email: changedUsers[i].email,
						});
					}
				} catch (error) {
					message =
						"Something went wrong! Please make sure all the uses' email are set correctly or reset correctly.";
				}
			}
			setLoading(false);
			alert(message);
		}
	};

	const setInitialStates = () => {
		if (customerUsers) {
			const usersTemp = JSON.parse(JSON.stringify(customerUsers));
			const obj = getUsersObject(usersTemp);
			setUsersObject(obj);

			const user = customer
				? customerUsers?.filter((ele) => ele.email === customer?.email && ele.role === 'ROLE_USER')
				: null;

			if (user && user?.length > 0) {
				setUpdateCustomerCheck(true);
			} else {
				setUpdateCustomerCheck(false);
			}
		}
	};

	useEffect(() => {
		setInitialStates();
	}, [customerUsers, customer]);

	return (
		<div className="left-side-container">
			<div className="customer-screen-headers" style={{ paddingBottom: 1 }}>
				<h2>Users</h2>
				<span className="right-side-component">
					<div className="customer-edit-button-container">
						{editing ? (
							<>
								<Button className="buttons-customer" onClick={onCancelClick}>
									Cancel
								</Button>
								<Button
									className="buttons-customer done-button-customer"
									disabled={loading}
									onClick={() => handleUpdateUsers()}
								>
									{loading ? 'Loading...' : 'Done'}
								</Button>
							</>
						) : (
							<Button className="buttons-customer" onClick={() => onEditClick()}>
								Edit
							</Button>
						)}
					</div>
				</span>
			</div>
			<CustomerUsersList
				updateCustomerCheck={updateCustomerCheck}
				onCheckboxClick={onCheckboxClick}
				editing={editing}
				users={customerUsers}
				customer={customer}
				usersObject={usersObject}
				handleEmailChanges={handleEmailChanges}
			/>
		</div>
	);
};

export default CustomerUsersSection;
