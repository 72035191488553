/* Copyright Levelise Ltd 2021 */

export const customStyles = () => {
    const windowWidth = window.screen.width;
    const borderStyle = '1px solid rgb(180, 180, 180)';
    const _width = windowWidth >= 660 ? 203 : 280;
    return {
        menu: (provided, state) => ({
            ...provided,
            marginTop: 4,
            marginBottom: 0,
            padding: 0,
            minWidth: _width,
            width: _width,
        }),
        container: (provided) => ({
            ...provided,
            minHeight: 1,
            width: 200,
            textAlign: 'left',
            border: 'none',
        }),
        control: (provided) => ({
            ...provided,
            minHeight: 1,
            height: 22,
            minWidth: _width,
            width: _width,
            border: borderStyle,
            boxShadow: 'none',
            padding: 0,
            '&:hover': {border: '1px solid grey'},
            '&:focus': {border: '1px solid grey'}
        }),
        input: (provided) => ({
            ...provided,
            margin: 0,
            paddingBottom: 0,
            paddingTop: 0,
            minHeight: 20,
            height: 20,
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            height: 20,
            minHeight: 1,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 2,
            paddingRight: 2,
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            height: 20,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 2,
            paddingRight: 2,
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            minHeight: 1,
            marginBottom: 4,
            marginTop: 4,
            height: 12,   
        }),
        clearIndicator: (provided) => ({
            ...provided,
            height: 20,
            minHeight: 0,
            paddingTop: 4.4,
            paddingBottom: 5.5,
            paddingLeft: 2,
            paddingRight: 4,
        }),
        valueContainer: (provided) => ({
            ...provided,
            minHeight: 1,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 4,
            paddingRight: 4,
        }),
        singleValue: (provided) => ({
            ...provided,
            minHeight: 0,
            paddingBottom: 0,
        }),
    };

}
