/* Copyright Levelise Ltd 2021 */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import './index.css';

const CollapsiblePanel = ({ children, ...props }) => {
    const { title, setCollapse, height } = props;
    const [isCollapse, setIsCollapse] = useState(true);
    const toggle = () => {
        setIsCollapse(!isCollapse);
        setCollapse(!isCollapse);
    }

    return (
        <div className='coll-panel' style={{ 'height': height }}>
            <div
                className={isCollapse ? 'coll-panel-title' : 'coll-panel-title coll-panel-title-collapsed'}
                onClick={() => toggle()}>
                <div>{title}</div>
                <FontAwesomeIcon
                    icon={isCollapse ? faAngleUp : faAngleDown}
                    style={{ fontSize: 15, marginLeft: 5, marginTop: 2 }} />
            </div>
            <div className={isCollapse ? 'coll-panel-component' : 'coll-panel-component coll-collapsed'}>
                {children}
            </div>
        </div>
    );
}

export default CollapsiblePanel;
