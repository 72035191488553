/* Copyright Levelise Ltd 2020-2022 */
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import AdminContext from '../../contexts/AdminContext';
import NavBar from '../../components/NavBar/index';
import SpecificationFrom from '../../components/SpecificationForm/index';
import { handleError } from '../../services/api-error-service';
import { routes } from '../../utils/constants';
import './index.css';


const ConfigurationRoute = () => {
    const adminContext = useContext(AdminContext);
    const navigate = useNavigate();
    const [error, setError] = useState();
    
    useEffect(() => {
        const error = adminContext.error;
        if (!!error && handleError(error)) {
            setError(error.status)
        }
    }, [adminContext.error])

    if (error) {
        navigate(`/error-page`);
    }

    return (
        <>
            <NavBar route={routes.configure_facility}/>
            <section className="configure-route">
                <div className="configure-form-container">
                    <SpecificationFrom />
                </div>
            </section>
        </>
    )
        
}

export default ConfigurationRoute;