/* Copyright Levelise Ltd 2024 */
import React from 'react';

const FacilityInfoTabCoT = ({
	facility,
	containerStyle,
}) => {
	const hasFacility = facility && !!Object.keys(facility).length;
	return (
		<ul className="customer-info-container" style={containerStyle}>
			<li>
				<span className="strong customer">ID</span>
				<span className="em customer">{hasFacility && facility?.id ? facility.id : '—'}</span>
			</li>
			<li>
				<span className="strong customer">Name</span>
				<span className="em customer">{hasFacility && facility?.name ? facility.name : '—'}</span>
			</li>
			<li>
				<span className="strong customer">DRU ID</span>
				<span className="em customer">{hasFacility && facility?.druId ? facility.druId : '—'}</span>
			</li>
			<li>
				<span className="strong customer"></span>
				<span className="em customer">&nbsp;</span>
			</li>
		</ul>
	);
};

export default FacilityInfoTabCoT;
