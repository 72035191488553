/* Copyright Levelise Ltd 2024 */
import React from 'react';
import { colors } from '../../utils/chart';
import { SpecInput } from '../Form';

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	inputAndUnitContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	errorMessage: {
		fontSize: 10,
		color: colors.errorRed,
		padding: 0,
		margin: 0,
        textAlign: 'start'
	},
};

const InputWithErrorMessageAndUnit = ({ errorMessage, unit, ...props }) => {
	return (
		<div style={styles.container}>
			<div style={styles.inputAndUnitContainer}>
				<SpecInput {...props} />
				{unit ? <span style={{ marginLeft: 5 }}>{unit}</span> : null}
			</div>
			{errorMessage ? <span style={styles.errorMessage}>{errorMessage}</span> : null}
		</div>
	);
};

export default InputWithErrorMessageAndUnit;
